import { format, isMatch, parse } from 'date-fns';
import { useQuery } from 'react-query';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import api from 'src/models/service/api';

import {
  ProjectsResponse,
  UseQueryProjectsProps,
  UseQueryProjectsReturn,
} from './types';

export const useQueryProjects = ({
  page = 1,
  searchValue = '',
  requestAllowed = true,
  onSuccess,
  limit = 8,
  projectType,
  engineName,
  engineVersion,
}: UseQueryProjectsProps): UseQueryProjectsReturn => {
  const translateFormat = useFormatDateLanguage();

  const keys: (string | number)[] = ['projects', searchValue];

  if (page) {
    keys.push(page);
  }

  if (projectType) {
    keys.push(projectType);
  }
  if (engineName) {
    keys.push(engineName);
  }
  if (engineVersion) {
    keys.push(engineVersion);
  }

  const { data, isLoading, isFetching, isError } = useQuery(
    keys,
    async () => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'MM/dd/yyyy',
        );
      }

      const queryParams = new URLSearchParams();

      if (searchValue.length >= 3) {
        if (isMatch(searchQuery, 'MM/dd/yyyy')) {
          queryParams.append('last_updated', searchQuery);
        } else {
          queryParams.append('project_name', searchValue);

          queryParams.append('y_series', searchValue);
        }
      }

      if (projectType) {
        queryParams.append('project_type', projectType);
      }
      if (engineName) {
        queryParams.append('engine_name', engineName);
      }
      if (engineVersion) {
        queryParams.append('engine_version_gte', engineVersion);
      }

      queryParams.append('skip', ((page - 1) * limit).toString());
      queryParams.append('limit', limit.toString());

      const response = await api.get<ProjectsResponse>(
        `/projects?${queryParams.toString()}`,
      );

      return response.data;
    },
    {
      onSuccess,
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
    },
  );

  return {
    projectsData: data,
    projectsIsLoading: isLoading || isFetching,
    projectsError: isError,
  };
};
