import styled from 'styled-components';

export const UploadYourSeriesMessageContainer = styled.div`
  margin: 0 auto;
  padding: 1.5rem 2rem;
  width: min-content;

  h3 {
    text-align: center;

    color: ${({ theme }) => theme.colors.gray6};
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }
`;

export const ContainerFakeTable = styled.div`
  position: relative;

  width: 25rem;

  margin-bottom: 3rem;

  > span {
    display: block;
    padding: 4px 1rem;
    background: ${({ theme }) => theme.colors.gray1};
    width: fit-content;
    border-radius: 9999px;

    color: ${({ theme }) => theme.colors.gray4};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;

    text-wrap: nowrap;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  table {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 0.5rem;
    border-spacing: 0rem;
    overflow: hidden;

    th {
      height: 2.5rem;
      background: ${({ theme }) => theme.colors.gray0};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }

    td {
      height: 3rem;

      color: ${({ theme }) => theme.colors.gray3};
      font-size: 0.75rem;
      font-weight: 500;

      border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};

      padding: 1rem;

      &:nth-child(2) {
        padding: 0.75rem 1rem;
      }

      div {
        margin-left: auto;
        padding: 0.25rem 0.75rem;
        width: fit-content;
        border-radius: 9999px;
        background: ${({ theme }) => theme.colors.gray0};
      }
    }

    tr:last-child {
      td {
        border-bottom: 0rem;
      }
    }
  }
`;
