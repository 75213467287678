import styled from 'styled-components';

export const OpenButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  position: fixed;
  right: 0;
  top: 45%;

  z-index: 1000;

  writing-mode: vertical-rl;

  padding: 1rem 0.5rem;

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
  border-left: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px 0rem 0rem 8px;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 8px 0px rgba(113, 128, 150, 0.24);

  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.gray6};

  transition: all 0.2s;

  svg {
    transform: rotate(90deg);
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray7};
  }
`;

type ContainerProps = {
  isOpen: boolean;
};

export const Container = styled.aside<ContainerProps>`
  width: 20rem;
  height: calc(100vh - 4.625rem);

  display: flex;
  flex-direction: column;

  position: fixed;
  right: 0;
  top: 4.625rem;

  z-index: 1000;

  padding: 1.5rem;

  border-right: 1px solid ${({ theme }) => theme.colors.gray2};

  background: ${({ theme }) => theme.colors.white};
  box-shadow: -8px 0px 13px 0px ${({ theme }) => theme.colors.gray2};

  transition: transform 1s;

  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(22rem)'};

  .filter-title-description {
    h2 {
      font-size: 1.5rem !important;
      font-weight: 700 !important;
      line-height: 2rem !important;

      color: ${({ theme }) => theme.colors.gray6} !important;
    }

    p {
      font-size: 0.875rem !important;
      font-weight: 400 !important;
      line-height: 1.375rem !important;

      color: ${({ theme }) => theme.colors.gray4} !important;
    }
  }
`;

export const CloseButton = styled.button`
  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 2.5rem;
  left: -1.05rem;

  padding: 0.5rem;

  color: ${({ theme }) => theme.colors.gray4};

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 100%;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: -8px 0px 13px 0px ${({ theme }) => theme.colors.gray2};

  transition: all 0.2s;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const FiltersContent = styled.div`
  height: fit-content;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1.5rem;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;

    margin-right: 10rem;
  }

  .loading-toggle {
    input {
      cursor: progress !important;
    }
  }
`;
