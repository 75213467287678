import styled from 'styled-components';

type FormContainerProps = {
  visible: boolean;
};

export const FormContainer = styled.form<FormContainerProps>`
  width: 22rem;

  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  position: absolute;
  top: 3.5rem;
  right: 50%;

  transform: translate(50%, 0);

  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;

  box-shadow: 0px 2px 16px 0px rgba(113, 128, 150, 0.24);

  padding: 1rem;

  z-index: 2;

  button,
  > div {
    width: 100%;
  }

  button {
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    > div {
      align-items: end;
    }
  }

  > div:last-of-type {
    height: 9.375rem;

    > div:last-of-type > div {
      height: 6rem;
    }
  }

  &::before {
    content: '';

    width: 1rem;
    height: 1rem;

    position: absolute;
    top: -0.5rem;
    right: 50%;

    transform: translate(50%, 0) rotate(45deg);

    background-color: ${({ theme }) => theme.colors.white};
  }
`;
