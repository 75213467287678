import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUserColor } from 'src/utils/colors/getUserColor';
import { Select } from 'src/components/Select';
import { User } from 'phosphor-react';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import {
  SelectOption,
  UserLabelProps,
  UserProps,
  UserSelectProps,
} from './types';
import { PlaceholderContainer, SelectContainer, UserInfo } from './styles';

const UserLabel: React.FC<UserLabelProps> = ({
  user,
  index,
  selected = false,
}) => (
  <UserInfo
    data-testid={`container-${selected ? 'selected-' : ''}option-${index}`}
  >
    <div
      data-testid="container-first-user-letter"
      style={{ background: getUserColor(index) }}
    >
      <p>{user[0]}</p>
    </div>

    <p>{user}</p>
  </UserInfo>
);

export const UserSelect: React.FC<UserSelectProps> = ({
  usersAlreadySelected,
  selectedUser,
  profile,
  ...rest
}) => {
  const { t: translate } = useTranslation();

  const { id } = useParams();

  const {
    data: userData,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useQuery(
    ['workspace users', id],
    async () => {
      const { data } = await apiWorkspace.get<UserProps[]>(
        `/workspaces/${id}/users`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id,
    },
  );

  const sortedUserData = useMemo(
    () =>
      userData
        ?.filter((user) => user.role !== 'reader')
        ?.sort((a, b) => (a.user > b.user ? 1 : a.user < b.user ? -1 : 0)),
    [userData],
  );

  const userOptions: SelectOption[] =
    sortedUserData?.map(({ user }, index) => ({
      label: <UserLabel user={user} index={index} />,
      value: user,
      isDisabled: usersAlreadySelected.includes(user) && user !== selectedUser,
    })) ?? [];

  return (
    <SelectContainer>
      <Select
        options={userOptions}
        value={
          selectedUser
            ? ({
                label: (
                  <UserLabel
                    user={selectedUser}
                    index={
                      sortedUserData?.findIndex(
                        ({ user }) => user === selectedUser,
                      ) ?? 0
                    }
                    selected
                  />
                ),
                value: selectedUser,
              } as SelectOption)
            : undefined
        }
        menuPosition="fixed"
        closeMenuOnScroll={(e) =>
          !(e.target as any).className.includes('select__menu-list')
        }
        placeholder={
          <PlaceholderContainer>
            <User />
            {translate('workspacePlanningFlowResponsible')}
          </PlaceholderContainer>
        }
        isClearable
        isLoading={isLoadingUser || isFetchingUser}
        style={{ width: '11rem' }}
        dataTestid={`select-${profile}-user`}
        {...rest}
      />
    </SelectContainer>
  );
};
