import styled, { css } from 'styled-components';

interface SelectLanguage {
  openModal: boolean;
}

export const SelectLanguage = styled.div<SelectLanguage>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;

  > svg:first-child {
    width: 1.688rem;
    height: 1.25rem;
  }

  > svg:nth-child(2) {
    margin-left: 0.25rem;
    color: ${({ theme }) => theme.colors.gray4};
    transition: color 0.2s;
    transform: ${({ openModal }) =>
      openModal ? 'rotate(180deg)' : 'rotate(0deg)'};

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }

    cursor: pointer;
  }
  > div {
    position: absolute;
  }
`;

interface MenuProps {
  visible: boolean;
}

export const Menu = styled.div<MenuProps>`
  margin-left: auto;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  width: 2.188rem;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 16px 32px rgba(97, 97, 97, 0.16);
  border-radius: 4px;

  position: absolute;
  top: 1.75rem;
  left: -0.25rem;
`;

interface MenuButtonProps {
  position: 'start' | 'middle' | 'end';
}

export const MenuButton = styled.button<MenuButtonProps>`
  width: 2.188rem;
  height: 1.75rem;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.colors.white};
  transition: 0.2s;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  z-index: 1001;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  border-radius: ${({ position }) =>
    position === 'start' ? '4px 4px 0 0' : position === 'end' && '0 0 4px 4px'};

  svg {
    width: 1.688rem;
    height: 1.25rem;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  :hover {
    background: rgba(76, 148, 255, 0.2);

    ${({ position, disabled }) =>
      !disabled
        ? position === 'start'
          ? css`
              border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
            `
          : position === 'middle'
          ? css`
              border-top: 1px solid ${({ theme }) => theme.colors.primary};
              border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
            `
          : position === 'end' &&
            css`
              border-top: 1px solid ${({ theme }) => theme.colors.primary};
            `
        : css`
            background: ${({ theme }) => theme.colors.gray1};
          `}
  }
`;

export const MenuOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;
