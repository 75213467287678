import styled from 'styled-components';

export const Container = styled.div`
  width: 30% !important;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
  }

  @media (max-height: 768px) {
    width: 35% !important;
  }
`;

export const ShareSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1rem;

  button {
    height: fit-content;
    padding: 0;

    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

type UserContentProps = {
  selectedRoleWidth?: number;
  isLoading?: boolean;
};

export const UserContent = styled.div<UserContentProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  position: relative;

  & + & {
    margin-top: 1.5rem;
  }

  > svg {
    width: 1.25rem;
    height: 1.25rem;

    color: ${({ theme }) => theme.colors.gray4};
  }

  .select__control,
  .select__control--is-focused {
    width: ${({ selectedRoleWidth }) => `${selectedRoleWidth}rem`};

    border: none !important;
    box-shadow: none !important;

    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};

    &:hover {
      border: none !important;
    }
  }

  .select__menu {
    width: 10.5rem;
    left: ${({ selectedRoleWidth }) => `${(selectedRoleWidth ?? 0) - 10.5}rem`};
  }

  .select__value-container {
    padding: 0 !important;
  }

  .select__indicator {
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }

  .select__single-value {
    cursor: pointer;

    margin: 0 !important;

    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};

    p,
    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  .select__dropdown-indicator {
    cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'pointer')};
  }

  .select__option {
    p {
      font-weight: 400;
    }
  }

  .select__option--is-selected {
    p {
      font-weight: 600;
    }
  }

  .select__option--is-disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1366px) {
    .select__control {
      width: 3.5rem;
    }

    .select__menu {
      left: -6.5rem;
    }

    .select__single-value {
      p {
        display: none;
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;

  flex: 1;

  > div:first-of-type {
    width: 1.75rem;
    height: 1.75rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    background-color: ${({ theme }) => theme.colors.primary};

    p {
      font-size: 0.75rem;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;

      color: ${({ theme }) => theme.colors.white};
    }
  }

  > p {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray5};

    flex: 1;

    word-break: break-all;
  }
`;

export const EmptyGuests = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    color: ${({ theme }) => theme.colors.gray5};

    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray4};
  }

  @media (max-width: 1366px) {
    img {
      height: 7rem;
    }
  }
`;

export const NoAccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.375rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;
