import styled, { css } from 'styled-components';

const STATUS_COLORS = {
  baseline: 'primary',
  adjusting: 'purple4',
  approved: 'teal4',
} as const;

const STATUS_BACKGROUND_COLORS = {
  baseline: 'rgba(76, 148, 255, 0.16)',
  adjusting: 'rgba(159, 122, 234, 0.16)',
  approved: 'rgba(56, 178, 172, 0.16)',
};

type ContainerProps = {
  status: keyof typeof STATUS_BACKGROUND_COLORS;
  selected: boolean;
  isDisabled: boolean;
  isConfigurationMode: boolean;
};

export const Container = styled.div<ContainerProps>`
  width: ${({ isConfigurationMode }) =>
    isConfigurationMode ? '11rem' : '9rem'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 0.5rem;

  margin-right: 3rem;

  > div:first-of-type {
    background: ${({ theme, isDisabled, status }) =>
      isDisabled ? theme.colors.gray1 : STATUS_BACKGROUND_COLORS[status]};

    svg {
      color: ${({ theme, isDisabled, status }) =>
        isDisabled ? theme.colors.gray4 : theme.colors[STATUS_COLORS[status]]};
    }
  }

  > div:nth-child(2) {
    svg {
      color: ${({ theme, isDisabled, status }) =>
        isDisabled ? theme.colors.gray4 : theme.colors[STATUS_COLORS[status]]};
    }
  }

  > p {
    font-size: 0.875rem;
    font-weight: ${({ selected }) => (selected ? 600 : 400)};
    line-height: 150%;
    color: ${({ theme, selected }) =>
      selected ? theme.colors.gray6 : theme.colors.gray4};
  }
`;

export const LineIcon = styled.div`
  font-size: 0;

  z-index: 1;

  background: white;
`;

export const Icon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border-radius: 5px;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const Button = styled.button`
  line-height: 0;
  padding: 0.5rem;

  svg {
    width: 1rem !important;
    height: 1rem !important;

    transition: all 0.2s;

    &:hover {
      filter: brightness(75%);
    }
  }
`;

export const AwaitingIcon = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  margin-top: 2.6rem;

  margin-left: -2.375rem;
  margin-right: 0.5rem;

  background: #ffffeb;
  border-radius: 100%;
  border: 1px dashed ${({ theme }) => theme.colors.yellow5};

  z-index: 2;

  svg {
    width: 0.75rem;
    height: 0.75rem;

    color: ${({ theme }) => theme.colors.yellow5};
  }

  transition: all 0.2s;

  &:hover {
    border: 1px dashed ${({ theme }) => theme.colors.yellow6};

    svg {
      color: ${({ theme }) => theme.colors.yellow6};
    }
  }
`;

type UsersContainerProps = {
  error: boolean;
};

export const UsersContainer = styled.div<UsersContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 0.5rem;

  label {
    width: 100%;

    display: block;

    font-size: 0.75rem;

    margin-bottom: 0.25rem;
  }

  > div + div {
    margin-top: 0.25rem;
  }

  ${({ error, theme }) =>
    error &&
    css`
      .select__control,
      .select__control:hover {
        border: 1px solid ${theme.colors.red2};
      }
    `}

  .button-rounded {
    position: relative !important;
    top: 0;
    right: 0;

    margin-top: 0.5rem;
  }
`;

type CalendarContainerProps = {
  error: boolean;
};

export const CalendarContainer = styled.div<CalendarContainerProps>`
  width: 100%;

  margin-top: 0.5rem;

  label {
    width: 100%;

    display: block;

    font-size: 0.75rem;

    margin-bottom: 0.25rem;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;

    > div {
      width: 100% !important;

      > div:first-of-type {
        margin-bottom: 0 !important;
      }

      > div:last-of-type {
        > div {
          ${({ error, theme }) =>
            error &&
            css`
              border: 1px solid ${theme.colors.red2};
            `}

          padding: 0.5rem 0.75rem !important;
        }

        > p {
          font-size: 0.625rem !important;
          margin-top: 0.25rem !important;
        }
      }
    }

    input {
      font-size: 0.75rem !important;
    }
  }

  .react-datepicker-popper {
    z-index: 2001;

    div {
      font-size: 0.75rem !important;
    }
  }

  .react-datepicker__navigation-icon::before {
    width: 8px;
    height: 8px;
  }

  .react-datepicker {
    width: 15.5rem;
  }

  .react-datepicker__day-name {
    margin-top: 0.5rem;
  }
`;

export const DeadlineInfo = styled.p`
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  line-height: 150% !important;

  color: ${({ theme }) => theme.colors.gray4} !important;

  margin-top: -0.25rem !important;
`;

export const UsersInfo = styled.div`
  p {
    font-size: 0.625rem;
    color: ${({ theme }) => theme.colors.gray4};

    text-align: center;
    white-space: pre-line;
    word-break: break-word;
  }

  margin-top: -0.25rem;
`;
