import React, { KeyboardEvent, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { TrashSimple } from '@phosphor-icons/react';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { ActionConfirmationModal } from 'src/workspaces/pages/Overview/components/Modals/ActionConfirmation';
import { PencilSimple } from 'phosphor-react';
import { getTextWidth } from 'src/utils/getTextWidth';

import {
  Container,
  CreatingNewStepContent,
  StepNameContainer,
  StepNameInput,
} from './styles';
import { StepProps } from './types';
import { PreviewStatus } from '../Status/Preview';
import { AdjustingStatus } from '../Status/Adjusting';
import { AwaitingApprovalStatus } from '../Status/AwaitingApproval';
import { ApprovedStatus } from '../Status/Approved';
import { PlanningFlowContext } from '..';

export const Step: React.FC<StepProps> = ({
  id,
  index,
  name,
  isCreatingStep,
  isLoadingNewStep,
}) => {
  const [showDeleteStepConfirmation, setShowDeleteStepConfirmation] =
    useState(false);

  const { t: translate } = useTranslation();

  const { userRole } = useSelector((state: RootState) => state.workspace);

  const {
    steps,
    selectedStep,
    isConfigurationMode,
    isPreviewConfiguration,
    handleDeleteLastStep,
    handleRenameStep,
  } = useContext(PlanningFlowContext);

  const inputStepNameId = `input-step-name-${id}`;

  const resizeInput = () => {
    const input = document.getElementById(inputStepNameId) as HTMLInputElement;

    input.style.width = `${
      getTextWidth(input.value, '500 12px Inter') / 16
    }rem`;
  };

  const handleFocusInput = () => {
    const input = document.getElementById(inputStepNameId) as HTMLInputElement;

    input.disabled = false;
    input.focus();
  };

  const handleDisableInput = () => {
    const input = document.getElementById(inputStepNameId) as HTMLInputElement;

    input.disabled = true;

    let newName = input.value.trim();

    if (!input.value) {
      newName = name;
      input.value = name;

      resizeInput();
    }

    handleRenameStep!(id, newName);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleDisableInput();
    }
  };

  useEffect(() => {
    resizeInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userIsManager = userRole === 'manager';

  const isLastStep = index === steps.length;
  const isFirstStep = index === 1;

  const step = steps[index - 1];

  const showDeleteStep =
    isLastStep &&
    userIsManager &&
    (!isFirstStep || isPreviewConfiguration) &&
    isConfigurationMode &&
    !isLoadingNewStep &&
    step.status !== 'approved';

  if (isCreatingStep) {
    return (
      <Container data-testid={`container-creating-step-${index}`}>
        <StepNameContainer>
          <StepNameInput value={name} disabled />
          <PencilSimple />
        </StepNameContainer>

        <CreatingNewStepContent>
          <ContainerSkeleton withLoading />

          <p>{translate('workspaceOverviewPlanningFlowCreatingStep')}</p>
        </CreatingNewStepContent>
      </Container>
    );
  }

  const stepIsSelected = selectedStep?.number === index;

  const lastStep = isFirstStep ? step : steps[index - 2];

  const previewIsDisabled = isPreviewConfiguration;
  const previewIsSelected =
    stepIsSelected &&
    (selectedStep.status === 'baseline' || selectedStep?.status === 'created');

  const allStatusIsDisabled =
    isPreviewConfiguration || step.status === 'created';

  const adjustingIsDisabled =
    isPreviewConfiguration ||
    ((step.status === 'created' || step.status === 'baseline') &&
      lastStep.status !== 'approved');

  const adjustingIsSelected =
    stepIsSelected && selectedStep.status === 'adjusting';

  const showAwaitingApprovalStatus = step.status === 'awaiting_approval';
  const awaitingApprovalIsSelected =
    stepIsSelected && selectedStep.status === 'awaiting_approval';

  const approvedIsDisabled = allStatusIsDisabled || step.status !== 'approved';
  const approvedIsSelected =
    stepIsSelected && selectedStep.status === 'approved';

  const canEdit = step.status !== 'approved';

  return (
    <Container data-testid={`container-step-${index}`}>
      <StepNameContainer>
        <StepNameInput
          id={`input-step-name-${id}`}
          defaultValue={name}
          onInput={resizeInput}
          onBlur={handleDisableInput}
          onKeyDown={handleKeyDown}
          disabled
          maxLength={35}
          data-testid="input-step-name"
        />

        {isConfigurationMode && canEdit && (
          <button
            type="button"
            onClick={handleFocusInput}
            data-testid="button-edit-step-name"
          >
            <PencilSimple />
          </button>
        )}
      </StepNameContainer>

      {isFirstStep && (
        <PreviewStatus
          stepId={id}
          isDisabled={previewIsDisabled}
          isSelected={previewIsSelected}
        />
      )}

      <AdjustingStatus
        stepId={id}
        isDisabled={adjustingIsDisabled}
        isSelected={adjustingIsSelected}
      />

      <AwaitingApprovalStatus
        stepId={id}
        isDisabled={!showAwaitingApprovalStatus}
        isSelected={awaitingApprovalIsSelected}
      />

      <ApprovedStatus
        stepId={id}
        isDisabled={approvedIsDisabled}
        isSelected={approvedIsSelected}
        currentApproverLevel={step.flow?.status?.approver}
      />

      {showDeleteStep && (
        <ButtonRounded
          type="button"
          onClick={() => setShowDeleteStepConfirmation(true)}
          icon={<TrashSimple />}
          disabled={!canEdit}
          data-testid="button-delete-step"
        />
      )}

      {showDeleteStepConfirmation && (
        <ActionConfirmationModal
          title={translate('workspaceOverviewDeleteStepConfirmationModalTitle')}
          description={translate(
            'workspaceOverviewDeleteStepConfirmationModalDescription',
          )}
          textToConfirm={name}
          onConfirm={handleDeleteLastStep!}
          setVisible={setShowDeleteStepConfirmation}
        />
      )}
    </Container>
  );
};
