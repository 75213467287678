import React, { ReactElement, createContext, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import ms from 'ms';

import apiWorkspace from '../service/api';

type IsSavingInformation = {
  type: 'information';
};

type IsSavingStep = {
  type: 'step';
  details: {
    stepNumber: number;
  };
};

type IsSavingUser = {
  type: 'user';
  details: {
    stepNumber: number;
    userNumber: number;
    profile: 'editor' | 'approver';
  };
};

type IsSaving = IsSavingInformation | IsSavingStep | IsSavingUser;

type PlanningHorizon = [Date | null, Date | null];

type Template = 'standard' | 'planning';

type UserProfile = 'approver' | 'editor';

type User = {
  user: string;
  profile: UserProfile;
  level?: number;
};

export type StepStatus =
  | 'created'
  | 'baseline'
  | 'adjusting'
  | 'awaiting_approval'
  | 'approved';

type Deadline = {
  type: 'editor' | 'approver';
  date: string;
};

type Step = {
  id: string;
  name: string;
  status: StepStatus;
  deadlines: Deadline[] | null;
  users: User[];
};

type SaveConfigurationReturn = {
  success: boolean;
};

type BasicInformationsForm = {
  name: string;
  description: string;
};

type PreviewUser = {
  profiles: {
    [email: string]: {
      type: UserProfile;
      level?: number;
    }[];
  };
};

type PreviewStep = {
  name: string;
  deadlines: Deadline[] | null;
  users: PreviewUser;
  created: string;
};

type ApprovalFlow = {
  horizon_dates?: {
    start: string;
    end: string;
  };
  enable: boolean;
};

export type PreviewData = {
  data: {
    steps: PreviewStep[] | null;
    approval_flow: ApprovalFlow | null;
  };
};

type StagingAreaData = {
  data: {
    label: string | null;
    description: string | null;
  };
};

export interface NewReleaseConfigurationContextType {
  selectedTemplate: Template;
  planningHorizon: PlanningHorizon;
  steps: Step[];
  isSaving?: IsSaving;
  showPlanningErrors: boolean;
  handleChangeTemplate: (template: Template) => void;
  handleChangePlanningHorizon: (planningHorizon: PlanningHorizon) => void;
  handleAddNewStep: () => void;
  handleDeleteLastStep: () => void;
  handleRenameStep: (stepId: string, newName: string) => void;
  handleAddUser: (
    stepId: string,
    email: string,
    profile: UserProfile,
    level?: number,
  ) => void;
  handleRemoveUser: (
    stepId: string,
    email: string,
    profile: UserProfile,
    level?: number,
  ) => void;
  handleChangeDeadline: (
    stepId: string,
    type: 'editor' | 'approver',
    date: Date,
  ) => void;
  setShowPlanningErrors: (display: boolean) => void;
  saveConfiguration: (
    data?: BasicInformationsForm,
  ) => Promise<SaveConfigurationReturn>;
  updateSteps: (steps: Step[]) => void;
}

export const NewReleaseConfigurationContext = createContext(
  {} as NewReleaseConfigurationContextType,
);

export const NewReleaseConfigurationProvider: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const { t: translate } = useTranslation();

  const [planningHorizon, setPlanningHorizon] = useState<PlanningHorizon>([
    null,
    null,
  ]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<Template>('standard');
  const [steps, setSteps] = useState<Step[]>([
    {
      name: `${translate('workspacePlanningFlowStage')} 1`,
      id: new Date().getTime().toString(),
      status: 'created',
      deadlines: null,
      users: [],
    },
  ]);
  const [previewSteps, setPreviewSteps] = useState<Step[]>([]);

  const [showPlanningErrors, setShowPlanningErrors] = useState(false);

  const [isSaving, setIsSaving] = useState<IsSaving>();

  const { id } = useParams();
  const location = useLocation();

  const isPreviewConfiguration = location.pathname.endsWith(
    '/control-panel/release-configuration',
  );

  const {
    workspace: { releasePreview, releaseSelected },
  } = useSelector((state: RootState) => state);

  const { data: stagingAreaData } = useQuery(
    ['workspace staging area', id],
    async () => {
      const { data } = await apiWorkspace.get<StagingAreaData>(
        `/workspaces/${id}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id,
    },
  );

  const {
    data: previewData,
    isLoading: isLoadingPreview,
    isFetching: isFetchingPreview,
  } = useQuery(
    ['workspace', id, 'releases', releasePreview],
    async () => {
      const { data } = await apiWorkspace.get<PreviewData>(
        `/workspaces/${id}/releases/${releasePreview}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id && !!releasePreview,
    },
  );

  const handleChangeTemplate = (template: Template) => {
    setSelectedTemplate(template);
  };

  const handleChangePlanningHorizon = (dates: PlanningHorizon) => {
    setPlanningHorizon(dates);
  };

  const handleAddNewStep = () => {
    setSteps((state) => [
      ...state,
      {
        name: `${translate('workspacePlanningFlowStage')} ${state.length + 1}`,
        id: new Date().getTime().toString(),
        status: 'created',
        deadlines: null,
        users: [],
      },
    ]);
  };

  const handleDeleteLastStep = () => {
    setSteps((state) => {
      if (state.length > 1) {
        return state.slice(0, -1);
      }

      return [
        {
          name: `${translate('workspacePlanningFlowStage')} 1`,
          id: new Date().getTime().toString(),
          status: 'created',
          deadlines: null,
          users: [],
        },
      ];
    });
  };

  const handleRenameStep = (stepId: string, newName: string) => {
    setSteps((state) =>
      state.map((step) =>
        step.id === stepId ? { ...step, name: newName } : step,
      ),
    );
  };

  const handleAddUser = (
    stepId: string,
    email: string,
    profile: UserProfile,
    level?: number,
  ) => {
    setSteps((state) =>
      state.map((step) => {
        if (step.id === stepId) {
          const newUser: User = { user: email, profile };

          if (profile === 'approver') {
            newUser.level = level;
          }

          return { ...step, users: [...step.users, newUser] };
        }

        return step;
      }),
    );
  };

  const handleRemoveUser = (
    stepId: string,
    email: string,
    profile: UserProfile,
    level?: number,
  ) => {
    setSteps((state) =>
      state.map((step) => {
        if (step.id === stepId) {
          const updatedUsers: User[] = [];

          for (let i = 0; i < step.users.length; i++) {
            const user = step.users[i];

            if (user.user !== email || user.profile !== profile) {
              if (profile === 'approver') {
                const userLevelIsHigherThanUserRemoved =
                  level && user.level && user.level > level;

                if (user.level && userLevelIsHigherThanUserRemoved) {
                  user.level -= 1;
                }
              }

              updatedUsers.push(user);
            }
          }

          return {
            ...step,
            users: updatedUsers,
          };
        }

        return step;
      }),
    );
  };

  const handleChangeDeadline = (
    stepId: string,
    type: 'editor' | 'approver',
    date: Date,
  ) => {
    setSteps((state) =>
      state.map((step) => {
        if (step.id === stepId) {
          let existsDeadlineType = false;

          const updatedDeadlines = [...(step.deadlines ?? [])];

          for (let i = 0; i < updatedDeadlines.length; i++) {
            const deadline = updatedDeadlines[i];

            if (deadline.type === type) {
              existsDeadlineType = true;
              updatedDeadlines[i].date = date.toISOString();

              break;
            }
          }

          if (!existsDeadlineType) {
            updatedDeadlines.push({ type, date: date.toISOString() });
          }

          return { ...step, deadlines: updatedDeadlines };
        }

        return step;
      }),
    );
  };

  const unlockEdition = async () => {
    try {
      await apiWorkspace.delete(`/workspaces/${id}/edit`);
      // eslint-disable-next-line no-empty
    } catch {}
  };

  const dateToString = (date: Date | null) =>
    `${date?.toISOString().slice(0, 10)}T00:00:00Z`;

  const stringToDate = (date: string) => new Date(date.replace(':00Z', ''));

  const deleteSteps = async (
    releaseId: string,
    numberStepsToDelete: number,
  ) => {
    for (let i = 0; i < numberStepsToDelete; i++) {
      await apiWorkspace.delete(
        `/workspaces/${id}/releases/${releaseId}/approval-flow/steps`,
      );
    }
  };

  const saveStepUsers = async (releaseId: string, index: number) => {
    const step = steps[index];

    const previewStepUsers = [...(previewSteps[index]?.users ?? [])];
    const previewStepApproverUsers =
      previewSteps[index]?.users.filter(
        (user) => user.profile === 'approver',
      ) ?? [];

    const newApproverUsers =
      step.users.filter((user) => user.profile === 'approver') ?? [];

    const approverUsersIsDiff =
      JSON.stringify(previewStepApproverUsers) !==
      JSON.stringify(newApproverUsers);

    if (approverUsersIsDiff) {
      for (let j = 0; j < previewStepApproverUsers.length; j++) {
        const user = previewStepApproverUsers[j];

        await apiWorkspace.delete(
          `/workspaces/${id}/releases/${releaseId}/approval-flow/steps/${
            index + 1
          }/users/${user.user}/profiles/${user.profile}`,
        );
      }
    }

    for (let j = 0; j < step.users.length; j++) {
      const user = step.users[j];

      const previewUserIndex =
        approverUsersIsDiff && user.profile === 'approver'
          ? -1
          : previewStepUsers.findIndex(
              (stepUser) =>
                stepUser.user === user.user &&
                stepUser.profile === user.profile,
            );

      const userAlreadySaved = previewUserIndex !== -1;

      if (!userAlreadySaved) {
        setIsSaving({
          type: 'user',
          details: {
            stepNumber: index,
            userNumber: j,
            profile: user.profile,
          },
        });

        await apiWorkspace.post(
          `/workspaces/${id}/releases/${releaseId}/approval-flow/steps/${
            index + 1
          }/users`,
          user,
        );
      } else {
        previewStepUsers.splice(previewUserIndex, 1);
      }
    }

    for (let j = 0; j < previewStepUsers.length; j++) {
      const user = previewStepUsers[j];

      if (
        (approverUsersIsDiff && user.profile !== 'approver') ||
        !approverUsersIsDiff
      ) {
        await apiWorkspace.delete(
          `/workspaces/${id}/releases/${releaseId}/approval-flow/steps/${
            index + 1
          }/users/${user.user}/profiles/${user.profile}`,
        );
      }
    }
  };

  const saveStepInfo = async (releaseId: string, index: number) => {
    const step = steps[index];

    const shouldCreateNewStep = previewSteps.length <= index;

    if (shouldCreateNewStep) {
      setIsSaving({ type: 'step', details: { stepNumber: index + 1 } });

      await apiWorkspace.post(
        `/workspaces/${id}/releases/${releaseId}/approval-flow/steps`,
        {
          name: step.name,
          deadlines: step.deadlines?.map((deadline) => ({
            ...deadline,
            date: deadline.date.replace('Z', ''),
          })),
        },
      );
    } else {
      const stepWasRenamed = previewSteps[index]?.name !== step.name;

      if (stepWasRenamed) {
        await apiWorkspace.patch(
          `/workspaces/${id}/releases/${releaseId}/approval-flow/steps/${
            index + 1
          }/name`,
          {
            name: step.name,
          },
        );
      }

      const deadlineWasUpdated =
        JSON.stringify(step.deadlines) !==
        JSON.stringify(previewSteps[index]?.deadlines);

      if (deadlineWasUpdated) {
        await apiWorkspace.patch(
          `/workspaces/${id}/releases/${releaseId}/approval-flow/steps/${
            index + 1
          }/deadlines`,
          step.deadlines?.map((deadline) => ({
            ...deadline,
            date: deadline.date.replace('Z', ''),
          })),
        );
      }
    }
  };

  const savePlanningConfiguration = async (releaseId: string) => {
    const previewDataInfo = previewData?.data;

    for (let i = 0; i < steps.length; i++) {
      await saveStepInfo(releaseId, i);

      await saveStepUsers(releaseId, i);
    }

    const previewHorizonDates = previewDataInfo?.approval_flow?.horizon_dates;

    const horizonDatesWasChanged =
      !previewHorizonDates ||
      previewHorizonDates.start.slice(0, 10) !==
        dateToString(planningHorizon[0]).slice(0, 10) ||
      previewHorizonDates.end.slice(0, 10) !==
        dateToString(planningHorizon[1]).slice(0, 10);

    if (horizonDatesWasChanged && isPreviewConfiguration) {
      await apiWorkspace.patch(
        `/workspaces/${id}/releases/${releaseId}/approval-flow/horizon-dates`,
        {
          start: dateToString(planningHorizon[0]),
          end: dateToString(planningHorizon[1]),
        },
      );
    }

    if (previewSteps && previewSteps.length > steps.length) {
      await deleteSteps(releaseId ?? '', previewSteps.length - steps.length);
    }
  };

  const saveConfiguration = async (
    data?: BasicInformationsForm,
  ): Promise<SaveConfigurationReturn> => {
    setIsSaving({ type: 'information' });

    const releaseId = isPreviewConfiguration
      ? releasePreview
      : releaseSelected?.id;

    try {
      if (isPreviewConfiguration && data) {
        const labelDescriptionWasChanged =
          stagingAreaData?.data.label !== data.name ||
          stagingAreaData.data.description !== data.description;

        if (labelDescriptionWasChanged) {
          await apiWorkspace.patch(`/workspaces/${id}/staging-area/labels`, {
            label: data.name,
            description: data.description,
          });
        }
      }

      if (selectedTemplate === 'planning' || !isPreviewConfiguration) {
        await savePlanningConfiguration(releaseId ?? '');
      } else if (previewData?.data?.steps?.length) {
        await deleteSteps(releaseId ?? '', previewData.data.steps.length);
      }

      await unlockEdition();

      return { success: true };
    } catch {
      return { success: false };
    } finally {
      setIsSaving(undefined);
    }
  };

  const updateSteps = (updatedStep: Step[]) => {
    setSteps(JSON.parse(JSON.stringify(updatedStep)));
    setPreviewSteps(JSON.parse(JSON.stringify(updatedStep)));
  };

  useEffect(() => {
    if (previewData && !isLoadingPreview && !isFetchingPreview) {
      if (previewData.data.steps?.length) {
        const formattedStep: Step[] = [];

        const horizonDates = previewData.data.approval_flow?.horizon_dates;

        if (horizonDates) {
          handleChangePlanningHorizon([
            stringToDate(horizonDates.start),
            stringToDate(horizonDates.end),
          ]);
        }

        previewData.data.steps.forEach((step) => {
          const stepUsers: User[] = [];

          const userProfiles = step.users?.profiles ?? {};

          const emails = Object.keys(userProfiles);

          emails.forEach((userEmail) => {
            const profiles = userProfiles[userEmail];

            profiles.forEach(({ type, level }) => {
              const user: User = { user: userEmail, profile: type };

              if (type === 'approver') {
                user.level = level;
              }

              stepUsers.push(user);
            });
          });

          formattedStep.push({
            id: step.created,
            name: step.name,
            status: 'created',
            deadlines: step.deadlines,
            users: stepUsers,
          });
        });

        setSteps(JSON.parse(JSON.stringify(formattedStep)));
        setPreviewSteps(JSON.parse(JSON.stringify(formattedStep)));

        handleChangeTemplate('planning');
      }
    }
  }, [previewData, isLoadingPreview, isFetchingPreview]);

  useEffect(() => {
    queryClient.refetchQueries(['workspace users', id]);
    queryClient.refetchQueries({
      queryKey: [
        'workspace',
        id,
        'releases',
        isPreviewConfiguration ? releasePreview : releaseSelected?.id,
      ],
      exact: true,
    });
  }, [id, isPreviewConfiguration, releasePreview, releaseSelected?.id]);

  return (
    <NewReleaseConfigurationContext.Provider
      value={{
        selectedTemplate,
        planningHorizon,
        steps,
        isSaving,
        showPlanningErrors,
        handleChangeTemplate,
        handleChangePlanningHorizon,
        handleAddNewStep,
        handleDeleteLastStep,
        handleRenameStep,
        handleAddUser,
        handleRemoveUser,
        handleChangeDeadline,
        setShowPlanningErrors,
        saveConfiguration,
        updateSteps,
      }}
    >
      {children}
    </NewReleaseConfigurationContext.Provider>
  );
};
