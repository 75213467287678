import React, { useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { Select } from 'src/components/Select';
import { DataError } from 'src/interface/axios';
import { changeIsFinish } from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { Tooltip } from 'react-tooltip';
import { translateTransformationText } from 'src/utils/translateTransformationText';
import { resetUserSelectionOptions } from 'src/models/redux/reducers/AIUserSelectionOptions';

import api from '../../../../service/api';
import {
  Container,
  ContainerModelInfo,
  ContainerSendProduction,
  ContentButton,
  ModelInfoListInformation,
  ModelList,
  SendButton,
} from '../../styles';
import {
  RFModelInfo,
  RFAnnualVariation,
  RFAnnualLevel,
  VariableImportance,
} from './types';
import { ActualForecast } from '../components/ActualForecast';
import { AnnualVariationChart } from '../components/AnnualVariationChart';
import { AnnualLevelChart } from '../components/AnnualLevelChart';
import { VariableImportanceChart } from '../components/VariableImportante';
import { ActualVSModelFit } from '../components/ActualVSModelFit';

export const TreeModelsModelSpecifics: React.FC = () => {
  const [modelId, setModelId] = useState(1);
  const [variablesQuantity, setVariablesQuantity] = useState(10);
  const [sendToProdDisabled, setSendToProdDisabled] = useState(true);
  const [sendToProdLoading, setSendToProdLoading] = useState(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);
  const [failedModalInfo, setFailedModalInfo] = useState<ErrorObject>();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    project,
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const {
    data: modelInfoData,
    isLoading: modelInfoIsLoading,
    isError: modelInfoError,
  } = useQuery(
    ['tree-models model info', project.id, project.selectedY?.id, modelId],
    async () => {
      const response = await api.get<RFModelInfo>(
        `/projects/${project.id}/${project.selectedY?.id}/models/tree-models/${modelId}/info`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: annualVariationData,
    isLoading: annualVariationLoading,
    isError: annualVariationError,
  } = useQuery(
    [
      'tree-models model annualVariation',
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const response = await api.get<RFAnnualVariation[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/tree-models/${modelId}/annual_variation`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: annualLevelData,
    isLoading: annualLevelLoading,
    isError: annualLevelError,
  } = useQuery(
    [
      'tree-models model annualLevel',
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const response = await api.get<RFAnnualLevel[]>(
        `/projects/${project.id}/${project.selectedY?.id}/models/tree-models/${modelId}/annual_level`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const {
    data: variableImportanceData,
    isLoading: variableImportanceLoading,
    isError: variableImportanceError,
  } = useQuery(
    [
      'tree-models model variableImportance',
      project.id,
      project.selectedY?.id,
      modelId,
    ],
    async () => {
      const response = await api.get<VariableImportance>(
        `/projects/${project.id}/${project.selectedY?.id}/models/tree-models/${modelId}/variable_importances/10`,
      );

      return response.data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  useEffect(() => {
    const modelNumber = searchParams.get('model_number');
    const summary = project.model?.model_summary.find(
      (modelSummary) => modelSummary.type === 'Tree Models',
    );

    if (modelNumber && summary && Number(modelNumber) <= summary.quantity) {
      setModelId(Number(modelNumber));
    } else {
      setModelId(1);
    }
  }, [project.model, searchParams]);

  useEffect(() => {
    if (project.id && project.selectedY && modelId) {
      setSendToProdDisabled(false);
    } else {
      setSendToProdDisabled(true);
    }
  }, [modelId, project.id, project.selectedY]);

  useEffect(() => {
    if (variableImportanceData && variableImportanceData?.x_name?.length < 10) {
      setVariablesQuantity(variableImportanceData?.x_name?.length);
    }
  }, [variableImportanceData, variableImportanceData?.x_name?.length]);

  async function handleSendToProduction() {
    setSendToProdLoading(true);
    setSendToProdDisabled(true);

    try {
      const { data } = await api.post(
        `/projects/${project.id}/${project.selectedY?.id}/model-in-production/tree-models/${modelId}`,
      );

      if (data) {
        navigate(`/models/time-series/${project.id}/user-selection`);
        queryClient.removeQueries('model production');
        queryClient.removeQueries('project overview');
        dispatch(changeIsFinish(false));
        dispatch(resetUserSelectionOptions());
      }
    } catch (err) {
      const error: AxiosError<DataError> | any = err;
      const errorMessage =
        error?.response?.data?.detail?.detail ??
        translate('sendToProdFailMessage');

      setFailedModalInfo({
        title: translate('requestFailed'),
        description: errorMessage,
      });
      setFailedModalVisible(true);
    }

    setSendToProdLoading(false);
    setSendToProdDisabled(false);
  }

  return (
    <Container data-cy="containerModelSpecifics">
      <Head
        title={`${translate('modelSpecTitle')} | ${translate(
          'modelExplorerText',
        )}`}
      />

      <Card
        textCard={`${translate('Tree_Models')} - ${translate(
          'modelSpecTitle',
        )}`}
        textDescription={translate('modelSpecDescription')}
        className="containerLinear"
      />

      <section>
        <ModelList className="containerLinear">
          <Card
            textCard={translate('selectModelTitle')}
            // textDescription="Sumary for... (cobrar curado do texto)"
          />
          {project.projectError ? (
            <Select
              label={translate('selectModelLabel')}
              placeholder={translate('selectModelPlaceHolder')}
              options={[{ label: 1, value: '1' }]}
              value={{ label: modelId, value: modelId }}
              onChange={(option: any) => {
                setModelId(option.value);
              }}
              isDisabled
            />
          ) : project.model?.model_list ? (
            <Select
              label={translate('selectModelLabel')}
              placeholder={translate('selectModelPlaceHolder')}
              options={Array.from(
                {
                  length:
                    project.model?.model_list.find(
                      (model) => model.type === 'Tree Models',
                    )?.quantity ?? 1,
                },
                (_, number) => ({
                  value: number + 1,
                  label: number + 1,
                }),
              )}
              value={{ label: modelId, value: modelId }}
              onChange={(option: any) => {
                setModelId(option.value);
              }}
            />
          ) : (
            <ContainerSkeleton style={{ height: '180px' }} />
          )}
        </ModelList>

        <ContainerSendProduction className="containerLinear">
          <Card
            textCard={translate('sendModelToProdTitle')}
            textDescription={translate('sendModelToProdDescr')}
          />
          <ContentButton>
            <SendButton
              buttonType="primary"
              onClick={handleSendToProduction}
              disabled={sendToProdDisabled}
              loading={sendToProdLoading}
              data-cy="button-send-to-production"
            >
              {translate('sendToProductionButtonText')}
            </SendButton>
          </ContentButton>
        </ContainerSendProduction>

        <ContainerModelInfo className="containerLinear">
          <Tooltip
            id="tree-models-model-info-tooltip"
            className="customTooltipTheme"
          />

          <Card textCard={translate('modelInfoTitle')} />
          {project.projectError || modelInfoError ? (
            <ModelInfoListInformation>
              <div>
                {/* <div>
                  <h4>Row ID</h4>
                  <p data-cy="data-info-row-id" data-testid="data-info-row-id">
                    --
                  </p>
                </div> */}
                <div>
                  <h4>{translate('nOfTrees')}</h4>
                  <p data-cy="data-info-trees" data-testid="data-info-trees">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('nOfVarsRandomSampled')}
                >
                  <h4>MTRY</h4>
                  <p data-cy="data-info-mtry" data-testid="data-info-mtry">
                    --
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <h4>{translate('sample')}</h4>
                  <p data-cy="data-info-sample" data-testid="data-info-sample">
                    --
                  </p>
                </div>
                <div>
                  <h4>{translate('type')}</h4>
                  <p data-cy="data-info-type" data-testid="data-info-type">
                    --
                  </p>
                </div>
                <div>
                  <h4>{translate('transformation')}</h4>
                  <p
                    data-cy="data-info-transformation"
                    data-testid="data-info-transformation"
                  >
                    --
                  </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('MAPEExplanationTooltip')}
                >
                  <h4>MAPE</h4>
                  <p data-cy="data-info-mape" data-testid="data-info-mape">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('WMAPEExplanationTooltip')}
                >
                  <h4>WMAPE</h4>
                  <p data-cy="data-info-wmape" data-testid="data-info-wmape">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('MPEExplanationTooltip')}
                >
                  <h4>MPE</h4>
                  <p data-cy="data-info-mpe" data-testid="data-info-mpe">
                    --
                  </p>
                </div>
              </div>
              <div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('RMSEExplanationTooltip')}
                >
                  <h4>RMSE</h4>
                  <p data-cy="data-info-rmse" data-testid="data-info-rmse">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('MASEExplanationTooltip')}
                >
                  <h4>MASE</h4>
                  <p data-cy="data-info-mase" data-testid="data-info-mase">
                    --
                  </p>
                </div>
                <div
                  data-tooltip-id="tree-models-model-info-tooltip"
                  data-tooltip-html={translate('MASEsExplanationTooltip')}
                >
                  <h4>MASEs</h4>
                  <p data-cy="data-info-mases" data-testid="data-info-mases">
                    --
                  </p>
                </div>
              </div>
            </ModelInfoListInformation>
          ) : !modelInfoData || modelInfoIsLoading ? (
            <ContainerSkeleton style={{ height: '180px' }} />
          ) : (
            modelInfoData && (
              <ModelInfoListInformation>
                <div>
                  {/* <div>
                    <h4>Row ID</h4>
                    <p
                      data-cy="data-info-row-id"
                      data-testid="data-info-row-id"
                    >
                      {modelInfoData.row_id ?? '--'}
                    </p>
                  </div> */}
                  <div>
                    <h4>{translate('nOfTrees')}</h4>
                    <p data-cy="data-info-trees" data-testid="data-info-trees">
                      {modelInfoData.n_trees ?? '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('nOfVarsRandomSampled')}
                  >
                    <h4>MTRY</h4>
                    <p data-cy="data-info-mtry" data-testid="data-info-mtry">
                      {modelInfoData.mtry ?? '--'}
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>{translate('sample')}</h4>
                    <p
                      data-cy="data-info-sample"
                      data-testid="data-info-sample"
                    >
                      {modelInfoData.sample ?? '--'}
                    </p>
                  </div>

                  <div>
                    <h4>{translate('type')}</h4>
                    <p data-cy="data-info-type" data-testid="data-info-type">
                      {modelInfoData?.selected_model?.model ?? '--'}
                    </p>
                  </div>

                  <div>
                    <h4>{translate('transformation')}</h4>
                    <p
                      data-cy="data-info-transformation"
                      data-testid="data-info-transformation"
                    >
                      {translateTransformationText(
                        modelInfoData?.transformation ?? '--',
                        user.language,
                      )}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('MAPEExplanationTooltip')}
                  >
                    <h4>MAPE</h4>
                    <p data-cy="data-info-mape" data-testid="data-info-mape">
                      {modelInfoData?.MAPE
                        ? `${modelInfoData?.MAPE.toFixed(2)}%`
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('WMAPEExplanationTooltip')}
                  >
                    <h4>WMAPE</h4>
                    <p data-cy="data-info-wmape" data-testid="data-info-wmape">
                      {modelInfoData?.WMAPE
                        ? `${modelInfoData?.WMAPE.toFixed(2)}%`
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('MPEExplanationTooltip')}
                  >
                    <h4>MPE</h4>
                    <p data-cy="data-info-mpe" data-testid="data-info-mpe">
                      {modelInfoData?.MPE
                        ? `${modelInfoData?.MPE.toFixed(2)}%`
                        : '--'}
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('RMSEExplanationTooltip')}
                  >
                    <h4>RMSE</h4>
                    <p data-cy="data-info-rmse" data-testid="data-info-rmse">
                      {modelInfoData?.RMSE
                        ? modelInfoData.RMSE.toFixed(2)
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('MASEExplanationTooltip')}
                  >
                    <h4>MASE</h4>
                    <p data-cy="data-info-mase" data-testid="data-info-mase">
                      {modelInfoData?.MASE
                        ? modelInfoData.MASE.toFixed(2)
                        : '--'}
                    </p>
                  </div>
                  <div
                    data-tooltip-id="tree-models-model-info-tooltip"
                    data-tooltip-html={translate('MASEsExplanationTooltip')}
                  >
                    <h4>MASEs</h4>
                    <p data-cy="data-info-mases" data-testid="data-info-mases">
                      {modelInfoData?.MASEs
                        ? modelInfoData.MASEs.toFixed(2)
                        : '--'}
                    </p>
                  </div>
                </div>
              </ModelInfoListInformation>
            )
          )}
        </ContainerModelInfo>
      </section>

      <section>
        <div className="containerLinear">
          <Card textCard={translate('modelSpecificsVarImportanceTitle')} />
          {variableImportanceData?.x_name ? (
            <Select
              label={translate('modelSpecificsVarImportanceLabel')}
              style={{ width: '300px', marginBottom: '16px' }}
              options={Array.from(
                {
                  length:
                    variableImportanceData?.x_name.length < 10
                      ? variableImportanceData?.x_name.length
                      : 10,
                },
                (_, number) => ({
                  value: number + 1,
                  label: number + 1,
                }),
              )}
              onChange={(option: any) => {
                setVariablesQuantity(option.value);
              }}
              value={{ label: variablesQuantity, value: variablesQuantity }}
            />
          ) : (
            (variableImportanceLoading || variableImportanceError) && (
              <Select
                label={translate('modelSpecificsVarImportanceLabel')}
                style={{ width: '300px', marginBottom: '24px' }}
                isDisabled
              />
            )
          )}

          {project.projectError || variableImportanceError ? (
            <>
              <ContainerMaintenance content="chart" />
            </>
          ) : !variableImportanceData ||
            variableImportanceLoading ||
            !variableImportanceData ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerSkeleton />
          ) : (
            <VariableImportanceChart
              variableImportanceData={variableImportanceData}
              variablesQuantity={variablesQuantity}
            />
          )}
        </div>
      </section>

      <ActualVSModelFit modelId={modelId} type="tree-models" />

      <ActualForecast modelId={modelId} type="tree-models" />

      <section>
        <div className="containerLinear">
          <Card textCard={translate('modelSpecAnnualVariationTitle')} />
          {project.projectError || annualVariationError ? (
            <ContainerMaintenance
              text={translate('modelSpecErrors').replace(
                'XXX',
                translate('modelSpecAnnualVariation'),
              )}
              content="chart"
            />
          ) : annualVariationLoading || !annualVariationData ? (
            <ContainerSkeleton />
          ) : (
            <AnnualVariationChart data={annualVariationData} />
          )}
        </div>

        <div className="containerLinear">
          <Card textCard={translate('modelSpecAnnualLevelTitle')} />
          {project.projectError || annualLevelError ? (
            <ContainerMaintenance
              text={translate('modelSpecErrors').replace(
                'XXX',
                translate('modelSpecAnnualLevel'),
              )}
              content="chart"
            />
          ) : !annualLevelData || annualLevelLoading ? (
            <ContainerSkeleton />
          ) : (
            <AnnualLevelChart data={annualLevelData} />
          )}
        </div>
      </section>

      <FailedModal
        errorInfo={failedModalInfo}
        visible={failedModalVisible}
        setVisible={setFailedModalVisible}
      />
    </Container>
  );
};
