import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { Card } from 'src/components/Card';
import {
  ChartLine,
  Path,
  Table,
  UserCircleGear,
  UsersThree,
} from 'phosphor-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import PlanningFlowPtImg from 'src/assets/planning-flow-template-pt.svg';
import PlanningFlowEnImg from 'src/assets/planning-flow-template-en.svg';

import { TemplateModalProps } from '../types';
import {
  Container,
  Footer,
  ImageContainer,
  InformationContainer,
  InformationContent,
  Item,
  List,
} from '../styles';

export const PlanningModal: React.FC<TemplateModalProps> = ({ setVisible }) => {
  const { t: translate } = useTranslation();

  const {
    auth: {
      user: { language },
    },
  } = useSelector((state: RootState) => state);

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible
      setVisible={setVisible}
      style={{ padding: 0 }}
      dataCy="modal-planning-template"
    >
      <Container>
        <ImageContainer>
          <img
            src={language === 'pt-br' ? PlanningFlowPtImg : PlanningFlowEnImg}
            alt=""
          />
        </ImageContainer>

        <InformationContainer>
          <InformationContent>
            <Card
              textCard={translate(
                'workspaceNewReleaseConfigurationPlanningTemplate',
              )}
              textDescription={translate(
                'workspaceNewReleaseConfigurationPlanningDescription',
              )}
            />

            <List>
              <Item color="gray">
                <UsersThree weight="duotone" />

                <p>
                  {translate(
                    'workspaceNewReleaseConfigurationStandardListCollaborativeEnv',
                  )}
                </p>
              </Item>

              <Item color="gray">
                <Table weight="duotone" />

                <p>
                  {translate(
                    'workspaceNewReleaseConfigurationStandardListTable',
                  )}
                </p>
              </Item>

              <Item color="gray">
                <ChartLine weight="duotone" />

                <p>
                  {translate(
                    'workspaceNewReleaseConfigurationStandardListChart',
                  )}
                </p>
              </Item>

              <Item color="green">
                <Path weight="duotone" />

                <p>
                  {translate(
                    'workspaceNewReleaseConfigurationPlanningListStep',
                  )}
                </p>
              </Item>

              <Item color="green">
                <UserCircleGear weight="duotone" />

                <p>
                  {translate(
                    'workspaceNewReleaseConfigurationPlanningListUserControl',
                  )}
                </p>
              </Item>
            </List>
          </InformationContent>

          <Footer>
            <Button
              buttonType="primary"
              onClick={handleCloseModal}
              data-testid="button-ok"
            >
              Ok
            </Button>
          </Footer>
        </InformationContainer>
      </Container>
    </Modal>
  );
};
