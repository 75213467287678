import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ModelSummary {
  type: string;
  quantity: number;
}

type ModelList = {
  type: string;
  quantity: number;
};

export type ModelTypes =
  | 'Forecast Combination'
  | 'ARIMA'
  | 'Regularized Regression'
  | 'Tree Models'
  | 'Elementary';

export type Column =
  | 'Model'
  | 'MASE'
  | 'MAPE'
  | 'MASEs'
  | 'WMAPE'
  | 'Transformation'
  | 'RMSE'
  | 'MPE';

export interface Model {
  accuracy: string[];
  model_summary: ModelSummary[];
  model_list: ModelList[];
  models_quantity: number;
  model_types: ModelTypes[];
}

export type YInfo = {
  few_rows: boolean;
  frequency: string;
  training_row_numbers: number;
};

type YFilters = {
  level_1?: string;
  level_2?: string;
  level_3?: string;
};
export type Frequency =
  | 'daily'
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'half-year'
  | 'annual';

export type Y = {
  name: string;
  label: string;
  id: string;
  status: string;
  info?: {
    few_rows: boolean;
    frequency: Frequency;
    training_row_numbers: number;
  };
  filters?: YFilters;
};

export type YBusinessStatus = 'processing' | 'success' | 'error';

type YBusiness = {
  y: string;
  status: YBusinessStatus;
};

type Filters = {
  name: string;
  options: string[];
};

export type LevelFilters = {
  level_1?: Filters;
  level_2?: Filters;
  level_3?: Filters;
};

type AppInfo = {
  engine: {
    name: string;
    version: string;
  };
};

export type PayloadProject = {
  id: string | null;
  name: string | null;
  icon: string | null;
  y: Y[];
  selectedY: Y | null;
  model?: Model | null;
  projectError?: boolean;
  columns: Column[];
  accuracyCrit: string | null;
  yBusiness: YBusiness[];
  updatedAt: string | null;
  createdAt: string | null;
  parentID: string | null;
  filters?: LevelFilters;
  selectedFilters: YFilters;
  appInfo: AppInfo | null;
};

export type YAndModel = {
  y: Y | null;
  model: Model | null;
  selectedFilters: YFilters;
};

const initialState: PayloadProject = {
  id: null,
  name: null,
  icon: null,
  y: [],
  selectedY: null,
  model: null,
  projectError: false,
  columns: [],
  accuracyCrit: null,
  yBusiness: [],
  updatedAt: null,
  createdAt: null,
  parentID: null,
  filters: {},
  selectedFilters: {},
  appInfo: null,
};

export const ProjectState = createSlice({
  name: 'project',
  initialState,
  reducers: {
    insert: (state, action: PayloadAction<PayloadProject>) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.icon = action.payload.icon;
      state.y = action.payload.y;
      state.selectedY = action.payload.selectedY;
      state.columns = action.payload.columns;
      state.model = action.payload.model;
      state.accuracyCrit = action.payload.accuracyCrit;
      state.yBusiness = action.payload.yBusiness;
      state.updatedAt = action.payload.updatedAt;
      state.createdAt = action.payload.createdAt;
      state.parentID = action.payload.parentID;
      state.filters = action.payload.filters;
      state.appInfo = action.payload.appInfo;
    },
    changeSelectedYAndModel: (state, action: PayloadAction<YAndModel>) => {
      state.selectedY = action.payload.y;
      state.model = action.payload.model;
      state.selectedFilters = action.payload.selectedFilters;
      state.projectError = false;
    },
    changeProjectRequestError: (state, action: PayloadAction<boolean>) => {
      state.projectError = action.payload;
    },
    changeColumns: (state, action: PayloadAction<Column[]>) => {
      state.columns = action.payload;
    },
    changeY: (state, action: PayloadAction<Y[]>) => {
      state.y = action.payload;
    },
    changeYBusinessStatus: (state, action: PayloadAction<YBusiness[]>) => {
      state.yBusiness = action.payload;
    },
    changeFilters: (state, action: PayloadAction<LevelFilters>) => {
      state.filters = action.payload;
    },
    clear: (state) => {
      state.id = null;
      state.name = null;
      state.icon = null;
      state.y = [];
      state.selectedY = null;
      state.model = null;
      state.projectError = false;
      state.columns = [];
      state.accuracyCrit = null;
      state.updatedAt = null;
      state.createdAt = null;
      state.parentID = null;
      state.appInfo = null;
    },
  },
});

export const {
  insert,
  changeSelectedYAndModel,
  changeProjectRequestError,
  changeColumns,
  changeY,
  clear,
  changeYBusinessStatus,
  changeFilters,
} = ProjectState.actions;

export default ProjectState.reducer;
