import styled from 'styled-components';

export const IndicatorsSearch = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-top: -4rem;
  padding-top: 1.5rem;

  svg:first-child {
    margin-left: 0.125rem !important;
    color: ${({ theme }) => theme.colors.gray4} !important;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;

  hr {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray1};
    margin-top: 1rem;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
`;

interface SelectedCategoryProps {
  disabled: boolean;
}

export const SelectedCategory = styled.button<SelectedCategoryProps>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1rem;

  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray4};
  text-align: left;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    margin-right: 1rem;
  }

  > div:first-child {
    max-width: 2rem;
  }
`;

type CleanFiltersButtonProps = {
  isClearFilterMinWidth: boolean;
};

export const CleanFiltersButton = styled.button<CleanFiltersButtonProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: 0.7s !important;
  margin-left: 0.5rem;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  margin-top: 1rem;
  text-align: right;

  width: ${({ isClearFilterMinWidth }) =>
    isClearFilterMinWidth ? 'min-content' : 'auto'};

  > div:first-child {
    width: 1rem;
  }

  * {
    color: ${({ theme }) => theme.colors.primary};
  }

  > svg {
    margin-right: 0.5rem;
  }

  :hover {
    * {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  > p {
    text-align: center;
  }
`;

export const ContainerWithoutCategories = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray7};
    text-align: center;
  }
`;

interface SubCategory {
  category:
    | 'capital and financial markets'
    | 'economic activity'
    | 'controls'
    | 'credit'
    | 'climatic data'
    | 'public finance'
    | 'environmental data'
    | 'external sector'
    | string;
}

export const SubCategory = styled.button<SubCategory>`
  width: 100% !important;

  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-top: 1.5rem;

  > div:first-child {
    display: flex;
    justify-content: flex-start !important;
    align-items: center;
    gap: 1rem;

    > svg {
      color: ${({ theme, category }) =>
        category === 'capital and financial markets'
          ? theme.colors.teal4
          : category === 'economic activity'
          ? theme.colors.green4
          : category === 'controls'
          ? theme.colors.blue4
          : category === 'credit'
          ? theme.colors.red4
          : category === 'climatic data'
          ? theme.colors.yellow4
          : category === 'public finance'
          ? theme.colors.purple4
          : category === 'external sector'
          ? theme.colors.cyan4
          : category === 'environmental data'
          ? theme.colors.orange4
          : theme.colors.primary} !important;
    }
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-align: left;
  }

  > div:last-child {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
    flex: 1 0 0;

    span {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colors.gray4};
    }

    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }
  }
`;
