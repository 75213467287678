import styled from 'styled-components';

import { StepStatus } from './types';

export const Container = styled.div`
  #planning-flow-users {
    font-size: 0.75rem !important;

    max-width: 23rem !important;

    padding: 0.75rem 1rem !important;
  }
`;

export const StepsContainer = styled.div`
  width: fit-content;
  max-width: 100%;

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: start;

  overflow-x: auto;

  > button {
    height: fit-content;

    align-self: center;

    padding: 0.75rem;
    margin-left: 1.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  > div + div {
    margin-left: 1.5rem;
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }
`;

type LineProps = {
  stepsQtty: number;
  isConfigurationMode: boolean;
};

export const Line = styled.hr<LineProps>`
  position: absolute;

  top: 5.8rem;

  margin-left: 2rem;

  width: ${({ stepsQtty, isConfigurationMode }) =>
    isConfigurationMode
      ? `calc(43.125rem + (${stepsQtty - 1}) * 30.625rem - 4rem)`
      : `calc(37.125rem + (${stepsQtty - 1}) * 26.625rem - 4rem)`};

  height: 2px;

  border-top: 1px solid ${({ theme }) => theme.colors.gray1};

  transition: all 0.2s;
`;

const STEP_STATUS_POSITION = {
  created: { configuration: '5.5rem', default: '5.5rem' },
  baseline: { configuration: '5.5rem', default: '5.5rem' },
  adjusting: { configuration: '7rem', default: '6rem' },
  awaiting_approval: { configuration: '15rem', default: '12rem' },
  approved: { configuration: '22rem', default: '18rem' },
};

type BlueLineProps = {
  stepNumber: number;
  stepStatus: StepStatus;
  isConfiguration: boolean;
};

export const BlueLine = styled.hr<BlueLineProps>`
  position: absolute;

  top: 5.8rem;

  margin-left: 2rem;

  width: ${({ stepNumber, stepStatus, isConfiguration }) =>
    `calc(${
      stepNumber !== 1 ? (isConfiguration ? '43.125rem' : '37.125rem') : '0rem'
    } + ${stepNumber > 2 ? stepNumber - 2 : 0} * ${
      isConfiguration ? '29.125rem' : '26.625rem'
    }  + ${
      STEP_STATUS_POSITION[stepStatus]?.[
        isConfiguration ? 'configuration' : 'default'
      ]
    } + ${
      stepNumber === 1 && stepStatus !== 'baseline' && stepStatus !== 'created'
        ? isConfiguration
          ? '12rem'
          : '10rem'
        : '0rem'
    })`};
  height: 2px;

  border-top: 1px solid ${({ theme }) => theme.colors.primary};

  z-index: 1;

  /* transition: width 2s ease-in-out; */
`;
