import React from 'react';

import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Calendar as CalendarIcon } from 'phosphor-react';
import { Frequency } from 'src/models/redux/reducers/Project';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import ptBR from 'date-fns/locale/pt-BR';
import enUS from 'date-fns/locale/en-US';

import { Input } from '../Input';
import { Container } from './styles';

type Props = {
  label?: string;
  frequency: Frequency | 'yearly';
  selectsRange?: boolean;
  onChange:
    | ((date: [Date | null, Date | null]) => void)
    | ((date: Date) => void);
  isDisabled?: boolean;
  error?: string;
  loading?: boolean;
  inputTestId: string;
} & Omit<ReactDatePickerProps, 'selectsRange' | 'onChange'>;

export const Calendar: React.FC<Props> = ({
  label,
  selectsRange,
  frequency,
  onChange,
  isDisabled = false,
  error,
  loading = false,
  inputTestId,
  ...rest
}) => {
  const { language } = useSelector((state: RootState) => state.auth.user);

  function handleValue(e: React.FocusEvent<HTMLInputElement, Element>) {
    e.preventDefault();
  }

  const pickerLanguage = language === 'pt-br' ? ptBR : enUS;

  const yearPicker = frequency === 'annual' || frequency === 'yearly';

  const dailyPicker = frequency === 'daily' || frequency === 'weekly';

  const monthPicker = !yearPicker && !dailyPicker;

  const dateFormat = monthPicker
    ? 'MM/yyyy'
    : yearPicker
    ? 'yyyy'
    : language === 'en-us'
    ? 'MM/dd/yyyy'
    : 'dd/MM/yyyy';

  return (
    <Container
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      data-testid={`datepicker-${inputTestId}`}
    >
      <DatePicker
        selectsRange={selectsRange}
        dateFormat={dateFormat}
        showMonthYearPicker={monthPicker}
        showYearPicker={yearPicker}
        disabledKeyboardNavigation
        onChange={onChange}
        customInput={
          <Input
            icon={<CalendarIcon size={32} />}
            invertedIcon
            label={label}
            testid={inputTestId}
            style={{ width: '16rem' }}
            disabled={isDisabled}
            error={error}
            dotLoading={loading}
          />
        }
        onChangeRaw={handleValue}
        className="customRangePicker"
        formatWeekDay={(day) => day[0].toUpperCase()}
        locale={pickerLanguage}
        showPopperArrow={false}
        data-testid="range-date-picker"
        showFourColumnMonthYearPicker
        disabled={isDisabled}
        {...rest}
      />
    </Container>
  );
};
