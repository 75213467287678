import { Serie } from '../../GroupView/types';

export const fakeSerie: Serie = {
  name: {
    'en-us': 'BRA - Economic Activity Index - IBC-BR',
    'pt-br': 'BRA - Índice de Atividade Econômica - IBC-BR',
  },
  source: {
    'en-us': 'Central Bank of Brazil',
    'pt-br': 'Central Bank of Brazil',
  },
  code: 'BRGDP0001PA2OAQY',
  indicator_code: 'BRGDP0001',
  date_start_projection: '',
  date_end_projection: '',
  date_start: '2004-01-01T00:00:00',
  date_end: '2023-10-01T00:00:00',
  region: {
    'en-us': 'Pará',
    'pt-br': 'Pará',
  },
  aggregation: {
    'en-us': 'Quarterly - Simple average',
    'pt-br': 'Trimestral - Média simples',
  },
  primary_transformation: {
    'en-us': 'Original',
    'pt-br': 'Original',
  },
  second_transformation: {
    'en-us': 'Year over year',
    'pt-br': 'Variação contra igual período do ano anterior',
  },
  last_updated: new Date(),

  status: 'active',
};
