import React, { useState, useEffect } from 'react';

import { MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Button } from 'src/components/Button';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Pagination } from 'src/components/Pagination';
import { RootState } from 'src/redux/store';
import { AutoComplete } from 'src/components/AutoComplete';
import {
  clearMyProjectOptions,
  saveMyProjectOptions,
} from 'src/models/redux/reducers/MyProjectsOptions';
import {
  AllCardsContainer,
  CardBody,
  CardContainer,
  CardContent,
  CardHead,
} from 'src/components/ProjectCard/styles';
import { Status } from 'src/components/Status';
import { Card } from 'src/components/Card';
import { useQueryProjects } from 'src/models/hooks/useQueryProjects';
import { ProjectsResponse } from 'src/models/hooks/useQueryProjects/types';
import {
  storageProjectNamesGet,
  storageProjectNamesRemove,
  storageProjectNamesSave,
} from 'src/models/storage/storageProjectNames';
import { useSearchDebounce } from 'src/hooks/useSearchDebounce';

import {
  ActionsContainer,
  ContainerWithoutGroups,
  DivSearchBox,
} from './styles';
import { ProjectCard } from '../ProjectCard';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

const QUANTITY_ITEMS_PAGE = 8;

export const ListProjects: React.FC = () => {
  const [projects, setProjects] = useState<ProjectsResponse>();
  const [page, setPage] = useState(1);
  const [oldProjectNames, setOldProjectNames] = useState<string[]>([]);

  const {
    auth: { user },
    myProjectsOptions,
  } = useSelector((state: RootState) => state);
  const { t: translate } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    searchValue,
    setSearchValue,
    requestAllowed,
    lastSearch,
    searchError,
    setRequestAllowed,
    isSearchValid,
    searchCompleted,
  } = useSearchDebounce();

  useEffect(() => {
    if (myProjectsOptions.page || myProjectsOptions.search) {
      if (myProjectsOptions.page) {
        setPage(myProjectsOptions.page);
      }
      if (myProjectsOptions.search) {
        setSearchValue(myProjectsOptions.search);
      }

      dispatch(clearMyProjectOptions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { projectsData, projectsIsLoading, projectsError } = useQueryProjects({
    page,
    searchValue,
    requestAllowed,
    limit: QUANTITY_ITEMS_PAGE,
    onSuccess(data) {
      if (searchValue && data?.total && user.email) {
        storageProjectNamesSave(user.email, searchValue);
        setOldProjectNames(storageProjectNamesGet(user.email));
      }
    },
  });

  useEffect(() => {
    if (user.email) {
      setOldProjectNames(storageProjectNamesGet(user.email));
    }
  }, [user.email]);

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData);
    }

    searchCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsData]);

  function saveSearchAndPageInRedux() {
    if (page !== 1 || searchValue) {
      dispatch(
        saveMyProjectOptions({
          page,
          search: searchValue,
        }),
      );
    }
  }

  function handleSearchProject(value: string) {
    if (isSearchValid(value)) {
      setPage(1);
    }
  }

  const handleChangePage = (pageAux: number): void => {
    setRequestAllowed(true);
    setPage(pageAux);
  };

  const redirectToCreateProject = () => {
    if (
      user.isClaaS &&
      user.isFaaS &&
      user.permissions.includes('create:project-claas') &&
      user.permissions.includes('create:projects')
    ) {
      navigate('/models/projects/new');
      return;
    }

    if (user.isClaaS && user.permissions.includes('create:project-claas')) {
      navigate('/models/projects/new/classification');
      return;
    }

    if (user.isFaaS && user.permissions.includes('create:projects')) {
      navigate('/models/projects/new/time-series');
    }
  };

  const oldProjectNamesShow = oldProjectNames.filter(
    (oldProjectNamesAux) =>
      searchValue !== oldProjectNamesAux &&
      oldProjectNamesAux.includes(searchValue),
  );

  const userHasPermissionToCreateProject =
    user.permissions.includes('create:projects') ||
    user.permissions.includes('create:project-claas');

  return (
    <>
      <DivSearchBox>
        <Card
          textCard={translate('myProjects')}
          textDescription={translate('projectsSubtTitle')}
        />
        <ActionsContainer>
          <AutoComplete
            icon={<MagnifyingGlass size="1.25rem" />}
            testid="searchProject"
            placeholder={translate('searchProject')}
            onChange={(event) => {
              handleSearchProject(event);
              setPage(1);
            }}
            error={
              searchError?.quantityLetters === 3
                ? translate(searchError.message).replace('XX', '3')
                : searchError?.quantityLetters === 50
                ? translate(searchError.message).replace('XX', '50')
                : undefined
            }
            options={oldProjectNamesShow}
            value={searchValue}
            onDelete={(index) => {
              if (oldProjectNames[index] && user.email) {
                storageProjectNamesRemove(user.email, oldProjectNames[index]);
                setOldProjectNames(storageProjectNamesGet(user.email));
              }
            }}
          />

          <div
            data-tooltip-id="models-my-projects-create-project"
            data-tooltip-content={
              !userHasPermissionToCreateProject
                ? translate('myProjectDoNotHasPermissionToCreateProject')
                : undefined
            }
          >
            <Button
              buttonType="primary"
              onClick={() => redirectToCreateProject()}
              data-cy="button-create-project"
              data-testid="button-create-project"
              disabled={!userHasPermissionToCreateProject}
            >
              <p>{translate('createProjectButton')}</p>
            </Button>
          </div>
        </ActionsContainer>
        <Tooltip
          id="models-my-projects-create-project"
          className="customTooltipTheme"
        />
      </DivSearchBox>

      {projectsError ? (
        <ContainerMaintenance
          content="projects"
          text={translate('fetchProjectsError')}
        />
      ) : projectsData?.total === 0 && searchValue.length >= 1 ? (
        <ContainerWithoutGroups>
          <Status
            type="noSearchResults"
            title={`${translate('searchProjectFail')} "${lastSearch}".`}
          />
        </ContainerWithoutGroups>
      ) : projectsIsLoading || !projects ? (
        <>
          <AllCardsContainer data-testid="content-projects">
            {Array.from({ length: 8 }, (_, number) => number).map((number) => (
              <CardContainer
                key={`card-loading-${number}`}
                data-testid={`loading-project-${number}`}
              >
                <CardContent style={{ cursor: 'default' }}>
                  <CardHead projectType="loading">
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '4rem',
                        height: '4rem',
                        position: 'absolute',
                        top: '1.5rem',
                        left: '1rem',
                      }}
                    />
                  </CardHead>

                  <CardBody>
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '80%',
                        height: '1.688rem',
                      }}
                    />

                    <span>{translate('projectHeadDependVar')}</span>

                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '90%',
                        height: '1.313rem',
                        marginTop: '0.5rem',
                      }}
                    />

                    <span>{translate('projectHeadLastUpdated')}</span>
                    <ContainerSkeleton
                      withLoading={false}
                      style={{
                        width: '65%',
                        height: '1.313rem',
                        marginTop: '0.5rem',
                      }}
                    />
                  </CardBody>
                </CardContent>
              </CardContainer>
            ))}
          </AllCardsContainer>

          {projects?.total && (
            <Pagination
              page={page}
              setPage={handleChangePage}
              total={projects.total}
              quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
              name={translate('paginationText')}
              style={{ marginTop: '0px' }}
            />
          )}
        </>
      ) : projects.total > 0 ? (
        <>
          <AllCardsContainer data-testid="content-projects">
            {projects.records.map((project, index) => (
              <ProjectCard
                key={`list-project-${index.toString()}`}
                id={project.id}
                name={project.name}
                iconUrl={project.icon_url}
                updatedAt={project.last_updated}
                created={project.created}
                type={project.type}
                dependentVar={project.ys}
                status={project.status}
                columns={project.overview.columns}
                parentID={project.parent_id}
                accuracyCrit={
                  project.model_spec.original?.accuracy_crit?.[0] ?? null
                }
                businessYsStatus={project.business.y_status ?? {}}
                saveSearchAndPage={saveSearchAndPageInRedux}
                filters={project.filters}
                appInfo={project.app_info}
              />
            ))}
          </AllCardsContainer>

          {projects?.total && (
            <Pagination
              page={page}
              setPage={handleChangePage}
              total={projects.total}
              quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
              name={translate('paginationText')}
              style={{ marginTop: '0px' }}
            />
          )}
        </>
      ) : (
        <ContainerMaintenance
          content="projects"
          text={translate('myProjectErrorNoProjectFound')}
          data-testid="container-without-projects"
        />
      )}
    </>
  );
};
