import React, {
  ReactElement,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ErrorObject } from 'src/components/Modal/Failed';
import { resetAIUserSelectionOptions } from 'src/models/redux/reducers/AIUserSelectionOptions';
import { Y, insert } from 'src/models/redux/reducers/Project';
import { changeIsFinish } from 'src/models/redux/reducers/ProjectOverviewExportTimeSeries';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { Project } from 'src/models/hooks/useQueryProjects/types';

import { DownloadStatus } from '../../DownloadWidget';

type Update = {
  id: string;
  label: string;
  created: string;
  status: string;
  cloned_from: string | null;
};

export type UpdateHistoryData = {
  selected: string;
  updates: Update[];
};

type ParentProject = {
  id: string;
  label: string;
  created: string;
  ys: Y[];
};

type UpdateShareList = {
  projects: {
    id: string;
    cloned_from: string;
    user: string;
    status: string | null;
    update: string | null;
  }[];
};

interface ModelUpdateInfo {
  updateHistory: UpdateHistoryData | undefined;
  versionLabel: string;
  modelUpdateDisabled: boolean;
  modelUpdateSideBarVisible: boolean;
  setModelUpdateSidebarVisible: (value: boolean) => void;
  handleAccessProjectUpdate: (id: string, label: string) => void;
  modelUpdateNavigationLoading: boolean;
  parentProject: ParentProject;
  errorOnUpdateAction: ErrorObject | undefined;
  setErrorOnUpdateAction: (value: ErrorObject | undefined) => void;
  updateToShare: string;
  setUpdateToShare: (value: string) => void;
  handleShareUpdate: () => void;
  updateShareList: UpdateShareList | undefined;
  updateShareListError: boolean;
  isUpdateShareListLoading: boolean;
  sendingUpdate: boolean;
  modelUpdateDocsModalVisible: boolean;
  setModelUpdateDocsModalVisible: (value: boolean) => void;
  lastUpdatedAccessible: Update | undefined;
  handleDownloadUpdate: (
    updateID: string,
    setDownloadRunning: (value: DownloadStatus) => void,
  ) => void;
}

export async function getLatestUpdateID(projectID: string): Promise<string> {
  try {
    const updatesData = await queryClient.fetchQuery(
      ['update history', projectID],
      async () => {
        const { data } = await api.get<UpdateHistoryData>(
          `/projects/${projectID}/updates`,
        );

        return data;
      },
      {
        staleTime: 1000 * 60 * 10,
        retry: false,
      },
    );

    const lastUpdatedAccessible =
      updatesData &&
      updatesData?.updates &&
      updatesData?.updates
        ?.sort(
          (a, b) => Number(new Date(b.created)) - Number(new Date(a.created)),
        )
        ?.find((update) => update.status === 'success');
    return lastUpdatedAccessible?.id ?? projectID;
  } catch (error) {
    return projectID;
  }
}

export const ModelUpdateContext = createContext({} as ModelUpdateInfo);

export const ModelUpdateProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const {
    id,
    name,
    createdAt,
    y,
    parentID: parentIDReference,
  } = useSelector((state: RootState) => state.project);

  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [parentProject, setParentProject] = useState<ParentProject | null>(
    null,
  );
  const [versionLabel, setVersionLabel] = useState('');
  const [modelUpdateNavigationLoading, setModelUpdateNavigationLoading] =
    useState(false);
  const [modelUpdateSideBarVisible, setModelUpdateSidebarVisible] =
    useState(false);
  const [updateToShare, setUpdateToShare] = useState('');
  const [errorOnUpdateAction, setErrorOnUpdateAction] = useState<ErrorObject>();
  const [sendingUpdate, setSendingUpdate] = useState(false);
  const [modelUpdateDocsModalVisible, setModelUpdateDocsModalVisible] =
    useState(false);

  useEffect(() => {
    if (!parentProject && id && name && createdAt) {
      if (parentIDReference && parentIDReference !== id) {
        api.get<Project>(`/projects/${parentIDReference}`).then(({ data }) => {
          setParentProject({
            id: data.id,
            label: data.name,
            created: data.created,
            ys: data.ys,
          });
        });
        return;
      }
      setParentProject({
        id: parentIDReference || id,
        label: name,
        created: createdAt,
        ys: y,
      });
    }
  }, [id, name, parentIDReference, parentProject, createdAt, y]);

  const {
    data: updateHistoryData,
    isFetching,
    isLoading,
    isError,
  } = useQuery(
    ['update history', parentIDReference || parentProject?.id || id],
    async () => {
      const { data } = await api.get<UpdateHistoryData>(
        `/projects/${parentIDReference || parentProject?.id || id}/updates`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 10,
      enabled: !!id || !!parentProject?.id || !!parentIDReference,
    },
  );

  const handleAccessProjectUpdate = useCallback(
    async (updateID: string, updateLabel: string) => {
      setModelUpdateNavigationLoading(true);
      try {
        const { data: projectData } = await api.get<Project>(
          `/projects/${updateID}`,
        );

        if (projectData) {
          const yBusinessAdjusted = Object.keys(
            projectData.business.y_status ?? {},
          ).map((key) => ({
            y: key,
            // @ts-expect-error to verificando em cima mas o typescript é teimoso
            status: projectData.business.y_status[key],
          }));

          dispatch(
            insert({
              id: projectData.id,
              name: projectData.name,
              icon: projectData.icon_url,
              y: projectData.ys,
              selectedY: projectData.ys[0],
              columns: projectData.overview.columns,
              accuracyCrit:
                projectData.model_spec.original?.accuracy_crit[0] ?? null,
              yBusiness: yBusinessAdjusted,
              updatedAt: projectData.last_updated,
              createdAt: projectData.created,
              parentID: projectData.parent_id,
              filters: projectData.filters,
              selectedFilters: {},
              appInfo: projectData.app_info,
            }),
          );
          dispatch(changeIsFinish(false));
          dispatch(resetAIUserSelectionOptions());

          if (parentProject?.id === updateID) {
            setVersionLabel('');
          } else {
            setVersionLabel(updateLabel);
          }

          setModelUpdateSidebarVisible(false);

          if (
            projectData.ys.filter((variable) => variable.status === 'success')
              .length > 1
          ) {
            navigate(`/models/time-series/${updateID}/project-overview`);
          } else {
            navigate(`/models/time-series/${updateID}/AI-selection`);
          }
        }
      } catch {
        setModelUpdateSidebarVisible(false);
      }
      setModelUpdateNavigationLoading(false);
    },
    [dispatch, parentProject?.id, navigate],
  );

  useEffect(() => {
    const sortedData =
      updateHistoryData &&
      updateHistoryData.updates.length &&
      updateHistoryData.updates.sort(
        (a, b) => Number(new Date(b.created)) - Number(new Date(a.created)),
      );

    const firstSuccessUpdate =
      sortedData && sortedData.find((update) => update.status === 'success');
    firstSuccessUpdate &&
      id !== firstSuccessUpdate.id &&
      handleAccessProjectUpdate(
        firstSuccessUpdate.id,
        firstSuccessUpdate.label,
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateHistoryData]);

  const {
    data: updateShareList,
    isError: updateShareListError,
    isLoading: isUpdateShareListLoading,
    isFetching: isUpdateShareListFetching,
  } = useQuery(
    ['update share', id, updateToShare],
    async () => {
      const { data } = await api.get<UpdateShareList>(
        `/projects/${
          parentIDReference || parentProject?.id || id
        }/updates/${updateToShare}/share`,
      );

      return data;
    },
    {
      staleTime: 10,
      enabled:
        !!updateToShare && (!!id || !!parentProject?.id || !!parentIDReference),
    },
  );

  const lastUpdatedAccessible =
    updateHistoryData &&
    updateHistoryData?.updates &&
    updateHistoryData?.updates
      ?.sort(
        (a, b) => Number(new Date(b.created)) - Number(new Date(a.created)),
      )
      ?.find((update) => update.status === 'success');

  const handleShareUpdate = useCallback(async () => {
    setSendingUpdate(true);
    try {
      await api.post(
        `/projects/${
          parentIDReference || parentProject?.id || id
        }/updates/${updateToShare}/share`,
      );

      queryClient.invalidateQueries('update share');
    } catch {
      setErrorOnUpdateAction({
        title: translate('shareUpdateErrorTitle'),
        description: translate('shareUpdateErrorDescription'),
      });
      setUpdateToShare('');
    }
    setSendingUpdate(false);
  }, [id, parentIDReference, parentProject?.id, translate, updateToShare]);

  const handleDownloadUpdate = useCallback(
    async (
      updateID: string,
      setDownloadStatus: (value: DownloadStatus) => void,
    ) => {
      setDownloadStatus('started');
      try {
        setDownloadStatus('running');
        const { data } = await api.get(`/projects/${updateID}/download`, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const updateInfo = updateHistoryData?.updates.find(
          (update) => update.id === updateID,
        );

        const fileURL = window.URL.createObjectURL(
          new Blob([data], { type: 'application/gzip' }),
        );

        const link = document.createElement('a');
        if (link.download !== undefined) {
          link.setAttribute('href', fileURL);
          link.setAttribute(
            'download',
            `Forecastpack_${name}_${updateInfo?.label ?? 'Original'}.zip`,
          );
          link.setAttribute('data-testid', 'download-start');
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
        }
        setDownloadStatus('success');
      } catch (error) {
        setDownloadStatus('error');
      }
    },
    [name, updateHistoryData?.updates],
  );

  return (
    <ModelUpdateContext.Provider
      value={{
        handleAccessProjectUpdate,
        modelUpdateDisabled:
          isError ||
          isLoading ||
          isFetching ||
          updateHistoryData?.updates.length === 0,
        updateHistory: updateHistoryData,
        modelUpdateSideBarVisible,
        setModelUpdateSidebarVisible,
        modelUpdateNavigationLoading,
        parentProject: parentProject!,
        versionLabel,
        errorOnUpdateAction,
        setErrorOnUpdateAction,
        handleShareUpdate,
        setUpdateToShare,
        updateToShare,
        updateShareList,
        isUpdateShareListLoading:
          isUpdateShareListLoading || isUpdateShareListFetching,
        updateShareListError,
        sendingUpdate,
        modelUpdateDocsModalVisible,
        setModelUpdateDocsModalVisible,
        lastUpdatedAccessible,
        handleDownloadUpdate,
      }}
    >
      {children}
    </ModelUpdateContext.Provider>
  );
};
