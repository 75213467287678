import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { WorkspaceHome } from 'src/workspaces/pages/Home';
import { CreateWorkspace } from 'src/workspaces/pages/Create';
import { WorkspaceOverview } from 'src/workspaces/pages/Overview';
import { ControlPanel } from 'src/workspaces/pages/ControlPanel';
import { WorkspaceProjections } from 'src/workspaces/pages/Projections';
import { WorkspaceOverallPerformance } from 'src/workspaces/pages/OverallPerformance';
import { WorkspaceVariablePerformance } from 'src/workspaces/pages/VariablePerformance';
import { NewReleaseConfiguration } from 'src/workspaces/pages/NewReleaseConfiguration';
import { NewReleaseConfigurationProvider } from 'src/workspaces/contexts/NewReleaseConfigurationContext';
import { WorkspaceConfigProvider } from 'src/workspaces/contexts/WorkspaceConfigContext';
import NotFound from 'src/pages/404';

import { PrivateRoutes } from '../PrivateRoute';

const NewReleaseConfigurationComponent: React.FC = () => (
  <NewReleaseConfigurationProvider>
    <NewReleaseConfiguration />
  </NewReleaseConfigurationProvider>
);

const CreateWorkspaceComponent: React.FC = () => (
  <WorkspaceConfigProvider>
    <CreateWorkspace />
  </WorkspaceConfigProvider>
);

export const WorkspaceRoutes = (): React.ReactElement => (
  <Routes>
    <Route
      caseSensitive
      path="/"
      element={
        <PrivateRoutes
          path="/"
          component={WorkspaceHome}
          roles={['isFaaS']}
          permissions={['read:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/new"
      element={
        <PrivateRoutes
          path="/new"
          component={CreateWorkspaceComponent}
          roles={['isFaaS']}
          permissions={['create:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/control-panel"
      element={
        <PrivateRoutes
          path="/:id/control-panel"
          component={ControlPanel}
          roles={['isFaaS']}
          permissions={['read:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/control-panel/edition"
      element={
        <PrivateRoutes
          path="/:id/control-panel/edition"
          component={CreateWorkspaceComponent}
          roles={['isFaaS']}
          permissions={['read:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/control-panel/release-configuration"
      element={
        <PrivateRoutes
          path="/:id/control-panel/release-configuration"
          component={NewReleaseConfigurationComponent}
          roles={['isFaaS']}
          permissions={['read:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/overview"
      element={
        <PrivateRoutes
          path="/:id/overview"
          component={WorkspaceOverview}
          roles={['isFaaS']}
          permissions={['read:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/projections"
      element={
        <PrivateRoutes
          path="/:id/projections"
          component={WorkspaceProjections}
          roles={['isFaaS']}
          permissions={['read:workspaces']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/overall-performance"
      element={
        <PrivateRoutes
          path="/:id/overall-performance"
          component={WorkspaceOverallPerformance}
          roles={['isFaaS']}
        />
      }
    />

    <Route
      caseSensitive
      path="/:id/variable-performance"
      element={
        <PrivateRoutes
          path="/:id/variable-performance"
          component={WorkspaceVariablePerformance}
          roles={['isFaaS']}
        />
      }
    />

    <Route path="*" element={<NotFound />} />
  </Routes>
);
