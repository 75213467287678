import React, { useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { X } from 'phosphor-react';
import apiWorkspace from 'src/workspaces/service/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { changeShowChangeHistory } from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import { format } from 'date-fns';
import { useInfiniteQuery } from 'react-query';
import ms from 'ms';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Tooltip } from 'react-tooltip';
import { addThousandSeparator } from 'src/utils/numbers/addThousandSeparator';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { enUS, ptBR } from 'date-fns/locale';

import {
  Container,
  Header,
  Content,
  Step,
  StepHeader,
  DateText,
  MessageError,
} from './styles';
import { Adjustments, HistoricalCard } from '../HistoricalCard';

type Logs = {
  action: string;
  data: {
    status: 'approved' | 'awaiting_approval';
    step: number;
    approval_message?: string[];
    awaiting_approval_message?: string[];
    disapproval_messages?: string[];
    adjustments?: {
      after: number;
      before: number;
      date: string;
      id: number;
      y: string;
    }[];
  };
  id: string;
  user: string;
  timestamp: string;
};

type LogsByStepAndDate = {
  step: {
    value: number;
    date: {
      value: string;
      logs: Logs[];
    }[];
  };
};

type Steps = {
  step: number | string;
  data: {
    value: string;
    cards: {
      id: string;
      email: string;
      adjustments: Adjustments[];
      approvalMessage: string | null;
      awaitingApprovalMessage: string | null;
      disapprovalMessage: string | null;
      isNewStage: boolean;
      hasStepBeenRemoved: boolean;
      discardedAdjustments: boolean;
      versionFinalized: boolean;
    }[];
  }[];
}[];

type ResponseLogs = {
  limit: number;
  skip: number;
  total: number;
  records: Logs[];
};

const QUANTITY_ITEMS_PAGE = 10;
const MAX_VALUE = 999999999.99;

export const Historical: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    workspace: { id, releaseSelected, frequency },
    workspaceOverviewOptions: { showChangeHistory },
    auth: { user },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top
            }px) - 74px)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 74px)`;
        }
      }
    }

    handleScroll(); //executa a primeira vez pra definir a
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const {
    data,
    isError,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['workspace', 'logs', id, 'releases', releaseSelected?.id],
    async ({ pageParam = 0 }) => {
      const response = await apiWorkspace.get<ResponseLogs>(
        `/workspaces/${id}/releases/${releaseSelected!.id}/logs?skip=${
          pageParam * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return response.data;
    },
    {
      enabled:
        !!id &&
        !!releaseSelected?.id &&
        !!releaseSelected.data.approval_flow?.enable,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_ITEMS_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
      staleTime: ms('5 min'),
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: 'always',
      refetchInterval: ms('1 min'),
    },
  );

  const fetchNextPageOnScroll = (
    event: React.UIEvent<HTMLDivElement>,
  ): void => {
    const isBottom =
      Math.floor(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop,
      ) <= event.currentTarget.clientHeight;

    if (isBottom && !isLoading && !isFetching && !isError && hasNextPage) {
      fetchNextPage();
    }
  };

  const formatValue = (value: number) => {
    if (value >= MAX_VALUE * -1 && value <= MAX_VALUE) {
      return addThousandSeparator(value, user.language);
    }

    return formatCompactNotation(value, 2, user.language);
  };

  const dateFormatter = (date: string): string => {
    let dateFormat = 'MMM/yy';

    if (frequency) {
      if (['annual', 'yearly'].includes(frequency)) {
        dateFormat = 'yyyy';
      } else if (
        frequency === 'daily' ||
        frequency === 'fortnightly' ||
        frequency === 'weekly'
      ) {
        dateFormat = user.language === 'en-us' ? 'MM/dd/yy' : 'dd/MM/yy';
      }
    }

    return format(new Date(date.replace(':00Z', '')), dateFormat, {
      locale: user.language === 'en-us' ? enUS : ptBR,
    });
  };

  function handleClose() {
    dispatch(changeShowChangeHistory(false));
  }

  const dateFormat = user.language === 'en-us' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

  const logsByStepAndDate: LogsByStepAndDate[] = [];

  data?.pages.forEach((page) =>
    page.records.forEach((log) => {
      const stepValue = log.data.step ?? 'finishedRelease';

      const stepIndex = logsByStepAndDate.findIndex(
        (aux) => aux.step.value === stepValue,
      );

      if (stepIndex !== -1) {
        const dateIndex = logsByStepAndDate[stepIndex].step.date.findIndex(
          (aux) => aux.value === format(new Date(log.timestamp), dateFormat),
        );

        if (dateIndex !== -1) {
          logsByStepAndDate[stepIndex].step.date[dateIndex].logs.push(log);
        } else {
          logsByStepAndDate[stepIndex].step.date.push({
            value: format(new Date(log.timestamp), dateFormat),
            logs: [log],
          });
        }
      } else {
        logsByStepAndDate.push({
          step: {
            value: stepValue,
            date: [
              {
                value: format(new Date(log.timestamp), dateFormat),
                logs: [log],
              },
            ],
          },
        });
      }
    }),
  );

  const steps: Steps = logsByStepAndDate.map((logByStep) => ({
    step: logByStep.step.value,
    data: logByStep.step.date.map((logByStepDate) => ({
      value: logByStepDate.value,
      cards: logByStepDate.logs.map((log) => {
        const adjustments: Adjustments[] = [];

        log.data.adjustments?.forEach((adjustment) => {
          const index = adjustments.findIndex(
            (aux) => aux.date === dateFormatter(adjustment.date),
          );

          let hierarchies = '';

          if (
            Object.values(releaseSelected?.data.y_filters?.[adjustment.y] || {})
              .length
          ) {
            hierarchies = Object.values(
              releaseSelected?.data.y_filters?.[adjustment.y] ?? {},
            )
              .filter((hierarchy) => hierarchy)
              .toString()
              .replaceAll(',', ' -> ');
          }

          const name = hierarchies
            ? `${adjustment.y} -> ${hierarchies}`
            : adjustment.y;

          const tooltip = `${formatValue(adjustment.before)} -> ${formatValue(
            adjustment.after,
          )}`;

          const adjustmentAux = {
            after: adjustment.after,
            before: adjustment.before,
            id: adjustment.id,
            name,
            tooltip,
          };

          if (index !== -1) {
            adjustments[index].ys.push(adjustmentAux);
          } else {
            adjustments.push({
              date: dateFormatter(adjustment.date),
              ys: [adjustmentAux],
            });
          }
        });

        const approvalMessage = log.data.approval_message?.length
          ? log.data.approval_message[0]
          : null;

        const awaitingApprovalMessage = log.data.awaiting_approval_message
          ?.length
          ? log.data.awaiting_approval_message[0]
          : null;

        const disapprovalMessage = log.data.disapproval_messages?.length
          ? log.data.disapproval_messages[0]
          : null;

        const isNewStage = log.action === 'added_step';

        const discardedAdjustments = log.action === 'discarded_adjusted';

        const hasStepBeenRemoved = log.action === 'removed_step';

        const versionFinalized = log.action === 'finished_release';

        return {
          id: log.id,
          email: log.user,
          adjustments,
          approvalMessage,
          awaitingApprovalMessage,
          disapprovalMessage,
          isNewStage,
          discardedAdjustments,
          hasStepBeenRemoved,
          versionFinalized,
        };
      }),
    })),
  }));

  return (
    <Container ref={containerRef} isOpen={showChangeHistory}>
      <Tooltip
        id="change-history-sidebar-tooltip"
        className="customTooltipTheme"
      />

      <Header>
        <h3>{t('workspaceOverviewLogsTitle')}</h3>
        <p>{t('workspaceOverviewLogsDescription')}</p>

        <button
          type="button"
          onClick={handleClose}
          data-testid="change-history-button-close"
          data-cy="change-history-button-close"
          aria-label="close feature store variables button"
        >
          <X size={16} />
        </button>
      </Header>

      <Content onScroll={fetchNextPageOnScroll}>
        {isError ? (
          <MessageError data-testid="change-history-message-error">
            {t('workspaceOverviewLogsMessageError')}
          </MessageError>
        ) : (
          steps.map((step, stepIndex) => (
            <Step key={`step-${stepIndex.toString()}`}>
              <StepHeader>
                <h4>
                  {step.step.toString() !== 'finishedRelease'
                    ? `${t('workspaceOverviewLogsStage')} ${step.step}`
                    : t('workspaceOverviewLogsRelease')}
                </h4>
              </StepHeader>
              {step.data.map((date, stepDataIndex) => (
                <React.Fragment
                  key={`step-${stepIndex.toString()}-data-${stepDataIndex.toString()}`}
                >
                  <DateText>{date.value}</DateText>
                  {date.cards.map((card) => (
                    <HistoricalCard
                      key={card.id}
                      email={card.email}
                      adjustments={card.adjustments}
                      approvalMessage={card.approvalMessage}
                      awaitingApprovalMessage={card.awaitingApprovalMessage}
                      disapprovalMessage={card.disapprovalMessage}
                      isNewStage={card.isNewStage}
                      discardedAdjustments={card.discardedAdjustments}
                      hasStepBeenRemoved={card.hasStepBeenRemoved}
                      versionFinalized={card.versionFinalized}
                    />
                  ))}
                </React.Fragment>
              ))}
            </Step>
          ))
        )}
        {isLoading || isFetching || isFetchingNextPage ? (
          Array.from({ length: 3 }).map((_, index) => (
            <div
              key={`logs-card-loading-${index.toString()}`}
              data-testid={`logs-card-loading-${index.toString()}`}
              style={{ marginTop: '2rem' }}
            >
              <ContainerSkeleton
                withLoading={false}
                style={{
                  width: '4rem',
                  height: '1rem',
                  marginBottom: '1rem',
                }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{ height: '9rem', marginBottom: '1rem' }}
              />
            </div>
          ))
        ) : steps.length === 0 ? (
          <MessageError data-testid="change-history-message-no-changes">
            {t('workspaceOverviewLogsStillNoChanges')}
          </MessageError>
        ) : null}
      </Content>
    </Container>
  );
};
