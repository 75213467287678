import React from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import EditingImg from 'src/assets/editing-one-page.svg';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { Status } from 'src/components/Status';

import { Container } from './styles';
import { ReviewModeEnabledProps } from './types';

export const ReviewModeEnabledModal: React.FC<ReviewModeEnabledProps> = ({
  setVisible,
}) => {
  const { t: translate } = useTranslation();

  return (
    <Modal visible style={{ width: '30rem' }}>
      <Container>
        <Status
          type="other"
          title={translate('workspaceOverviewResultsReviewModeEnabledTitle')}
          description={translate(
            'workspaceOverviewResultsReviewModeEnabledDescription',
          )}
          img={EditingImg}
          isModal
          dataCy="review-mode-enabled"
        />
      </Container>

      <ModalFooter>
        <Button
          buttonType="primary"
          onClick={() => setVisible(false)}
          data-testid="review-mode-enabled-ok-button"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
