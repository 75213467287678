const modelInProductionEnPTBR = {
  modelInProductionTitle: 'Seleção do Usuário',
  modelInProductionDescription:
    'Otimize sua tomada de decisão com base nos resultados de seu modelo favorito. Acesse previsões, decomponha resultados históricos, simule cenários alternativos e muito mais.',

  modelInProductionSelectedModel: 'Modelo Selecionado',
  modelInProductionSelectedModelSample: 'Amostra',
  modelInProductionSelectedModelTransformation: 'Transformação',
  modelInProductionAnnualGrowth: 'Taxa de Crescimento Anual (%)',
  modelInProductionAnnualSeries: 'Série Anual',

  modelInProductionLevel: 'Nível',
  modelInProductionVariation: 'Variação',

  modelInProductionActual: 'Atual',
  modelInProductionForecast: 'Projeção automática das Variáveis explicativas',

  modelInProductionExport: 'Exportar',
  modelInProductionExportDescription:
    'Exporte um arquivo .xlsx que contém a série de interesse, com suas previsões, e todas as variáveis explicativas consideradas pelo modelo.',

  modelInProductionDate: 'Data',
  modelInProductionValue: 'Valor',
  modelInProductionVariable: 'Variável',
  modelInProductionPercentage: 'Porcentagem',
  modelInProductionYVariation: 'Variação Y',
  modelInProductionDataType: 'Tipo de Dados',
  modelInProductionErrorMessage:
    'Projeção do modelo usando o comportamento da própria série histórica.',

  modelInProductionEstimatedCoefficients: 'Coeficientes Estimados',
  modelInProductionSimulations: 'Simulações',
  modelInProductionYear: 'Ano',
  modelInProductionRestoreDefaultValues: 'Restaurar valores padrão',
  modelInProductionSimulate: 'Simular',
  modelInProductionProjectionExplanatoryVariables:
    'Projeção das Variáveis Explicativas',

  modelInProductionExplainingResults: 'Entendendo os Resultados',
  modelInProductionExplainingResultsDescription:
    'Decomponha os resultados históricos com base nos principais determinantes identificados pelo seu modelo preferido.',
  modelInProductionExplainingInfo:
    'Os gráficos mostram as variações percentuais das séries (linhas e pontos) desagregadas em componentes principais (barras), de forma que a soma desta última seja equivalente à primeira. As variáveis de fluxo são o oposto das variáveis de estoque. <br/> Tecnicamente, as variáveis de fluxo são medidas em um determinado período de tempo, nunca o transcendendo. <br/> Exemplos: o PIB é uma variável de fluxo, pois é medido em trimestres, semestres ou anos, enquanto a população é uma variável de estoque, pois de um mês para o próximo o estoque populacional não é zerado.',
  modelInProductionBreakdownMessage:
    'Visualização não está disponível para esse modelo.',
  modelInProductionBreakdownIsNotAvailableFirst3Models:
    'Visualização disponível apenas para os 3 primeiros modelos arima.',
  modelInProductionArimaBreakdownIsNotAvailableDailyWeeklyFortnightly:
    'Visualização não disponível para dados com frequência diária, semanal ou quinzenal.',
  modelInProductionArimaBreakdownOnlyAvailableForMonthlyData:
    'Disponível apenas para dados mensais.',

  modelInProductionDownloadError: 'Ocorreu um erro ao baixar o arquivo.',
  modelInProductionSimulationError: 'Ocorreu um erro durante a simulação.',
  modelInProductionSeeMoreInformation: 'Veja mais informações sobre o modelo',
  modelInProductionMessageErrorAnnualGrowthRateNotThisModel:
    'A taxa de crescimento anual não está disponível para este modelo',
  modelInProductionMessageErrorAnnualSeriesNotThisModel:
    'A série anual não está disponível para este modelo',
  modelInProductionMessageErrorAnnualSeriesRateIsNotModel:
    'A taxa de série anual não está disponível para este modelo',
  modelInProductionMessageErrorQuarterlySeriesRateIsNotModel:
    'A taxa de série trimestral não está disponível para este modelo',
  modelInProductionMessageErrorBimonthlySeriesRateIsNotModel:
    'A taxa de série bimestral não está disponível para este modelo',
  modelInProductionMessageErrorHalfYearSeriesRateIsNotModel:
    'A taxa de série semestral não está disponível para este modelo',

  modelInProductionExplanatoryVariablesLatestData: 'Dados recentes',
};

export default modelInProductionEnPTBR;
