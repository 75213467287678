import styled, { css, keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-320px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

const disappearToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to{
    
    opacity: 0;
    transform: translateX(-320px);
  }
`;

interface ContainerProps {
  visible: boolean;
  loading: boolean;
}

export const Container = styled.aside<ContainerProps>`
  position: fixed;
  left: 0;
  height: 100vh;

  width: 100%;
  min-width: ${({ visible }) => (visible ? '22rem' : '0px')};
  max-width: ${({ visible }) => (visible ? '22rem' : '0px')};
  transition: min-width 1s;
  margin-bottom: -1.5rem;

  background: ${({ theme }) => theme.colors.white};

  border-right: ${({ theme }) => `1px solid ${theme.colors.gray2}`};

  z-index: 1200;

  animation: ${({ visible }) => (visible ? appearFromLeft : disappearToLeft)} 1s;

  cursor: ${({ loading }) => (loading ? 'progress' : 'auto')};

  button {
    cursor: ${({ loading }) => (loading ? 'progress' : 'pointer')};
  }

  > div {
    display: flex;
    flex-direction: column;
    position: sticky;
    height: 100%;
    top: 4.625rem; // 74header
    overflow-y: scroll;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }

  ::-webkit-scrollbar-track {
    transition: 0.2s;
    margin: 0.188rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

interface OverlayProps {
  visible: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  background: rgba(226, 232, 240, 0.08);
  backdrop-filter: ${({ visible }) => (visible ? 'blur(4px)' : 'none')};
  transition: backdrop-filter 1s ease-in;
  z-index: 1000;
`;

export const ContentTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  padding: 2rem 1.5rem 0;

  h3 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    color: ${({ theme }) => theme.colors.gray7};
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const UpdateHistoryList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0 !important;

  padding: 0 1.5rem;

  gap: 1rem;
  width: 100%;

  overflow-y: auto;
`;

interface VersionProps {
  selected: boolean;
  disabled?: boolean;
}

export const Version = styled.div<VersionProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;

  display: flex;

  align-items: flex-start;
  align-self: stretch;

  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray2};
  border-radius: 8px;

  padding: 1rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `};

  > div:first-child {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;

      margin-bottom: 0.75rem;

      h4 {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: ${({ theme }) => theme.colors.gray6};
        white-space: pre-line;
        word-break: break-word;

        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.gray5};
        white-space: pre-line;
        word-break: break-word;
      }
    }

    > p {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.gray6};

      span {
        font-size: 0.625rem;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.05rem;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.gray5};
      }
    }

    > p + p {
      margin-top: 0.5rem;
    }
  }
`;

export const CloseSidebarButton = styled.button`
  z-index: 2001;

  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  gap: 0.625rem;

  position: absolute;
  top: 2.5rem;
  right: -1.1rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 50%;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

type StatusContainerProps = {
  status: 'in progress' | 'done';
};

export const StatusContainer = styled.div<StatusContainerProps>`
  width: fit-content;

  border-radius: 999px;

  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem !important;

  font-size: 0.625rem;
  font-weight: 500;
  line-height: normal;

  ${({ status, theme }) =>
    status === 'in progress'
      ? css`
          color: ${theme.colors.primary};
          background: rgba(76, 148, 255, 0.12);
        `
      : css`
          color: ${theme.colors.gray5};
          background: ${theme.colors.gray1};
        `}
`;
