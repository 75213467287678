import { format } from 'date-fns';
import { WorkspaceOverviewPeriod } from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';

export const getSelectedFrequencyAndPeriod = (
  frequency: string,
  period: WorkspaceOverviewPeriod,
  language: 'en-us' | 'pt-br',
): string => {
  if (period[0] === null) {
    return frequency;
  }

  const dateFormat = language === 'en-us' ? 'MM/dd/yyyy' : 'dd/MM/yyyy';

  const firstDate = format(period[0], dateFormat);

  let secondDate = '';

  if (period[1]) {
    secondDate = format(period[1], dateFormat);
  }

  return `${frequency} (${firstDate} - ${secondDate})`;
};
