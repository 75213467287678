import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { CheckCircle, Copy, File, X } from 'phosphor-react';
import { CodeBlock, github } from 'react-code-blocks';
import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import api from 'src/models/service/api';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Project } from 'src/models/hooks/useQueryProjects/types';

import { sample } from './documentationCode';
import {
  CloseModalButton,
  CodeBlock as CodeBlockContainer,
  Container,
  LanguageSelector,
  LanguageButton,
  CopyCodeButton,
  ProjectIDContainer,
  GetTokenButton,
} from './styles';
import { ModelUpdateContext } from '../../contexts/ModelUpdateContext';

const apiURL = process.env.REACT_APP_API_MODEL_UPDATE_URL;

export const ModelUpdateDocsModal: React.FC = () => {
  const { t: translate } = useTranslation();
  const [language, setLanguage] = useState('python');
  const { getAccessTokenSilently } = useAuth0();
  const { modelUpdateDocsModalVisible, setModelUpdateDocsModalVisible } =
    useContext(ModelUpdateContext);

  const { id, parentID: parentIDReference } = useSelector(
    (state: RootState) => state.project,
  );
  const { language: appLanguage } = useSelector(
    (state: RootState) => state.auth.user,
  );

  const [codeCopied, setCodeCopied] = useState(false);
  const [apiTokenCopied, setAPITokenCopied] = useState(false);

  function openDocumentation() {
    const linkToDocumentation =
      appLanguage === 'pt-br'
        ? 'https://storage.googleapis.com/bkt-prod-4casthub/docs/model-update-api-pt-br.pdf'
        : 'https://storage.googleapis.com/bkt-prod-4casthub/docs/model-update-api-en-us.pdf';
    const link = document.createElement('a');
    link.setAttribute('href', linkToDocumentation ?? '');
    link.setAttribute('target', '_blank');
    link.style.visibility = 'hidden';
    link.click();
  }

  const { data: yData } = useQuery(
    ['parent project', parentIDReference, id],
    async () => {
      const { data } = await api.get<Project>(
        `/projects/${parentIDReference || id}`,
      );

      const ys = data.ys.map((y) => y.name);

      return ys;
    },
    {
      enabled: !!parentIDReference || !!id,
    },
  );

  const PythonCode = yData
    ? sample.python
        .replace(
          '"DATASET_FILE_NAMES"',
          yData.length > 1
            ? `${yData
                .map(
                  (y, index) =>
                    `NEW_DATASET_FILENAME_${index + 1} = "dataset-${y}.csv"\n`,
                )
                .join('')}`
            : `NEW_DATASET_FILENAME = "dataset-${yData[0]}.csv"`,
        )
        .replace(
          '"YS"',
          yData.length > 1
            ? `${yData
                .map(
                  (y, index) =>
                    `y${index + 1} = "${y}"\ndataset_${
                      index + 1
                    } = pd.read_csv(NEW_DATASET_FILENAME_${
                      index + 1
                    }).replace(np.nan, None).to_dict(orient="records")\n`,
                )
                .join('')}`
            : `y = "${yData[0]}"\ndataset = pd.read_csv(NEW_DATASET_FILENAME).replace(np.nan, None).to_dict(orient="records")`,
        )
        .replace(
          '"DATALIST"',
          yData.length > 1
            ? `datasets = {\n  ${yData
                .map(
                  (_, index) =>
                    `y${index + 1}: dataset_${index + 1}${
                      index + 1 < yData.length ? ',\n  ' : '\n'
                    }`,
                )
                .join('')}}`
            : 'datasets = {\n  y: dataset\n}',
        )
        .replace('{ORIGINAL_PROJECT_ID}', parentIDReference ?? id!)
        .replace('{APIURL}', apiURL!)
        .replace('"COMMENT"', translate('updateDocsModalFileComment'))
    : sample.python.replace(
        '"COMMENT"',
        translate('updateDocsModalFileComment'),
      );

  const RCode = yData
    ? sample.r
        .replace(
          '"NEW_DATASET_FILENAME"',
          yData.length > 1
            ? `${yData
                .map(
                  (y, index) =>
                    `NEW_DATASET_FILENAME_${index + 1} <- "dataset-${y}.csv"\n`,
                )
                .join('')}`
            : `NEW_DATASET_FILENAME <- "dataset-${yData[0]}.csv"`,
        )
        .replace(
          '"YS"',
          yData.length > 1
            ? `${yData
                .map(
                  (y, index) =>
                    `y${index + 1} <- "${y}"\ndataset_${
                      index + 1
                    } <- utils::read.csv(NEW_DATASET_FILENAME_${index + 1})\n`,
                )
                .join('')}`
            : `y <- "${yData[0]}"\ndataset <- utils::read.csv(NEW_DATASET_FILENAME)`,
        )
        .replace(
          '"DATA_LIST"',
          yData.length > 1
            ? `datasets <- list(\n  ${yData
                .map(
                  (_, index) =>
                    `dataset_${index + 1}${
                      index + 1 < yData.length ? ',\n  ' : '\n'
                    }`,
                )
                .join('')})`
            : `datasets <- list(dataset)`,
        )
        .replace(
          '"CYS"',
          yData.length > 1
            ? `${yData
                .map(
                  (_, index) =>
                    `y${index + 1}${index + 1 < yData.length ? ', ' : ''}`,
                )
                .join('')}`
            : 'y',
        )
        .replace('{ORIGINAL_PROJECT_ID}', parentIDReference ?? id!)
        .replace('{APIURL}', apiURL!)
        .replace('"COMMENT"', translate('updateDocsModalFileComment'))
    : sample.r.replace('"COMMENT"', translate('updateDocsModalFileComment'));

  async function copyCodeToClipboard() {
    setCodeCopied(true);
    const token = await getAccessTokenSilently();

    id &&
      navigator.clipboard.writeText(
        language === 'python'
          ? PythonCode.replace('API TOKEN', token).replace(
              'SEU PROJECT ID',
              parentIDReference ?? id,
            )
          : RCode.replace('API TOKEN', token).replace(
              'SEU PROJECT ID',
              parentIDReference ?? id,
            ),
      );

    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);
  }

  const copyAPIToken = async () => {
    setAPITokenCopied(true);
    const token = await getAccessTokenSilently();

    navigator.clipboard.writeText(token);

    setTimeout(() => {
      setAPITokenCopied(false);
    }, 3000);
  };

  return (
    <Modal
      visible={modelUpdateDocsModalVisible}
      setVisible={setModelUpdateDocsModalVisible}
    >
      <Container>
        <h3>{translate('updateDocsModalTitle')}</h3>
        <p>{translate('updateDocsModalDescription')}</p>

        <ProjectIDContainer>
          <div>
            <p>{translate('projectID')}</p>
            <span>{parentIDReference ?? id}</span>
          </div>
        </ProjectIDContainer>

        <LanguageSelector>
          <GetTokenButton
            copied={apiTokenCopied}
            data-testid="get-token-button"
            onClick={copyAPIToken}
          >
            {apiTokenCopied ? <CheckCircle size={20} /> : <Copy size={20} />}
            <p>
              {apiTokenCopied
                ? `API Token ${translate('copied')}!`
                : `${translate('copy')} API Token`}
            </p>
          </GetTokenButton>
          <div>
            <LanguageButton
              onClick={() => {
                setLanguage('python');
                setCodeCopied(false);
              }}
              selected={language === 'python'}
              data-testid="button-language-python"
              data-cy="button-language-python"
            >
              <p>Python</p>
            </LanguageButton>
            <LanguageButton
              onClick={() => {
                setLanguage('r');
                setCodeCopied(false);
              }}
              selected={language === 'r'}
              data-testid="button-language-r"
              data-cy="button-language-r"
            >
              <p>R</p>
            </LanguageButton>
          </div>
        </LanguageSelector>
        <div />
        <CodeBlockContainer>
          <CopyCodeButton
            onClick={copyCodeToClipboard}
            data-testid="copy-code-button"
            data-cy="copy-code-button"
            copied={codeCopied}
          >
            <div>
              {codeCopied ? <CheckCircle size={20} /> : <Copy size={20} />}
              <p>
                {codeCopied ? `${translate('copied')}!` : translate('copy')}
              </p>
            </div>
          </CopyCodeButton>
          <div>
            <CodeBlock
              language={language}
              text={
                language === 'python'
                  ? PythonCode.replace(
                      'SEU PROJECT ID',
                      translate('yourProjectID'),
                    )
                  : RCode.replace('SEU PROJECT ID', translate('yourProjectID'))
              }
              showLineNumbers={false}
              theme={github}
              wrapLines
              codeBlock
            />
          </div>
        </CodeBlockContainer>

        <CloseModalButton
          type="button"
          onClick={() => setModelUpdateDocsModalVisible(false)}
          data-cy="close-script-modal-button"
          data-testid="close-script-modal-button"
        >
          <X size={20} />
        </CloseModalButton>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={openDocumentation}
          data-cy="see-documentation-button"
          data-testid="see-documentation-button"
          icon={<File size={20} />}
          className="naked-button-blue"
        >
          {translate('claasAPIModalSeeDocs')}
        </Button>
        <Button
          buttonType="primary"
          onClick={() => setModelUpdateDocsModalVisible(false)}
          data-cy="ok-button"
          data-testid="ok-button"
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};
