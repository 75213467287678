import React from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { PerformanceByPeriodTable } from './components/PerformanceByPeriod';
import { Container } from './styles';
import { ButtonSyncAdjust } from '../../components/ButtonSyncAdjust';

export const WorkspaceOverallPerformance: React.FC = () => {
  const { t: translate } = useTranslation();
  const { releaseSelected } = useSelector(
    (state: RootState) => state.workspace,
  );

  const showButtonSyncAdjust = !releaseSelected?.data.approval_flow?.enable;

  return (
    <Container>
      <Head title={translate('labelOverallPerformance')} />

      <div className="containerLinear page-title">
        <Card
          textCard={translate('labelOverallPerformance')}
          textDescription={translate('workspaceOverallPerformanceDescription')}
        />

        {showButtonSyncAdjust && <ButtonSyncAdjust />}
      </div>

      <PerformanceByPeriodTable />
    </Container>
  );
};
