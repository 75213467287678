import React, { ReactElement, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { Loading } from 'src/components/Loading';
import { FeatureStoreLayout } from 'src/feature-store/components/Layout';
import { ModelsLayout } from 'src/models/components/Layout';
import { RootState } from 'src/redux/store';
import CookieImg from 'src/assets/terms-of-use.svg';
import { SearchBar } from 'src/feature-store/components/Layout/SearchBar';
import { WorkspaceLayout } from 'src/workspaces/components/Layout';

import { Footer } from './Footer';
import { Header } from './Header';
import { Container, ContainerCookie, ContainerCookieText } from './styles';

export const Layout: React.FC<{ children: ReactElement }> = ({ children }) => {
  const location = useLocation();
  const [modalCookieVisible, setModalCookieVisible] = useState(false);

  const { isAuthenticated, isLoading } = useAuth0();
  const { isAuthenticated: isAuthenticatedRedux, user } = useSelector(
    (state: RootState) => state.auth,
  );

  const { t: translate } = useTranslation();

  const LinkToPolicy = process.env.REACT_APP_LINK_PRIVACY_POLICY;
  const LinkToTermsOfUsePT = process.env.REACT_APP_LINK_TERMS_OF_USE;
  const LinkToTermsOfUseEN = process.env.REACT_APP_LINK_TERMS_OF_USE_EN;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const value = localStorage.getItem('modalCookieVisible');
    if (value !== 'false') {
      setModalCookieVisible(true);
    }
  }, []);

  const handleClickAcceptCookie = () => {
    setModalCookieVisible(false);
    localStorage.setItem('modalCookieVisible', 'false');
  };

  const isFeatureStoreRoutes = location.pathname.includes('/feature-store');
  const isHomePage = location.pathname === '/';

  if (isLoading && !isAuthenticated) {
    return <Loading data-testid="layout-loading" />;
  }

  if (!isAuthenticatedRedux) {
    return (
      <>
        {children}
        {isAuthenticatedRedux && <Footer style={{ marginTop: '80px' }} />}
        <ContainerCookie visible={modalCookieVisible}>
          <div>
            <img src={CookieImg} alt="cookies" />
            <ContainerCookieText>
              <p>
                {`${translate('layoutTextCookie')} `}
                <a
                  href={
                    user.language === 'en-us'
                      ? LinkToTermsOfUseEN
                      : LinkToTermsOfUsePT
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate('layoutTextTerms')}
                </a>
                {`. ${translate('layoutTextRead')} `}
                <a
                  href={LinkToPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate('layoutTextPrivacyPolicy')}
                </a>
                .
              </p>
            </ContainerCookieText>
            <Button buttonType="primary" onClick={handleClickAcceptCookie}>
              {translate('layoutTextAcceptCookie')}
            </Button>
          </div>
        </ContainerCookie>
      </>
    );
  }

  return (
    <>
      <Container
        data-testid="layout-container"
        data-cy="layout-container"
        isFeatureStore={isFeatureStoreRoutes}
      >
        <Header />

        {isFeatureStoreRoutes &&
          !location.pathname.endsWith('chat4i') &&
          !location.pathname.includes('admin') && <SearchBar />}

        <div
          data-testid="layout-container-div"
          data-cy="layout-container-div"
          className="layout-container-div"
        >
          {location.pathname.includes('/models') ||
          location.pathname.includes('/admin') ||
          location.pathname.includes('/user-module') ? (
            <ModelsLayout>{children}</ModelsLayout>
          ) : isFeatureStoreRoutes ? (
            <FeatureStoreLayout>{children}</FeatureStoreLayout>
          ) : location.pathname.startsWith('/workspaces') ? (
            <WorkspaceLayout>{children}</WorkspaceLayout>
          ) : (
            children
          )}
        </div>

        {!isHomePage && <Footer />}
      </Container>

      <ContainerCookie visible={modalCookieVisible}>
        <div>
          <img src={CookieImg} alt="cookies" />
          <ContainerCookieText>
            <p>
              {`${translate('layoutTextCookie')} `}
              <a
                href={
                  user.language === 'en-us'
                    ? LinkToTermsOfUseEN
                    : LinkToTermsOfUsePT
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate('layoutTextTerms')}
              </a>
              {`. ${translate('layoutTextRead')} `}
              <a href={LinkToPolicy} target="_blank" rel="noopener noreferrer">
                {translate('layoutTextPrivacyPolicy')}
              </a>
              .
            </p>
          </ContainerCookieText>
          <Button buttonType="primary" onClick={handleClickAcceptCookie}>
            {translate('layoutTextAcceptCookie')}
          </Button>
        </div>
      </ContainerCookie>
    </>
  );
};
