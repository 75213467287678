import { useQuery } from 'react-query';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { DataTypeResponse, UseQueryYTypesResponse } from './types';

export const useQueryYTypes = (): UseQueryYTypesResponse => {
  const {
    data: dataType,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['workspace data types'],
    async () => {
      const { data } = await apiWorkspace.get<DataTypeResponse>(
        `/workspaces/types/y`,
      );

      const defaultOptions = ['marketsize', 'sellout', 'others'];

      return data.data
        .filter(({ code }) => !defaultOptions.includes(code))
        .sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0));
    },
    {
      staleTime: ms('3 min'),
    },
  );

  return { data: dataType, isLoading: isLoading || isFetching, isError };
};
