import React, { CSSProperties, ReactNode } from 'react';

import { Container, Content } from './styles';
import { ModalOverlay } from './Overlay';

type PropsModal = {
  visible?: boolean;
  setVisible?: (value: boolean) => void;
  style?: CSSProperties;
  cancel?: () => void;
  animation?: boolean;
  children?: ReactNode;
  hasFooter?: boolean;
  dataCy?: string;
  animationName?: 'opacityAnimation' | 'defaultAnimation';
};

export const Modal: React.FC<PropsModal> = ({
  visible = false,
  setVisible,
  cancel,
  style,
  children,
  animation = true,
  hasFooter = false,
  dataCy,
  animationName = 'defaultAnimation',
}) => (
  <>
    <Container visible={visible} data-cy={dataCy} data-testid={dataCy}>
      <ModalOverlay
        id="container-modal-overlay"
        data-testid="modalOverlay"
        onClick={() => {
          setVisible && setVisible(false);
          cancel && cancel();
        }}
      />
      <Content
        visible={visible}
        style={style}
        animation={animation}
        animationName={animationName}
        hasFooter={hasFooter}
      >
        {children}
      </Content>
    </Container>
  </>
);
