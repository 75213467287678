import styled from 'styled-components';

export const MySeriesContainer = styled.div`
  padding: 1.5rem;

  > div {
    margin: 0 auto;

    max-width: 1140px;
    @media (min-width: 1140px) {
      min-width: 1140px;
    }
  }
`;

export const MySeriesHeader = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;

  margin-bottom: 1.5rem;

  > div:first-child {
    margin-bottom: 0;
  }
`;

export const ContentAutoCompleteInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  height: 3.125rem;

  input {
    width: 15.625rem;
  }

  .inputAdjustPadding {
    div {
      > div {
        padding-top: 0.8125rem !important;
        padding-bottom: 0.8125rem !important;

        height: 2.8125rem;
      }
    }
  }
`;

export const Content = styled.div``;
