import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { UseQueryReleaseDataResponse, ReleaseData } from './types';

export const useQueryReleaseData = (
  workspaceId?: string | null,
  releaseId?: string | null,
  refetchInterval = true,
): UseQueryReleaseDataResponse => {
  const navigate = useNavigate();

  const needRefetchData = (releaseData?: ReleaseData) =>
    releaseData?.status !== 'finished' && releaseData?.data?.steps?.length;

  const { data, isLoading, isFetching } = useQuery(
    ['workspace', workspaceId, 'releases', releaseId],
    async () => {
      const response = await apiWorkspace.get<ReleaseData>(
        `/workspaces/${workspaceId}/releases/${releaseId}`,
      );

      return response.data;
    },
    {
      staleTime: ms('5 min'),
      onError: () => navigate('/workspaces'),
      enabled: !!workspaceId && !!releaseId,
      refetchIntervalInBackground: refetchInterval,
      refetchOnWindowFocus: refetchInterval
        ? ({ state }) => {
            if (needRefetchData(state.data)) {
              return 'always';
            }

            return false;
          }
        : false,
      refetchInterval: refetchInterval
        ? (releaseData) => {
            if (needRefetchData(releaseData)) {
              return ms('30s');
            }

            return false;
          }
        : false,
    },
  );

  return { data, isLoading: isLoading || isFetching };
};
