/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { FileXls, FileCsv } from 'phosphor-react';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { MenuOverlay } from 'src/feature-store/pages/GroupView/styles';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { useTranslation } from 'react-i18next';

import { ToggleSwitchProjectionContainer } from './styles';

type MenuExportProps = {
  isVisible: boolean;
  handleDownload: (type: 'csv' | 'excel') => void;
  setIsVisible: (isVisible: boolean) => void;
  setIsFullPeriodForecast: (isFull: boolean) => void;
  styles?: React.CSSProperties;
  isChecked: boolean;
};

export const MenuExportProjection = ({
  isVisible,
  handleDownload,
  setIsVisible,
  setIsFullPeriodForecast,
  styles,
  isChecked,
}: MenuExportProps) => {
  const { t: translate } = useTranslation();

  return (
    <MenuContainer
      visible={isVisible}
      style={{ bottom: '3.125rem', width: 'max-content', ...styles }}
    >
      {isVisible && (
        <MenuOverlay
          data-testid="menu-overlay"
          visible={isVisible}
          onClick={() => {
            setIsVisible(false);
            setIsFullPeriodForecast(false);
          }}
        />
      )}

      <ToggleSwitchProjectionContainer position="start">
        <p>{translate('fullForecast')}</p>
        <ToggleSwitch
          onChange={(event) => setIsFullPeriodForecast(event.target.checked)}
          checked={isChecked}
          data-testid="toggle-switch-projection"
        />
      </ToggleSwitchProjectionContainer>

      <MenuOption
        type="button"
        data-testid="buttonXLXS"
        onClick={() => handleDownload('excel')}
        position="alone"
      >
        <FileXls size="1.25rem" />
        <p>Excel</p>
      </MenuOption>

      <MenuOption
        type="button"
        data-testid="buttonCSV"
        onClick={() => handleDownload('csv')}
        position="end"
      >
        <FileCsv size="1.25rem" />
        <p>CSV</p>
      </MenuOption>
    </MenuContainer>
  );
};
