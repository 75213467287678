export const light = {
  colors: {
    primaryLight: '#81B3FF',
    primary: '#4C94FF',
    primaryDark: '#2265C9',

    secondaryLight: '#FFABD3',
    secondary: '#F27DB4',
    secondaryDark: '#C74F88',

    orange2: '#f48026',
    orange4: '#ED8936',

    red2: '#ff6b6b',
    red3: '#a8224a',
    red4: '#F56565',
    red5: '#E53E3E',
    red6: '#C53030',

    blue4: '#4299E1',

    green: '#22a867',
    green4: '#48BB78',
    green5: '#38A169',
    green6: '#2F855A',

    yellow: '#F4CF2A',
    yellow0: '#FFFFF0',
    yellow2: '#FAF089',
    yellow4: '#ECC94B',
    yellow5: '#D69E2E',
    yellow6: '#FAB005',

    purple4: '#9F7AEA',
    purple5: '#6B46C1',

    teal4: '#38B2AC',
    teal5: '#2f9691',

    cyan4: '#0BC5EA',

    white: '#fff',
    gray0: '#F7FAFC',
    gray1: '#EDF2F7',
    gray2: '#E2E8F0',
    gray3: '#CBD5E0',
    gray4: '#A0AEC0',
    gray5: '#718096',
    gray6: '#4A5568',
    gray7: '#2D3748',
    gray8: '#1A202C',
    gray9: '#171923',
    black: '#000',
  } as const,
};
export default light;
