import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryReleaseData } from 'src/workspaces/hooks/useQueryReleaseData';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Label } from 'src/components/Label';
import { useQueryYTypes } from 'src/workspaces/hooks/useQueryYTypes';

import { AggregationValues, Container, Description, Title } from './styles';

const AGG_TRANSLATIONS = {
  sum: 'workspaceConfigAggregationsSum',
  average: 'workspaceConfigAggregationsAverage',
  last_of_period: 'workspaceConfigAggregationsEndOfPeriod',
  not_aggregated: 'workspaceConfigAggregationsNoAggregation',
} as const;

export const Parametrization: React.FC = () => {
  const { t: translate } = useTranslation();

  const { workspace } = useSelector((state: RootState) => state);

  const { data: releaseData } = useQueryReleaseData(
    workspace.id,
    workspace.releaseSelected?.id,
    false,
  );

  const { data: dataTypes } = useQueryYTypes();

  const dataTypesDict: { [key: string]: string } =
    dataTypes?.reduce(
      (obj, value) => ({
        ...obj,
        [value.code]: value.label,
      }),
      {},
    ) ?? {};

  const aggregations = releaseData?.data.aggregation;

  const firstTemporalAggregation =
    aggregations?.options?.temporal?.[0]?.frequency_method ?? 'sum';
  const isIndividualTemporalAggregation = aggregations?.options?.temporal?.some(
    ({ frequency_method }) => frequency_method !== firstTemporalAggregation,
  );

  let firstLevelAggregation =
    aggregations?.options?.hierarchical?.[0]?.frequency_method;
  if (!firstLevelAggregation) {
    firstLevelAggregation = aggregations?.enable ? 'sum' : 'not_aggregated';
  }

  const isIndividualLevelAggregation =
    aggregations?.options?.hierarchical?.some(
      ({ frequency_method }) => frequency_method !== firstLevelAggregation,
    );

  const parametrizationOptions =
    aggregations?.options?.hierarchical ??
    aggregations?.options?.temporal ??
    [];

  const hasMarketSizeAndSellOut =
    parametrizationOptions?.filter(({ y_type }) =>
      ['marketsize', 'sellout'].includes(y_type.toLowerCase()),
    ).length === 2;

  const showTemporalAggregation =
    workspace.frequency !== 'annual' && workspace.frequency !== 'yearly';

  return (
    <Container data-testid="container-parametrization">
      <Title>{translate('workspaceOverviewParametrization')}</Title>
      <Description>
        {translate('workspaceOverviewParametrizationDescription')}
      </Description>

      <AggregationValues>
        {showTemporalAggregation && (
          <>
            <Label>
              {translate('workspaceOverviewParametrizationTemporalAggregation')}
            </Label>

            {!isIndividualTemporalAggregation ? (
              <p data-testid="text-temporal-aggregation">
                {translate(AGG_TRANSLATIONS[firstTemporalAggregation])}
              </p>
            ) : (
              aggregations?.options?.temporal.map((agg) => {
                const yTypeLowerCase = agg.y_type.toLowerCase();

                if (hasMarketSizeAndSellOut && yTypeLowerCase === 'sellout')
                  return null;

                const isMarketSizeAndSellOut =
                  hasMarketSizeAndSellOut && yTypeLowerCase === 'marketsize';

                const yType = isMarketSizeAndSellOut
                  ? 'Market Size / Sell Out'
                  : yTypeLowerCase === 'others'
                  ? translate('workspaceOverviewOthers')
                  : yTypeLowerCase === 'marketsize'
                  ? 'Market Size'
                  : yTypeLowerCase === 'sellout'
                  ? 'Sell Out'
                  : dataTypesDict[agg.y_type] ?? agg.y_type;

                return (
                  <p
                    key={yType}
                    data-testid={`text-individual-temporal-aggregation-${yType
                      .replaceAll(' ', '-')
                      .toLowerCase()}`}
                  >
                    <span>{yType}: </span>
                    {translate(AGG_TRANSLATIONS[agg.frequency_method])}
                  </p>
                );
              })
            )}
          </>
        )}

        <Label style={{ marginTop: '1.5rem', display: 'block' }}>
          {translate('workspaceOverviewParametrizationLevelAggregation')}
        </Label>

        {!isIndividualLevelAggregation ? (
          <p data-testid="text-level-aggregation">
            {translate(AGG_TRANSLATIONS[firstLevelAggregation])}
          </p>
        ) : (
          aggregations?.options?.hierarchical.map((agg) => {
            const yTypeLowerCase = agg.y_type.toLowerCase();

            if (hasMarketSizeAndSellOut && yTypeLowerCase === 'sellout')
              return null;

            const isMarketSizeAndSellOut =
              hasMarketSizeAndSellOut && yTypeLowerCase === 'marketsize';

            const yType = isMarketSizeAndSellOut
              ? 'Market Size / Sell Out'
              : yTypeLowerCase === 'others'
              ? translate('workspaceOverviewOthers')
              : yTypeLowerCase === 'marketsize'
              ? 'Market Size'
              : yTypeLowerCase === 'sellout'
              ? 'Sell Out'
              : dataTypesDict[agg.y_type] ?? agg.y_type;

            return (
              <p
                key={yType}
                data-testid={`text-individual-level-aggregation-${yType
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              >
                <span>{yType}: </span>
                {translate(AGG_TRANSLATIONS[agg.frequency_method])}
              </p>
            );
          })
        )}
      </AggregationValues>
    </Container>
  );
};
