import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 1rem;
`;

export const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 400;
  line-height: normal;

  color: ${({ theme }) => theme.colors.gray6};
`;

export const Description = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.gray5};

  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
`;

export const AggregationValues = styled.div`
  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray6};

    margin-top: 0.5rem;
  }

  span {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;
