import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PencilSimple } from 'phosphor-react';
import { Controller, useForm } from 'react-hook-form';
import { RootState } from 'src/redux/store';
import { Input } from 'src/components/Input';
import { Button } from 'src/components/Button';
import { TextArea } from 'src/components/TextArea';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  updateWorkspaceDescription,
  updateWorkspaceName,
} from 'src/workspaces/redux/reducers/Workspace';
import { queryClient } from 'src/service/queryClient';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Status } from 'src/components/Status';
import apiWorkspace from 'src/workspaces/service/api';
import * as Yup from 'yup';

import {
  BasicInformationsForm,
  EditBasicInformationsModalProps,
} from './types';
import { FormContainer } from './styles';

const regexNoSpecialCharacters = /[®ŧ←↓→øþæßðđŋħˀĸł«»©µ]/;

const formSchema = Yup.object().shape({
  name: Yup.string()
    .transform((value) => value.trim())
    .test(
      'no-forbidden-chars',
      'projectErrorNoSpecialCharacters',
      (value) => !regexNoSpecialCharacters.test(value ?? ''),
    )
    .min(3, 'projectErrorMinCharacters')
    .max(50, 'projectErrorMaxCharacters')
    .required('requiredField'),
  description: Yup.string()
    .transform((value) => value.trim())
    .min(3, 'projectErrorMinCharacters')
    .max(350, 'createWorkspaceDescriptionErrorMaxCharacters')
    .required('requiredField'),
});

export const EditBasicInformationsModal: React.FC<
  EditBasicInformationsModalProps
> = ({ visible, setVisible }) => {
  const [isSavingBasicInformations, setIsSavingBasicInformations] =
    useState(false);
  const [saveError, setSaveError] = useState(false);

  const ref = useRef<HTMLFormElement>(null);
  const errorModalRef = useRef<HTMLDivElement>(null);

  const { workspace } = useSelector((state: RootState) => state);
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<BasicInformationsForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: workspace.name ?? '',
      description: workspace.description ?? '',
    },
  });

  const handleEditBasicInformations = async (data: BasicInformationsForm) => {
    setIsSavingBasicInformations(true);

    try {
      await apiWorkspace.patch(`/workspaces/${workspace.id}`, data);

      dispatch(updateWorkspaceName(data.name));
      dispatch(updateWorkspaceDescription(data.description));

      queryClient.invalidateQueries('workspaces');
      queryClient.refetchQueries(['workspace data', workspace.id]);

      setVisible(false);
    } catch {
      setSaveError(true);
    } finally {
      setIsSavingBasicInformations(false);
    }
  };

  const handleCloseSaveErrorModal = () => {
    setSaveError(false);
  };

  useEffect(() => {
    const isEditButton = (node: Node | null) => {
      const editButtonText = translate('edit');

      return (
        node instanceof HTMLElement &&
        node.nodeName === 'BUTTON' &&
        node.textContent?.includes(editButtonText)
      );
    };

    const handleClickOutside = (event: MouseEvent) => {
      const clickedContainer = event.target as Node;
      const clickedParentContainer = clickedContainer.parentNode;

      const clickedContainerIsEditButton = isEditButton(clickedContainer);

      const clickedParentContainerIsEditButton = isEditButton(
        clickedParentContainer,
      );

      if (
        ref.current &&
        !clickedContainerIsEditButton &&
        !clickedParentContainerIsEditButton &&
        !ref.current.contains(clickedContainer) &&
        !errorModalRef?.current?.contains(clickedContainer) &&
        !isSavingBasicInformations
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [translate, setVisible, isSavingBasicInformations]);

  useEffect(() => {
    if (!visible) {
      reset({
        name: workspace.name ?? '',
        description: workspace.description ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, visible]);

  return (
    <>
      <FormContainer
        ref={ref}
        onSubmit={handleSubmit(handleEditBasicInformations)}
        visible={visible}
        data-testid="modal-edit-basic-informations"
      >
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <Input
              type="text"
              label={translate('name')}
              placeholder={translate(
                'workspaceControlPanelEditNamePlaceholder',
              )}
              value={value}
              onChange={onChange}
              data-testid="input-name"
              error={
                errors.name?.message ? translate(errors.name?.message) : ''
              }
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <TextArea
              label={translate('description')}
              placeholder={translate(
                'workspaceControlPanelEditDescriptionPlaceholder',
              )}
              value={value}
              onChange={onChange}
              style={{ height: '7.5rem' }}
              data-testid="textarea-description"
              error={
                errors.description?.message
                  ? translate(errors.description?.message)
                  : ''
              }
            />
          )}
        />

        <Button
          buttonType="primary"
          icon={<PencilSimple />}
          type="submit"
          loading={isSavingBasicInformations}
          disabled={isSavingBasicInformations}
          style={{ marginTop: errors.description?.message ? '1rem' : '0' }}
          data-testid="button-save-edition"
        >
          {translate('edit')} Workspace
        </Button>
      </FormContainer>

      {saveError && (
        <div ref={errorModalRef}>
          <Modal
            visible={saveError}
            setVisible={setSaveError}
            style={{ width: '30rem' }}
          >
            <Status
              type="cloudWarning"
              isModal
              title={translate(
                'workspaceControlPanelEditBasicInformationsErrorTitle',
              )}
              description={translate(
                'workspaceControlPanelEditBasicInformationsErrorDescription',
              )}
            />

            <ModalFooter>
              <Button
                buttonType="primary"
                onClick={handleCloseSaveErrorModal}
                data-testid="button-ok-error"
              >
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};
