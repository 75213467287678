import React, {
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';
import { X } from 'phosphor-react';
import { AxiosError } from 'axios';
import { queryClient } from 'src/service/queryClient';
import { Modal } from 'src/components/Modal';
import { Status } from 'src/components/Status';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import apiWorkspace from 'src/workspaces/service/api';
import { useQueryYTypes } from 'src/workspaces/hooks/useQueryYTypes';

import {
  TagsContainer,
  DefaultTag,
  WorkspaceTag,
  MenuContainer,
  Option,
  EmptyOption,
  MenuInformation,
  NewOption,
  ErrorInfo,
  Spinner,
} from './styles';
import { Subtitle } from '../Step4/styles';

export const DataType: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showCharactersMinNumberInvalid, setShowCharactersMinNumberInvalid] =
    useState(false);

  const [isCreating, setIsCreating] = useState('');
  const [creationErrorDescription, setCreationErrorDescription] = useState('');

  const dataTypeRef = useRef<HTMLDivElement>(null);

  const { t: translate } = useTranslation();

  const { yTypesDefinedByUser, handleAddNewYType, handleRemoveYType } =
    useContext(WorkspaceConfigContext);

  const { data: typesData, isLoading } = useQueryYTypes();

  const typeOptions = useMemo(
    () =>
      typesData?.filter(
        ({ label }) =>
          !yTypesDefinedByUser.includes(label) &&
          label.toLowerCase().includes(searchValue.toLowerCase().trim()),
      ),
    [typesData, yTypesDefinedByUser, searchValue],
  );

  const allOptionsLowerCase = useMemo(
    () => [...(typeOptions?.map(({ label }) => label.toLowerCase()) ?? [])],
    [typeOptions],
  );

  const handleSelectNewType = (code: string) => {
    handleAddNewYType(code);
    setSearchValue('');
  };

  const handleOpenDataTypeMenu = () => {
    setOpenMenu(true);

    const input = document.getElementById('input-data-type');

    if (input) {
      input.focus();
    }
  };

  const showCreateOption =
    !!searchValue &&
    !allOptionsLowerCase?.includes(searchValue.toLowerCase().trim());

  const showTypeIsSelected =
    showCreateOption &&
    !isCreating &&
    (yTypesDefinedByUser.some(
      (type) => type.toLowerCase() === searchValue.toLowerCase().trim(),
    ) ||
      [
        'marketsize',
        'sellout',
        'others',
        'outros',
        'market size',
        'sell out',
      ].includes(searchValue.toLowerCase().trim()));

  const regex = new RegExp('^[A-Za-z0-9_ ]*$');

  const hasSpecialCharacters = !regex.test(searchValue);

  const createOptionInvalidMessage = hasSpecialCharacters
    ? 'workspaceConfigDataTypeSpecialCharacters'
    : showCharactersMinNumberInvalid && searchValue.length < 4
    ? 'workspaceConfigDataTypeMinCharacters'
    : searchValue.length > 50
    ? 'workspaceConfigDataTypeMaxCharacters'
    : showTypeIsSelected
    ? 'workspaceConfigDataTypeAlreadySelected'
    : '';

  const handleCreateNewDataType = async () => {
    if (createOptionInvalidMessage) {
      return;
    }

    if (searchValue.length < 4) {
      setShowCharactersMinNumberInvalid(true);

      return;
    }

    const newYType = searchValue;
    const newYTypeNormalized = searchValue.replace(/\b\w/g, (char) =>
      char.toUpperCase(),
    );

    setIsCreating(newYType);
    setSearchValue('');

    handleAddNewYType(newYTypeNormalized);

    try {
      await apiWorkspace.post('workspaces/types/y', { label: newYType });

      await queryClient.refetchQueries('workspace data types');
    } catch (err) {
      const error = err as AxiosError;

      let descriptionError = 'workspaceConfigDataTypeCreationDescriptionError';

      if (error.response?.status === 400) {
        if (error.response.data?.detail === 'Y Type already exists') {
          await queryClient.refetchQueries('workspace data types');

          descriptionError = 'workspaceConfigDataTypeExistsDescriptionError';
        }
      }

      handleRemoveYType(newYTypeNormalized);
      setSearchValue(newYType);

      setCreationErrorDescription(descriptionError);
    } finally {
      setIsCreating('');
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && showCreateOption) {
      handleCreateNewDataType();
    }
  };

  useEffect(() => {
    if (!isCreating && openMenu) {
      const input = document.getElementById('input-data-type');

      if (input && openMenu) {
        input.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreating]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dataTypeRef.current &&
        !dataTypeRef.current.contains(event.target as Node)
      ) {
        setSearchValue('');
        setOpenMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isLoadingDataTypes = isLoading && !typesData?.length;

  return (
    <>
      <Subtitle style={{ marginBottom: '0.5rem' }}>
        {translate('workspaceConfigDataType')}
      </Subtitle>

      <TagsContainer
        ref={dataTypeRef}
        role="button"
        onClick={handleOpenDataTypeMenu}
        data-testid="container-data-type-options"
      >
        <DefaultTag data-testid="container-option-market-size">
          Market Size
        </DefaultTag>
        <DefaultTag data-testid="container-option-sell-out">
          Sell Out
        </DefaultTag>
        <DefaultTag data-testid="container-option-others">
          {translate('others')}
        </DefaultTag>

        {yTypesDefinedByUser.map((type) => (
          <WorkspaceTag
            key={type}
            data-testid={`container-option-${type
              .toLowerCase()
              .replaceAll(' ', '-')}`}
          >
            {type}

            {isCreating.toLowerCase() === type.toLowerCase() ? (
              <Spinner data-testid="creating-data-type" />
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveYType(type);
                }}
                data-testid={`button-remove-option-${type
                  .toLowerCase()
                  .replaceAll(' ', '-')}`}
              >
                <X />
              </button>
            )}
          </WorkspaceTag>
        ))}

        <input
          id="input-data-type"
          type="text"
          placeholder={translate('workspaceConfigAddDataType')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyPress}
          disabled={!!isCreating}
          autoComplete="off"
          data-testid="input-data-type"
        />

        {openMenu && (
          <MenuContainer data-testid="container-data-type-menu">
            {isLoadingDataTypes ? (
              <EmptyOption data-testid="container-loading-data-types">
                {translate('loading')}...
              </EmptyOption>
            ) : (
              <>
                <MenuInformation>
                  <p>{translate('workspaceConfigSelectCreateDataType')}</p>
                </MenuInformation>

                {typeOptions?.map(({ code, label }) => (
                  <Option
                    key={code}
                    type="button"
                    onClick={() => handleSelectNewType(label)}
                    data-testid={`button-option-${code}`}
                  >
                    {label}
                  </Option>
                ))}

                {!typeOptions?.length && !searchValue && (
                  <EmptyOption
                    style={{ textAlign: 'start' }}
                    data-testid="container-empty-data-types"
                  >
                    {translate('selectNoOptions')}
                  </EmptyOption>
                )}
              </>
            )}

            {showCreateOption && (
              <NewOption
                type="button"
                onClick={handleCreateNewDataType}
                style={{
                  boxShadow:
                    typeOptions && typeOptions.length >= 4
                      ? '0px -4px 8px 0px rgba(45, 55, 72, 0.04)'
                      : 'none',
                }}
                data-testid="button-add-type"
              >
                <p>
                  {translate('workspaceConfigCreate')}{' '}
                  <span>{searchValue}</span>
                </p>

                {createOptionInvalidMessage && (
                  <ErrorInfo data-testid="text-invalid-data-type">
                    {translate(createOptionInvalidMessage)}
                  </ErrorInfo>
                )}
              </NewOption>
            )}
          </MenuContainer>
        )}

        {!!creationErrorDescription && (
          <Modal
            visible
            style={{ width: '33rem' }}
            dataCy="modal-data-type-creation-error"
          >
            <Status
              type="cloudWarning"
              isModal
              title={translate('workspaceConfigDataTypeCreationTitleError')}
              description={translate(creationErrorDescription)}
            />

            <ModalFooter>
              <Button
                buttonType="primary"
                onClick={() => setCreationErrorDescription('')}
                data-testid="button-ok"
              >
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </TagsContainer>
    </>
  );
};
