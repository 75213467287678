import React from 'react';

import { Info, XCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { Tooltip } from 'react-tooltip';

import { ContainerLabel, ContainerSelect } from './styles';
import { SelectProps } from './types';

export const Select: React.FC<SelectProps> = ({
  label,
  information,
  style,
  className,
  error,
  placeholder,
  isMulti = false,
  dataTestid,
  ...rest
}) => {
  const { t: translate } = useTranslation();

  return (
    <div
      style={style}
      className={className}
      data-cy={
        dataTestid ??
        label?.replaceAll(' ', '-').toLocaleLowerCase() ??
        placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase() ??
        undefined
      }
      data-testid={
        dataTestid ??
        label?.replaceAll(' ', '-').toLocaleLowerCase() ??
        placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase() ??
        undefined
      }
    >
      {label ? (
        <ContainerLabel>
          <label
            htmlFor={
              `react-select-${label
                ?.replaceAll(' ', '-')
                .toLocaleLowerCase()}` ??
              placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
            }
          >
            {label}
          </label>
          {information && (
            <>
              <Info
                size="1.125rem"
                data-tooltip-id="select-tooltip"
                data-tooltip-html={information}
                data-cy={`select-information-${label?.replaceAll(' ', '-')}`}
                data-testid="select-information"
              />
              <Tooltip id="select-tooltip" className="customTooltipTheme" />
            </>
          )}
        </ContainerLabel>
      ) : (
        <label
          htmlFor={
            `react-select-${
              label?.replaceAll(' ', '-').toLocaleLowerCase() ?? dataTestid
            }` ??
            placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
          }
          style={{ display: 'none' }}
        >
          {dataTestid}
        </label>
      )}

      <ContainerSelect
        error={!!error}
        isMulti={isMulti}
        disabled={rest.isDisabled}
      >
        <ReactSelect
          name={
            `react-select-${
              label?.replaceAll(' ', '-').toLocaleLowerCase() ?? dataTestid
            }` ??
            placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
          }
          inputId={
            `react-select-${
              label?.replaceAll(' ', '-').toLocaleLowerCase() ?? dataTestid
            }` ??
            placeholder?.toString().replaceAll(' ', '-').toLocaleLowerCase()
          }
          placeholder={placeholder ?? translate('select')}
          instanceId={
            label?.replaceAll(' ', '-').toLocaleLowerCase() ??
            placeholder?.toString()?.replaceAll(' ', '-').toLocaleLowerCase() ??
            undefined
          }
          isMulti={isMulti}
          noOptionsMessage={() => translate('selectNoOptions')}
          loadingMessage={() => `${translate('loading')}...`}
          classNamePrefix="select"
          {...rest}
        />

        {!!error && (
          <XCircle size="1.25rem" data-testid="icon_select_error_validation" />
        )}
        <p>{error}</p>
      </ContainerSelect>
    </div>
  );
};
