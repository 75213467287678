import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type WorksType = 'projects';

export type PayloadMyWorks = {
  isAccessing: WorksType;
};

const initialState: PayloadMyWorks = {
  isAccessing: 'projects',
};

export const MyWorksState = createSlice({
  name: 'myWorks',
  initialState,
  reducers: {
    changeIsAccessing: (state, action: PayloadAction<WorksType>) => {
      state.isAccessing = action.payload;
    },
  },
});

export const { changeIsAccessing } = MyWorksState.actions;

export default MyWorksState.reducer;
