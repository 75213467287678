import styled from 'styled-components';

export const CellNewSerieContainer = styled.div`
  p {
    font-weight: 500;
    font-size: 0.75rem;
    text-align: center;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray7};

    margin-bottom: 0.25rem;
  }

  div {
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;

    span {
      color: ${({ theme }) => theme.colors.gray5};
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 150%;
    }
  }
`;
