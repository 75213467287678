import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonsContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    height: fit-content;

    padding: 0.5rem 0.75rem;

    font-weight: 500;
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colors.gray5};

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 999px;

    transition: all 0.2s;

    > div > div {
      margin-right: 0.05rem;
    }

    &:not(:disabled):hover {
      color: ${({ theme }) => theme.colors.gray6};
      border-color: ${({ theme }) => theme.colors.gray3};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray4};
      border-color: ${({ theme }) => theme.colors.gray1};
      cursor: not-allowed;
    }
  }
`;
