import React, { useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import { useQuery } from 'react-query';
import apiWorkspace from 'src/workspaces/service/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  WorkspaceRelease,
  WorkspaceReleaseSelected,
  updateReleaseSelected,
} from 'src/workspaces/redux/reducers/Workspace';
import ms from 'ms';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { useQueryWorkspaceData } from 'src/workspaces/hooks/useQueryWorkspaceData';

import { VersionInfo } from '../VersionInfo';
import { ContainerProject, ProjectDetails } from './styles';
import { WorkspaceSideBarContext } from '..';

interface WorkspaceIdProps {
  workspaceId: string;
}

interface ReleasesResponse {
  skip: number;
  limit: number;
  total: number;
  records: WorkspaceRelease[];
}

export const WorkspaceInfo: React.FC<WorkspaceIdProps> = ({ workspaceId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { openMenuSelectRelease } = useContext(WorkspaceSideBarContext);

  const { t: translate } = useTranslation();

  const {
    workspace,
    auth: {
      user: { email },
    },
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);

  const { data: workspaceData } = useQueryWorkspaceData(
    workspaceId,
    !!workspaceId && !!email,
    email ?? '',
    true,
  );

  const { data: releasesData, isError: releasesIsError } = useQuery(
    ['workspace', workspaceId, 'releases'],
    async () => {
      const response = await apiWorkspace.get<ReleasesResponse>(
        `/workspaces/${workspaceId}/releases`,
      );

      return response.data;
    },
    {
      staleTime: ms('1 day'),
    },
  );

  const releaseCurrentId = workspace.releaseCurrent
    ? workspace.releaseCurrent
    : workspaceData?.releases?.current
    ? workspaceData.releases?.current
    : releasesData?.records?.find(
        (release) =>
          release.published_at &&
          release.status === 'success' &&
          release.preview === false,
      )?.id;

  const { data: releaseSelectedData, isError: releaseSelectedIsError } =
    useQuery(
      ['workspace', workspaceId, 'releases', releaseCurrentId],
      async () => {
        const response = await apiWorkspace.get<WorkspaceReleaseSelected>(
          `/workspaces/${workspaceId}/releases/${releaseCurrentId}`,
        );
        return response.data;
      },
      {
        staleTime: ms('1 day'),
        enabled:
          !!workspaceId &&
          !!releasesData?.records?.length &&
          !!releaseCurrentId &&
          !workspace.releaseSelected,
      },
    );

  if (releasesIsError || releaseSelectedIsError) {
    navigate('/workspaces');
  }

  if (workspaceData && !releaseCurrentId && !workspace?.releaseSelected?.id) {
    navigate('/workspaces');
  }

  useEffect(() => {
    if (releaseSelectedData && !workspace.releaseSelected && releasesData) {
      const index = releasesData.records.findIndex(
        ({ id }) => id === releaseSelectedData.id,
      );

      dispatch(
        updateReleaseSelected({
          releaseSelected: {
            ...releaseSelectedData,
            index,
          },
          ySelected: releaseSelectedData.data.ys[0],
        }),
      );
    }
  }, [dispatch, releaseSelectedData, workspace.releaseSelected, releasesData]);

  const isLoading = !workspace.id;

  const isPreview = workspace.releaseSelected?.id === workspace.releasePreview;

  const selectedReleaseName = workspace?.releaseSelected?.name ?? '';
  const selectedReleaseNameFormatted = selectedReleaseName?.replace(
    'Version',
    translate('version'),
  );

  const selectedReleaseLabel = workspace?.releaseSelected?.label;

  const selectedReleaseLabelFormatted =
    !selectedReleaseLabel || selectedReleaseLabel === selectedReleaseName
      ? selectedReleaseNameFormatted
      : selectedReleaseLabel;

  return (
    <ContainerProject data-testid="container-sidebar-project">
      <ProjectDetails
        onClick={() => navigate('/workspaces')}
        expanded={isExpanded}
      >
        {isLoading ? (
          <>
            <ContainerSkeleton
              data-testid="project-loading"
              withLoading={false}
              style={{ width: '53px', height: '53px', margin: 0 }}
            />
            <div style={{ flex: '1' }}>
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '170px', height: '24px', margin: '0' }}
              />
              <ContainerSkeleton
                withLoading={false}
                style={{ width: '170px', height: '24px', margin: '4px 0 0' }}
              />
            </div>
          </>
        ) : (
          <>
            <img
              src={
                checkIconURL(workspace?.icon) && workspace?.icon
                  ? workspace?.icon
                  : arrayIcon[0]
              }
              alt="workspace-icon"
            />
            <div>
              <h4>{workspace?.name}</h4>
              <p>{translate('workspaceSelectAnotherProject')}</p>
            </div>
          </>
        )}
      </ProjectDetails>

      {workspace.releaseSelected &&
      releasesData?.records?.length &&
      isExpanded ? (
        <div style={{ width: '16.938rem' }}>
          <VersionInfo
            versionName={
              isPreview ? translate('preview') : selectedReleaseLabelFormatted
            }
            versionDescription={
              isPreview ? undefined : workspace?.releaseSelected?.description
            }
            horizonDates={
              workspace?.releaseSelected?.data?.approval_flow?.horizon_dates
            }
            showOpenSidebarButton={!isPreview}
            handleOpenSidebar={openMenuSelectRelease}
          />
        </div>
      ) : isExpanded ? (
        <ContainerSkeleton
          data-testid="release-selected-loading"
          withLoading={false}
          style={{ width: '100%', height: '3rem', margin: 0 }}
        />
      ) : null}
    </ContainerProject>
  );
};
