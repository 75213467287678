import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Input } from 'src/components/Input';
import parseHTML from 'html-react-parser';

import { ActionConfirmationModalProps } from './types';
import { Content } from './styles';

export const ActionConfirmationModal: React.FC<
  ActionConfirmationModalProps
> = ({ setVisible, title, description, onConfirm, textToConfirm }) => {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const { t: translate } = useTranslation();

  const handleConfirmAction = async () => {
    setLoading(true);

    await onConfirm();

    setLoading(false);
    setVisible(false);
  };

  const confirmButtonIsDisabled =
    loading || (!!textToConfirm && inputValue !== textToConfirm);

  return (
    <Modal
      visible
      dataCy="modal-action-confirmation"
      style={{ width: '30rem' }}
    >
      <Content>
        <h2 data-testid="text-title">{title}</h2>
        <p data-testid="text-description">{parseHTML(description)}</p>

        {textToConfirm && (
          <Input
            placeholder={textToConfirm}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            data-testid="input-action-confirmation"
          />
        )}
      </Content>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={() => setVisible(false)}
          disabled={loading}
          data-testid="button-cancel"
        >
          {translate('cancel')}
        </Button>

        <Button
          buttonType="primary"
          onClick={handleConfirmAction}
          disabled={confirmButtonIsDisabled}
          loading={loading}
          data-testid="button-confirm"
        >
          {translate('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
