import styled from 'styled-components';

type MenuContainerProps = {
  isOpen: boolean;
};

export const MenuContainer = styled.div<MenuContainerProps>`
  position: relative;

  > div {
    position: relative;
    height: 100%;
    width: 15rem;
    max-width: ${({ isOpen }) => (isOpen ? '15rem' : '0rem')};
    overflow: hidden;

    display: flex;
    flex-direction: column;

    background-color: ${({ theme }) => theme.colors.white};

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 8px;

    transition: 0.5s max-width linear;

    > div:first-child {
      position: absolute;
      inset: 0;
      display: flex;
      flex-direction: column;

      transition: 3s left linear;

      width: calc(15rem - 2px);
    }
  }
`;

export const MenuHeader = styled.div`
  padding: 1rem;

  h4 {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 8px;
  }

  p {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const MenuFooter = styled.div`
  position: sticky;
  bottom: 0px;
  background: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};

  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  > div {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    div {
      margin-bottom: 0px;
      p {
        margin-right: 0px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.gray5};
        font-size: 12px;
        line-height: 150%;
      }
    }
    input {
      min-width: 3rem;
    }
  }
`;

export const MinimizeMaximizeButton = styled.button`
  position: absolute;
  top: 50%;
  right: 0rem;
  transform: translate(50%, -50%);

  background: ${({ theme }) => theme.colors.white};

  padding: 0.375rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  line-height: 0rem;

  transition: 0.2s border-color ease-in-out;

  z-index: 4;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
    width: 1rem;
    height: 1rem;

    transition: 0.2s color ease-in-out;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.gray3};

    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const ContentMenu = styled.div`
  position: relative;
  flex: 1;
`;

export const OptionsMenu = styled.div`
  overflow-y: auto;
  position: absolute;
  inset: 0;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  .close-menu {
    svg {
      transform: rotate(-180deg);
    }
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray1};
  }

  button:not(:first-of-type) {
    width: fit-content;
    height: 2rem;

    padding: 0.5rem;
    padding-right: 1rem;
  }

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

type CheckboxContainerProps = {
  level: number;
  hasSubMenu: boolean;
};

export const CheckboxContainer = styled.button<CheckboxContainerProps>`
  width: 100%;
  height: 100%;

  flex: 1;

  padding: 0.5rem;
  padding-left: ${({ level }) => level}rem;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  text-align: start;

  label {
    p {
      font-size: 0.875rem !important;
      font-weight: 400;
      word-wrap: break-word;

      color: ${({ theme }) => theme.colors.gray6} !important ;
    }

    span {
      width: 0.875rem !important;
      height: 0.875rem !important;

      ::after {
        height: 0.25rem !important;
        width: 0.45rem !important;

        border-left: 2.5px solid white !important;
        border-bottom: 2.5px solid white !important;
      }
    }
  }

  > p {
    font-size: 0.875rem !important;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;
