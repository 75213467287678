import React, { ForwardedRef, Fragment, useState } from 'react';

import { Combobox, Transition } from '@headlessui/react';
import { CSSProperties } from 'styled-components';
import { X } from 'phosphor-react';

import { Input } from '../Input';
import {
  AutoCompleteButtonDelete,
  AutoCompleteComboBox,
  AutoCompleteContentOption,
  AutoCompleteOption,
  AutoCompleteOptions,
} from './styles';

interface AutoCompleteProps {
  label?: string;
  options: string[];
  value: string;
  placeholder?: string;
  error?: string;
  styles?: CSSProperties;
  icon?: React.ReactElement;
  testid?: string;
  onChange: (value: string) => void;
  onDelete?: (index: number) => void;
  className?: string;
  disabled?: boolean;
}

export const AutoComplete = React.forwardRef(
  (
    {
      label,
      options,
      value,
      placeholder,
      error,
      styles,
      icon,
      testid,
      onChange,
      onDelete,
      className,
      disabled,
    }: AutoCompleteProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [query, setQuery] = useState('');

    function handleSearch(aux: string) {
      setQuery(aux);
      onChange(aux);
    }

    const filteredOption =
      query === ''
        ? options
        : options.filter((option) =>
            option
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          );

    return (
      <AutoCompleteComboBox
        onChange={onChange}
        value={value}
        ref={ref}
        disabled={!!disabled}
      >
        <div style={styles} className={className}>
          <Combobox.Input
            as={Input}
            autoComplete="off"
            label={label}
            displayValue={(option: string) => option}
            onChange={(event) => {
              handleSearch(event.target.value);
            }}
            placeholder={placeholder}
            error={error}
            value={value}
            icon={icon}
            testid={testid}
          />

          {filteredOption.length ? (
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <AutoCompleteOptions className="absolute mt-2 max-h-60 w-full overflow-auto rounded-sm bg-gray-900 text-base shadow-lg ring-1 ring-green-700 ring-opacity-50 focus:outline-none sm:text-sm z-10">
                {filteredOption.map((option, index) => (
                  <AutoCompleteContentOption key={option}>
                    <Combobox.Option
                      value={option}
                      as={AutoCompleteOption}
                      hasButtonDelete={!!onDelete}
                    >
                      <span>{option}</span>
                    </Combobox.Option>
                    {onDelete && (
                      <AutoCompleteButtonDelete
                        type="button"
                        onClick={() => onDelete(index)}
                      >
                        <X size={12} />
                      </AutoCompleteButtonDelete>
                    )}
                  </AutoCompleteContentOption>
                ))}
              </AutoCompleteOptions>
            </Transition>
          ) : null}
        </div>
      </AutoCompleteComboBox>
    );
  },
);
