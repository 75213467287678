import React, { useContext, useEffect, useState } from 'react';

import { Card } from 'src/components/Card';
import { useTranslation } from 'react-i18next';
import {
  ExternalSeries,
  Project,
  WorkspaceConfigContext,
} from 'src/workspaces/contexts/WorkspaceConfigContext';
import { Tooltip } from 'react-tooltip';
import { ContainerWarning } from 'src/models/components/ContainerWarning';

import {
  ContentProjectsAndSeries,
  Step2Container,
  Step2Header,
} from './styles';
import { StepFooter } from '../StepFooter';
import { SelectProjects } from '../SelectProjects';
import { SelectSeries } from '../SelectSeries';

type Step2Props = {
  setStep: (step: number) => void;
};

export const Step2: React.FC<Step2Props> = ({ setStep }) => {
  const [selectedProjects, setSelectedProjects] = useState<Project[]>([]);
  const [selectedSeriesTag, setSelectedSeriesTag] = useState<ExternalSeries[]>(
    [],
  );

  const { t } = useTranslation();

  const {
    isEdition,
    projects,
    externalSeries,
    setFrequency,
    saveProjectAndExternalSeries,
  } = useContext(WorkspaceConfigContext);

  useEffect(() => {
    if (projects.length) {
      setSelectedProjects(projects);
    }
  }, [projects]);

  useEffect(() => {
    if (externalSeries.length) {
      setSelectedSeriesTag(externalSeries);
    }
  }, [externalSeries]);

  useEffect(() => {
    if (selectedProjects.length >= 1) {
      const yStatusIsSuccess = selectedProjects[0].ys.find(
        (y) => y.status === 'success',
      );
      if (yStatusIsSuccess?.info?.frequency)
        setFrequency(yStatusIsSuccess?.info?.frequency);
    } else if (selectedSeriesTag.length && selectedSeriesTag[0].series.length) {
      const serieStatusSuccess = selectedSeriesTag[0].series.find(
        (serie) => serie.status === 'success',
      );
      if (serieStatusSuccess) {
        setFrequency(serieStatusSuccess.frequency);
      }
    } else if (
      selectedProjects.length === 0 &&
      selectedSeriesTag.length === 0 &&
      !isEdition
    ) {
      setFrequency(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProjects, selectedSeriesTag, isEdition]);

  function addOrRemoveSelectProjects(project: Project) {
    const exists = selectedProjects.some(
      (selectedProject) => selectedProject.id === project.id,
    );
    if (exists) {
      setSelectedProjects((state) =>
        state.filter((selectedProject) => selectedProject.id !== project.id),
      );
    } else {
      setSelectedProjects((state) => [...state, project]);
    }
  }

  function addOrRemoveSelectSeriesTag(externalSerieTag: ExternalSeries) {
    const exists = selectedSeriesTag.some(
      (selectedSerieTag) => selectedSerieTag.tag === externalSerieTag.tag,
    );
    if (exists) {
      setSelectedSeriesTag((state) =>
        state.filter(
          (selectedSerieTag) => selectedSerieTag.tag !== externalSerieTag.tag,
        ),
      );
    } else {
      setSelectedSeriesTag((state) => [...state, externalSerieTag]);
    }
  }

  const backStep = () => {
    saveProjectAndExternalSeries([...selectedProjects], [...selectedSeriesTag]);

    setStep(1);
  };

  const nextStep = () => {
    saveProjectAndExternalSeries([...selectedProjects], [...selectedSeriesTag]);

    setStep(3);
  };

  const variablesSelected =
    selectedProjects.reduce(
      (acc, current) =>
        acc + current.ys.filter((y) => y.status === 'success').length,
      0,
    ) +
    selectedSeriesTag.reduce(
      (acc, current) =>
        acc +
        current.series.filter((serie) => serie.status === 'success').length,
      0,
    );

  return (
    <Step2Container className="containerLinear" data-testid="container-step-2">
      <Step2Header>
        <Card
          textCard={t('createWorkspaceSelectVariablesTitle')}
          textDescription={t('createWorkspaceSelectVariablesDescription')}
        />

        {variablesSelected > 1000 && (
          <ContainerWarning
            visible
            text={t('createWorkspaceVariableSelectMoreThan1000Warning')}
          />
        )}
      </Step2Header>

      <ContentProjectsAndSeries>
        <SelectProjects
          addOrRemoveSelectProjects={addOrRemoveSelectProjects}
          selectedProjects={selectedProjects}
        />

        <SelectSeries
          selectedSeriesTag={selectedSeriesTag}
          addOrRemoveSelectSeriesTag={addOrRemoveSelectSeriesTag}
        />
      </ContentProjectsAndSeries>

      <StepFooter
        stepQtty={4}
        selectedStep={2}
        backStep={{
          text: t('step1'),
          onClick: backStep,
          disabled: false,
        }}
        nextStep={{
          text: t('step3'),
          disabled: !selectedProjects.length && !selectedSeriesTag.length,
          onClick: nextStep,
          removeIcon: isEdition,
        }}
        discardButton={undefined}
      />
      <Tooltip
        id="workspace-select-project"
        className="customTooltipTheme customTooltipMyProjects"
      />

      <Tooltip
        id="workspace-projects-dependent-variables"
        className="customTooltipTheme customTooltipMyProjects"
      />
    </Step2Container>
  );
};
