import ms from 'ms';
import { useQuery } from 'react-query';
import { SerieData } from 'src/models/hooks/useQuerySeries';
import apiSeries from 'src/models/service/apiSeries';

export type SeriesGroupedByTag = {
  [tag: string]: SerieData[];
};

type useQuerySeriesProps = {
  searchValue: string;
  requestAllowed: boolean;
  onSuccess?: (data: SeriesGroupedByTag | undefined) => void;
  limit?: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQuerySeriesGroupedByTag = ({
  requestAllowed,
  searchValue,
  onSuccess,
}: useQuerySeriesProps) => {
  const { data, isLoading, isFetching, isError } = useQuery<SeriesGroupedByTag>(
    ['series-grouped-by-tag', searchValue],
    async () => {
      const queryParams = new URLSearchParams();

      if (searchValue) {
        queryParams.append('filter', searchValue);
      }

      const response = await apiSeries.get<SeriesGroupedByTag>(
        `/tags/series?${queryParams.toString()}`,
      );

      return response.data;
    },
    {
      staleTime: ms('20 min'),
      cacheTime: ms('20 min'),
      onSuccess: (successData) => {
        if (Object.keys(successData).length && onSuccess) {
          onSuccess(successData);
        }
      },
      enabled: requestAllowed || searchValue === '',
    },
  );

  return {
    seriesGroupedByTagData: data,
    seriesGroupedByTagIsLoading: isLoading || isFetching,
    seriesGroupedByTagIsError: isError,
  };
};
