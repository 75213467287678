import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Head } from 'src/components/Head';
import {
  changeEditionModeEnabled,
  changeReviewModeEnabled,
} from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';

import { Container } from './styles';
import { ListWorkspaces } from './components/ListWorkspaces';

export const WorkspaceHome: React.FC = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeEditionModeEnabled(false));
    dispatch(changeReviewModeEnabled(false));
  }, [dispatch]);

  return (
    <Container>
      <Head title={translate('workspaceHead')} />
      <div className="containerLinear">
        <ListWorkspaces />
      </div>
    </Container>
  );
};
