import styled from 'styled-components';

export const MySeriesMessage = styled.span`
  font-weight: 500;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.gray5};
  border-radius: 9999px;
  background: ${({ theme }) => theme.colors.gray1};
  line-height: 0.7562rem;

  padding: 0.125rem 0.5rem;

  text-wrap: nowrap;
`;
