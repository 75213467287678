import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { LabelContainer } from 'src/models/pages/Modelling/TimeSeries/components/InputFile/styles';
import apiWorkspace from 'src/workspaces/service/api';
import { queryClient } from 'src/service/queryClient';
import * as yup from 'yup';

import { InputUserShare } from './InputUserShare';
import {
  Container,
  Header,
  InfoShare,
  InputContainer,
  InputError,
} from './styles';
import { ShareWorkSpaceModalProps } from './types';

export const ShareWorkSpace: React.FC<ShareWorkSpaceModalProps> = ({
  setVisible,
  workspaceId,
  users,
}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [invalidEmail, setInvalidEmail] = useState('');
  const [permissionType, setPermissionType] = useState<
    'editor' | 'reader' | 'manager'
  >('reader');

  const [emailsError, setEmailsError] = useState<string[]>([]);
  const [shareIsLoading, setShareIsLoading] = useState(false);

  const [inputValue, setInputValue] = useState('');

  const { t: translate } = useTranslation();

  const emailSchema = yup.string().email().required();

  const handleShareWorkspace = async () => {
    setShareIsLoading(true);
    setEmailsError([]);

    const sharedEmailsError: string[] = [];

    const allEmails = [...emails];

    const emailWithouSpace = inputValue.trim();

    if (
      emailWithouSpace &&
      emailSchema.isValidSync(emailWithouSpace) &&
      !emails.includes(emailWithouSpace) &&
      !users.includes(emailWithouSpace)
    ) {
      allEmails.push(emailWithouSpace);
    }

    for (let i = 0; i < allEmails.length; i++) {
      try {
        await apiWorkspace.put(`workspaces/${workspaceId}/users`, {
          user: allEmails[i],
          role: permissionType,
        });
      } catch {
        sharedEmailsError.push(allEmails[i]);
      }
    }

    await queryClient.invalidateQueries(['workspace users', workspaceId]);

    if (!sharedEmailsError.length) {
      setVisible();
    } else {
      setEmails(allEmails.filter((email) => sharedEmailsError.includes(email)));
      setEmailsError(sharedEmailsError);
    }

    setShareIsLoading(false);
  };

  const inputValueIsNotValid =
    inputValue.trim() &&
    (!emailSchema.isValidSync(inputValue.trim()) ||
      emails.includes(inputValue.trim()) ||
      users.includes(inputValue.trim()));

  return (
    <Modal
      visible
      setVisible={setVisible}
      style={{ display: 'flex', flexDirection: 'column' }}
      dataCy="share-workspace-modal"
    >
      <Header data-testid="header-share-workspace" />
      <Container>
        <InfoShare data-testid="info-share-workspace">
          <h4>{translate('shareWorkSpaceChooseUserToShare')}</h4>
          <span>{translate('shareWorkSpaceDescriptionShare')}</span>
        </InfoShare>

        {!!emailsError.length && (
          <InputError
            data-testid="share-workspace-error"
            style={{
              width: '90%',
              textAlign: 'center',
            }}
          >
            <p>{translate('shareWorkspaceError')}</p>
          </InputError>
        )}

        <InputContainer>
          <LabelContainer>
            <label>{translate('email')}</label>
          </LabelContainer>

          <InputUserShare
            emails={emails}
            setEmails={setEmails}
            setInvalidEmail={setInvalidEmail}
            permissionType={permissionType}
            setPermissionType={setPermissionType}
            users={users}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />

          {!!invalidEmail && (
            <InputError data-testid="invalid-email-error">
              <p>{invalidEmail}</p>
            </InputError>
          )}
        </InputContainer>
      </Container>

      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={setVisible}
          data-testid="workspace-share-cancel-button"
        >
          {translate('back')}
        </Button>
        <Button
          buttonType="primary"
          disabled={
            (!emails.length && !inputValue.trim()) ||
            inputValueIsNotValid ||
            !!invalidEmail ||
            shareIsLoading
          }
          loading={shareIsLoading}
          onClick={handleShareWorkspace}
          data-testid="workspace-share-confirm-button"
        >
          {translate('shareUpdateSendUpdateButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
