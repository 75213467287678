import styled, { css } from 'styled-components';

type TableSeriesContainerProps = {
  isLoading: boolean;
};

export const TableSeriesContainer = styled.div<TableSeriesContainerProps>`
  min-height: 30rem;
  width: 100%;
  display: flex;

  .ag-center-cols-viewport,
  .ag-header-viewport {
    border-left: unset;
    border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  .ag-cell,
  .ag-pinned-right-header {
    border-left: unset !important;
  }

  .ag-center-cols-viewport {
    min-height: 100% !important;
  }

  //height
  .ag-root-wrapper,
  .ag-root-wrapper-body,
  .ag-center-cols-container {
    height: 100% !important;
  }

  .ag-grid-table-container,
  .ag-root-wrapper {
    max-height: 30rem;
  }

  > div:first-child {
    width: 100%;

    flex: 1;
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      .ag-body-horizontal-scroll,
      .ag-sticky-bottom::after {
        display: none;
      }
    `}
`;
