import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Pencil, Warning, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Td } from 'src/components/Table';
import { CheckBox } from 'src/components/CheckBox';
import light from 'src/styles/themes/light';
import { TextArea } from 'src/components/TextArea';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';

import {
  CheckBoxInflate,
  RenameVariableAndModelIDContainer,
  TrContent,
  WarningIcon,
} from '../Row/styles';
import {
  GetValueKey,
  SetValue,
  YsDisabled,
  YsError,
  YsInflate,
  YsLabel,
  YsModelId,
  YsModelUpdate,
  YsOriginalName,
  YsSelected,
} from '../../Step3/types';

type Props = {
  yKey: string;
  isVisible: boolean;
  tag: string;
  getValue: (key: GetValueKey) => SetValue[GetValueKey];
  setValue: (key: GetValueKey, value: SetValue[GetValueKey]) => void;
  validateNewLabel: (
    projectId: string,
    variableId: string,
    newLabel: string,
  ) => void;
  showOriginalYName: boolean;
};

export const SerieRow: React.FC<Props> = memo(
  ({
    yKey,
    isVisible,
    tag,
    getValue,
    setValue,
    validateNewLabel,
    showOriginalYName,
  }) => {
    const { t: translate } = useTranslation();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const { frequency } = useContext(WorkspaceConfigContext);

    const ysSelected = getValue('ysSelected') as YsSelected;
    const ysLabel = getValue('ysLabel') as YsLabel;
    const ysOriginalName = getValue('ysOriginalName') as YsOriginalName;

    const ysInflate = getValue('ysInflate') as YsInflate;
    const ysError = getValue('ysError') as YsError;
    const ysDisabled = getValue('ysDisabled') as YsDisabled;

    const isSelected = ysSelected[yKey];
    const label = ysLabel[yKey];
    const originalName = ysOriginalName[yKey];

    const inflate = ysInflate[yKey];
    const error = ysError[yKey];
    const isDisabled = ysDisabled[yKey];

    const serieId = yKey.replace(`${tag}-`, '');

    const checkEmptyVariableWhenUnselect = useCallback(() => {
      if (!label) {
        setValue('ysLabel', { [yKey]: originalName });
      }

      setValue('ysInflate', { [yKey]: false });
    }, [yKey, label, setValue, originalName]);

    const isRepeatedVariableLabelOrNameError = (err: string) =>
      err === 'createWorkspaceRepeatedVariable';

    const handleEnableFocusInput = (id: string) => {
      const input = document.getElementById(id);
      if (input) {
        input.focus();
      }
    };

    const handleCheckVariable = (checked: boolean) => {
      if (!checked) {
        checkEmptyVariableWhenUnselect();
      }

      setValue('ysSelected', { [yKey]: !ysSelected[yKey] });
    };

    useEffect(() => {
      const onResize = () => {
        setScreenWidth(window.innerWidth);
      };

      window.addEventListener('resize', onResize);

      return () => window.removeEventListener('resize', onResize);
    }, []);

    const resizeVariableInput = useCallback(() => {
      const renameVariable = document.getElementById(
        `rename-serie-${tag}-${serieId}`,
      );

      if (renameVariable) {
        renameVariable.style.height = '1.5rem'; // Define a altura como automática para recalcular
        renameVariable.style.height = `${renameVariable?.scrollHeight / 16}rem`; // Ajusta a altura conforme o conteúdo inserido
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, serieId]);

    useEffect(() => {
      if (isVisible) {
        resizeVariableInput();
      }
    }, [resizeVariableInput, isSelected, label, isVisible]);

    useEffect(() => {
      validateNewLabel(tag, serieId, label);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tag, serieId]);

    const adjustNames = screenWidth <= 1400;

    const yNameAdjusted =
      ysOriginalName[yKey].length > 25 && adjustNames
        ? `${ysOriginalName[yKey].substring(0, 22)}...`
        : ysOriginalName[yKey];

    const hasInflateYs = frequency === 'monthly';

    return (
      <TrContent
        key={yKey}
        disabled={isDisabled}
        data-testid={`row-tag-${tag}-serie-${serieId}`}
        style={{ display: isVisible ? 'table-row' : 'none' }}
      >
        <Td className="firstTd" />

        <Td>
          <CheckBox
            onChange={({ target: { checked } }) => {
              handleCheckVariable(checked);
            }}
            checked={ysSelected[yKey]}
            disabled={isDisabled}
            style={{ paddingLeft: '1.125rem' }}
            data-testid={`checkbox-tag-${tag}-serie-${serieId}`}
          />
        </Td>

        <Td className="withoutPaddingLeft">
          <RenameVariableAndModelIDContainer error={isDisabled}>
            <TextArea
              id={`rename-serie-${tag}-${serieId}`}
              onChange={({ target: { value: vl } }) => {
                setValue('ysLabel', { [yKey]: vl });

                validateNewLabel(tag, serieId, vl);

                resizeVariableInput();
              }}
              value={ysLabel[yKey]}
              disabled={isDisabled || !isSelected}
              data-testid={`serie-name-tag-${tag}-serie-${serieId}`}
            />

            <Button
              buttonType="naked"
              icon={<Pencil />}
              onClick={() =>
                handleEnableFocusInput(`rename-serie-${tag}-${serieId}`)
              }
              disabled={ysDisabled[yKey] || !ysSelected[yKey]}
              data-testid={`rename-serie-button-tag-${tag}-serie-${serieId}`}
            />

            {!!error &&
              isSelected &&
              (!isRepeatedVariableLabelOrNameError(error) ? (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(error ?? '')}
                  data-testid={`rename-serie-error-tag-${tag}-serie-${serieId}`}
                  color={light.colors.red2}
                >
                  <X weight="bold" size="0.875rem" />
                </WarningIcon>
              ) : (
                <WarningIcon
                  data-tooltip-id="config-workspace-tooltip"
                  data-tooltip-html={translate(error ?? '')}
                  data-testid={`rename-serie-warning-tag-${tag}-serie-${serieId}`}
                  color={light.colors.secondary}
                >
                  <Warning weight="fill" size="1rem" />
                </WarningIcon>
              ))}
          </RenameVariableAndModelIDContainer>
        </Td>

        <Td
          data-testid={`original-serie-name-tag-${tag}-serie-${serieId}`}
          data-tooltip-id="workspace-table"
          data-tooltip-html={
            yNameAdjusted !== ysOriginalName[yKey]
              ? ysOriginalName[yKey]
              : undefined
          }
          style={{ display: showOriginalYName ? 'table-cell' : 'none' }}
        >
          {yNameAdjusted}
        </Td>

        <Td />
        <Td />

        {hasInflateYs && (
          <Td className="projectLastTd">
            <CheckBoxInflate
              label=""
              onChange={({ target }) =>
                setValue('ysInflate', { [yKey]: target.checked })
              }
              checked={inflate}
              disabled={isDisabled || !isSelected}
              data-cy={`checkbox-inflate-serie-tag-${tag}-serie-${serieId}`}
              data-testid={`checkbox-inflate-serie-tag-${tag}-serie-${serieId}`}
            />
          </Td>
        )}
      </TrContent>
    );
  },
  (prevProps, nextProps) => {
    const prevYKey = prevProps.yKey;
    const nextYKey = nextProps.yKey;
    const prevYsSelected = prevProps.getValue('ysSelected') as YsSelected;
    const prevYsLabel = prevProps.getValue('ysLabel') as YsLabel;
    const prevYsOriginalName = prevProps.getValue(
      'ysOriginalName',
    ) as YsOriginalName;
    const prevYsModelUpdate = prevProps.getValue(
      'ysModelUpdate',
    ) as YsModelUpdate;
    const prevYsModelId = prevProps.getValue('ysModelId') as YsModelId;
    const prevYsInflate = prevProps.getValue('ysInflate') as YsInflate;
    const prevYsError = prevProps.getValue('ysError') as YsError;
    const prevYsDisabled = prevProps.getValue('ysDisabled') as YsDisabled;
    const nextYsSelected = nextProps.getValue('ysSelected') as YsSelected;
    const nextYsLabel = nextProps.getValue('ysLabel') as YsLabel;
    const nextYsOriginalName = nextProps.getValue(
      'ysOriginalName',
    ) as YsOriginalName;
    const nextYsModelUpdate = nextProps.getValue(
      'ysModelUpdate',
    ) as YsModelUpdate;
    const nextYsModelId = nextProps.getValue('ysModelId') as YsModelId;
    const nextYsInflate = nextProps.getValue('ysInflate') as YsInflate;
    const nextYsError = nextProps.getValue('ysError') as YsError;
    const nextYsDisabled = nextProps.getValue('ysDisabled') as YsDisabled;
    return (
      JSON.stringify(prevProps.tag) === JSON.stringify(nextProps.tag) &&
      prevYKey === nextYKey &&
      JSON.stringify(prevYsSelected[prevYKey]) ===
        JSON.stringify(nextYsSelected[nextYKey]) &&
      JSON.stringify(prevYsLabel[prevYKey]) ===
        JSON.stringify(nextYsLabel[nextYKey]) &&
      JSON.stringify(prevYsOriginalName[prevYKey]) ===
        JSON.stringify(nextYsOriginalName[nextYKey]) &&
      JSON.stringify(prevYsModelUpdate[prevYKey]) ===
        JSON.stringify(nextYsModelUpdate[nextYKey]) &&
      JSON.stringify(prevYsModelId[prevYKey]) ===
        JSON.stringify(nextYsModelId[nextYKey]) &&
      JSON.stringify(prevYsInflate[prevYKey]) ===
        JSON.stringify(nextYsInflate[nextYKey]) &&
      JSON.stringify(prevYsError[prevYKey]) ===
        JSON.stringify(nextYsError[nextYKey]) &&
      JSON.stringify(prevYsDisabled[prevYKey]) ===
        JSON.stringify(nextYsDisabled[nextYKey]) &&
      prevProps.showOriginalYName === nextProps.showOriginalYName &&
      prevProps.isVisible === nextProps.isVisible
    );
  },
);
