import styled from 'styled-components';

export const Container = styled.div``;

export const FormContainer = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 4rem;
`;

export const FieldsContainer = styled.div`
  max-width: 100%;
  width: 30rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;

  > div:nth-child(2) {
    height: 15rem;

    > div:last-of-type > div {
      height: 13.35rem;
    }
  }
`;

export const IconContainer = styled.div`
  width: 8.25rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.5rem;

  color: ${({ theme }) => theme.colors.gray5};
`;

export const IconButton = styled.button`
  width: 8.25rem;
  height: 8.25rem;

  border-radius: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.gray2}`};
  background-color: ${({ theme }) => theme.colors.gray3};

  transition: all 0.2s;

  img {
    width: 100%;
    height: 100%;

    border-radius: 8px;

    &:hover {
      border-radius: 7px;
    }
  }

  div {
    width: 100%;
    height: 100%;

    border-radius: 7px;
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.gray3};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
