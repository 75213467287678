import styled from 'styled-components';

export const Step2Container = styled.div`
  #workspace-projects-dependent-variables {
    max-width: 90vw !important;

    z-index: 10000 !important;

    word-break: break-all;

    font-size: 0.75rem;
    line-height: 150%;
  }
`;

export const Step2Header = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  gap: 1.5rem;

  > div:first-child {
    flex: 2;
  }

  > div:nth-child(2) {
    max-width: fit-content;
    margin-top: 0;
    flex: 1;
  }
`;

export const ContentProjectsAndSeries = styled.div`
  display: flex;
  gap: 2rem;
`;
