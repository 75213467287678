import styled from 'styled-components';

type TableSerieTagContainerProps = {
  color: string;
};

export const TableSerieTagContainer = styled.div<TableSerieTagContainerProps>`
  display: flex;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  background: ${({ color }) => color}1E;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;

  span {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: ${({ color }) => color};

    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
