import styled from 'styled-components';

export const UserLevelContainer = styled.div`
  position: relative;
`;

export const LevelContainer = styled.div`
  width: 1.25rem;
  height: 1.25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: translateY(-50%);

  background: ${({ theme }) => theme.colors.gray1};
  border-radius: 100%;

  p {
    font-size: 0.625rem;
    font-weight: 500;
    line-height: normal;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;
