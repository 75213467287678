import React, { ReactElement, createContext, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import ms from 'ms';
import ExcelJS from 'exceljs';
import { SerieData } from 'src/models/hooks/useQuerySeries';

import apiWorkspace from '../service/api';
import { useQueryWorkspaceData } from '../hooks/useQueryWorkspaceData';
import { useQuerySeriesGroupedByTag } from '../hooks/useQuerySeriesGroupedByTag';

type Frequency =
  | 'daily'
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'half-year'
  | 'annual'
  | 'yearly';

type Y = {
  id: string;
  name: string;
  label: string;
  status: string;
  info?: {
    frequency: Frequency;
  };
};

export type Project = {
  id: string;
  name: string;
  parent_id: string | null;
  created: string;
  ys: Y[];
};

export type ExternalSeries = {
  tag: string;
  series: Omit<SerieData, 'created'>[];
};

type Serie = {
  projectId: string;
  projectName: string;
  yLabel: string;
  y: string;
  modelUpdate: {
    label: string;
    value: string;
    y: string | null;
    isDisabled: boolean;
  };
  modelId: {
    model_id: string | 'ai-selection' | 'user-selection';
    model_label: 'ai-selection' | 'user-selection' | 'others' | null;
    error: boolean;
  };
  isInflated: boolean;
  type?: string | null;
  type_label?: string | null;

  yName: string;
  selected: boolean;
  error: string | null;
  isDisabled: boolean;
  seriesId: string;
  source: 'projects' | 'external_series';
  serieTag: string;
};

type YHierarchiesProps = {
  name: string; // Nome do filtro
  value: string | null; // Opção do filtro
};

type YsHierarchiesProps = {
  [yLabel: string]: YHierarchiesProps[];
};

type Categorization = {
  ys: YsHierarchiesProps;
  hierarchies: string[];
};

type ParamsProps = {
  id: string;
};

type Filter = {
  name: string;
  options: string[];
};

type Filters = {
  [levelNumber: string]: Filter | null;
};

type YFilters = {
  [yLabel: string]: {
    [levelNumber: string]: string;
  };
};

type Ys = {
  project_id: string;
  y: string;
  y_label: string;
  model_id: string;
  model_label: 'ai-selection' | 'user-selection' | null;
  is_inflated: boolean;
  type?: null | string;
  type_label?: null | string;
  series_id: string;
  tag: string;
  source: 'external_series' | 'projects';
};

type Enable = {
  enable: boolean;
};

type StagingArea = {
  id: string;
  created_at: string;
  updated_at: string;
  data: {
    ys: Ys[];
    filters: Filters;
    y_filters: YFilters;
    market_share: Enable | null;
    aggregation: Enable | null;
    approval_flow: Enable | null;
  };
};

type StagingAreaHierarchies = {
  aggregation: Enable | null;
  hierarchies: string[];
  market_share: Enable | null;
  ys: {
    hierarchy: YHierarchiesProps[];
    type_label: string | null;
    y_label: string;
  }[];
};

type TemporalAggregation = {
  y_type: string;
  frequency_method: 'sum' | 'average' | 'last_of_period' | 'not_aggregated';
};

type LevelAggregation = {
  y_type: string;
  frequency_method: 'sum' | 'average' | 'not_aggregated';
};

type StagingAreaAggregations = {
  temporal: TemporalAggregation[];
  hierarchical: LevelAggregation[];
};

type ProjectProps = {
  id: string;
  name: string;
  parent_id: string | null;
  created: string;
  ys: {
    id: string;
    name: string;
    label: string;
    status: string;
    frequency: Frequency;
  }[];
};

type UpdateParentId = {
  [key: string]: string;
};

type SeriesLabel = {
  [key: string]: string;
};

export type WorkspaceConfigContextType = {
  workspaceId?: string;
  isEdition: boolean;
  name: string;
  description: string;
  iconUrl: string;
  frequency?: Frequency;
  projects: Project[];
  updateParentId?: UpdateParentId;
  series: Serie[];
  categorization?: Categorization;
  file?: File;
  enableMarketShare: boolean;
  levelAggregation: LevelAggregation[];
  temporalAggregation: TemporalAggregation[];
  stagingAreaData?: StagingArea;
  isLoadingWorkspace?: boolean;
  isLoadingStagingArea?: boolean;
  isErrorStagingArea?: boolean;
  isLoadingStagingAreaHierarchies?: boolean;
  isErrorStagingAreaHierarchies?: boolean;
  hasSeriesChanges: boolean;
  updatingCategorization?: boolean;
  yTypesDefinedByUser: string[];
  yTypesUsedOnCategorization: string[];
  isLoadingStagingAreaAggregations?: boolean;
  isErrorStagingAreaAggregations?: boolean;
  externalSeries: ExternalSeries[];
  setFrequency: (freq?: Frequency) => void;
  setFile: (file?: File) => void;
  setEnableMarketShare: (enable: boolean) => void;
  setLevelAggregation: (aggregation: LevelAggregation[]) => void;
  setTemporalAggregation: (aggregation: TemporalAggregation[]) => void;
  handleAddNewYType: (type: string) => void;
  handleRemoveYType: (type: string) => void;
  saveBasicInformations: (
    workspaceName: string,
    workspaceDescription: string,
    workspaceIconUrl: string,
  ) => void;
  saveSeriesInformation: (series: Serie[], variablesLabel: SeriesLabel) => void;
  saveCategorization: (categorization?: Categorization) => void;
  saveProjectAndExternalSeries: (
    projects: Project[],
    externalSeries: ExternalSeries[],
  ) => void;
};

export const WorkspaceConfigContext = createContext(
  {} as WorkspaceConfigContextType,
);

export const WorkspaceConfigProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [iconUrl, setIconUrl] = useState('');

  const [frequency, setFrequency] = useState<Frequency>();

  const [projects, setProjects] = useState<Project[]>([]);
  const [externalSeries, setExternalSeries] = useState<ExternalSeries[]>([]);
  const [updateParentId, setUpdateParentId] = useState<UpdateParentId>();
  const [series, setSeries] = useState<Serie[]>([]);
  const [seriesLabel, setSeriesLabel] = useState<SeriesLabel>({});

  const [categorization, setCategorization] = useState<Categorization>();
  const [file, setFile] = useState<File>();

  const [yTypesDefinedByUser, setYTypesDefinedByUser] = useState<string[]>([]);
  const [yTypesUsedOnCategorization, setYTypesUsedOnCategorization] = useState<
    string[]
  >([]);

  const [updatingCategorization, setUpdatingCategorization] = useState(false);

  const [enableMarketShare, setEnableMarketShare] = useState(false);

  const location = useLocation();

  const { id: workspaceId } = useParams<ParamsProps>();

  const { seriesGroupedByTagData, seriesGroupedByTagIsLoading } =
    useQuerySeriesGroupedByTag({
      requestAllowed: true,
      searchValue: '',
    });

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const isEdition =
    location.pathname.includes('/control-panel/edition') || !!workspace?.id;

  const [levelAggregation, setLevelAggregation] = useState<LevelAggregation[]>(
    isEdition
      ? []
      : [
          {
            y_type: 'Others',
            frequency_method: 'not_aggregated',
          },
        ],
  );
  const [temporalAggregation, setTemporalAggregation] = useState<
    TemporalAggregation[]
  >(
    isEdition
      ? []
      : [
          {
            y_type: 'Others',
            frequency_method: 'sum',
          },
        ],
  );

  const [hasSeriesChanges, setHasSeriesChanges] = useState(false);

  const { t: translate } = useTranslation();

  const { isLoading, isFetching } = useQueryWorkspaceData(
    workspaceId ?? '',
    !!isEdition && !workspace?.id && !!workspaceId && !!email,
    email ?? '',
  );

  const {
    data: stagingAreaData,
    isLoading: isLoadingStagingArea,
    isFetching: isFetchingStagingArea,
    isError: isErrorStagingArea,
  } = useQuery(
    ['workspace staging area', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingArea>(
        `/workspaces/${workspaceId}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const {
    data: stagingAreaHierarchiesData,
    isLoading: isLoadingStagingAreaHierarchies,
    isFetching: isFetchingStagingAreaHierarchies,
    isError: isErrorStagingAreaHierarchies,
  } = useQuery(
    ['workspace staging area hierarchies', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingAreaHierarchies>(
        `/workspaces/${workspaceId}/staging-area/hierarchies`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const {
    data: stagingAreaAggregationsData,
    isLoading: isLoadingStagingAreaAggregations,
    isFetching: isFetchingStagingAreaAggregations,
    isError: isErrorStagingAreaAggregations,
    error: errorStagingAreaAggregations,
  } = useQuery<StagingAreaAggregations, AxiosError>(
    ['workspace staging area aggregations', workspaceId],
    async () => {
      const { data } = await apiWorkspace.get<StagingAreaAggregations>(
        `/workspaces/${workspaceId}/staging-area/aggregation`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!workspaceId,
    },
  );

  const saveProjectAndExternalSeries = async (
    selectedProjects: Project[],
    selectedExternalSeries: ExternalSeries[],
  ) => {
    const externalSeriesTags = externalSeries.map(
      (externalSerie) => externalSerie.tag,
    );

    const projectsIds = selectedProjects.map((project) => project.id);

    let hasChanges = true;

    const variables = series.filter(
      (serie) =>
        externalSeriesTags.includes(serie.serieTag) ||
        projectsIds.includes(serie.projectId),
    );

    if (!hasSeriesChanges) {
      hasChanges = serieWasChanged(variables);

      setHasSeriesChanges(hasChanges);
    }

    let updatedVariables: Serie[] | undefined = [...variables];

    if (hasChanges && (file || categorization?.hierarchies.length)) {
      setUpdatingCategorization(true);

      updatedVariables = await checkCategorization(variables);

      setUpdatingCategorization(false);
    } else {
      setSeries(variables);
    }

    if (!updatedVariables?.length) {
      setSeries(variables);
    }

    setProjects(selectedProjects);
    setExternalSeries(selectedExternalSeries);
  };

  const saveBasicInformations = (
    workspaceName: string,
    workspaceDescription: string,
    workspaceIconUrl: string,
  ) => {
    setName(workspaceName);
    setDescription(workspaceDescription);
    setIconUrl(workspaceIconUrl);
  };

  const serieWasChanged = (newSerie: Serie[]) => {
    const newSerieSelected: Serie[] = [];

    newSerie.forEach((y) => {
      if (y.selected) {
        if (y.source === 'projects') {
          newSerieSelected.push({
            ...y,
            modelUpdate: {
              label: '',
              value: y.modelUpdate!.value,
              y: null,
              isDisabled: false,
            },
            modelId: {
              model_id: y.modelId.model_id,
              model_label: y.modelId.model_label,
              error: false,
            },
          });
        } else {
          newSerieSelected.push(y);
        }
      }
    });

    const seriesSelected: Serie[] = [];

    series.forEach((y) => {
      if (y.selected) {
        seriesSelected.push({
          ...y,
          modelUpdate: {
            label: '',
            value: y.modelUpdate.value,
            y: null,
            isDisabled: false,
          },
          modelId: {
            model_id: y.modelId.model_id,
            model_label: y.modelId.model_label,
            error: false,
          },
        });
      }
    });

    return JSON.stringify(newSerieSelected) !== JSON.stringify(seriesSelected);
  };

  const removeExcelRows = async (
    updatedFile: File,
    rows: string[],
  ): Promise<Blob | undefined> => {
    if (file) {
      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(updatedFile);

      return new Promise((resolve, reject) => {
        reader.onload = async () => {
          const buffer = reader.result;

          await workbook.xlsx.load(buffer as Buffer);

          const worksheet = workbook.worksheets[0];

          for (let i = worksheet.rowCount; i >= 1; i--) {
            const row = worksheet.getRow(i);

            if (rows.includes(row.getCell(1).value as string)) {
              worksheet.spliceRows(i, 1);
            }
          }

          const modifiedBuffer = await workbook.xlsx.writeBuffer();

          const modifiedFile = new Blob([modifiedBuffer], { type: file.type });

          resolve(modifiedFile);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    }

    return undefined;
  };

  const isIndividualTemporalAggregation = () => {
    const firstFrequencyMethod =
      temporalAggregation[0]?.frequency_method ?? 'sum';

    return !!temporalAggregation.find(
      (agg) => agg.frequency_method !== firstFrequencyMethod,
    );
  };

  const isIndividualLevelAggregation = () => {
    const firstFrequencyMethod = levelAggregation[0]?.frequency_method ?? 'sum';

    return !!levelAggregation.find(
      (agg) => agg.frequency_method !== firstFrequencyMethod,
    );
  };

  const addRowsToExcel = async (
    newRowData: { label: string; project: string }[],
  ): Promise<Blob | undefined> => {
    if (file) {
      let hasOthersOptions = true;
      let otherType = 'Others';

      setYTypesUsedOnCategorization((state) => {
        const stateLowerCase = state.map((value) => value.toLowerCase());

        if (
          !stateLowerCase.includes('outros') &&
          !stateLowerCase.includes('others')
        ) {
          hasOthersOptions = false;

          return [...state, translate('createWorkspaceTemplateOthers')];
        }

        if (stateLowerCase.includes('outros')) {
          otherType = 'Outros';
        }

        return [...state];
      });

      if (!hasOthersOptions) {
        const isIndividualTemporalAgg = isIndividualTemporalAggregation();
        const isIndividualLevelAgg = isIndividualLevelAggregation();

        setTemporalAggregation((state) => [
          ...state,
          {
            y_type: translate('createWorkspaceTemplateOthers'),
            frequency_method: isIndividualTemporalAgg
              ? 'sum'
              : temporalAggregation[0].frequency_method,
          },
        ]);
        setLevelAggregation((state) => [
          ...state,
          {
            y_type: translate('createWorkspaceTemplateOthers'),
            frequency_method: isIndividualLevelAgg
              ? 'sum'
              : levelAggregation[0].frequency_method,
          },
        ]);
      }

      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);

      return new Promise((resolve, reject) => {
        reader.onload = async () => {
          const buffer = reader.result;

          await workbook.xlsx.load(buffer as Buffer);

          const worksheet = workbook.worksheets[0];

          newRowData.forEach((data) => {
            worksheet.addRow([
              data.label,
              otherType,
              translate('createFiltersNotDefined'),
            ]);
          });

          const modifiedBuffer = await workbook.xlsx.writeBuffer();

          const modifiedFile = new Blob([modifiedBuffer], { type: file.type });

          resolve(modifiedFile);
        };

        reader.onerror = (error) => {
          reject(error);
        };
      });
    }

    return undefined;
  };

  const checkCategorization = async (
    newSerie: Serie[],
  ): Promise<Serie[] | undefined> => {
    let discardCategorization = false;

    const updatedYsCategorization = { ...categorization?.ys };
    const categorizationYsKey = Object.keys(categorization?.ys ?? {});
    const selectedLabels: string[] = [];

    const isManualCategorization = !!categorization?.hierarchies.length;

    const ysAdded: { label: string; project: string }[] = [];

    const updatedSeries = [...newSerie];

    for (let i = 0; i < newSerie.length; i++) {
      const y = newSerie[i];

      const key = `${y.projectId}-${y.y}`;

      if (y.selected) {
        if (seriesLabel[key] && seriesLabel[key] !== y.yLabel) {
          discardCategorization = true;
          break;
        }

        if (!seriesLabel[key]) {
          ysAdded.push({ label: y.yLabel, project: y.projectName });
        } else if (file) {
          const yExists = series.find(
            (serie) =>
              serie.projectId === y.projectId &&
              serie.y === y.y &&
              serie.selected,
          );

          if (!yExists) {
            ysAdded.push({ label: y.yLabel, project: y.projectName });
          }
        }

        if (isManualCategorization && !categorizationYsKey.includes(y.yLabel)) {
          const newYCategorization = [
            {
              name: categorization.hierarchies[0],
              value: translate('createFiltersNotDefined'),
            },
          ];

          if (enableMarketShare || isEdition) {
            newYCategorization[0].name = categorization.hierarchies[1];

            newYCategorization.unshift({
              name: translate('createWorkspaceTemplateVariableType'),
              value: translate('createWorkspaceTemplateOthers'),
            });

            const serieIndex = series.findIndex(
              (serie) => serie.projectId === y.projectId && serie.y === y.y,
            );

            if (serieIndex !== -1) {
              updatedSeries[serieIndex].type = translate(
                'createWorkspaceTemplateOthers',
              );
            }
          }

          updatedYsCategorization[y.yLabel] = newYCategorization;
        }

        selectedLabels.push(y.yLabel);
      }
    }

    if (discardCategorization) {
      setCategorization(undefined);
      setFile(undefined);
    } else if (isManualCategorization) {
      categorizationYsKey.forEach((key) => {
        if (!selectedLabels.includes(key)) {
          delete updatedYsCategorization[key];
        }
      });

      setSeries(updatedSeries);
      setCategorization({ ...categorization, ys: updatedYsCategorization });

      return updatedSeries;
    } else {
      const ysRemoved: string[] = [];

      series.forEach((serie) => {
        if (serie.selected && !selectedLabels.includes(serie.yLabel)) {
          ysRemoved.push(serie.yLabel);
        }
      });

      let updatedFile = file;

      if (ysAdded.length && updatedFile) {
        try {
          const blob = await addRowsToExcel(ysAdded);

          if (blob) {
            updatedFile = new File([blob], updatedFile.name, {
              type: updatedFile.type,
              lastModified: Date.now(),
            });
          }

          // eslint-disable-next-line no-empty
        } catch {}
      }

      if (ysRemoved.length && updatedFile) {
        try {
          const blob = await removeExcelRows(updatedFile, ysRemoved);

          if (blob) {
            updatedFile = new File([blob], updatedFile.name, {
              type: updatedFile.type,
              lastModified: Date.now(),
            });
          }

          // eslint-disable-next-line no-empty
        } catch {}
      }

      setFile(updatedFile);
    }

    return undefined;
  };

  const saveSeriesInformation = async (
    variables: Serie[],
    variablesLabel: SeriesLabel,
  ) => {
    let hasChanges = true;

    if (!hasSeriesChanges) {
      hasChanges = serieWasChanged(variables);

      setHasSeriesChanges(hasChanges);
    }

    let updatedVariables: Serie[] | undefined = [...variables];

    if (hasChanges && (file || categorization?.hierarchies.length)) {
      setUpdatingCategorization(true);

      updatedVariables = await checkCategorization(variables);

      setUpdatingCategorization(false);
    } else {
      setSeries(variables);
    }

    if (!updatedVariables?.length) {
      setSeries(variables);
    }

    setSeriesLabel(variablesLabel);
  };

  const saveCategorization = (filter?: Categorization) => {
    if (filter) {
      const keys = Object.keys(filter.ys);

      keys.forEach((key) => {
        if (!filter.ys[key][0].value) {
          filter.ys[key][0].value = translate('createFiltersNotDefined');
        }
      });
    }

    setCategorization(filter);
  };

  const handleAddNewYType = (type: string) => {
    setYTypesDefinedByUser((state) => [...state, type]);
  };

  const handleRemoveYType = (typeToRemove: string) => {
    setYTypesDefinedByUser((state) =>
      state.filter((type) => type !== typeToRemove),
    );
  };

  useEffect(() => {
    const getAllYTypes = async () => {
      const allYTypes: Set<string> = new Set();

      if (file) {
        const arrayBuffer = await file.arrayBuffer();

        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(arrayBuffer);

        const worksheet = workbook.worksheets[0];

        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            const thirdCell = row.getCell(2).value;

            if (thirdCell) {
              allYTypes.add(thirdCell.toString());
            }
          }
        });
      }

      const yTypesArray = Array.from(allYTypes);

      if (
        JSON.stringify(yTypesArray) !==
        JSON.stringify(yTypesUsedOnCategorization)
      ) {
        const firstTemporalAggregation =
          temporalAggregation[0]?.frequency_method ?? 'sum';
        const firstLevelAggregation =
          levelAggregation[0]?.frequency_method ?? 'sum';

        const isIndividualTemporalAgg = isIndividualTemporalAggregation();
        const isIndividualLevelAgg = isIndividualLevelAggregation();

        const updatedTemporalAggregation: TemporalAggregation[] = [];
        const updatedLevelAggregation: LevelAggregation[] = [];

        for (let i = 0; i < temporalAggregation.length; i++) {
          const aggregation = temporalAggregation[i];

          if (yTypesArray.includes(aggregation.y_type)) {
            allYTypes.delete(aggregation.y_type);

            updatedTemporalAggregation.push(aggregation);
            updatedLevelAggregation.push(levelAggregation[i]);
          }
        }

        const ysTypeMissing = Array.from(allYTypes);

        for (let i = 0; i < ysTypeMissing.length; i++) {
          updatedTemporalAggregation.push({
            y_type: ysTypeMissing[i],
            frequency_method: isIndividualTemporalAgg
              ? 'sum'
              : firstTemporalAggregation,
          });
          updatedLevelAggregation.push({
            y_type: ysTypeMissing[i],
            frequency_method: isIndividualLevelAgg
              ? 'sum'
              : firstLevelAggregation,
          });
        }

        if (!updatedTemporalAggregation.length) {
          updatedTemporalAggregation.push({
            y_type: translate('createWorkspaceTemplateOthers'),
            frequency_method: temporalAggregation[0]?.frequency_method ?? 'sum',
          });
          updatedLevelAggregation.push({
            y_type: translate('createWorkspaceTemplateOthers'),
            frequency_method: levelAggregation[0]?.frequency_method ?? 'sum',
          });
        }

        setTemporalAggregation(updatedTemporalAggregation);
        setLevelAggregation(updatedLevelAggregation);
      }

      setYTypesUsedOnCategorization(yTypesArray);
    };

    if (file) {
      getAllYTypes();
    } else if (!file && !isEdition) {
      setYTypesUsedOnCategorization([
        translate('createWorkspaceTemplateOthers'),
      ]);
      setTemporalAggregation([
        {
          y_type: translate('createWorkspaceTemplateOthers'),
          frequency_method: temporalAggregation[0]?.frequency_method ?? 'sum',
        },
      ]);
      setLevelAggregation([
        {
          y_type: translate('createWorkspaceTemplateOthers'),
          frequency_method: levelAggregation[0]?.frequency_method ?? 'sum',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (!file && !categorization?.hierarchies.length) {
      setYTypesUsedOnCategorization([
        translate('createWorkspaceTemplateOthers'),
      ]);
      setTemporalAggregation([
        {
          y_type: translate('createWorkspaceTemplateOthers'),
          frequency_method: temporalAggregation[0]?.frequency_method ?? 'sum',
        },
      ]);
      setLevelAggregation([
        {
          y_type: translate('createWorkspaceTemplateOthers'),
          frequency_method: levelAggregation[0]?.frequency_method ?? 'sum',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, categorization]);

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      workspace?.name &&
      workspace?.description &&
      workspace?.icon
    ) {
      setName(workspace.name);
      setDescription(workspace.description);
      setIconUrl(workspace.icon);
    }
  }, [isLoading, isFetching, workspace]);

  useEffect(() => {
    if (
      stagingAreaData &&
      !isLoadingStagingArea &&
      !isFetchingStagingArea &&
      seriesGroupedByTagData &&
      !seriesGroupedByTagIsLoading
    ) {
      const loadProjectData = async (
        projectId: string,
      ): Promise<ProjectProps> => {
        let projectResponse: ProjectProps;

        const response = queryClient.getQueryData<ProjectProps>([
          'workspace project',
          workspaceId,
          projectId,
        ]);

        try {
          if (response) {
            projectResponse = response;
          } else {
            projectResponse = await queryClient.fetchQuery<ProjectProps>(
              ['workspace project', workspaceId, projectId],
              async () => {
                const { data } = await apiWorkspace.get(
                  `/workspaces/${workspaceId}/users/projects/${projectId}`,
                );

                return data;
              },
              {
                staleTime: ms('3 min'),
              },
            );
          }
        } catch {
          return {
            id: '',
            name: '',
            parent_id: '',
            ys: [],
            created: '',
          };
        }

        return projectResponse;
      };

      const getSeriesInformation = async () => {
        const ys = stagingAreaData.data.ys;

        const allSeries: Serie[] = [];

        const updateParentIds: UpdateParentId = {};
        const allSelectedProjects: ProjectProps[] = [];
        const allProjectIds: string[] = [];
        const allSeriesLabel: SeriesLabel = {};

        const allYTypes: Set<string> = new Set();

        for (let i = 0; i < ys.length; i++) {
          if (ys[i].source !== 'external_series') {
            let projectId = ys[i].project_id;
            let updatedProject = {} as ProjectProps;
            let yId = ys[i].y;

            if (!allProjectIds.includes(projectId)) {
              let projectData = await loadProjectData(ys[i].project_id);

              if (projectData.parent_id) {
                updatedProject = projectData;
                projectData = await loadProjectData(projectData.parent_id);
              }

              projectId = projectData.id;

              if (updatedProject?.id) {
                yId =
                  projectData.ys.find(
                    (y) =>
                      y.id === yId ||
                      (y.id.endsWith(`_${yId}`) &&
                        y.id.startsWith('forecast_')),
                  )?.id ?? '';

                updateParentIds[updatedProject.id] = projectId;
              } else {
                updateParentIds[projectId] = projectId;
              }

              if (!allProjectIds.includes(projectId)) {
                projectData.ys.forEach((y) => {
                  const isSelected = y.id === yId;
                  const isUpdate = updatedProject?.id;

                  const isDisabled = y.status !== 'success';

                  if (!frequency && !isDisabled) {
                    setFrequency(y.frequency);
                  }

                  allSeries.push({
                    projectId,
                    projectName: projectData.name,
                    yLabel: y.label,
                    y: isSelected ? yId : y.id,
                    modelUpdate: {
                      label:
                        isSelected && isUpdate
                          ? ''
                          : `Original\n${projectData.created}`,
                      value:
                        isSelected && isUpdate
                          ? updatedProject.id
                          : projectData.id,
                      y: null,
                      isDisabled: false,
                    },
                    modelId: {
                      model_id: isDisabled ? '--' : 'ai-selection',
                      error: isDisabled,
                      model_label: isDisabled ? 'others' : 'ai-selection',
                    },
                    isInflated: false,
                    yName: y.name,
                    selected: false,
                    error: '',
                    isDisabled,
                    type: 'Others',
                    type_label: 'Others',
                    seriesId: '',
                    source: 'projects',
                    serieTag: '',
                  });

                  const serieKey = `${projectId}-${isSelected ? yId : y.id}`;
                  allSeriesLabel[serieKey] = y.label;
                });

                allSelectedProjects.push(projectData);
                allProjectIds.push(projectId);
              }

              const yIndex = allSeries.findIndex(
                (serie) => serie.y === yId && serie.projectId === projectId,
              );

              if (yIndex !== -1) {
                const yTypeLabel = ys[i].type_label;

                allSeries[yIndex].modelUpdate = {
                  label: '',
                  value: ys[i].project_id,
                  isDisabled: false,
                  y: ys[i].y,
                };
                allSeries[yIndex].yLabel = ys[i].y_label;
                allSeries[yIndex].selected = true;
                allSeries[yIndex].isInflated = ys[i].is_inflated;
                allSeries[yIndex].modelId = {
                  model_label: ys[i].model_label ?? 'others',
                  model_id: ys[i].model_label ?? ys[i].model_id,
                  error: false,
                };
                allSeries[yIndex].type = ys[i].type;
                allSeries[yIndex].type_label = yTypeLabel;

                const serieKey = `${allSeries[yIndex].projectId}-${allSeries[yIndex].y}`;
                allSeriesLabel[serieKey] = ys[i].y_label;

                if (yTypeLabel) {
                  allYTypes.add(yTypeLabel);
                }
              }
            } else {
              const yIndex = allSeries.findIndex(
                (serie) => serie.y === yId && serie.projectId === projectId,
              );

              if (yIndex !== -1) {
                const yTypeLabel = ys[i].type_label;

                allSeries[yIndex].yLabel = ys[i].y_label;
                allSeries[yIndex].selected = true;
                allSeries[yIndex].isInflated = ys[i].is_inflated;
                allSeries[yIndex].modelId = {
                  model_label: ys[i].model_label ?? 'others',
                  model_id: ys[i].model_label ?? ys[i].model_id,
                  error: false,
                };
                allSeries[yIndex].type = ys[i].type;
                allSeries[yIndex].type_label = yTypeLabel;
                allSeries[yIndex].modelUpdate = {
                  label: '',
                  value: ys[i].project_id,
                  isDisabled: false,
                  y: ys[i].y,
                };

                const serieKey = `${allSeries[yIndex].projectId}-${allSeries[yIndex].y}`;
                allSeriesLabel[serieKey] = ys[i].y_label;

                if (yTypeLabel) {
                  allYTypes.add(yTypeLabel);
                }
              }
            }
          }
        }

        const allSelectedTags: ExternalSeries[] = [];

        const ysSeries = ys.filter((y) => y.source === 'external_series');
        const tagsSelected = new Set<string>();
        const ySerieKeyAdded: string[] = [];

        ysSeries.forEach((ySerie) => {
          tagsSelected.add(ySerie.tag);
        });

        tagsSelected.forEach((tag) => {
          if (seriesGroupedByTagData?.[tag]) {
            seriesGroupedByTagData?.[tag].forEach((serie) => {
              const isDisabled = serie.status !== 'success';
              if (!frequency && !isDisabled) {
                setFrequency(serie.frequency);
              }
              const ySerieExists = ysSeries.find(
                (ySerie) => ySerie.series_id === serie.id,
              );

              const yTypeLabel = ySerieExists?.type_label;

              allSeries.push({
                projectId: '',
                projectName: '',
                yLabel: ySerieExists ? ySerieExists.y_label : serie.name,
                y: serie.id,
                modelUpdate: {
                  label: '',
                  value: '',
                  y: '',
                  isDisabled: false,
                },
                modelId: {
                  model_id: 'ai-selection',
                  error: false,
                  model_label: 'ai-selection',
                },
                isInflated: ySerieExists ? ySerieExists.is_inflated : false,
                yName: serie.name,
                selected: !!ySerieExists,
                error: null,
                isDisabled,
                type: ySerieExists?.type ?? 'Others',
                type_label: ySerieExists?.type_label ?? 'Others',
                seriesId: serie.id,
                source: 'external_series',
                serieTag: tag,
              });
              const serieKey = `${tag}-${serie.id}`;
              allSeriesLabel[serieKey] = ySerieExists
                ? ySerieExists.y_label
                : serie.name;
              ySerieKeyAdded.push(serieKey);

              if (yTypeLabel) {
                allYTypes.add(yTypeLabel);
              }
            });
            allSelectedTags.push({
              tag,
              series: seriesGroupedByTagData[tag],
            });
          }
        });

        const ysRemainingSeries = ysSeries.filter((ySerie) => {
          const key = `${ySerie.tag}-${ySerie.series_id}`;
          return !ySerieKeyAdded.includes(key);
        });

        ysRemainingSeries.forEach((yRemainingSerie) => {
          allSeries.push({
            projectId: '',
            projectName: '',
            yLabel: yRemainingSerie.y_label,
            y: yRemainingSerie.series_id,
            modelUpdate: {
              label: '',
              value: '',
              y: '',
              isDisabled: false,
            },
            modelId: {
              model_id: 'ai-selection',
              error: false,
              model_label: 'ai-selection',
            },
            isInflated: yRemainingSerie.is_inflated,
            yName: yRemainingSerie.y,
            selected: true,
            error: null,
            isDisabled: false,
            type: yRemainingSerie.type ?? 'Others',
            type_label: yRemainingSerie.type_label ?? 'Others',
            seriesId: yRemainingSerie.series_id,
            source: 'external_series',
            serieTag: yRemainingSerie.tag,
          });

          const tagIndex = allSelectedTags.findIndex(
            (selectedTag) => selectedTag.tag === yRemainingSerie.tag,
          );

          const yTypeLabel = yRemainingSerie?.type_label;
          if (yTypeLabel) {
            allYTypes.add(yTypeLabel);
          }

          if (tagIndex !== -1) {
            allSelectedTags[tagIndex].series.push({
              id: yRemainingSerie.series_id,
              name: yRemainingSerie.y,
              status: 'success',
              frequency: 'monthly',
              tag: yRemainingSerie.tag,
            });
          } else {
            allSelectedTags.push({
              tag: yRemainingSerie.tag,
              series: [
                {
                  id: yRemainingSerie.series_id,
                  name: yRemainingSerie.y,
                  status: 'success',
                  frequency: 'monthly',
                  tag: yRemainingSerie.tag,
                },
              ],
            });
          }
        });

        setProjects(allSelectedProjects);
        setExternalSeries(allSelectedTags);
        setUpdateParentId(updateParentIds);
        setSeries(allSeries);
        setSeriesLabel(allSeriesLabel);

        const yTypesArr = Array.from(allYTypes);

        setYTypesUsedOnCategorization(yTypesArr);

        const defaultYTypes = ['others', 'marketsize', 'sellout', 'outros'];

        setYTypesDefinedByUser(
          yTypesArr.filter(
            (type) => !defaultYTypes.includes(type.toLowerCase()),
          ),
        );
      };

      getSeriesInformation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stagingAreaData,
    isLoadingStagingArea,
    isFetchingStagingArea,
    workspaceId,
    seriesGroupedByTagData,
    seriesGroupedByTagIsLoading,
  ]);

  useEffect(() => {
    if (
      stagingAreaHierarchiesData &&
      !isLoadingStagingAreaHierarchies &&
      !isFetchingStagingAreaHierarchies
    ) {
      setEnableMarketShare(
        stagingAreaHierarchiesData.market_share?.enable ?? false,
      );

      const newCategorization: Categorization = {
        ys: {},
        hierarchies: [...stagingAreaHierarchiesData.hierarchies],
      };

      if (stagingAreaHierarchiesData.hierarchies.length) {
        newCategorization.hierarchies.unshift(
          translate('createWorkspaceTemplateVariableType'),
        );
      }

      const ys = stagingAreaHierarchiesData.ys;

      ys.forEach(({ y_label, hierarchy, type_label }) => {
        const yHierarchy = [...hierarchy];

        if (yHierarchy.length) {
          yHierarchy.unshift({
            name: translate('createWorkspaceTemplateVariableType'),
            value: type_label,
          });
        }

        newCategorization.ys[y_label] = yHierarchy;
      });

      setCategorization(newCategorization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stagingAreaHierarchiesData,
    isLoadingStagingAreaHierarchies,
    isFetchingStagingAreaHierarchies,
  ]);

  useEffect(() => {
    if (
      stagingAreaData?.data &&
      enableMarketShare !== stagingAreaData.data.market_share?.enable
    ) {
      setHasSeriesChanges(true);
    }
  }, [stagingAreaData, levelAggregation, enableMarketShare]);

  useEffect(() => {
    if (levelAggregation.length && stagingAreaData) {
      const hasLevelAggregation = levelAggregation.some(
        (level) => level.frequency_method !== 'not_aggregated',
      );

      if (hasLevelAggregation !== stagingAreaData?.data.aggregation?.enable) {
        setHasSeriesChanges(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelAggregation, stagingAreaData?.data.aggregation?.enable]);

  useEffect(() => {
    if (
      stagingAreaAggregationsData &&
      !isLoadingStagingAreaAggregations &&
      !isFetchingStagingAreaAggregations
    ) {
      setTemporalAggregation(stagingAreaAggregationsData.temporal);
      setLevelAggregation(stagingAreaAggregationsData.hierarchical);
    }
  }, [
    stagingAreaAggregationsData,
    isLoadingStagingAreaAggregations,
    isFetchingStagingAreaAggregations,
  ]);

  useEffect(() => {
    if (
      isErrorStagingAreaAggregations &&
      errorStagingAreaAggregations?.response?.status === 404 &&
      yTypesUsedOnCategorization.length &&
      stagingAreaData
    ) {
      if (!temporalAggregation.length) {
        setTemporalAggregation(
          yTypesUsedOnCategorization.map((type) => ({
            y_type: type,
            frequency_method: 'sum',
            variation_method: 'year_over_year',
          })),
        );
      }
      if (!levelAggregation.length) {
        const aggregationEnabled = stagingAreaData.data.aggregation?.enable;

        setLevelAggregation(
          yTypesUsedOnCategorization.map((type) => ({
            y_type: type,
            frequency_method: aggregationEnabled ? 'sum' : 'not_aggregated',
            variation_method: aggregationEnabled
              ? 'year_over_year'
              : 'not_aggregated',
          })),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isErrorStagingAreaAggregations,
    errorStagingAreaAggregations,
    yTypesUsedOnCategorization,
    stagingAreaData,
  ]);

  return (
    <WorkspaceConfigContext.Provider
      value={{
        workspaceId: workspaceId ?? workspace.id ?? '',
        isEdition,
        name,
        description,
        iconUrl,
        frequency,
        projects,
        updateParentId,
        series,
        categorization,
        file,
        enableMarketShare,
        levelAggregation,
        temporalAggregation,
        stagingAreaData,
        isLoadingWorkspace: isLoading || isFetching,
        isLoadingStagingArea: isLoadingStagingArea || isFetchingStagingArea,
        isErrorStagingArea,
        isLoadingStagingAreaHierarchies:
          isLoadingStagingAreaHierarchies || isFetchingStagingAreaHierarchies,
        isErrorStagingAreaHierarchies,
        updatingCategorization,
        yTypesUsedOnCategorization,
        yTypesDefinedByUser,
        isLoadingStagingAreaAggregations:
          isLoadingStagingAreaAggregations || isFetchingStagingAreaAggregations,
        isErrorStagingAreaAggregations:
          isErrorStagingAreaAggregations &&
          errorStagingAreaAggregations?.response?.status !== 404,
        setFrequency,
        setFile,
        setEnableMarketShare,
        setLevelAggregation,
        setTemporalAggregation,
        handleAddNewYType,
        handleRemoveYType,
        saveBasicInformations,
        saveSeriesInformation,
        saveCategorization,
        hasSeriesChanges,
        saveProjectAndExternalSeries,
        externalSeries,
      }}
    >
      {children}
    </WorkspaceConfigContext.Provider>
  );
};
