import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;

  padding: 1.5rem 2rem;
  margin-top: 0.8rem;
  margin-bottom: 0.1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray1};
  border-radius: 8px;

  position: relative;

  .button-rounded {
    position: absolute;
    top: -0.813rem;
    right: -0.813rem;

    padding: 0.25rem;

    background: white;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const StepNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0.125rem 1rem;

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 999px;

  max-width: 18rem;

  button {
    line-height: 0;

    transition: all 0.2s;

    svg {
      width: 0.875rem;
      height: 0.875rem;

      color: ${({ theme }) => theme.colors.gray5};
    }

    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.gray6};
      }
    }
  }
`;

export const StepNameInput = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: auto;
  min-width: 1rem;
  max-width: 18rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  background: transparent;

  font-size: 0.75rem;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.gray5};

  border: 0;
  outline: 0;

  border-bottom: 1px solid transparent;

  &:disabled {
    cursor: auto !important;
  }

  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray4};
  }
`;

export const CreatingNewStepContent = styled.div`
  width: 18.125rem;
  height: 6.313rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    height: fit-content;
  }

  > div > div > div {
    width: 3rem !important;
    height: 3rem !important;
  }

  p {
    margin-top: 1rem;

    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
