import React, { useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Lightning, SignOut, UserGear } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from 'src/assets/logo.svg';
import { LanguageMenu } from 'src/components/LanguageMenu';
import { logout } from 'src/redux/reducers/Auth';
import { RootState } from 'src/redux/store';
import { ReactComponent as FeatureStoreActiveIcon } from 'src/assets/colorful-feature-store-icon.svg';
import { ReactComponent as ModelsActiveIcon } from 'src/assets/colorful-models-icon.svg';
import { ReactComponent as WorkspacesActiveIcon } from 'src/assets/colorful-workspace-icon.svg';
import { ReactComponent as FeatureStoreGrayIcon } from 'src/assets/gray-feature-store-icon.svg';
import { ReactComponent as ModelsGrayIcon } from 'src/assets/gray-models-icon.svg';
import { ReactComponent as WorkspacesGrayIcon } from 'src/assets/gray-workspace-icon.svg';

import { UserConfigs } from '../UserConfigs';
import {
  Container,
  ContentRightActionButtons,
  Tab,
  UpgradeContainer,
} from './styles';

export const Header: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  ...rest
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const auth0 = useAuth0();

  const { isFreemium } = user;

  const [menuVisible, setMenuVisible] = useState(false);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  }, [auth0, dispatch]);

  const isHomePage = location.pathname === '/';

  const canAccessFeatureStore = user.isFeatureStore;
  const canAccessModels = user.isFaaS || user.isClaaS;
  const canAccessWorkspaces =
    user.permissions?.includes('create:workspaces') ||
    user.permissions?.includes('read:workspaces');

  const featureStoreIsActive = location.pathname.startsWith('/feature-store');
  const modelsIsActive =
    (location.pathname.includes('/project') ||
      location.pathname.includes('/model') ||
      location.pathname.includes('/data-view')) &&
    !location.pathname.startsWith('/workspaces');
  const workspacesIsActive = location.pathname.startsWith('/workspaces');

  return (
    <Container {...rest}>
      <div>
        <Link to="/">
          <Logo data-testid="header-logo" data-cy="header-logo" />
        </Link>
      </div>

      {!isHomePage && (
        <nav>
          <Tab
            isActive={featureStoreIsActive}
            isDisabled={!canAccessFeatureStore}
          >
            <Link
              to="/feature-store/home"
              data-testid="header-link-feature-store"
              data-cy="header-link-feature-store"
            >
              {featureStoreIsActive ? (
                <FeatureStoreActiveIcon width="1.25rem" height="1.25rem" />
              ) : (
                <FeatureStoreGrayIcon width="1.25rem" height="1.25rem" />
              )}
              Feature Store
            </Link>
          </Tab>

          <Tab isActive={modelsIsActive} isDisabled={!canAccessModels}>
            <Link
              to="/models/projects"
              data-testid="header-link-faas"
              data-cy="header-link-faas"
            >
              {modelsIsActive ? (
                <ModelsActiveIcon width="1.25rem" height="1.25rem" />
              ) : (
                <ModelsGrayIcon width="1.25rem" height="1.25rem" />
              )}
              Models
            </Link>
          </Tab>

          <Tab isActive={workspacesIsActive} isDisabled={!canAccessWorkspaces}>
            <Link
              to="/workspaces"
              data-testid="anchor-workspaces-tab"
              data-cy="header-link-workspaces"
            >
              {workspacesIsActive ? (
                <WorkspacesActiveIcon width="1.25rem" height="1.25rem" />
              ) : (
                <WorkspacesGrayIcon width="1.25rem" height="1.25rem" />
              )}
              Workspaces
            </Link>
          </Tab>
        </nav>
      )}

      <ContentRightActionButtons>
        {isFreemium && (
          <UpgradeContainer
            data-testid="upgrade-button"
            data-cy="upgrade-button"
            onClick={() =>
              window.open(`https://4intelligence.ai/freemium/upgrade`, '_blank')
            }
          >
            <Lightning size="1.125rem" weight="fill" />
            {translate('upgrade')}
          </UpgradeContainer>
        )}

        <LanguageMenu />
        <button
          type="button"
          data-testid="button-user-settings"
          style={{ marginRight: '1rem' }}
          onClick={() => setMenuVisible(true)}
          aria-label="user settings button"
        >
          <UserGear size="1.5rem" />
        </button>

        <button
          type="button"
          onClick={handleLogout}
          id="button-logout"
          data-testid="button-logout"
          data-cy="button-logout"
          aria-label="logout button"
        >
          <SignOut size="1.5rem" />
        </button>
      </ContentRightActionButtons>
      <UserConfigs visible={menuVisible} setVisible={setMenuVisible} />
    </Container>
  );
};
