import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Head } from 'src/components/Head';
import { RootState } from 'src/redux/store';
import { ArrowUpRight, Database, Heart, UserCircleGear } from 'phosphor-react';
import featureStoreIcon from 'src/assets/colorful-feature-store-icon.svg';
import modelsIcon from 'src/assets/colorful-models-icon.svg';
import workspacesIcon from 'src/assets/colorful-workspace-icon.svg';

import {
  AdminContainer,
  AdminOptionLink,
  AdminOptions,
  Container,
  DisabledLink,
  Module,
  ModuleIcon,
  ModuleLink,
  ModulesContainer,
  PlansLink,
} from './styles';

export const Home: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t: translate } = useTranslation();

  const canAccessFeatureStore = user.isFeatureStore;
  const canAccessModels = user.isFaaS || user.isClaaS;
  const canAccessWorkspaces =
    user.permissions?.includes('create:workspaces') ||
    user.permissions?.includes('read:workspaces');

  const canAccessUserControl = user.isSupport || user.managerClientId;
  const canAccessFeatureStoreIndicators = user?.roles?.includes('isFsAdmin');
  const canAccessTheAccessGroups =
    user?.permissions?.includes('edit:access-groups');

  const showAdminOptions =
    canAccessUserControl ||
    canAccessFeatureStoreIndicators ||
    canAccessTheAccessGroups;

  const userControlLink = user.isSupport
    ? '/user-module/customers-list'
    : user.managerClientId
    ? `/user-module/customers-list/${user.managerClientId}`
    : '/';

  return (
    <Container>
      <Head title="4intelligence" />

      <h1>{translate('homeWelcome')}</h1>
      <p>{translate('homeModuleQuestion')}</p>

      <ModulesContainer>
        <Module
          moduleType={canAccessFeatureStore ? 'feature-store' : 'disabled'}
          data-testid="container-feature-store-module"
        >
          <ModuleIcon>
            <img src={featureStoreIcon} alt="feature store icon" />
          </ModuleIcon>

          <div>
            <h4>{translate('homeFeatureStoreSubheading')}</h4>
            <h2>{translate('homeFeatureStoreTitle')}</h2>

            <p>{translate('homeFeatureStoreDescription')}</p>
          </div>

          {canAccessFeatureStore ? (
            <ModuleLink
              to="/feature-store/home"
              data-testid="anchor-access-feature-store"
              data-cy="link-feature-store"
            >
              {translate('homeEnterButton')}
            </ModuleLink>
          ) : (
            <DisabledLink data-testid="container-access-feature-store-disabled">
              {translate('homeEnterButton')}
            </DisabledLink>
          )}
        </Module>

        <div>
          <Module
            moduleType={canAccessModels ? 'models' : 'disabled'}
            data-testid="container-models-module"
          >
            <ModuleIcon>
              <img src={modelsIcon} alt="models icon" />
            </ModuleIcon>

            <div>
              <h4>{translate('homeModelsSubheading')}</h4>
              <h2>{translate('homeModelsTitle')}</h2>

              <p>{translate('homeModelsDescription')}</p>
            </div>

            {canAccessModels ? (
              <ModuleLink
                to="/models/projects"
                data-testid="anchor-access-models"
                data-cy="link-faas"
              >
                {translate('homeEnterButton')}
              </ModuleLink>
            ) : (
              <DisabledLink data-testid="container-access-models-disabled">
                {translate('homeEnterButton')}
              </DisabledLink>
            )}
          </Module>

          {!canAccessModels && (
            <PlansLink
              to="https://4intelligence.ai/plans"
              target="_blank"
              data-testid="anchor-discover-plans"
            >
              <>
                {translate('homeDiscoverOurPlans')}

                <ArrowUpRight />
              </>
            </PlansLink>
          )}
        </div>

        <div>
          <Module
            moduleType={canAccessWorkspaces ? 'workspaces' : 'disabled'}
            data-testid="container-workspaces-module"
          >
            <ModuleIcon>
              <img src={workspacesIcon} alt="workspaces icon" />
            </ModuleIcon>

            <div>
              <h4>{translate('homeWorkspacesSubheading')}</h4>
              <h2>{translate('homeWorkspacesTitle')}</h2>

              <p>{translate('homeWorkspacesDescription')}</p>
            </div>

            {canAccessWorkspaces ? (
              <ModuleLink
                to="/workspaces"
                data-testid="anchor-access-workspaces"
              >
                {translate('homeEnterButton')}
              </ModuleLink>
            ) : (
              <DisabledLink data-testid="container-access-workspaces-disabled">
                {translate('homeEnterButton')}
              </DisabledLink>
            )}
          </Module>

          {canAccessModels && !canAccessWorkspaces && (
            <PlansLink
              to="https://4intelligence.ai/plans"
              target="_blank"
              data-testid="anchor-discover-plans"
            >
              <>
                {translate('homeDiscoverOurPlans')}

                <ArrowUpRight />
              </>
            </PlansLink>
          )}
        </div>
      </ModulesContainer>

      {showAdminOptions && (
        <AdminContainer data-testid="container-admin-options">
          <h3>{translate('homeAdminOptions')}</h3>

          <AdminOptions>
            {canAccessUserControl && (
              <AdminOptionLink
                to={userControlLink}
                data-testid="anchor-access-user-control"
              >
                <>
                  <UserCircleGear />
                  {translate('homeAdminUserControl')}
                </>
              </AdminOptionLink>
            )}

            {canAccessFeatureStoreIndicators && (
              <AdminOptionLink
                to="/feature-store/admin"
                data-testid="anchor-access-feature-store-indicators"
                data-cy="link-admin-fs"
              >
                <>
                  <Database />
                  {translate('homeAdminFeatureStoreIndicators')}
                </>
              </AdminOptionLink>
            )}

            {canAccessTheAccessGroups && (
              <AdminOptionLink
                to="/feature-store/admin/groups-premium"
                data-testid="anchor-access-the-access-groups"
                data-cy="link-group-access"
              >
                <>
                  <Heart />
                  {translate('homeAdminAccessGroups')}
                </>
              </AdminOptionLink>
            )}
          </AdminOptions>
        </AdminContainer>
      )}
    </Container>
  );
};
