import { format, isMatch, parse } from 'date-fns';
import { useInfiniteQuery } from 'react-query';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import api from 'src/models/service/api';

import {
  ProjectsResponse,
  UseQueryProjectsProps,
} from '../useQueryProjects/types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useInfiniteQueryProjects({
  searchValue = '',
  requestAllowed = true,
  onSuccess,
  limit = 8,
  projectType,
  engineName,
  engineVersion,
}: Omit<UseQueryProjectsProps, 'page'>) {
  const translateFormat = useFormatDateLanguage();

  const key = ['infinite-projects', searchValue];

  if (projectType) {
    key.push(projectType);
  }
  if (engineName) {
    key.push(engineName);
  }
  if (engineVersion) {
    key.push(engineVersion);
  }

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    key,
    async ({ pageParam = 0 }) => {
      let searchQuery = searchValue;
      if (isMatch(searchValue, translateFormat)) {
        searchQuery = format(
          parse(searchValue, translateFormat, new Date()),
          'MM/dd/yyyy',
        );
      }

      const queryParams = new URLSearchParams();

      if (searchValue.length >= 3) {
        if (isMatch(searchQuery, 'MM/dd/yyyy')) {
          queryParams.append('last_updated', searchQuery);
        } else {
          queryParams.append('project_name', searchValue);

          queryParams.append('y_series', searchValue);
        }
      }

      if (projectType) {
        queryParams.append('project_type', projectType);
      }
      if (engineName) {
        queryParams.append('engine_name', engineName);
      }
      if (engineVersion) {
        queryParams.append('engine_version_gte', engineVersion);
      }

      queryParams.append('skip', (pageParam * limit).toString());
      queryParams.append('limit', limit.toString());

      const response = await api.get<ProjectsResponse>(
        `/projects?${queryParams.toString()}`,
      );

      return response.data;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * limit < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
      staleTime: 1000 * 60,
      enabled: requestAllowed || searchValue === '',
      onSuccess: (successData) => {
        if (successData.pages.length && onSuccess) {
          onSuccess(successData.pages[0]);
        }
      },
    },
  );

  return {
    projectsData: data,
    projectsIsLoading: isLoading || isFetching,
    projectsError: isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
}
