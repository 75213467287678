import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 4.625rem);

  background: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 3rem 2rem;

  > h1 {
    color: ${({ theme }) => theme.colors.gray7};
    font-size: 2rem;
    font-weight: 400;
    line-height: normal;
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;

    margin-top: 0.5rem;
  }
`;

export const ModulesContainer = styled.div`
  width: 100%;
  max-width: 71.25rem;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 4rem 1.5rem;

  flex-wrap: wrap;

  margin-top: 5rem;
`;

const MODULE_DARK_COLOR = {
  'feature-store': 'primaryDark',
  models: 'secondaryDark',
  workspaces: 'green6',
  disabled: 'gray2',
} as const;

const MODULE_COLOR = {
  'feature-store': 'primary',
  models: 'secondary',
  workspaces: 'green4',
  disabled: 'gray2',
} as const;

type ModuleProps = {
  moduleType: keyof typeof MODULE_COLOR;
};

export const Module = styled.div<ModuleProps>`
  min-width: 22.75rem;
  max-width: 22.75rem;
  height: 18.375rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;

  flex: 1;

  border: 1px solid
    ${({ theme, moduleType }) => `${theme.colors[MODULE_COLOR[moduleType]]}33`};
  border-radius: 1rem;

  padding: 4rem 1.5rem 1.5rem 1.5rem;

  transition: all 0.2s;

  &:hover {
    border: 1px solid
      ${({ theme, moduleType }) => theme.colors[MODULE_COLOR[moduleType]]};
    box-shadow: 0px 8px 32px 0px
      ${({ theme, moduleType }) =>
        `${theme.colors[MODULE_COLOR[moduleType]]}33`};

    div:first-of-type {
      border: 1px solid
        ${({ theme, moduleType }) => theme.colors[MODULE_COLOR[moduleType]]};
      box-shadow: 0px -8px 24px 0px ${({ theme, moduleType }) => `${theme.colors[MODULE_COLOR[moduleType]]}1A`};
    }

    a {
      background: ${({ theme, moduleType }) =>
        theme.colors[MODULE_DARK_COLOR[moduleType]]} !important;
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.06rem;
    text-transform: uppercase;
  }

  h2 {
    color: ${({ theme }) => theme.colors.gray7};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: normal;

    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  p {
    color: ${({ theme }) => theme.colors.gray5};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 150%;
  }

  a {
    background: ${({ theme, moduleType }) =>
      `${theme.colors[MODULE_COLOR[moduleType]]}1F`};

    color: ${({ theme, moduleType }) => theme.colors[MODULE_COLOR[moduleType]]};
  }

  div:first-of-type {
    border: 1px solid
      ${({ theme, moduleType }) =>
        `${theme.colors[MODULE_COLOR[moduleType]]}33`};

    &::before {
      content: '';

      width: 30%;
      height: 30%;

      position: absolute;
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      background: ${({ theme, moduleType }) =>
        theme.colors[MODULE_COLOR[moduleType]]};
      border-radius: 100%;

      filter: blur(12px);

      z-index: -1;
    }
  }

  ${({ moduleType, theme }) =>
    moduleType === 'disabled' &&
    css`
      border: 1px solid ${theme.colors[MODULE_COLOR.disabled]};

      cursor: not-allowed;

      h4 {
        color: ${theme.colors.gray3};
      }

      h2 {
        color: ${theme.colors.gray5};
      }

      p {
        color: ${theme.colors.gray4};
      }

      div:first-of-type {
        border: 1px solid ${theme.colors[MODULE_COLOR.disabled]};
      }
    `}
`;

export const ModuleIcon = styled.div`
  width: fit-content;
  height: fit-content;

  position: absolute;
  top: 0%;
  left: 50%;

  transform: translate(-50%, -50%);

  background: ${({ theme }) => theme.colors.white};
  border-radius: 100%;

  padding: 1.25rem;

  font-size: 0;

  img {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const ModuleLink = styled(NavLink)`
  width: 100%;
  height: fit-content;

  display: inline-block;

  font-size: 0.875rem;
  font-weight: 600;

  border-radius: 5px;

  margin-top: 2rem;
  padding: 0.75rem 1.5rem;

  transition: all 0.2s;

  cursor: pointer;
`;

export const DisabledLink = styled.div`
  width: 100%;
  height: fit-content;

  display: inline-block;

  font-size: 0.875rem;
  font-weight: 600;

  border-radius: 5px;

  margin-top: 2rem;
  padding: 0.75rem 1.5rem;

  background: ${({ theme }) => theme.colors.white} !important;
  border: 1px solid ${({ theme }) => theme.colors[MODULE_COLOR.disabled]} !important;

  color: ${({ theme }) => theme.colors.gray4} !important;

  cursor: not-allowed;
`;

export const AdminContainer = styled.div`
  margin-top: 2.5rem;

  h3 {
    color: ${({ theme }) => theme.colors.gray6};
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
  }
`;

export const AdminOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  margin-top: 1rem;
`;

export const AdminOptionLink = styled(NavLink)`
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;

  border-radius: 999px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  font-size: 0.875rem;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.gray5};

  transition: all 0.2s;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.6 !important;
  }

  &:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.gray6};

    svg {
      color: ${({ theme }) => theme.colors.gray5};
    }

    border: 1px solid ${({ theme }) => theme.colors.gray3};
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

    display: flex;
    justify-content: center;
    align-items: center;

    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;
  }
`;

export const PlansLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  color: ${({ theme }) => theme.colors.primary};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.375rem;

  margin-top: 1rem;

  transition: all 0.2s;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
  }
`;
