const homePTBRTranslations = {
  homeWelcome: 'Bem-vindo à plataforma',
  homeModuleQuestion: 'Qual módulo você deseja acessar?',
  homeEnterButton: 'Acessar',
  homeFeatureStoreSubheading: 'Economic Data',
  homeFeatureStoreTitle: 'Feature Store',
  homeFeatureStoreDescription:
    'Acesse dados e projeções econômicas confiáveis. Pronto para usar. Tudo em um só lugar.',
  homeModelsSubheading: 'AI Models',
  homeModelsTitle: 'Models',
  homeModelsDescription:
    'Crie modelos de previsão de forma simples. Testes em escala e seleção dos melhores modelos. Resultados rápidos e precisos.',
  homeWorkspacesSubheading: 'Integrated Planning',
  homeWorkspacesTitle: 'Workspaces',
  homeWorkspacesDescription:
    'Crie um ambiente de trabalho colaborativo. Resultados e insights de desempenho. Governança eficaz.',
  homeAdminOptions: 'Opções de Administrador',
  homeAdminUserControl: 'Controle de usuário',
  homeAdminFeatureStoreIndicators: 'Indicadores da Feature Store',
  homeAdminAccessGroups: 'Grupos de acesso',
  homeDiscoverOurPlans: 'Conheça nossos planos',
};

export default homePTBRTranslations;
