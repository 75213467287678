import styled, { css, keyframes } from 'styled-components';

export const ContainerProject = styled.div`
  width: 100%;
  margin-bottom: 2.125rem;

  .css-165luzk-option {
    cursor: not-allowed;
  }
`;

type ProjectDetailsProps = {
  expanded: boolean;
};

const fadeInOut = keyframes`
  0% {
    display: flex;
  }
  100% {
    display: none;
  }
`;

const fadeIn = keyframes`
  0% {
    display: none;
  }
  100% {
    display: flex;
  }
`;

export const ProjectDetails = styled.div<ProjectDetailsProps>`
  display: flex;
  flex-direction: row;

  margin-left: ${({ expanded }) => (expanded ? '0' : '0.8rem')};

  margin-bottom: ${({ expanded }) => (expanded ? '1rem' : '1.5rem')};

  cursor: pointer;

  img {
    width: 3rem;
    height: 3rem;

    border-radius: 8px;
  }

  ${({ expanded }) =>
    expanded &&
    css`
      width: 16.938rem !important;
    `}

  div:last-of-type {
    display: flex;
    flex-direction: column;

    max-height: ${({ expanded }) => (expanded ? '10rem' : '3rem')} !important;

    opacity: ${({ expanded }) => (expanded ? 1 : 0)} !important;

    animation: ${({ expanded }) => (expanded ? fadeIn : fadeInOut)} 0.5s
      ease-in-out forwards;

    overflow: hidden !important;

    transition: max-height 0.2s, opacity 0.5s;

    margin-left: 1rem;

    h4 {
      color: ${({ theme }) => theme.colors.gray6};
      font-size: 1rem;
      line-height: 1.688rem;
      font-weight: 600;

      white-space: pre-line;
      word-break: break-word;
    }

    p {
      color: ${({ theme }) => theme.colors.gray4};
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.375rem;

      margin-top: 0.125rem;
    }
  }
`;
