export const MySeriesPTBRTranslate = {
  mySeriesTitle: 'Minhas Séries',
  mySeriesDescription: 'Lista de todas as séries externas que você fez upload',
  mySeriesYouHaveNotUploaded: 'Você ainda não subiu nenhuma série',
  mySeriesClickTheButtonAbove:
    'Clique no botão acima e faça o upload de suas séries modeladas externamente.',
  mySeriesInvalidFormatFile: 'Formato de arquivo inválido',
  mySeriesSearchPlaceholder: 'Procurar série',
  mySeriesSearchFail: 'Não foi possível encontrar nenhum série contendo',
  mySeriesFetchError: 'Não foi possível carregar as séries',
  mySeriesButtonUpload: 'Enviar novas séries',
  mySeriesUploadYourSeries: 'Faça o upload de suas séries',
  mySeriesUploading: 'fazendo upload',
  mySeriesCreateSerieErrorTitle: 'Falha ao criar',
  mySeriesCreateSerieErrorDescription:
    'Ocorreu um erro ao criar a série {{serie}}. Por favor, tente novamente mais tarde.',
  mySeriesDeleteSerieErrorTitle: 'Falha ao deletar',
  mySeriesDeleteSerieErrorDescription:
    'Ocorreu um erro ao deletar a série {{serie}}. Por favor, tente novamente mais tarde.',
  mySeriesUploadFileUploadYourFile: 'Faça o upload do seu arquivo',
  mySeriesUploadFileDragItHere:
    'Arraste-o aqui ou clique para selecioná-lo manualmente.',
  mySeriesUploadFileViewRequirements: 'Veja os requisito',
  mySeriesUploadFileEnterTheTag: 'Digite a tag para essa série',
  mySeriesOverwriteTitle_one: 'Série já disponível',
  mySeriesOverwriteTitle_other: 'Séries já disponíveis',
  mySeriesOverwriteDescription_one:
    'A série {{series}} já está disponível.<br/>Deseja sobrescrever a série existente?',
  mySeriesOverwriteDescription_other:
    'As séries {{series}} já estão disponíveis.<br/>Deseja sobrescrever as séries existentes?',
  mySeriesUploadErrorTitle: 'Não é possível fazer upload',
  mySeriesUploadErrorLeast3Characters:
    'Os nomes das séries devem conter pelo menos 3 caracteres.',
  mySeriesUploadErrorMaximum50Characters:
    'Os nomes das séries devem conter no máximo 50 caracteres.',
  mySeriesUploadErrorDefault:
    'Ocorreu um erro ao fazer upload. Por favor, tente novamente mais tarde.',
  mySeriesNoEspecialCharacters: 'Caracteres especiais não são permitidos',
};
