import React from 'react';

import { Head } from 'src/components/Head';
import { useTranslation } from 'react-i18next';
import { MyProjectsAndSeriesHeader } from 'src/models/components/MyProjectsAndSeriesHeader';

import { ListProjects } from './components/ListProjects';
import { Container } from './styles';

export const MyProjects: React.FC = () => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <Head title={translate('projectsHeadTitle')} />
      <div className="containerLinear">
        <MyProjectsAndSeriesHeader />

        <ListProjects />
      </div>
    </Container>
  );
};
