const viewFeaturesPTBR = {
  viewFeaturesTitle: 'Ver Séries',
  viewFeaturesSeriesDescription:
    'Visualize o gráfico desta série, navegue pelas aberturas disponíveis e encontre mais informações sobre este indicador.',
  viewFeaturesDescription: 'Descrição',
  viewFeaturesRegion: 'Região',
  viewFeaturesVariable: 'Variável',
  viewFeaturesFrequency: 'Frequência',
  viewFeaturesPrimaryTr: 'Transformação Primária',
  viewFeaturesSecondaryTr: 'Transformação Secundária',
  viewFeaturesLine: 'Linha',
  viewFeaturesBar: 'Barra',
  viewFeaturesArea: 'Área',
  viewFeaturesProjection: 'Projeção',
  viewFeaturesSendToGroup: 'Enviar para um grupo',
  viewFeaturesSendToGroupButton: 'Enviar para um grupo ',
  viewFeaturesSendToGroupDescription:
    'Envie uma ou mais séries deste indicador para seus grupos.',
  viewFeaturesExportSeries: 'Exportar série ',
  viewFeaturesExportSeriesDescription:
    'Exporte as informações dessa série para uma planilha.',
  viewFeaturesUnableSerie: 'Não foi possível carregar esta série.',
  viewFeaturesUpdateInfo:
    'Oops! Essa série está em manutenção devido a alguma atualização. Em breve ela estará disponível novamente.',

  viewFeatureStartHistoric: 'Início do Histórico',
  viewFeatureEndHistoric: 'Fim do Histórico',
  viewFeatureStartProjection: 'Início da Projeção',
  viewFeatureEndProjection: 'Fim da Projeção',
  viewFeatureLastUpdated: 'Última Atualização',
  viewFeatureLastUpdateObservation: 'Última Atualização Histórica',
  viewFeatureLastUpdateProjection: 'Última Atualização de Projeção',
  viewFeatureStoreBlueMessage:
    'Você não tem acesso a esta série da Feature Store',
  viewFeatureHistorical: 'Histórico',
  viewFeatureProjection: 'Projeção',
  viewFeatureCopySerieToClipboard: 'Copiar código da série',
  viewFeatureCopySeriesToClipboard: 'Copiar código das séries',
  viewFeaturesSaveToGroup: 'Salve em um grupo',
  viewFeaturesLoadingSeries:
    'Aguarde o carregamento das informações para alterar essa opção',
};

export default viewFeaturesPTBR;
