import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Eye, X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { useInfiniteQuery, useQuery } from 'react-query';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';
import {
  WorkspaceReleaseSelected,
  updateReleaseSelected,
} from 'src/workspaces/redux/reducers/Workspace';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import {
  changeEditionModeEnabled,
  changeReviewModeEnabled,
  changeWorkspaceOverviewInflation,
  changeWorkspaceOverviewStep,
  resetWorkspaceOverviewPeriod,
} from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import { changeWorkspaceProjectionsInflation } from 'src/workspaces/redux/reducers/WorkspaceProjectionsOptions';
import { ButtonRounded } from 'src/components/ButtonRounded';

import { WorkspaceSideBarContext } from '..';
import {
  CloseSidebarButton,
  Container,
  ContentTitle,
  Overlay,
  StatusContainer,
  UpdateHistoryList,
  Version,
} from './styles';

type ReleaseMenuProps = {
  workspaceId: string;
};

interface ReleaseRecord {
  id: string;
  name: string;
  label: string;
  description: string;
  status: string;
  preview: boolean;
  performances: {
    id: string;
    project_id: string;
    y_label: string;
    type: string;
  }[];
  published_at: Date | null;
  index?: number | null;
  approval_flow?: {
    enable?: boolean;
    horizon_dates?: {
      start: string;
      end: string;
    };
  } | null;
}

interface ReleasesResponse {
  skip: number;
  limit: number;
  total: number;
  records: ReleaseRecord[];
}

export const ReleaseMenu: React.FC<ReleaseMenuProps> = ({ workspaceId }) => {
  const { releaseSelected } = useSelector(
    (state: RootState) => state.workspace,
  );

  const [releaseCurrentId, setReleaseCurrentId] = useState(
    releaseSelected?.id ?? '',
  );
  const [releaseIndex, setReleaseCurrentIndex] = useState(
    releaseSelected?.index ?? 0,
  );

  const { menuReleaseVisible, closeMenuSelectRelease } = useContext(
    WorkspaceSideBarContext,
  );
  const { t: translate } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const QUANTITY_ITEMS_PAGE = 25;

  useEffect(() => {
    function isElementVisible(element: HTMLElement) {
      const rect = element.getBoundingClientRect();
      return rect.bottom >= 0 && rect.top <= window.innerHeight;
    }

    function handleScroll() {
      const footer = document.getElementById('footer');

      if (footer) {
        if (isElementVisible(footer)) {
          if (containerRef.current) {
            containerRef.current.style.height = `calc(100vh - (100vh - ${
              footer.getBoundingClientRect().top
            }px) - 74px)`;
          }
        } else if (containerRef.current) {
          containerRef.current.style.height = `calc(100vh - 74px)`;
        }
      }
    }

    handleScroll();

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [menuReleaseVisible]);

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isBottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight;

    if (
      isBottom &&
      !isLoadingReleases &&
      !isFetchingReleases &&
      !isErrorReleases &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const {
    data: releasesData,
    isLoading: isLoadingReleases,
    isFetching: isFetchingReleases,
    isError: isErrorReleases,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['workspace', 'releases on sidebar', workspaceId],
    async ({ pageParam = 0 }) => {
      const { data } = await apiWorkspace.get<ReleasesResponse>(
        `/workspaces/${workspaceId}/releases?skip=${
          pageParam * QUANTITY_ITEMS_PAGE
        }&limit=${QUANTITY_ITEMS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: ms('5 min'),
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_ITEMS_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const {
    data: releaseSelectedData,
    isLoading: isLoadingSelectedRelease,
    isFetching: isFetchingSelectedRelease,
  } = useQuery(
    ['workspace', workspaceId, 'releases', releaseCurrentId],
    async () => {
      const response = await apiWorkspace.get<WorkspaceReleaseSelected>(
        `/workspaces/${workspaceId}/releases/${releaseCurrentId}`,
      );
      return response.data;
    },
    {
      staleTime: ms('5 min'),
      enabled: !!workspaceId && !!releasesData && !!releaseCurrentId,
    },
  );

  const getReleaseNameFormatted = (name: string, label: string | null) => {
    const nameFormatted = name?.replace('Version', translate('version'));

    return !label || label === name ? nameFormatted : label;
  };

  useEffect(() => {
    if (releaseSelectedData) {
      const ySelected = releaseSelectedData.data.ys[0];

      dispatch(
        updateReleaseSelected({
          releaseSelected: { ...releaseSelectedData, index: releaseIndex },
          ySelected,
        }),
      );

      if (ySelected?.is_inflated) {
        dispatch(changeWorkspaceProjectionsInflation('nominal'));
        dispatch(changeWorkspaceOverviewInflation('nominal'));
      }

      closeMenuSelectRelease();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, releaseSelectedData]);

  useEffect(() => {
    dispatch(resetWorkspaceOverviewPeriod());
  }, [dispatch, releaseSelectedData?.id]);

  useEffect(() => {
    dispatch(changeEditionModeEnabled(false));
    dispatch(changeReviewModeEnabled(false));
    dispatch(changeWorkspaceOverviewStep(null));
  }, [releaseCurrentId, dispatch]);

  return (
    <>
      {menuReleaseVisible && (
        <Overlay
          visible={menuReleaseVisible}
          style={{
            cursor:
              isLoadingSelectedRelease || isFetchingSelectedRelease
                ? 'progress'
                : 'auto',
          }}
        />
      )}
      <Container
        visible={menuReleaseVisible}
        ref={containerRef}
        loading={isLoadingSelectedRelease || isFetchingSelectedRelease}
      >
        {menuReleaseVisible && (
          <CloseSidebarButton
            onClick={closeMenuSelectRelease}
            disabled={isLoadingSelectedRelease || isFetchingSelectedRelease}
            data-testid="close-history-button"
          >
            <X size="1.125rem" />
          </CloseSidebarButton>
        )}
        <div>
          <ContentTitle>
            <h3>{translate('workspaceSidebarUpdateTitle')}</h3>
            <p>{translate('workspaceSidebarUpdateDescription')}</p>
          </ContentTitle>
          <UpdateHistoryList onScroll={handleOnScroll}>
            {isLoadingReleases || isFetchingReleases || isFetchingNextPage
              ? skeleton
              : releasesData?.pages.map((releases, index) => (
                  // eslint-disable-next-line react/jsx-indent
                  <Fragment key={`${index + 1}`}>
                    {releases.records.map((release, indexReleases) =>
                      !release.preview ? (
                        <Version
                          selected={releaseSelected?.id === release.id}
                          key={`release-${release?.id.toString()}`}
                          data-testid={`release-${release?.id.toString()}`}
                          disabled={
                            release.status === 'error' ||
                            release.status === 'processing' ||
                            release.status === 'publishing'
                          }
                        >
                          <div>
                            {release.approval_flow?.enable && (
                              <StatusContainer
                                status={
                                  release.status === 'finished'
                                    ? 'done'
                                    : 'in progress'
                                }
                                data-testid="text-release-status"
                              >
                                {release.status === 'finished'
                                  ? translate('workspaceSideBarFinalized')
                                  : translate('workspaceSideBarInProgress')}
                              </StatusContainer>
                            )}

                            <div>
                              <h4 data-testid="text-release-name">
                                {getReleaseNameFormatted(
                                  release.name,
                                  release.label,
                                )}
                              </h4>
                              <p data-testid="text-release-description">
                                {release.description}
                              </p>
                            </div>

                            <p>
                              <span data-testid="text-creation-date">
                                {translate('workspaceSideBarCreationDate')}:{' '}
                              </span>
                              {format(
                                new Date(release.published_at!),
                                `${translateFormat}`,
                              )}
                            </p>

                            {release.approval_flow?.enable &&
                              release.approval_flow?.horizon_dates?.start && (
                                <p data-testid="text-horizon-dates">
                                  <span>
                                    {translate('workspaceSideBarHorizon')}:{' '}
                                  </span>
                                  {format(
                                    new Date(
                                      release.approval_flow?.horizon_dates?.start,
                                    ),
                                    'MM/yy',
                                  )}{' '}
                                  -{' '}
                                  {format(
                                    new Date(
                                      release.approval_flow?.horizon_dates?.end,
                                    ),
                                    'MM/yy',
                                  )}
                                </p>
                              )}
                          </div>

                          <ButtonRounded
                            label={translate('workspaceSideBarView')}
                            icon={<Eye />}
                            type="button"
                            onClick={() => {
                              setReleaseCurrentIndex(indexReleases);
                              setReleaseCurrentId(release.id);
                            }}
                            disabled={
                              release.status === 'error' ||
                              release.status === 'processing' ||
                              release.status === 'publishing'
                            }
                            data-testid={`navigate-release-${release?.id.toString()}`}
                            aria-label="access release"
                          />
                        </Version>
                      ) : null,
                    )}
                  </Fragment>
                ))}
          </UpdateHistoryList>
        </div>
      </Container>
    </>
  );
};

const skeleton = Array.from({ length: 4 }, (_, index) => (
  <Version selected={false} key={`release-${index.toString()}`}>
    <div>
      <ContainerSkeleton
        withLoading={false}
        style={{
          height: '20px',
          width: `${Math.random() * 100 + 150}px`,
          marginBottom: '0.5rem',
        }}
      />

      <ContainerSkeleton
        withLoading={false}
        style={{
          height: '20px',
          width: `${Math.random() * 100 + 150}px`,
        }}
      />
    </div>
    <div>
      <ContainerSkeleton
        withLoading={false}
        style={{
          height: '1.25rem',
          width: `1.25rem`,
        }}
      />
    </div>
  </Version>
));
