export const WorkspaceNewReleaseConfigurationPTBRTranslations = {
  workspaceNewReleaseConfigurationTitle: 'Configure a nova versão',
  workspaceNewReleaseConfigurationDescription:
    'Escolha um nome e descrição para sua nova versão e configure as próximas etapas.',
  workspaceNewReleaseConfigurationNamePlaceholder:
    'Digite o nome da sua nova versão',
  workspaceNewReleaseConfigurationDescriptionPlaceholder:
    'Escreva uma descrição para a sua nova versão',
  workspaceNewReleaseConfigurationSaveConfigurationButton:
    'Salvar configuração',

  workspaceNewReleaseConfigurationChooseTemplate: 'Escolha um template',
  workspaceNewReleaseConfigurationStandardTitle: 'Padrão',
  workspaceNewReleaseConfigurationStandardDescription:
    'Visualize suas variáveis em um só lugar e faça todos os ajustes necessários.',
  workspaceNewReleaseConfigurationPlanningTitle: 'Planejamento',
  workspaceNewReleaseConfigurationPlanningDescription:
    'Crie diferentes ciclos de planejamento e obtenha mais ferramentas de governança.',
  workspaceNewReleaseConfigurationSaveErrorTitle: 'Algo deu errado',
  workspaceNewReleaseConfigurationSaveErrorDescription:
    'Ocorreu um erro ao salvar a configuração. Por favor, tente novamente mais tarde.',
  workspaceNewReleaseConfigurationStagingAreaErrorDescription:
    'Ocorreu um erro ao carregar os dados. Por favor, tente novamente mais tarde.',
  workspaceNewReleaseConfigurationStandardListCollaborativeEnv:
    'Ambiente colaborativo',
  workspaceNewReleaseConfigurationStandardListTable:
    'Dados categorizados organizados em uma tabela inteligente',
  workspaceNewReleaseConfigurationStandardListChart:
    'Visualize seus dados graficamente e compare diferentes variáveis',
  workspaceNewReleaseConfigurationPlanningListStep:
    'Acompanhe o desenvolvimento em diferentes etapas e passos',
  workspaceNewReleaseConfigurationPlanningListUserControl:
    'Estabeleça uma governança com controle de usuários e aprovação',
  workspaceNewReleaseConfigurationStandardTemplate: 'Template Padrão',
  workspaceNewReleaseConfigurationPlanningTemplate: 'Template de Planejamento',
  workspaceNewReleaseConfigurationPlanningDisabledTooltip:
    'Este template está disponível somente para frequência de dados mensal.',

  workspaceNewReleaseConfigurationPlanningSettings:
    'Configurações do planejamento',
  workspaceNewReleaseConfigurationPlanningHorizon: 'Horizonte de planejamento',
  workspaceNewReleaseConfigurationConfigureStage: 'Configure as etapas',
  workspaceNewReleaseConfigurationPlanningHorizonPlaceholder:
    'Escolha uma data',
};
