import React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Link, MyProjectsAndSeriesHeaderContainer } from './style';

export const MyProjectsAndSeriesHeader: React.FC = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const isMyProjectSelected = pathname.includes('/projects');
  const isMySeriesSelected = pathname.includes('/series');

  return (
    <MyProjectsAndSeriesHeaderContainer>
      <Link
        isSelected={isMyProjectSelected}
        to="/models/projects"
        data-testid="button-select-projects"
        data-cy="button-select-projects"
      >
        {t('myProjects')}
      </Link>
      <Link
        isSelected={isMySeriesSelected}
        to="/models/series"
        data-testid="button-select-series"
        data-cy="button-select-series"
      >
        {t('mySeriesTitle')}
      </Link>
    </MyProjectsAndSeriesHeaderContainer>
  );
};
