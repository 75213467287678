import styled from 'styled-components';

export const OverwriteModalContainer = styled.div`
  max-width: 25rem;

  p {
    max-height: 20rem;
    overflow-y: auto;
  }
`;
