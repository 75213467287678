import React, { MouseEvent, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Label } from 'src/components/Label';
import { CalendarCheck, Cube, MagnifyingGlass } from 'phosphor-react';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { NewReleaseConfigurationContext } from 'src/workspaces/contexts/NewReleaseConfigurationContext';

import { Container, TemplateContainer, TemplateContent } from './styles';
import { StandardModal } from '../TemplateModal/Standard';
import { PlanningModal } from '../TemplateModal/Planning';

export const TemplateOptions: React.FC = () => {
  const [showPlanningPreview, setShowPlanningPreview] = useState(false);
  const [showStandardPreview, setShowStandardPreview] = useState(false);

  const { t: translate } = useTranslation();

  const { workspace } = useSelector((state: RootState) => state);

  const { selectedTemplate, isSaving, handleChangeTemplate } = useContext(
    NewReleaseConfigurationContext,
  );

  const handleShowStandardPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setShowStandardPreview(true);
  };

  const handleShowPlanningPreview = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    setShowPlanningPreview(true);
  };

  const workspaceIsMonthly = workspace.frequency === 'monthly';

  const planningTemplateIsDisabled = !workspaceIsMonthly || !!isSaving;

  return (
    <Container>
      <Label style={{ display: 'inline-block', marginBottom: '0.5rem' }}>
        {translate('workspaceNewReleaseConfigurationChooseTemplate')}
      </Label>

      <TemplateContainer
        type="button"
        selected={selectedTemplate === 'standard'}
        onClick={() => handleChangeTemplate('standard')}
        disabled={!!isSaving}
        isSaving={!!isSaving}
        data-testid="button-standard-template"
      >
        <Cube weight="duotone" />

        <TemplateContent>
          <h3>{translate('workspaceNewReleaseConfigurationStandardTitle')}</h3>
          <p>
            {translate('workspaceNewReleaseConfigurationStandardDescription')}
          </p>

          <ButtonRounded
            label="Preview"
            icon={<MagnifyingGlass />}
            type="button"
            disabled={!!isSaving}
            onClick={handleShowStandardPreview}
            data-testid="button-standard-preview"
          />
        </TemplateContent>
      </TemplateContainer>

      <TemplateContainer
        type="button"
        selected={selectedTemplate === 'planning'}
        onClick={() => handleChangeTemplate('planning')}
        disabled={planningTemplateIsDisabled}
        isSaving={!!isSaving}
        data-testid="button-planning-template"
        data-tooltip-id="tooltip-release-configuration"
        data-tooltip-content={
          !workspaceIsMonthly
            ? translate(
                'workspaceNewReleaseConfigurationPlanningDisabledTooltip',
              )
            : ''
        }
      >
        <CalendarCheck weight="duotone" />

        <TemplateContent>
          <h3>{translate('workspaceNewReleaseConfigurationPlanningTitle')}</h3>
          <p>
            {translate('workspaceNewReleaseConfigurationPlanningDescription')}
          </p>

          <ButtonRounded
            label="Preview"
            icon={<MagnifyingGlass />}
            type="button"
            disabled={planningTemplateIsDisabled}
            onClick={handleShowPlanningPreview}
            data-testid="button-planning-preview"
          />
        </TemplateContent>
      </TemplateContainer>

      {showStandardPreview && (
        <StandardModal setVisible={setShowStandardPreview} />
      )}

      {showPlanningPreview && (
        <PlanningModal setVisible={setShowPlanningPreview} />
      )}
    </Container>
  );
};
