import React, { useEffect, useState } from 'react';

import { Spinner } from 'src/components/Spinner';
import { useQuerySeriesById } from 'src/models/hooks/useQuerySeriesById';
import { queryClient } from 'src/service/queryClient';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { FailedModal } from 'src/components/Modal/Failed';

import { CellNewSerieContainer } from './styles';

type CellNewSerieProps = {
  serieId: string;
  serieName: string;
  removeNewSerie: (newSerieId: string) => void;
};

export const CellNewSerie: React.FC<CellNewSerieProps> = ({
  serieId,
  serieName,
  removeNewSerie,
}) => {
  const [creationRequested, setCreationRequested] = useState(true);
  const [failedModalVisible, setFailedModalVisible] = useState(false);

  const { t } = useTranslation();

  const { serieData, serieIsError } = useQuerySeriesById({
    id: serieId,
    enabled: creationRequested,
    retryThreeSeconds: true,
  });

  useEffect(() => {
    queryClient.removeQueries(['serie', serieId]);
  }, [serieId]);

  useEffect(() => {
    if (creationRequested) {
      if (serieData?.status === 'success') {
        setCreationRequested(false);
        removeNewSerie(serieId);
        queryClient.refetchQueries('series');
        queryClient.refetchQueries('serie-tags');
        queryClient.refetchQueries('series-grouped-by-tag');
      } else if (serieIsError) {
        setCreationRequested(false);
        setFailedModalVisible(true);
        removeNewSerie(serieId);
      }
    }
  }, [creationRequested, removeNewSerie, serieData, serieId, serieIsError]);

  async function closeModal() {
    setFailedModalVisible(false);
    queryClient.refetchQueries('series');
    queryClient.removeQueries(['serie', serieId]);
    queryClient.refetchQueries('serie-tags');
    queryClient.refetchQueries('series-grouped-by-tag');
  }

  return (
    <CellNewSerieContainer>
      <p>{serieName}</p>
      <div>
        <Spinner
          spinnerType="primary"
          data-testid={`cell-new-serie-${serieName}-loading`}
        />
        <span>{t('mySeriesUploading')}</span>
      </div>
      {failedModalVisible &&
        createPortal(
          <FailedModal
            visible
            setVisible={closeModal}
            errorInfo={{
              title: t('mySeriesCreateSerieErrorTitle'),
              description: t('mySeriesCreateSerieErrorDescription', {
                serie: serieName,
              }),
            }}
          />,
          document.body,
        )}
    </CellNewSerieContainer>
  );
};
