import React from 'react';

import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import api from 'src/feature-store/service/api';

import { PreDefinedGroupsProps } from '../../Groups/types';
import { IndicatorForAnalyze } from '../IndicatorForAnalyze';
import {
  Header,
  IndicatorsContainer,
  ListIndicatorsForAnalyzeContainer,
} from './styles';

export const ListIndicatorsForAnalyze: React.FC = () => {
  const QUANTITY_INDICATORS_PAGE = 15;

  const { t: translate } = useTranslation();

  const {
    data: indicatorsForAnalyze,
    isFetching: isIndicatorsForAnalyzeFetching,
    isLoading: isIndicatorsForAnalyzeLoading,
    isError: isIndicatorsForAnalyzeErrored,
    fetchNextPage: fetchNextPageIndicatorsForAnalyze,
    hasNextPage: isIndicatorsForAnalyzeHasNextPage,
    // isFetchingNextPage: isIndicatorsForAnalyzesFetchingNextPage,
  } = useInfiniteQuery(
    ['indicators for analyze'],
    async ({ pageParam = 0 }) => {
      const { data } = await api.get<PreDefinedGroupsProps>(
        `/groups/predefineds?category=data&skip=${
          pageParam * QUANTITY_INDICATORS_PAGE
        }&limit=${QUANTITY_INDICATORS_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * QUANTITY_INDICATORS_PAGE < lastPage.total) {
          return pages.length;
        }
        return undefined;
      },
    },
  );

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const isEnd =
      Math.ceil(
        event.currentTarget.scrollWidth - event.currentTarget.scrollLeft,
      ) === event.currentTarget.clientWidth;

    if (
      isEnd &&
      !isIndicatorsForAnalyzeLoading &&
      !isIndicatorsForAnalyzeFetching &&
      !isIndicatorsForAnalyzeErrored &&
      isIndicatorsForAnalyzeHasNextPage
    ) {
      fetchNextPageIndicatorsForAnalyze();
    }
  };

  const array = Array.from(Array(QUANTITY_INDICATORS_PAGE), (_, i) => i);
  const indicatorListIsLoading = (
    <IndicatorsContainer>
      {array.map((i) => (
        <ContainerSkeleton
          key={i + 1}
          withLoading={false}
          style={{
            width: '12.5rem',
            height: '7.1875rem',
            minWidth: '12.5rem',
          }}
        />
      ))}
    </IndicatorsContainer>
  );

  return (
    <ListIndicatorsForAnalyzeContainer>
      <Header>
        <span data-testid="text-indicator-for-analyze-title">
          {translate('indicatorsForAnalyzeHeaderTitle')}
        </span>
        <p data-testid="text-indicator-for-analyze-description">
          {translate('indicatorsForAnalyzeDescription')}
        </p>
      </Header>

      <IndicatorsContainer onScroll={handleOnScroll}>
        {isIndicatorsForAnalyzeLoading
          ? indicatorListIsLoading
          : indicatorsForAnalyze?.pages.map((indicators, index) => (
              // eslint-disable-next-line react/jsx-indent, react/no-array-index-key
              <React.Fragment key={index + 1}>
                {indicators.data.map((indicator) => (
                  <IndicatorForAnalyze
                    id={indicator.id}
                    title={indicator.name}
                    key={indicator.id}
                    accessType={indicator.access_type}
                  />
                ))}
              </React.Fragment>
            ))}
      </IndicatorsContainer>

      {isIndicatorsForAnalyzeErrored && (
        <ContainerMaintenance
          data-testid="container-maintenance"
          content="table"
          text={translate('indicatorsForAnalyzeErrorTitle')}
          description={translate('indicatorsForAnalyzeErrorDescription')}
        />
      )}
    </ListIndicatorsForAnalyzeContainer>
  );
};
