import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Calendar } from 'src/components/Calendar';
import { Label } from 'src/components/Label';
import { NewReleaseConfigurationContext } from 'src/workspaces/contexts/NewReleaseConfigurationContext';
import { PlanningFlow } from 'src/workspaces/components/PlanningFlow';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { Container } from './styles';
import { DateRange } from './types';

export const PlanningConfiguration: React.FC = () => {
  const { t: translate } = useTranslation();

  const { id } = useParams();

  const {
    planningHorizon,
    steps,
    showPlanningErrors,
    handleChangePlanningHorizon,
    handleAddNewStep,
    handleDeleteLastStep,
    handleRenameStep,
    handleAddUser,
    handleRemoveUser,
    handleChangeDeadline,
  } = useContext(NewReleaseConfigurationContext);

  const {
    workspace: { releasePreview },
  } = useSelector((state: RootState) => state);

  const {
    data: dateRangeData,
    isLoading: isLoadingDateRange,
    isFetching: isFetchingDateRange,
  } = useQuery(
    ['workspace release', 'date range', id, releasePreview],
    async () => {
      const { data } = await apiWorkspace.get<DateRange>(
        `/workspaces/${id}/releases/${releasePreview}/approval-flow/date-range`,
      );

      return {
        start: data.start.replace(':00Z', ''),
        end: data.end.replace(':00Z', ''),
      };
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id && !!releasePreview,
    },
  );

  const onCalendarClose = () => {
    if (planningHorizon[0] && !planningHorizon[1]) {
      handleChangePlanningHorizon([planningHorizon[0], planningHorizon[0]]);
    }
  };

  return (
    <Container>
      <h3>{translate('workspaceNewReleaseConfigurationPlanningSettings')}</h3>

      <Calendar
        label={translate('workspaceNewReleaseConfigurationPlanningHorizon')}
        frequency="monthly"
        inputTestId="datepicker-planning-horizon"
        selectsRange
        startDate={planningHorizon[0]}
        endDate={planningHorizon[1]}
        onChange={handleChangePlanningHorizon}
        placeholderText={translate(
          'workspaceNewReleaseConfigurationPlanningHorizonPlaceholder',
        )}
        minDate={dateRangeData ? new Date(dateRangeData.start) : undefined}
        maxDate={dateRangeData ? new Date(dateRangeData.end) : undefined}
        error={
          showPlanningErrors && !planningHorizon[0]
            ? translate('requiredField')
            : undefined
        }
        onCalendarClose={onCalendarClose}
        loading={isLoadingDateRange || isFetchingDateRange}
        isDisabled={isLoadingDateRange || isFetchingDateRange}
      />

      <Label>
        {translate('workspaceNewReleaseConfigurationConfigureStage')}
      </Label>

      <PlanningFlow
        steps={steps}
        showErrors={showPlanningErrors}
        handleAddNewStep={handleAddNewStep}
        handleDeleteLastStep={handleDeleteLastStep}
        handleRenameStep={handleRenameStep}
        handleAddUser={handleAddUser}
        handleRemoveUser={handleRemoveUser}
        handleChangeDeadline={handleChangeDeadline}
      />
    </Container>
  );
};
