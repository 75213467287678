import React, { useContext, useRef, useState } from 'react';

import { DotsThreeVertical, NotePencil, Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import { ErrorObject, FailedModal } from 'src/components/Modal/Failed';
import { CardMenuOverlay } from 'src/components/ProjectCard/styles';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import { setClearMyFavoriteDateRangeAndIdSelected } from 'src/feature-store/redux/reducers/Groups';
import { RootState } from 'src/redux/store';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';

import { CreateGroupModal } from '../CreateGroupModal';
import { GroupToEdit } from '../CreateGroupModal/types';
import { DeleteGroupModal } from '../DeleteGroupModal';
import {
  ActionDots,
  FavoriteContainer,
  FavoriteContent,
  FavoriteIcon,
  InfoContainer,
} from './styles';

interface GroupCardProps {
  id: string;
  iconUrl: string;
  name: string;
  predefined?: boolean;
  isLoading?: boolean;
  description: string;
}

export const GroupCard: React.FC<GroupCardProps> = ({
  id,
  iconUrl,
  name,
  predefined,
  isLoading = false,
  description,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [menuVisible, setMenuVisible] = useState(false);
  const [isDeleteFavoriteModalOpen, setIsDeleteFavoriteModalOpen] =
    useState(false);
  const [failedModalIsVisible, setFailedModalIsVisible] = useState(false);
  const [editModalIsVisible, setEditModalIsVisible] = useState(false);
  const [error, setError] = useState<ErrorObject>();

  const { user } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const {
    idFavoriteViewingPage: idFavoriteViewing,
    setterIdFavoriteViewingPage,
    favoriteType,
  } = useContext(FeatureStoreSidebarContext);

  function handleMenuVisible() {
    if (menuVisible === false) {
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  }

  const left = Math.ceil(ref.current?.getBoundingClientRect().left ?? 0) + 30;

  const favoriteToEdit: GroupToEdit = {
    id,
    name,
    description,
    icon_url: iconUrl,
    is_predefined: predefined!,
  };

  if (isLoading) {
    return (
      <FavoriteContainer isActive={false} data-testid="loading-group-card">
        <FavoriteIcon>
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: '40px',
              height: '40px',
            }}
          />
        </FavoriteIcon>

        <InfoContainer style={{ marginLeft: '0.5rem' }}>
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: `${Math.random() * (180 - 100) + 60}px`,
              height: '1.5rem',
            }}
          />
          <ContainerSkeleton
            withLoading={false}
            style={{
              width: '8.125rem',
              height: '1.5rem',
            }}
          />
        </InfoContainer>
      </FavoriteContainer>
    );
  }

  return (
    <FavoriteContainer
      isActive={id === idFavoriteViewing}
      key={id}
      data-testid={`favorite-card-${name.replaceAll(' ', '-').toLowerCase()}`}
    >
      <FavoriteContent
        onClick={() => {
          navigate(`/feature-store/favorites/${id}`);
          setterIdFavoriteViewingPage(id);
          dispatch(setClearMyFavoriteDateRangeAndIdSelected());
        }}
      >
        <FavoriteIcon>
          <img
            data-testid={`favorite-img-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            src={
              predefined
                ? 'https://storage.googleapis.com/bkt-prod-4casthub/icons/2f9f5ebb-0ac6-461d-b816-49e33149b5b8.png'
                : checkIconURL(iconUrl)
                ? iconUrl
                : arrayIcon[0]
            }
            alt="icon favorite"
          />
        </FavoriteIcon>

        <InfoContainer
          data-testid={`favorite-info-card-${name
            .replaceAll(' ', '-')
            .toLowerCase()}`}
        >
          <h5
            data-testid={`favorite-name-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          >
            {name}
          </h5>
        </InfoContainer>
      </FavoriteContent>

      {((predefined && user.isFsAdmin) || !predefined) && (
        <>
          <ActionDots
            onClick={handleMenuVisible}
            data-testid={`button-menu-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            ref={ref}
          >
            <DotsThreeVertical size="32px" />
          </ActionDots>

          <MenuContainer
            visible={menuVisible}
            style={{
              left,
              top: '358px',
            }}
          >
            {menuVisible && (
              <CardMenuOverlay
                data-testid="menu-overlay"
                visible={menuVisible}
                onClick={() => setMenuVisible(false)}
              />
            )}
            <MenuOption
              position="start"
              type="button"
              data-testid={`menu-button-edit-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`menu-button-edit-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              onClick={() => {
                setEditModalIsVisible(true);
                setMenuVisible(false);
              }}
            >
              <NotePencil size="1.125rem" />
              <p>{translate('edit')}</p>
            </MenuOption>
            <MenuOption
              position="end"
              type="button"
              data-testid={`menu-button-delete-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`menu-button-delete-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              onClick={() => {
                setIsDeleteFavoriteModalOpen(true);
                setMenuVisible(false);
              }}
            >
              <Trash size="1.125rem" />
              <p>{translate('delete')}</p>
            </MenuOption>
          </MenuContainer>
        </>
      )}

      <FailedModal
        visible={failedModalIsVisible}
        setVisible={setFailedModalIsVisible}
        errorInfo={error}
      />

      <DeleteGroupModal
        visible={isDeleteFavoriteModalOpen}
        setVisible={setIsDeleteFavoriteModalOpen}
        setErrorVisible={setFailedModalIsVisible}
        setError={setError}
        categoryType={favoriteType}
        group={{
          id,
          name,
        }}
      />

      <CreateGroupModal
        visible={editModalIsVisible}
        setVisible={setEditModalIsVisible}
        groupToEdit={favoriteToEdit}
        categoryType={favoriteType}
        predefined={favoriteType !== 'user'}
      />
    </FavoriteContainer>
  );
};
