import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:nth-child(2) {
    > div {
      text-align: right;

      p {
        margin-right: 0px;
      }
    }
  }

  input {
    display: block !important;
    margin-left: auto !important;
  }
`;

export const Content = styled.div`
  height: 100%;
`;

export const ProjectContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  margin-bottom: 1.5rem;

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    > div {
      width: 15rem;
    }

    > button {
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: end;

    p {
      margin-right: 0 !important;
    }
  }
`;

export const VariablesEmptyContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  flex: 1;

  padding: 0 10rem;

  .plus-button {
    height: fit-content;

    margin-top: -1.75rem;
    padding: 1rem;

    border-radius: 100%;

    z-index: 1001;

    cursor: default;

    svg {
      width: 2rem;
      height: 2rem;

      margin: 0px;
    }
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray6};

    margin-top: 2rem;
  }

  p {
    max-width: 100%;
    width: 30rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.gray5};

    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
`;

export const ImageContainer = styled.div`
  width: 13.625rem;
  height: 14.0625rem;

  position: relative;

  > div,
  > img {
    width: 100%;
    height: 100%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;

    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 53.85%);
  }
`;

type TableContainerProps = {
  hasError: boolean;
};

export const TableContainer = styled.div<TableContainerProps>`
  overflow-y: auto;
  overflow-x: auto;

  width: 100%;
  height: 100%;

  max-height: 40rem;
  min-height: 30rem;

  border-radius: 8px;

  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.red2 : theme.colors.gray2};

  transition: border 0.2s ease-in-out;

  table {
    margin: 0;
    border-spacing: 0rem;

    th {
      position: sticky;
      top: 0;

      background: ${({ theme }) => theme.colors.gray0};
      z-index: 1;

      opacity: 1;

      color: ${({ theme }) => `${theme.colors.gray7}CC`} !important;

      padding: 1rem;

      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.08rem;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.gray6};

      &:first-child {
        border-right: 1px solid ${({ theme }) => theme.colors.gray2};
        padding-left: 1rem;
      }
    }

    td {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }

  ::-webkit-scrollbar-track {
    margin-top: 0px;
  }

  ::-webkit-scrollbar {
    width: 0.4rem;
  }

  .rename-variable-column {
    min-width: 12rem;

    width: 23rem;

    @media (max-width: 1366px) {
      width: 15rem;
    }
  }

  .checkbox-select-all {
    padding-left: 1.125rem;

    > span {
      bottom: 0.125rem;
    }
  }
`;

export const ContentInflateSeries = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }
`;

type ErrorMessageProps = {
  visible: boolean;
};

export const ErrorMessage = styled.p<ErrorMessageProps>`
  font-weight: 400;
  font-size: ${({ visible }) => (visible ? '0.875rem' : '0')};
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.red2};
  opacity: 0;

  transition: opacity 0.2s ease-in-out, margin-top 0.2s ease-in-out;

  ${({ visible }) =>
    visible &&
    css`
      margin-top: 0.5rem;
      font-size: 0.875rem;
      opacity: 1;
    `}
`;
