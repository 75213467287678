export const categoriesSidebarENTranslate = {
  categoriesSidebarFilters: 'Filter search',
  categoriesSidebarDescription:
    'Search for what you want or browse our filters and categories.',
  categoriesSidebarCountries: 'COUNTRY',
  categoriesSidebarIndicatorType: 'INDICATORS TYPE',
  categoriesSidebarSelectCountries: 'Select the country',
  categoriesBoxSidebarTitle: 'CATEGORIES',
  categoriesAllSelectedOnCountryFilter:
    'Select a country to explore categories',
};
