import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  .customTooltipTheme {
    font-size: 0.75rem !important;
  }

  .baseline-adjusted-value-tooltip {
    font-size: 0.75rem !important;

    p + p {
      margin-top: 0.25rem;
    }

    b {
      font-weight: 600;
    }
  }

  .filter-y-type {
    height: unset !important;
    transition: height 0;
  }
`;

export type TableStatus =
  | 'editing'
  | 'awaiting_approval'
  | 'reviewing'
  | 'approved'
  | 'disapproved'
  | null;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
`;

type TableContainerProps = {
  status: TableStatus;
};

export const TableContainer = styled.div<TableContainerProps>`
  position: relative;
  width: 100%;

  ${({ status }) =>
    status === 'editing'
      ? css`
          .ag-root-wrapper {
            border: 1px solid ${({ theme }) => theme.colors.purple4};
            box-shadow: 0px 0px 16px 0px rgba(151, 71, 255, 0.24);
          }
        `
      : status === 'awaiting_approval'
      ? css`
          .ag-root-wrapper {
            border: 1px solid ${({ theme }) => theme.colors.yellow4};
            box-shadow: 0px 0px 16px 0px rgba(236, 201, 75, 0.24);
          }
        `
      : status === 'reviewing' || status === 'approved'
      ? css`
          .ag-root-wrapper {
            border: 1px solid ${({ theme }) => theme.colors.teal4};
            box-shadow: 0px 0px 16px 0px rgba(56, 178, 172, 0.24);
          }
        `
      : status === 'disapproved' &&
        css`
          .ag-root-wrapper {
            border: 1px solid ${({ theme }) => theme.colors.red5};
            box-shadow: 0px 0px 16px 0px rgba(229, 62, 62, 0.24);
          }
        `}

  .grouped-header {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    color: ${({ theme }) => theme.colors.gray6} !important;

    p {
      font-size: 0.75rem !important;
      font-weight: 400 !important;
      line-height: 150% !important;
      letter-spacing: 0.06rem !important;
      text-transform: uppercase !important;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      flex-shrink: 1;
    }

    svg {
      width: 1rem;
      height: 1rem;

      flex-shrink: 0;

      color: ${({ theme }) => theme.colors.gray4} !important;

      display: ${({ status }) => (status === 'editing' ? 'flex' : 'none')};
    }
  }

  .forecast-values {
    background-color: ${({ theme }) =>
      `${theme.colors.secondary}14`} !important;

    text-align: center;
    color: ${({ theme }) => theme.colors.secondaryDark} !important;
  }

  .planning-horizon-values {
    background-color: ${({ theme }) => `${theme.colors.primary}14`} !important;
    text-align: center;
    color: ${({ theme }) => theme.colors.primaryDark} !important;
  }

  .forecast-values.ag-cell-highlight {
    background-color: ${({ theme }) => `${theme.colors.primary}0A`} !important;
  }

  .group-row-icon {
    display: ${({ status }) => (status === 'editing' ? 'flex' : 'none')};
  }

  .ag-column-first {
    border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  .marketshare-cell {
    font-weight: 500 !important;

    div,
    span,
    p {
      font-weight: 500 !important;
    }
  }
`;

export const TableButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  width: 100%;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 1;

  > button,
  > div > button {
    height: fit-content;

    padding: 0.25rem 1rem !important;

    border-radius: 100px;

    font-size: 0.75rem;

    transition: all 0.2s;

    svg {
      width: 0.875rem;
      height: 0.875rem;

      transition: all 0.2s;
    }
  }

  .enable-edition-button {
    color: ${({ theme }) => theme.colors.gray5};
    border: 1px solid ${({ theme }) => theme.colors.gray2};

    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }

    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.gray6};
      border: 1px solid ${({ theme }) => theme.colors.gray5};

      svg {
        color: ${({ theme }) => theme.colors.gray5};
      }
    }

    :disabled {
      color: ${({ theme }) => theme.colors.gray4};
      border: 1px solid ${({ theme }) => theme.colors.gray1};

      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }

  .discard-edition-button {
    color: ${({ theme }) => theme.colors.purple4};
    border: 1px solid ${({ theme }) => theme.colors.purple4};

    svg {
      color: ${({ theme }) => theme.colors.purple4};
    }

    :hover {
      color: ${({ theme }) => theme.colors.purple5};
      border: 1px solid ${({ theme }) => theme.colors.purple5};

      svg {
        color: ${({ theme }) => theme.colors.purple5};
      }
    }
  }

  .save-edition-button {
    background: ${({ theme }) => theme.colors.purple4};
    border: 1px solid ${({ theme }) => theme.colors.purple4};

    :hover {
      background: ${({ theme }) => theme.colors.purple5};
      border: 1px solid ${({ theme }) => theme.colors.purple5};
    }

    :disabled {
      background-color: #c3b3e3;
      border: 1px solid #c3b3e3;
    }
  }

  .button-submit-for-approval {
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.gray2};

    svg {
      color: ${({ theme }) => theme.colors.primary};
    }

    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.primaryDark};
      border: 1px solid ${({ theme }) => theme.colors.primaryDark};

      svg {
        color: ${({ theme }) => theme.colors.primaryDark};
      }
    }

    :disabled {
      color: ${({ theme }) => theme.colors.gray4};
      border: 1px solid ${({ theme }) => theme.colors.gray1};

      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }

  .button-review-scenario {
    background: ${({ theme }) => theme.colors.yellow4};

    :hover:not(:disabled) {
      background: ${({ theme }) => theme.colors.yellow};
    }

    :disabled {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.gray4};
      border: 1px solid ${({ theme }) => theme.colors.gray1};

      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }

  .waiting-approval-tag {
    color: ${({ theme }) => theme.colors.yellow4};
    border: 1px solid ${({ theme }) => theme.colors.yellow4};
    background: linear-gradient(
        0deg,
        rgba(236, 201, 75, 0.12) 0%,
        rgba(236, 201, 75, 0.12) 100%
      ),
      #fff;
  }

  .approved-tag {
    color: ${({ theme }) => theme.colors.teal4};
    border: 1px solid ${({ theme }) => theme.colors.teal4};
    background: linear-gradient(
        0deg,
        rgba(56, 178, 172, 0.12) 0%,
        rgba(56, 178, 172, 0.12) 100%
      ),
      #fff;
  }

  .button-approve {
    background: ${({ theme }) => theme.colors.teal4};

    :hover:not(:disabled) {
      background: ${({ theme }) => theme.colors.teal5};
    }
  }

  .button-disapprove-or-exit {
    color: ${({ theme }) => theme.colors.teal4};
    border: 1px solid ${({ theme }) => theme.colors.teal4};

    svg {
      color: ${({ theme }) => theme.colors.teal4};
    }

    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.teal5};
      border: 1px solid ${({ theme }) => theme.colors.teal5};

      svg {
        color: ${({ theme }) => theme.colors.teal5};
      }
    }
  }

  .button-discard-adjustments {
    color: ${({ theme }) => theme.colors.gray5};
    border: 1px solid ${({ theme }) => theme.colors.gray2};

    svg {
      color: ${({ theme }) => theme.colors.gray4};
    }

    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.gray6};
      border: 1px solid ${({ theme }) => theme.colors.gray5};

      svg {
        color: ${({ theme }) => theme.colors.gray5};
      }
    }

    :disabled {
      color: ${({ theme }) => theme.colors.gray4};
      border: 1px solid ${({ theme }) => theme.colors.gray1};

      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }

  .button-new-adjustments {
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.red5};
    background: ${({ theme }) => theme.colors.red5};

    svg {
      color: ${({ theme }) => theme.colors.white};
    }

    :hover:not(:disabled) {
      color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.red6};
      background: ${({ theme }) => theme.colors.red6};

      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

const ErrorAppearanceAnimation = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

type TableErrorProps = {
  visible: boolean;
};

export const TableError = styled.div<TableErrorProps>`
  height: 2.125rem;
  max-width: 2.125rem;

  position: absolute;
  right: 1.25rem;
  bottom: 1.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;

  padding: 0.25rem 0.375rem;

  border-radius: 9999px;

  background: white;
  border: 1px solid ${({ theme }) => theme.colors.red2};

  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.red2};

  z-index: 100;

  cursor: pointer;

  overflow: hidden;

  transition: all 1s linear;

  svg {
    width: 1.25rem;
    height: 1.25rem;

    flex-shrink: 0;
  }

  p {
    white-space: nowrap;
  }

  &:hover {
    max-width: 31.25rem;
  }

  ${({ visible }) =>
    visible &&
    css`
      animation: ${ErrorAppearanceAnimation} 0.7s;
    `}
`;

export const SubmitApprovalContainer = styled.div`
  position: relative;
`;

export const Tag = styled.div`
  height: fit-content;

  padding: 0.25rem 1rem;

  border-radius: 100px;

  font-size: 0.75rem;
  font-weight: 600;
`;

export const HeaderRightContent = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  flex-direction: row;
  gap: 1rem;

  @media (min-width: 1600px) {
    top: 1rem;
  }
`;

export const SelectedFiltersExportContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.5rem;

  > div {
    margin-bottom: 0;
  }
`;

export const DiscardingAdjustmentsContainer = styled.div`
  height: 22rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  p {
    text-align: center;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const ButtonUpdateAnimationStatus = {
  opened: keyframes`
  0%{
    transform:scale(0%);
    opacity: 0%;
  }

  50%{
    transform:scale(110%);
    opacity:100%
  }
  75%{
    transform:scale(90%);
    opacity:100%
  }

  100%{
    transform:scale(100%);
    opacity:100%
  }
  `,
  closed: keyframes`
  25%{
   transform:scale(90%);
   opacity:100%
 }

 50%{
   transform:scale(110%);
   opacity:100%
 }
 100%{
   transform:scale(0%);
   opacity: 0%;
 }
`,
};

type ButtonUpdateProps = {
  status: 'opened' | 'closed' | null;
};

export const ButtonUpdate = styled.button<ButtonUpdateProps>`
  position: fixed;
  bottom: 3rem;
  right: 3rem;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  background-color: ${({ theme }) => theme.colors.primary};

  z-index: 5;

  height: fit-content;

  padding: 0.75rem 1rem;

  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};

  border-radius: 999px;

  transition: background 0.2s, color 0.2s, right 1s;

  box-shadow: ${({ theme }) => theme.colors.primary} 0px 0px 8px 0px;

  ${({ status }) =>
    status
      ? css`
          animation: ${() => ButtonUpdateAnimationStatus[status]} 1s ease-in-out
            forwards;
        `
      : css`
          transform: scale(0%);
          opacity: 0%;
        `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
    box-shadow: ${({ theme }) => theme.colors.primaryDark} 0px 0px 8px 0px;
  }
`;
