import styled from 'styled-components';

export const CellDefaultContainer = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.gray7};

  white-space: break-spaces;
  word-wrap: anywhere;
  overflow: hidden;
`;
