import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components/Modal';
import { Card } from 'src/components/Card';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Button } from 'src/components/Button';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';
import { Select } from 'src/components/Select';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';
import { ReactComponent as SumImg } from 'src/assets/sum.svg';
import { ReactComponent as AverageImg } from 'src/assets/average.svg';
import { ReactComponent as EndOfPeriodImg } from 'src/assets/end-of-period.svg';
import { useQueryYTypes } from 'src/workspaces/hooks/useQueryYTypes';

import {
  AggregationContainer,
  Container,
  DataType,
  TemporalSelectOption,
} from './styles';
import {
  IndividualAggregationsModalProps,
  LevelAggregation,
  LevelAggregationOption,
  TemporalAggregation,
  TemporalAggregationOption,
  TypeLevelAggregation,
  TypeTemporalAggregation,
} from './types';

export const IndividualAggregationsModal: React.FC<
  IndividualAggregationsModalProps
> = ({ visible, setVisible }) => {
  const {
    temporalAggregation,
    levelAggregation,
    yTypesUsedOnCategorization,
    setTemporalAggregation,
    setLevelAggregation,
    frequency,
  } = useContext(WorkspaceConfigContext);

  const [updatedTemporalAgg, setUpdatedTemporalAgg] =
    useState<TypeTemporalAggregation[]>(temporalAggregation);
  const [updatedLevelAgg, setUpdatedLevelAgg] =
    useState<TypeLevelAggregation[]>(levelAggregation);

  const { t: translate } = useTranslation();

  const { data: typesData } = useQueryYTypes();

  const typesDataDict: { [key: string]: string } =
    typesData?.reduce(
      (obj, value) => ({
        ...obj,
        [value.code]: value.label,
      }),
      {},
    ) ?? {};

  const handleClose = () => {
    setVisible(false);
  };

  const handleConfirm = () => {
    setTemporalAggregation(updatedTemporalAgg);
    setLevelAggregation(updatedLevelAgg);

    setVisible(false);
  };

  const handleChangeTemporalAggregation = (
    yType: string,
    value: TemporalAggregation,
    isMarketSizeAndSellOut: boolean,
  ) => {
    setUpdatedTemporalAgg((state) =>
      state.map((agg) => {
        if (agg.y_type === yType) {
          return { ...agg, frequency_method: value };
        }

        if (isMarketSizeAndSellOut && agg.y_type.toLowerCase() === 'sellout') {
          return { ...agg, frequency_method: value };
        }

        return agg;
      }),
    );
  };

  const handleChangeLevelAggregation = (
    yType: string,
    value: LevelAggregation,
    isMarketSizeAndSellOut: boolean,
  ) => {
    setUpdatedLevelAgg((state) =>
      state.map((agg) => {
        if (agg.y_type === yType) {
          return {
            ...agg,
            frequency_method: value,
          };
        }

        if (isMarketSizeAndSellOut && agg.y_type.toLowerCase() === 'sellout') {
          return {
            ...agg,
            frequency_method: value,
          };
        }

        return agg;
      }),
    );
  };

  useEffect(() => {
    setUpdatedTemporalAgg(temporalAggregation);
  }, [temporalAggregation]);

  useEffect(() => {
    setUpdatedLevelAgg(levelAggregation);
  }, [levelAggregation]);

  const yTypesUsedOnCategorizationLowerCase = useMemo(
    () => yTypesUsedOnCategorization.map((type) => type.toLowerCase()),
    [yTypesUsedOnCategorization],
  );

  const hasMarketSizeAndSellOut =
    yTypesUsedOnCategorizationLowerCase.includes('marketsize') &&
    yTypesUsedOnCategorizationLowerCase.includes('sellout');

  const TemporalSumLabel = (
    <TemporalSelectOption>
      <SumImg /> {translate('workspaceConfigAggregationsSum')}
    </TemporalSelectOption>
  );

  const TemporalAverageLabel = (
    <TemporalSelectOption>
      <AverageImg /> {translate('workspaceConfigAggregationsAverage')}
    </TemporalSelectOption>
  );

  const TemporalEndOfPeriodLabel = (
    <TemporalSelectOption>
      <EndOfPeriodImg />
      {translate('workspaceConfigAggregationsEndOfPeriod')}
    </TemporalSelectOption>
  );

  const temporalAggOptions: TemporalAggregationOption[] = [
    {
      label: TemporalSumLabel,
      value: 'sum',
    },
    {
      label: TemporalAverageLabel,
      value: 'average',
    },
    {
      label: TemporalEndOfPeriodLabel,
      value: 'last_of_period',
    },
  ];

  const levelAggTranslation = {
    sum: translate('workspaceConfigAggregationsSum'),
    average: translate('workspaceConfigAggregationsAverage'),
    not_aggregated: translate('workspaceConfigAggregationsNoAggregation'),
  };

  const levelAggOptions: LevelAggregationOption[] = [
    {
      label: levelAggTranslation.not_aggregated,
      value: 'not_aggregated',
    },
    {
      label: levelAggTranslation.sum,
      value: 'sum',
    },
    {
      label: levelAggTranslation.average,
      value: 'average',
    },
  ];

  const showTemporalAggregation =
    frequency !== 'annual' && frequency !== 'yearly';

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      animationName="opacityAnimation"
      dataCy="modal-individual-aggregation-configuration-container"
      style={{
        width: showTemporalAggregation ? '37rem' : '30rem',
        animationDuration: '1s',
      }}
    >
      <Container data-testid="modal-individual-aggregation-configuration">
        <Card
          textCard={translate('workspaceAggregationConfigurationTitle')}
          textDescription={translate(
            'workspaceAggregationConfigurationDescription',
          )}
        />

        <div>
          {updatedLevelAgg.map((levelAgg, index) => {
            const typeLoweCase = levelAgg.y_type.toLowerCase();

            if (hasMarketSizeAndSellOut && typeLoweCase === 'sellout') {
              return null;
            }

            const isMarketSizeAndSellOut =
              hasMarketSizeAndSellOut && typeLoweCase === 'marketsize';

            const yType = isMarketSizeAndSellOut
              ? 'Market Size / Sell Out'
              : ['others', 'outros'].includes(typeLoweCase)
              ? transformUppercaseFirstLetter(levelAgg.y_type)
              : typeLoweCase === 'marketsize'
              ? 'Market Size'
              : typeLoweCase === 'sellout'
              ? 'Sell Out'
              : typesDataDict?.[levelAgg.y_type]
              ? typesDataDict?.[levelAgg.y_type]
              : levelAgg.y_type;

            const temporalAgg = temporalAggregation[index];

            return (
              <AggregationContainer
                key={levelAgg.y_type}
                data-testid={`container-aggregation-${yType
                  .toLowerCase()
                  .replaceAll(' ', '-')}`}
              >
                <DataType data-testid="text-y-type">{yType}</DataType>

                <div>
                  <Select
                    label={translate(
                      'workspaceConfigAggregationsLevelAggregation',
                    )}
                    options={levelAggOptions}
                    onChange={(option) =>
                      handleChangeLevelAggregation(
                        levelAgg.y_type,
                        (option as LevelAggregationOption).value,
                        isMarketSizeAndSellOut,
                      )
                    }
                    defaultValue={
                      {
                        label: levelAggTranslation[levelAgg.frequency_method],
                        value: levelAgg.frequency_method,
                      } as any
                    }
                    menuPlacement="auto"
                    menuPosition="fixed"
                    closeMenuOnScroll={(e) =>
                      !(e.target as any).className.includes('select__menu-list')
                    }
                    isSearchable={false}
                  />

                  {showTemporalAggregation && !!temporalAgg && (
                    <Select
                      label={translate(
                        'workspaceConfigAggregationsTemporalAggregation',
                      )}
                      options={temporalAggOptions}
                      defaultValue={
                        {
                          label:
                            temporalAgg.frequency_method === 'sum'
                              ? TemporalSumLabel
                              : temporalAgg.frequency_method === 'average'
                              ? TemporalAverageLabel
                              : TemporalEndOfPeriodLabel,
                          value: temporalAgg.frequency_method,
                        } as any
                      }
                      onChange={(option) =>
                        handleChangeTemporalAggregation(
                          temporalAgg.y_type,
                          (option as TemporalAggregationOption).value,
                          isMarketSizeAndSellOut,
                        )
                      }
                      menuPlacement="auto"
                      menuPosition="fixed"
                      closeMenuOnScroll={(e) =>
                        !(e.target as any).className.includes(
                          'select__menu-list',
                        )
                      }
                      isSearchable={false}
                    />
                  )}
                </div>
              </AggregationContainer>
            );
          })}
        </div>

        <ModalFooter>
          <Button
            buttonType="naked"
            onClick={handleClose}
            data-testid="button-cancel"
          >
            {translate('cancel')}
          </Button>

          <Button
            buttonType="primary"
            onClick={handleConfirm}
            data-testid="button-confirm"
          >
            {translate('confirm')}
          </Button>
        </ModalFooter>
      </Container>
    </Modal>
  );
};
