import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FormContainer = styled.form`
  max-width: 77rem;
`;

export const BasicInformationsContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    gap: 4rem;
  }
`;

export const Footer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 1rem;

  margin-top: 2rem;
`;
