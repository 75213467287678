import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Head } from 'src/components/Head';
import { FiltersSidebar } from 'src/components/FiltersSidebar';
import { RadioButtonOptions } from 'src/components/FiltersSidebar/RadioButtonFilter/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  WorkspaceOverviewForecast,
  WorkspaceOverviewFrequency,
  WorkspaceOverviewInflation,
  WorkspaceOverviewPeriod,
  WorkspaceOverviewTransformation,
  changeWorkspaceOverviewBimonthlyPeriod,
  changeWorkspaceOverviewForecast,
  changeWorkspaceOverviewFrequency,
  changeWorkspaceOverviewHalfYearPeriod,
  changeWorkspaceOverviewInflation,
  changeWorkspaceOverviewOriginalPeriod,
  changeWorkspaceOverviewQuarterlyPeriod,
  changeWorkspaceOverviewTransformation,
  changeWorkspaceOverviewYearlyPeriod,
} from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import { Question } from 'phosphor-react';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import {
  CalendarProps,
  Checkbox,
  Radio,
  Toggle,
} from 'src/components/FiltersSidebar/types';
import { NewReleaseConfigurationProvider } from 'src/workspaces/contexts/NewReleaseConfigurationContext';

import { Container, LoadingContainer } from './styles';
import { ResultsComparison } from './components/ResultsComparison';
import { EditionModeEnabledModal } from './components/Modals/EditionModeEnabled';
import { useQueryReleaseData } from '../../hooks/useQueryReleaseData';
import { Historical } from './components/Historical';
import { Results } from './components/Results';
import { Parametrization } from '../../components/Parametrization';
import { Planning } from './components/Planning';

export const WorkspaceOverview: React.FC = () => {
  const [showEditionModeEnabledModal, setShowEditionModeEnabledModal] =
    useState(false);

  const {
    workspace,
    workspaceOverviewOptions: {
      frequency,
      transformation,
      inflation,
      forecast,
      period,
      editionModeEnabled,
    },
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const { data: releaseData, isLoading: releaseIsLoading } =
    useQueryReleaseData(workspace.id, workspace.releaseSelected?.id);

  const canChangeFilterOption = () => {
    if (editionModeEnabled) {
      setShowEditionModeEnabledModal(true);
    }

    return !editionModeEnabled;
  };

  const handleChangeFrequencyOption = (option: WorkspaceOverviewFrequency) => {
    if (option !== frequency) {
      if (canChangeFilterOption()) {
        dispatch(changeWorkspaceOverviewFrequency(option));
      }
    }
  };

  const handleChangeTransformationOption = (
    option: WorkspaceOverviewTransformation,
  ) => {
    if (option !== transformation) {
      if (canChangeFilterOption()) {
        dispatch(changeWorkspaceOverviewTransformation(option));
      }
    }
  };

  const handleChangeForecastOption = (option: WorkspaceOverviewForecast) => {
    if (option !== forecast) {
      if (canChangeFilterOption()) {
        dispatch(changeWorkspaceOverviewForecast(option));
      }
    }
  };

  const handleChangePeriodOption = (option: WorkspaceOverviewPeriod) => {
    if (option !== period[frequency === 'monthly' ? 'original' : frequency]) {
      if (canChangeFilterOption()) {
        if (frequency === 'yearly') {
          dispatch(changeWorkspaceOverviewYearlyPeriod(option));
        } else if (frequency === 'quarterly') {
          dispatch(changeWorkspaceOverviewQuarterlyPeriod(option));
        } else if (frequency === 'bimonthly') {
          dispatch(changeWorkspaceOverviewBimonthlyPeriod(option));
        } else if (frequency === 'half-year') {
          dispatch(changeWorkspaceOverviewHalfYearPeriod(option));
        } else {
          dispatch(changeWorkspaceOverviewOriginalPeriod(option));
        }
      }
    }
  };

  const handleSelectInflation = (inflationAux: WorkspaceOverviewInflation) => {
    if (inflation !== inflationAux) {
      dispatch(changeWorkspaceOverviewInflation(inflationAux));
    }
  };

  const canSelectYearly = !['yearly', 'annual'].includes(
    workspace.frequency ?? '',
  );

  const canSelectQuarterly = ![
    'bimonthly',
    'quarterly',
    'half-year',
    'yearly',
    'annual',
  ].includes(workspace.frequency ?? '');

  const canSelectBimonthly = workspace.frequency === 'monthly';

  const canSelectHalfYear = ['monthly', 'bimonthly', 'quarterly'].includes(
    workspace.frequency ?? '',
  );

  const frequencyOptions: RadioButtonOptions[] = [
    {
      label: translate('original'),
      value: 'original',
      checked: frequency === 'original',
    },
  ];

  if (canSelectBimonthly) {
    frequencyOptions.push({
      label: translate('bimonthly'),
      value: 'bimonthly',
      checked: frequency === 'bimonthly',
    });
  }

  if (canSelectQuarterly) {
    frequencyOptions.push({
      label: translate('quarterly'),
      value: 'quarterly',
      checked: frequency === 'quarterly',
    });
  }

  if (canSelectHalfYear) {
    frequencyOptions.push({
      label: translate('half-year'),
      value: 'half-year',
      checked: frequency === 'half-year',
    });
  }

  if (canSelectYearly) {
    frequencyOptions.push({
      label: translate('annual'),
      value: 'yearly',
      checked: frequency === 'yearly',
    });
  }

  const transformationOptions: RadioButtonOptions[] = [
    {
      label: translate('level'),
      value: 'level',
      checked: transformation === 'level',
    },
    {
      label: translate('filterSidebarVariationPoP'),
      value: 'variationPoP',
      checked: transformation === 'variationPoP',
    },
    {
      label: translate('filterSidebarVariationYoY'),
      value: 'variationYoY',
      checked: transformation === 'variationYoY',
    },
  ];

  const forecastOptions: RadioButtonOptions[] = [
    {
      label: translate('original'),
      value: 'original',
      checked: forecast === 'original',
    },
    {
      label: translate('workspaceProjectionsMostRecent'),
      value: 'adjusted',
      checked: forecast === 'adjusted',
    },
  ];

  const hasYIsInflated =
    releaseData && releaseData.data.ys.some((y) => y.is_inflated);

  const inflationOptions: RadioButtonOptions[] = [
    {
      label: 'Real',
      value: 'real',
      checked: inflation === 'real',
    },
    {
      label: 'Nominal',
      value: 'nominal',
      checked: inflation === 'nominal',
      disabled: !hasYIsInflated,
    },
  ];

  useEffect(() => {
    if (workspace.releaseSelected) {
      if (hasYIsInflated === false && inflation === 'nominal') {
        dispatch(changeWorkspaceOverviewInflation('real'));
      }
    }
  }, [
    dispatch,
    hasYIsInflated,
    inflation,
    workspace.releaseSelected,
    workspace.ySelected,
  ]);

  useEffect(() => {
    if (
      (frequency === 'yearly' && !canSelectYearly) ||
      (frequency === 'quarterly' && !canSelectQuarterly) ||
      (frequency === 'bimonthly' && !canSelectBimonthly) ||
      (frequency === 'half-year' && !canSelectHalfYear)
    ) {
      dispatch(changeWorkspaceOverviewFrequency('original'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace.frequency]);

  const skipVariationFilter =
    workspace.frequency === 'weekly' ||
    workspace.frequency === 'daily' ||
    workspace.frequency === 'fortnightly' ||
    workspace.frequency === 'bimonthly';

  const filters: Array<Toggle | Radio | Checkbox | CalendarProps> = [
    {
      type: 'radio',
      name: translate('frequency'),
      options: frequencyOptions,
      onChange: (value) =>
        handleChangeFrequencyOption(value as WorkspaceOverviewFrequency),
    },
    {
      type: 'radio',
      name: translate('filterSidebarTransformation'),
      tooltip: {
        icon: Question,
        info: translate('filterSidebarTooltipTransformation'),
      },
      options: skipVariationFilter
        ? transformationOptions.splice(0, 1)
        : transformationOptions,
      onChange: (value) =>
        handleChangeTransformationOption(
          value as WorkspaceOverviewTransformation,
        ),
    },
    {
      type: 'radio',
      name: translate('value'),
      tooltip: {
        icon: Question,
        info: translate('workspaceProjectionsRadioInflationValueTooltip'),
      },
      options: inflationOptions,
      onChange: (value) =>
        handleSelectInflation(value as WorkspaceOverviewInflation),
    },
  ];

  if (
    !releaseData?.data.approval_flow?.enable &&
    workspace.frequency === 'monthly' &&
    workspace.releaseSelected?.id !== workspace.releasePreview
  ) {
    filters.push({
      type: 'radio',
      name: translate('projection'),
      options: forecastOptions,
      onChange: (value) =>
        handleChangeForecastOption(value as WorkspaceOverviewForecast),
    });
  }

  filters.push({
    type: 'calendar',
    name: translate('period'),
    frequency:
      frequency === 'original' ? workspace.frequency ?? 'monthly' : frequency,
    selectedDate: period[frequency === 'monthly' ? 'original' : frequency],
    onChange: (value) =>
      handleChangePeriodOption(value as WorkspaceOverviewPeriod),
  });

  if ((!releaseData && releaseIsLoading) || !releaseData) {
    return (
      <LoadingContainer>
        <ContainerSkeleton
          data-testid="container-loading-release-data"
          className="containerLinear"
          style={{ height: '100%' }}
        />
      </LoadingContainer>
    );
  }

  function moveButtonUpdateData(isOpenFilter: boolean) {
    const buttonUpdate = document.getElementById('button-update-data');
    if (buttonUpdate) {
      if (isOpenFilter) {
        buttonUpdate.style.right = '23rem';
      } else {
        buttonUpdate.style.right = '3rem';
      }
    }
  }

  return (
    <Container>
      <Head title={translate('workspaceOverviewTitle')} />

      {!!releaseData?.data.approval_flow?.enable && <Historical />}

      <FiltersSidebar
        filters={filters}
        parametrization={<Parametrization />}
        onOpenOrCloseSidebar={moveButtonUpdateData}
      />

      {!!releaseData?.data.approval_flow?.enable &&
        !!releaseData.data.steps?.length && (
          <NewReleaseConfigurationProvider>
            <Planning />
          </NewReleaseConfigurationProvider>
        )}

      <Results />

      <ResultsComparison />

      {showEditionModeEnabledModal && (
        <EditionModeEnabledModal setVisible={setShowEditionModeEnabledModal} />
      )}
    </Container>
  );
};
