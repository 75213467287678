import React, { useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ICellRendererParams,
  ISetFilterParams,
} from 'ag-grid-community';
import { AgGridTable } from 'src/components/AgGridTable';
import { SerieData } from 'src/models/hooks/useQuerySeries';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import { Tooltip } from 'react-tooltip';
import { useQuerySerieTags } from 'src/models/hooks/useQuerySerieTags';

import { TableSeriesContainer } from './styles';
import { CellLoading } from '../CellLoading';
import { TableSerieTag } from '../Tag';
import { DeleteSerie } from '../DeleteSerie';
import { CellDefault } from '../CellDefault';
import { CellNewSerie } from '../CellNewSerie';

type TableSeriesProps = {
  data: SerieData[];
  isLoading: boolean;
  onHandleScroll: () => void;
  removeNewSerie: (newSerieId: string) => void;
  updateSelectedTags: (tags: string[]) => void;
};

const dataLoading: SerieData[] = Array.from({ length: 6 }).map(() => ({
  id: '',
  created: '2020-01-01',
  frequency: 'monthly',
  name: '',
  status: '',
  tag: '',
}));

export const TableSeries: React.FC<TableSeriesProps> = ({
  data,
  isLoading,
  onHandleScroll,
  removeNewSerie,
  updateSelectedTags,
}) => {
  const { serieTagsData, serieTagsIsLoading, serieTagsIsError } =
    useQuerySerieTags();

  const [tableRendered, setTableRendered] = useState(false);

  const tableRef = useRef<AgGridReact>(null);

  const { t } = useTranslation();

  const translateFormat = useFormatDateLanguage();

  const colDefs: ColDef[] = [
    {
      field: 'name',
      headerName: t('name'),
      pinned: true,
      cellRenderer: (params: ICellRendererParams<SerieData>) => {
        if (isLoading) {
          return <CellLoading />;
        }
        if (params.data?.status === 'created') {
          return (
            <CellNewSerie
              serieName={params.data.name}
              serieId={params.data.id}
              removeNewSerie={removeNewSerie}
            />
          );
        }
        return (
          <CellDefault
            name={params.data!.name}
            columnName={params.column!.getColId()}
            value={params.data!.name}
          />
        );
      },
    },
    {
      field: 'frequency',
      headerName: t('frequency'),
      valueFormatter: ({ value }) => t(value),
    },
    { field: 'id' },
    {
      field: 'tag',
      cellRenderer: (params: ICellRendererParams) => {
        if (isLoading) {
          return <CellLoading />;
        }
        return <TableSerieTag value={params.value} name={params.data.name} />;
      },
      suppressHeaderFilterButton:
        isLoading || serieTagsIsLoading || serieTagsIsError,
      filter: 'agSetColumnFilter',
      menuTabs: ['filterMenuTab'],

      filterParams: {
        treeList: true,
        values: serieTagsData,
      } as ISetFilterParams,
    },
    {
      field: 'created',
      maxWidth: 144,
      headerName: t('createdAt'),
      valueFormatter: ({ value }) =>
        format(
          new Date(value.replaceAll('Z', '')),
          `${translateFormat}, HH:mm`,
        ),
    },
    {
      headerName: '',
      pinned: 'right',
      minWidth: 52,
      maxWidth: 52,
      cellRenderer: (param: ICellRendererParams) => (
        <DeleteSerie serieName={param.data.name} serieId={param.data.id} />
      ),
    },
  ];

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 140,
      maxWidth: 230,
      autoHeight: true,
      sortable: false,
      suppressHeaderMenuButton: true,
      resizable: false,

      cellRenderer: (params: ICellRendererParams<SerieData>) => {
        if (isLoading) {
          return <CellLoading />;
        }

        const columnName = params.column!.getColId();

        if (
          params.data?.status === 'created' &&
          ['id', 'frequency'].includes(columnName)
        ) {
          return <CellLoading />;
        }

        const value = params.valueFormatted || params.value;

        return (
          <CellDefault
            name={params.data!.name}
            columnName={columnName}
            value={value}
          />
        );
      },
    }),
    [isLoading],
  );

  const rowData = !isLoading ? data : dataLoading;

  return (
    <TableSeriesContainer isLoading={isLoading} data-cy="container-table">
      <AgGridTable
        tableRef={tableRef}
        rowData={rowData}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        maxRemHeight={30}
        suppressCellFocus
        suppressMenuHide
        suppressContextMenu
        enableRangeHandle={false}
        enableRangeSelection={false}
        onFirstDataRendered={() => setTableRendered(true)}
        checkDomLayoutType={tableRendered}
        containerStyle={{
          height: '30rem',
        }}
        onFilterChanged={(param) => {
          if (param.columns.length && param.columns[0].getColId() === 'tag') {
            param.api.getColumnFilterInstance('tag').then((filter) => {
              //@ts-expect-error:ignora
              if (filter?.valueModel?.selectedKeys) {
                const selectedKeys = Array.from(
                  //@ts-expect-error:ignora
                  filter.valueModel.selectedKeys,
                ) as string[];

                updateSelectedTags(selectedKeys);
              }
            });
          }
        }}
        onBodyScrollEnd={(event) => {
          if (event.top !== 0) {
            onHandleScroll();
          }
        }}
        suppressScrollOnNewData
      />
      <Tooltip
        id="table-serie-tooltip"
        className="customTooltipTheme maxWidthUnsetImportant"
      />
    </TableSeriesContainer>
  );
};
