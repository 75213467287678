import React, { useState } from 'react';

import { Trash, Warning } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalLoading } from 'src/components/Modal/Loading';
import { queryClient } from 'src/service/queryClient';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import parseHTML from 'html-react-parser';
import apiWorkspace from 'src/workspaces/service/api';

import { Container } from './styles';
import { RemoveUserModalProps } from './types';
import { ErrorModal } from '../Error';

export const RemoveUserModal: React.FC<RemoveUserModalProps> = ({
  setVisible,
  workspaceId,
  user,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { t: translate } = useTranslation();

  const handleRemoveUser = async () => {
    setLoading(true);

    try {
      await apiWorkspace.delete(`/workspaces/${workspaceId}/users/${user}`);

      await queryClient.invalidateQueries(['workspace users', workspaceId]);
      await queryClient.invalidateQueries(['workspaces']);

      setVisible();
    } catch {
      setError(true);
    }

    setLoading(false);
  };

  if (loading) {
    return (
      <ModalLoading
        visible
        style={{ padding: '3.5rem 2rem 2rem 2rem', width: '17.5rem' }}
        data-testid="loading-remove-user"
      />
    );
  }

  if (error) {
    return (
      <ErrorModal
        setVisible={setVisible}
        title={translate('workspaceControlPanelUserRemoveErrorTitle')}
        description={translate(
          'workspaceControlPanelUserRemoveErrorDescription',
        )}
      />
    );
  }

  return (
    <Modal visible setVisible={setVisible}>
      <Container data-testid="remove-user-modal">
        <Warning size="2.5rem" />
        <h2 data-testid="remove-title">
          {translate('workspaceControlPanelUserRemoveTitle')}
        </h2>
        <p data-testid="remove-description">
          {parseHTML(
            translate('workspaceControlPanelUserRemoveDescription').replace(
              'XXX',
              user,
            ),
          )}
        </p>
      </Container>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={setVisible}
          data-testid="cancel-button"
        >
          {translate('cancel')}
        </Button>
        <Button
          buttonType="primary"
          icon={<Trash size="1.125rem" />}
          onClick={handleRemoveUser}
          data-testid="remove-button"
        >
          {translate('workspaceControlPanelRemove')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
