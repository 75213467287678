import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;

  margin-bottom: 2rem;
  padding: 0.75rem 1rem;

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray2};

  transition: all 0.2s;

  &:disabled {
    cursor: default;
  }

  &:not(:disabled):hover {
    border: 1px solid ${({ theme }) => theme.colors.gray3};

    > svg {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 0.25rem;
    flex: 1;

    svg {
      width: 1rem;
      height: 1rem;

      color: ${({ theme }) => theme.colors.gray5};
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      flex: 1;

      p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${({ theme }) => theme.colors.gray6};
        text-align: start;

        white-space: pre-line;
        word-break: break-word;
      }

      svg {
        max-width: 0.875rem;
        min-width: 0.875rem;
        width: 0.875rem;
        height: 0.875rem;

        color: ${({ theme }) => theme.colors.gray4};
      }
    }

    > p {
      font-size: 0.625rem;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.05rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.gray4};
    }
  }

  > svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  > svg:first-of-type {
    margin-right: 0.25rem;
    color: ${({ theme }) => theme.colors.gray5};
  }

  .newTooltipTheme {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.375rem !important;
    text-align: start;
    color: ${({ theme }) => theme.colors.gray6};

    z-index: 1;

    box-shadow: 0px 2px 16px 0px rgba(113, 128, 150, 0.24);

    padding: 1rem !important;

    border-radius: 8px;

    background: ${({ theme }) => theme.colors.white};

    max-width: 15rem;

    opacity: 1;

    white-space: pre-line;
    word-break: break-word;

    z-index: 9000 !important;
  }
`;
