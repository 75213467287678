const menuSideBarPTBRTranslations = {
  labelUserSelection: 'Seleção do Usuário',
  labelAISelection: 'Seleção IA',
  labelProjectOverview: 'Visão Geral',
  labelOverallPerformance: 'Desempenho Geral',
  labelVariablePerformance: 'Performance da Variável',
  labelModelExplorer: 'Explorador de Modelos',
  labelModelSpecifics: 'Detalhamento do Modelo',
  labelModelDispersion: 'Dispersão dos Modelos',
  labelCrossValidation: 'Validação Cruzada',
  labelForecastCombination: 'Combinação de Modelos',
  labelOverview: 'Visão Geral',
  labelCorrelationMatrix: 'Matriz de Correlação',
  labelVariableImportance: 'Importância das Variáveis',
  labelDataView: 'Visualizar Dados',
  labelTreeModels: 'Modelo de Árvore',
  labelRegularizedRegression: 'Regressão Regularizada',
  labelElementary: 'Elementar',
  labelArima: 'ARIMA',
  sideBarSelectAnotherProject: 'Clique para selecionar outro projeto',
  sideBarDependentVariable: 'Variável Dependente',
  sideBarProject: 'PROJETO',
  sideBarVariable: 'RESULTADOS',
  sideBarUserSelection: 'PORTFÓLIO DE MODELOS',
  sideBarAISelectionDisabled:
    'Desculpe, essa funcionalidade não está disponível.',
  sideBarCheckDependentVariableConfig: 'Confira a sua configuração',
  sideBarFilterPlaceholder: 'Selecione...',
  sideBarDependentVariablePlaceholder: 'Selecione uma variável...',
  sideBarSelectVariable: 'Variável',
};

export default menuSideBarPTBRTranslations;
