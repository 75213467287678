import styled from 'styled-components';

export const SelectContainer = styled.div`
  .select__value-container {
    padding: 0.5rem 0.75rem !important;

    > div:last-of-type {
      height: 0.75rem;
      margin-top: -0.6rem;
    }
  }

  .select__dropdown-indicator {
    padding-right: 0.75rem !important;
  }

  .select__single-value > div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select__menu-portal {
    z-index: 10000;
  }

  .select__indicators {
    padding-left: 0.5rem;

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .select__clear-indicator {
    padding: 0;
    padding-right: 0.25rem;
  }

  .select__dropdown-indicator {
    padding-left: 0;
  }

  .select__input {
    font-size: 0.75rem;
  }

  .select__single-value {
    margin-right: 0;

    width: -webkit-fill-available;

    p {
      font-weight: 500;
    }
  }

  .select__option--is-disabled {
    cursor: not-allowed;

    > div {
      opacity: 0.6;
    }
  }

  .select__control--is-disabled {
    background: ${({ theme }) => theme.colors.gray0};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  flex: 1;

  > div:first-of-type {
    width: 0.875rem;
    height: 0.875rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    background-color: ${({ theme }) => theme.colors.primary};

    p {
      font-size: 0.5rem;
      font-weight: 700;
      line-height: 150%;
      text-transform: uppercase;

      color: ${({ theme }) => theme.colors.white};
    }
  }

  > p {
    font-size: 0.75rem;
    font-weight: 400;

    color: ${({ theme }) => theme.colors.gray6};

    word-break: break-all;

    flex: 1;
  }
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;

  font-size: 0.75rem;

  svg {
    width: 0.875rem;
    height: 0.875rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;
