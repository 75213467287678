import React, { useContext, useEffect, useRef, useState } from 'react';

import { MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AutoComplete } from 'src/components/AutoComplete';
import { useSearchDebounce } from 'src/hooks/useSearchDebounce';
import { useInfiniteQueryProjects } from 'src/models/hooks/useInfiniteQueryProjects';
import {
  storageProjectNamesGet,
  storageProjectNamesRemove,
  storageProjectNamesSave,
} from 'src/models/storage/storageProjectNames';
import { RootState } from 'src/redux/store';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Status } from 'src/components/Status';
import { CheckBox } from 'src/components/CheckBox';

import { ProjectShow, SelectProjectsProps } from './types';
import { LoadingProjectCard } from '../LoadingProjectCard';
import { Content, ProjectCard, SelectProjectsContainer } from './styles';
import { UploadProjectSerieMessage } from '../UploadProjectSerieMessage';

const LIMIT = 8;

export const SelectProjects: React.FC<SelectProjectsProps> = ({
  selectedProjects,
  addOrRemoveSelectProjects,
}) => {
  const [oldProjectNames, setOldProjectNames] = useState<string[]>([]);

  const { frequency, isEdition } = useContext(WorkspaceConfigContext);

  const { user } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();

  const contentRef = useRef<HTMLDivElement>(null);

  const {
    searchValue,
    requestAllowed,
    searchError,
    isSearchValid,
    searchCompleted,
    lastSearch,
  } = useSearchDebounce();

  const {
    projectsData,
    projectsIsLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    projectsError,
  } = useInfiniteQueryProjects({
    searchValue,
    requestAllowed,
    projectType: 'modelling',
    engineName: 'bergman',
    engineVersion: '0.5.2',
    limit: LIMIT,
    onSuccess(data) {
      if (searchValue && data?.total && user.email) {
        storageProjectNamesSave(user.email, searchValue);
        setOldProjectNames(storageProjectNamesGet(user.email));
      }
    },
  });

  useEffect(() => {
    searchCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsData]);

  useEffect(() => {
    if (user.email) {
      setOldProjectNames(storageProjectNamesGet(user.email));
    }
  }, [user.email]);

  const fetchNextPageOnScroll = (
    event: React.UIEvent<HTMLDivElement>,
  ): void => {
    const isBottom =
      Math.floor(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop,
      ) <= event.currentTarget.clientHeight;

    if (isBottom && !projectsIsLoading && !projectsError && hasNextPage) {
      fetchNextPage();
    }
  };

  function handleSearchProject(value: string) {
    isSearchValid(value);
  }

  const oldProjectNamesShow = oldProjectNames.filter(
    (oldProjectNamesAux) =>
      searchValue !== oldProjectNamesAux &&
      oldProjectNamesAux.includes(searchValue),
  );

  const listProjectsShow: ProjectShow[] = [];

  projectsData?.pages.forEach((projectData) => {
    projectData.records.forEach((project) => {
      const ysWillShow = project.ys.slice(0, 3).map((y) => y.name);

      const ysText = ysWillShow.toString().replaceAll(',', ', ');

      const ysRemaining = project.ys.length - ysWillShow.length;

      let ysRemainingTooltip: string | null = null;

      let ysRemainingText: string | null = null;

      if (ysRemaining > 0) {
        ysRemainingText = ` + ${ysRemaining} ${t('variables')}`;
        ysRemainingTooltip = project.ys
          .slice(3)
          .map((y) => y.name)
          .toString();
      }

      const isStatusSuccess =
        project.status === 'success' || project.status === 'partial_success';

      const isSelected = selectedProjects.some(
        (selectedProject) => selectedProject.id === project.id,
      );

      const projectFrequency = project.ys.find((y) => y.status === 'success')
        ?.info?.frequency;

      const isDifferentFrequency = frequency
        ? projectFrequency !== frequency
        : false;

      let errorMessage: string | null = null;

      if (isDifferentFrequency && isStatusSuccess) {
        if (isEdition) {
          errorMessage = t(
            'selectProjectWorkspaceIsDisabledDifferentFrequencyOfWorkspace',
          );
        } else {
          errorMessage = t(
            'selectProjectWorkspaceIsDisabledDifferentFrequency',
          );
        }
      }

      listProjectsShow.push({
        id: project.id,
        iconUrl: checkIconURL(project.icon_url)
          ? project.icon_url
          : arrayIcon[0],
        name: project.name,
        created: project.created,
        ys: project.ys,
        parent_id: project.parent_id,
        isSelected,
        errorMessage,
        isDisabled: !isStatusSuccess || !!errorMessage,
        ysText,
        ysRemainingTooltip,
        ysRemainingText,
      });
    });
  });

  const paddingRight =
    contentRef.current &&
    contentRef.current?.scrollHeight > contentRef.current?.clientHeight
      ? '0.5rem'
      : '0rem';

  const noProject =
    searchValue.length === 0 &&
    listProjectsShow.length === 0 &&
    projectsIsLoading === false;

  return (
    <SelectProjectsContainer>
      <AutoComplete
        icon={<MagnifyingGlass size="1.25rem" />}
        label={t('createWorkspaceMyProjectsLabel')}
        placeholder={t('searchProject')}
        testid="searchProject"
        error={
          searchError?.quantityLetters === 3
            ? t(searchError.message).replace('XX', '3')
            : searchError?.quantityLetters === 50
            ? t(searchError.message).replace('XX', '50')
            : undefined
        }
        onChange={(value) => {
          handleSearchProject(value);
        }}
        value={searchValue}
        options={oldProjectNamesShow}
        disabled={noProject}
        onDelete={(index) => {
          if (oldProjectNames[index] && user.email) {
            storageProjectNamesRemove(user.email, oldProjectNames[index]);
            setOldProjectNames(storageProjectNamesGet(user.email));
          }
        }}
      />

      <Content
        onScroll={fetchNextPageOnScroll}
        data-testid="workspace-content-projects"
        ref={contentRef}
        style={{ paddingRight }}
      >
        {projectsError ? (
          <ContainerMaintenance
            content="projects"
            text={t('fetchProjectsError')}
          />
        ) : projectsData?.pages.length &&
          projectsData?.pages[0].total === 0 &&
          searchValue.length >= 1 ? (
          // eslint-disable-next-line react/jsx-indent
          <Status
            type="noSearchResults"
            title={`${t('searchProjectFail')} "${lastSearch}".`}
          />
        ) : noProject ? (
          <UploadProjectSerieMessage type="project" />
        ) : (
          <>
            {listProjectsShow?.map((project) => (
              <ProjectCard
                key={project.id}
                onClick={() => addOrRemoveSelectProjects(project)}
                disabled={project.isDisabled}
                data-tooltip-id="workspace-select-project"
                data-testid={`card-project-${project.id}`}
                data-tooltip-html={project.errorMessage}
              >
                <CheckBox checked={project.isSelected} />
                <div>
                  <img src={project.iconUrl} alt="" />
                  <div>
                    <h4>{project.name}</h4>
                    <p>
                      {project.ysText}
                      <span
                        data-tooltip-id="workspace-projects-dependent-variables"
                        data-tooltip-content={project.ysRemainingTooltip}
                      >
                        {project.ysRemainingText}
                      </span>
                    </p>
                  </div>
                </div>
              </ProjectCard>
            ))}
            {(projectsIsLoading ||
              isFetchingNextPage ||
              listProjectsShow.length === 0) &&
              Array.from({ length: LIMIT }).map((_, index) => (
                <LoadingProjectCard
                  index={index}
                  key={`card-loading-${index + 1}`}
                />
              ))}
          </>
        )}
      </Content>
    </SelectProjectsContainer>
  );
};
