import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import {
  CardBody,
  CardContainer,
  CardContent,
  CardMenuOverlay,
} from 'src/components/ProjectCard/styles';
import { useTranslation } from 'react-i18next';
import { arrayIcon, checkIconURL } from 'src/utils/icons/handleProjectIcon';
import { Tooltip } from 'react-tooltip';
import { useFormatDateLanguage } from 'src/hooks/useFormatDateLanguage';
import {
  DotsThreeVertical,
  Eye,
  GearSix,
  PencilSimple,
  UserGear,
  Warning,
  X,
} from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuContainer, MenuOption } from 'src/components/Menu/styles';
import light from 'src/styles/themes/light';
import { Button } from 'src/components/Button';
import { ConfirmDeletionModal } from 'src/components/Modal/ConfirmDeletion';
import apiWorkspace from 'src/workspaces/service/api';
import { RootState } from 'src/redux/store';
import { queryClient } from 'src/service/queryClient';
import { FailedModal } from 'src/components/Modal/Failed';
import { ModalLoading } from 'src/components/Modal/Loading';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { useQuery } from 'react-query';
import ms from 'ms';
import {
  insert,
  updateReleaseSelected,
} from 'src/workspaces/redux/reducers/Workspace';
import { resetWorkspaceOverviewOptions } from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';
import { resetWorkspaceProjectionsOptions } from 'src/workspaces/redux/reducers/WorkspaceProjectionsOptions';

import {
  ButtonOpenMenu,
  ContainerModal,
  UserRoleContainer,
  WorkspaceContainer,
  WorkspaceHead,
} from './styles';

// eslint-disable-next-line no-shadow
enum UserRole {
  EDITOR = 'editor',
  READER = 'reader',
  MANAGER = 'manager',
}

interface Card {
  id: string;
  name: string;
  description: string;
  icon_url: string;
  last_updated: string;
  user_role: UserRole;
  created_by: string;
  releases: {
    current?: string;
    preview?: string;
  };
  status: string;
}

interface WorkspaceCardProps {
  card: Card;
  saveSearchAndPage: () => void;
}

interface WorkspaceUser {
  user: string;
  role: UserRole;
}

export const WorkspaceCard: React.FC<WorkspaceCardProps> = ({
  card,
  saveSearchAndPage,
}) => {
  const {
    name,
    description,
    last_updated,
    icon_url,
    id,
    user_role,
    created_by,
    releases,
    status,
  } = card;

  const [openMenu, setOpenMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [showNoPermissionDeleteModal, setShowNoPermissionDeleteModal] =
    useState(false);

  const { email } = useSelector((state: RootState) => state.auth.user);

  const translateFormat = useFormatDateLanguage();
  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formattedData = format(
    new Date(last_updated),
    `${translateFormat}, HH:mm`,
  );

  const {
    data: userData,
    isLoading: userIsLoading,
    isFetching: userIsFetching,
    isError: userIsError,
  } = useQuery(
    ['workspace users', id],
    async () => {
      const { data } = await apiWorkspace.get<WorkspaceUser[]>(
        `/workspaces/${id}/users`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: loadingUsers,
    },
  );

  const deleteWorkspace = async () => {
    setDeleteLoading(true);
    setDeleteError(false);

    try {
      await apiWorkspace.delete(`/workspaces/${id}/users/${email}`);

      await queryClient.invalidateQueries('workspaces');
    } catch {
      setDeleteError(true);
    }

    setDeleteLoading(false);
  };

  const handleDelete = async () => {
    if (user_role === UserRole.READER || user_role === UserRole.EDITOR) {
      setShowDeleteModal(true);
    } else {
      setLoadingUsers(true);
    }
  };

  useEffect(() => {
    const checkCanDelete = () => {
      let manager = 0;
      let others = 0;

      userData?.forEach(({ role }) => {
        if (role === UserRole.MANAGER) {
          manager++;
        } else {
          others++;
        }
      });

      if (manager === 1 && others > 0) return false;
      return true;
    };

    if (
      !userIsLoading &&
      !userIsFetching &&
      loadingUsers &&
      (userData || userIsError)
    ) {
      if (userData) {
        const canDelete = checkCanDelete();

        setLoadingUsers(false);

        if (canDelete) {
          setShowDeleteModal(true);
        } else {
          setShowNoPermissionDeleteModal(true);
        }
      } else {
        setLoadingUsers(false);
        setShowDeleteModal(true);
      }
    }
  }, [userData, userIsLoading, userIsFetching, userIsError, loadingUsers]);

  const saveWorkspaceInfo = () => {
    dispatch(
      insert({
        id,
        name,
        description,
        icon: icon_url,
        lastUpdated: last_updated,
        userRole: user_role,
        createdBy: created_by,
        frequency: null,
        releaseCurrent: releases?.current || null,
        releasePreview: releases?.preview || null,
        status,
        canSyncAdjust: true,
      }),
    );

    dispatch(updateReleaseSelected({ releaseSelected: null, ySelected: null }));
    dispatch(resetWorkspaceOverviewOptions());
    dispatch(resetWorkspaceProjectionsOptions());
  };

  const handleAccessWorkspace = () => {
    saveWorkspaceInfo();

    saveSearchAndPage();

    navigate(`/workspaces/${id}/control-panel`);
  };

  const isDisabled = !releases?.current;

  const handleNavigateToWorkspace = () => {
    if (isDisabled) {
      return;
    }

    saveWorkspaceInfo();

    saveSearchAndPage();

    navigate(`/workspaces/${id}/overview`);
  };

  return (
    <>
      <CardContainer
        data-testid={`workspace-${id}`}
        disabled={isDisabled}
        style={{ maxHeight: '19rem' }}
      >
        <WorkspaceContainer>
          <CardContent
            onClick={handleNavigateToWorkspace}
            disabled={isDisabled}
          >
            <WorkspaceHead>
              <div>
                <img
                  src={checkIconURL(icon_url) ? icon_url : arrayIcon[0]}
                  alt="workspace icon"
                  data-testid={`workspace-${name
                    .replaceAll(' ', '-')
                    .toLowerCase()}-icon`}
                />
                <h5
                  data-testid={`workspace-${name
                    .replaceAll(' ', '-')
                    .toLowerCase()}-name`}
                  data-tooltip-id="workspace-description"
                  data-tooltip-content={name.length > 33 ? name : null}
                >
                  {name.length <= 30 ? name : `${name.slice(0, 30)}...`}
                </h5>
              </div>
            </WorkspaceHead>

            <CardBody className="workspace-body">
              <span className="workspace-description-title">
                {translate('description')}
              </span>
              <p
                data-testid={`workspace-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}-description`}
                data-tooltip-id="workspace-description"
                data-tooltip-content={
                  description.length > 115 ? description : null
                }
              >
                {description.length <= 115
                  ? description
                  : `${description.slice(0, 112)}...`}
              </p>

              <span>{translate('projectHeadLastUpdated')}</span>
              <p
                data-testid={`workspace-${name
                  .replaceAll(' ', '-')
                  .toLowerCase()}-last-updated`}
              >
                {formattedData}
              </p>
            </CardBody>
          </CardContent>
          <ButtonOpenMenu
            buttonType="naked"
            icon={<DotsThreeVertical />}
            onClick={() => setOpenMenu(true)}
            data-testid={`button-open-menu-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          />
        </WorkspaceContainer>

        <MenuContainer
          visible={openMenu}
          style={{ top: '5rem', right: '1.5rem' }}
          data-testid="actions-menu"
        >
          {openMenu && (
            <CardMenuOverlay
              data-testid="menu-overlay"
              visible={openMenu}
              onClick={() => setOpenMenu((state) => !state)}
            />
          )}
          {user_role !== UserRole.READER && (
            <MenuOption
              position="start"
              color={light.colors.primary}
              type="button"
              data-testid={`menu-button-control-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              data-cy={`menu-button-control-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
              onClick={handleAccessWorkspace}
            >
              <GearSix size="1.125rem" />
              <p>{translate('myProjectsWorkspaceControl')}</p>
            </MenuOption>
          )}

          <MenuOption
            position="end"
            color={light.colors.primary}
            type="button"
            onClick={handleDelete}
            data-testid={`menu-button-delete-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
            data-cy={`menu-button-delete-${name
              .replaceAll(' ', '-')
              .toLowerCase()}`}
          >
            <X size="1.125rem" />
            <p>{translate('workspaceCardDelete')}</p>
          </MenuOption>
        </MenuContainer>

        <UserRoleContainer>
          {user_role === UserRole.READER ? (
            <Eye
              data-tooltip-id="workspace-card"
              data-tooltip-html={translate('workspaceCardCanView')}
              data-testid={`can-view-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            />
          ) : user_role === UserRole.MANAGER ? (
            <UserGear
              data-tooltip-id="workspace-card"
              data-tooltip-html={translate('workspaceCardCanManager')}
              data-testid={`can-manager-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            />
          ) : (
            <PencilSimple
              data-tooltip-id="workspace-card"
              data-tooltip-html={translate('workspaceCardCanEdit')}
              data-testid={`can-edit-${name
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            />
          )}
        </UserRoleContainer>
      </CardContainer>

      <Tooltip id="workspace-card" className="customTooltipTheme" />

      <Tooltip
        id="workspace-description"
        className="customTooltipTheme"
        style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
      />

      {showDeleteModal && (
        <ConfirmDeletionModal
          visible={showDeleteModal}
          setVisible={setShowDeleteModal}
          textToConfirm={name}
          description={translate('workspaceCardDeleteDescription')}
          errorMessage={translate('workspaceCardDeleteWrongNameError')}
          isLoading={deleteLoading}
          handleDelete={deleteWorkspace}
        />
      )}

      {deleteError && (
        <FailedModal
          visible={deleteError}
          setVisible={setDeleteError}
          errorInfo={{
            title: translate('workspaceCardDeleteErrorTitle'),
            description: translate('workspaceCardDeleteErrorDescription'),
          }}
        />
      )}

      {showNoPermissionDeleteModal && (
        <Modal
          visible={showNoPermissionDeleteModal}
          setVisible={setShowNoPermissionDeleteModal}
        >
          <ContainerModal data-testid="no-permission-delete-modal">
            <Warning size="3rem" data-testid="x-circle-icon" />

            <h1 data-testid="warning-modal-title">
              {translate('workspaceCardNoPermissionToDeleteTitle')}
            </h1>
            <p data-testid="warning-modal-description">
              {translate('workspaceCardNoPermissionToDeleteDescription')}
            </p>
          </ContainerModal>
          <ModalFooter>
            <Button
              buttonType="naked"
              data-testid="back-button"
              onClick={() => setShowNoPermissionDeleteModal(false)}
            >
              {translate('back')}
            </Button>

            <Button
              buttonType="primary"
              data-testid="navigate-control-panel-button"
              onClick={handleAccessWorkspace}
            >
              {translate('myProjectsWorkspaceControl')}
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {loadingUsers && (
        <ModalLoading
          visible={loadingUsers}
          style={{ padding: 0, width: '17.5rem' }}
          data-testid="loading-workspace-users"
        />
      )}
    </>
  );
};
