import React, { useRef } from 'react';

import { getColorByFirstLetter } from 'src/utils/colors/getColorByFirstLetter';

import { TableSerieTagContainer } from './styles';

type TableSerieTag = {
  name: string;
  value: string;
};

export const TableSerieTag: React.FC<TableSerieTag> = ({ name, value }) => {
  const textRef = useRef<HTMLSpanElement>(null);

  const showTooltip = textRef.current
    ? textRef.current.clientWidth < textRef.current.scrollWidth
    : false;

  const color = getColorByFirstLetter(value);

  return (
    <TableSerieTagContainer
      data-tooltip-id={showTooltip ? 'table-serie-tooltip' : undefined}
      data-tooltip-html={showTooltip ? value : undefined}
      data-cy={`cell-${name}-tag-${value}`}
      data-testid={`cell-${name}-tag-${value}`}
      color={color}
    >
      <span ref={textRef}>{value}</span>
    </TableSerieTagContainer>
  );
};
