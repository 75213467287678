import styled, { css } from 'styled-components';

export const UploadProjectSerieMessageContainer = styled.div`
  margin: 0 auto;
  padding: 1.5rem 2rem;
  width: min-content;

  h3 {
    text-align: center;

    color: ${({ theme }) => theme.colors.gray6};
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.6875rem;

    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;

    color: ${({ theme }) => theme.colors.gray5};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.primary};

      :visited {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

type ContainerFakeTableProps = {
  type: 'project' | 'serie';
};

export const ContainerFakeTable = styled.div<ContainerFakeTableProps>`
  position: relative;

  width: 25rem;

  margin-bottom: 3rem;

  > span {
    display: block;
    padding: 4px 1rem;
    background: ${({ theme }) => theme.colors.gray1};
    width: fit-content;
    border-radius: 9999px;

    color: ${({ theme }) => theme.colors.gray4};
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;

    text-wrap: nowrap;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  table {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    border-radius: 0.5rem;
    border-spacing: 0rem;
    overflow: hidden;

    th {
      height: 2.5rem;
      background: ${({ theme }) => theme.colors.gray0};
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }

    td {
      border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};

      > div {
        height: 4.5rem;
        padding: 1rem;
        display: flex;

        opacity: 0.7;

        label {
          padding-left: 2.125rem;
          pointer-events: none;
        }

        input {
        }

        > div {
          display: flex;
          align-items: center;
          gap: 1rem;

          img {
            width: 2rem;
            height: 2rem;
            border-radius: 3.2px;

            filter: grayscale(1);
          }

          > div {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            align-items: start;

            h4 {
              font-weight: 500;
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors.gray6};

              ${({ type }) =>
                type === 'serie' &&
                css`
                  padding: 0.25rem 0.75rem;
                  background: ${({ theme }) => theme.colors.gray6}1E;
                  border-radius: 9999px;
                `}
            }

            p {
              font-weight: 400;
              font-size: 0.75rem;

              color: ${({ theme }) => theme.colors.gray4};
            }
          }
        }
      }
    }

    tr:last-child {
      td {
        border-bottom: 0rem;
      }
    }
  }
`;
