import React, { createContext, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Plus } from 'phosphor-react';
import { ButtonRounded } from 'src/components/ButtonRounded';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useLocation } from 'react-router-dom';

import { BlueLine, Container, Line, StepsContainer } from './styles';
import { PlanningFlowContextType, PlanningFlowProps } from './types';
import { Step } from './Step';

const MAX_NUMBER_STEPS = 5;

export const PlanningFlowContext = createContext({} as PlanningFlowContextType);

export const PlanningFlow: React.FC<PlanningFlowProps> = ({
  steps,
  selectedStep,
  showErrors,
  handleAddNewStep,
  handleDeleteLastStep,
  handleRenameStep,
  handleAddUser,
  handleRemoveUser,
  handleChangeDeadline,
  handleAccessStep,
  isPublishedConfiguration = false,
}) => {
  const [isFirstSelectedStep, setIsFirstSelectedStep] = useState(true);

  const stepsContainer = useRef<HTMLDivElement>(null);

  const { t: translate } = useTranslation();
  const location = useLocation();

  const { userRole } = useSelector((state: RootState) => state.workspace);

  let activeStep = steps[steps.length - 1];
  let activeStepNumber = steps.length;

  for (let i = 0; i < steps.length; i++) {
    if (steps[i].status === 'created') {
      const previousStep = steps[i - 1];

      if (i === 0 || previousStep.status === 'approved') {
        activeStep = steps[i];
        activeStepNumber = i + 1;
        break;
      } else {
        activeStep = previousStep;
        activeStepNumber = i;
        break;
      }
    }
  }

  const stepsQtty = steps.length;

  const isPreviewConfiguration = location.pathname.endsWith(
    '/control-panel/release-configuration',
  );

  const isConfigurationMode =
    isPreviewConfiguration || isPublishedConfiguration;

  useEffect(() => {
    const scrollToEnd = () => {
      const container = stepsContainer.current;

      if (container) {
        container.scrollLeft = container.scrollWidth;
      }
    };

    const scrollToSelectedStep = () => {
      const container = stepsContainer.current;

      if (container && selectedStep && isFirstSelectedStep) {
        container.scrollLeft = (selectedStep.number - 1) * 26.625 * 16;
        setIsFirstSelectedStep(false);
      }
    };

    if (!isConfigurationMode) {
      scrollToSelectedStep();
    } else {
      scrollToEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stepsQtty,
    isConfigurationMode,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(selectedStep),
    isFirstSelectedStep,
  ]);

  const userIsManager = userRole === 'manager';

  const canAddNewStep =
    stepsQtty < MAX_NUMBER_STEPS && userIsManager && isConfigurationMode;

  const allStatus = [
    'created',
    'baseline',
    'adjusting',
    'awaiting_approval',
    'approved',
  ];

  const activeStepStatusIndex =
    allStatus.findIndex((status) => status === activeStep?.status) ?? 0;

  const selectedStepStatusIndex =
    allStatus.findIndex((status) => status === selectedStep?.status) ?? 0;

  return (
    <Container>
      <PlanningFlowContext.Provider
        value={{
          steps,
          isConfigurationMode,
          isPreviewConfiguration,
          selectedStep,
          showErrors,
          handleDeleteLastStep,
          handleRenameStep,
          handleAddUser,
          handleRemoveUser,
          handleChangeDeadline,
          handleAccessStep,
        }}
      >
        <StepsContainer ref={stepsContainer} data-testid="container-steps">
          <Line
            stepsQtty={stepsQtty}
            isConfigurationMode={isConfigurationMode}
          />

          {(!isConfigurationMode || isPublishedConfiguration) && (
            <BlueLine
              stepNumber={activeStepNumber}
              stepStatus={
                activeStepNumber === selectedStep?.number &&
                activeStepStatusIndex < selectedStepStatusIndex
                  ? selectedStep.status
                  : activeStep?.status
              }
              isConfiguration={isConfigurationMode}
            />
          )}

          {steps?.map((step, index) => (
            <React.Fragment key={step.id}>
              <Step
                id={step.id}
                index={index + 1}
                name={step.name}
                isCreatingStep={false}
                isLoadingNewStep={false}
              />
            </React.Fragment>
          ))}

          {canAddNewStep && (
            <ButtonRounded
              type="button"
              icon={<Plus />}
              onClick={handleAddNewStep}
              disabled={!canAddNewStep}
              data-testid="button-add-new-step"
              data-tooltip-id="planning-flow"
              data-tooltip-content={
                stepsQtty === 5
                  ? translate(
                      'workspaceOverviewPlanningFlowNumberMaxOfStepsTooltip',
                    )
                  : undefined
              }
            />
          )}
        </StepsContainer>
      </PlanningFlowContext.Provider>

      <Tooltip id="planning-flow" className="white-tooltip-theme" />

      <Tooltip
        id="planning-flow-users"
        className="white-tooltip-theme planning-flow-users"
      />
    </Container>
  );
};
