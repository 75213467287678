import styled from 'styled-components';

export const Container = styled.div`
  --ag-row-hover-color: ${({ theme }) => theme.colors.gray0};
  --ag-border-color: ${({ theme }) => theme.colors.gray2};
  --ag-range-selection-background-color: transparent;

  // Cor dos ícones
  .fa {
    color: ${({ theme }) => theme.colors.gray5};
  }

  // Estilização dos textos do header
  .ag-header-group-text,
  .ag-header-cell-label {
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    letter-spacing: 0.06rem !important;
    text-transform: uppercase !important;

    color: ${({ theme }) => theme.colors.gray6} !important;
  }

  // Alinhamento dos textos do header
  .ag-header-group-cell,
  .ag-header-cell-label {
    justify-content: center !important;
  }

  // Adição das bordas verticais do header
  .ag-header-group-cell {
    border-left: 1px solid ${({ theme }) => theme.colors.gray2} !important;
  }

  // Esconder "borda" do header para alterar o tamanho das colunas
  .ag-header-cell-resize {
    opacity: 0;
  }

  .ag-header-cell {
    background: ${({ theme }) => theme.colors.gray0} !important;
  }

  .ag-header-group-cell {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  // "Remoção" do ícone de menu
  .ag-header-container .ag-header-cell-menu-button {
    display: none;
  }

  // Remoção das bordas horizontais das linhas
  .ag-row {
    border: none;
  }

  .ag-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Estilização da altura da primeira coluna
  .ag-cell-auto-height {
    display: flex;
    align-items: center;

    > div {
      width: 100%;
    }
  }

  // Estilização dos textos do body
  .ag-cell-value {
    font-size: 0.75rem !important;
    font-weight: 400 !important;

    color: ${({ theme }) => theme.colors.gray7} !important;

    text-align: center;
  }

  // Margem entre o ícone de expandir e o texto
  .ag-group-expanded,
  .ag-group-contracted {
    margin-right: 0.5rem;
  }

  .ag-pinned-left-header {
    border: none !important;
  }

  // Remoção da borda dos valores da coluna fixada
  .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(
      .ag-cell-range-single-cell
    ) {
    border-right: none;
  }

  // Adição da borda direita na coluna fixada
  .ag-center-cols-viewport,
  .ag-header-viewport,
  .ag-sticky-top-viewport {
    border-left: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  // Estilização das células selecionadas
  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-top:not(.ag-cell-inline-editing) {
    border-top: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-right:not(.ag-cell-inline-editing) {
    border-right: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-left:not(.ag-cell-inline-editing) {
    border-left: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }

  .ag-cell.ag-cell-range-selected:not(
      .ag-cell-range-single-cell
    ).ag-cell-range-bottom:not(.ag-cell-inline-editing) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }

  .ag-cell.ag-cell-range-selected.ag-cell-range-single-cell:not(
      .ag-cell-inline-editing
    ) {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }

  .ag-cell-range-selected {
    background-color: transparent;
  }

  .ag-range-handle {
    height: 6px !important;
  }

  // Estilização do background quando clicamos para copiar os valores
  .ag-cell-highlight {
    background-color: ${({ theme }) => `${theme.colors.primary}0A`} !important;
  }

  // Estilização do ícone de filtro
  span .ag-icon-filter {
    color: ${({ theme }) => theme.colors.gray5};
  }

  // Estilização dos checkbox
  .ag-checked::after {
    color: ${({ theme }) => theme.colors.primary};
  }

  .ag-indeterminate::after {
    color: ${({ theme }) => theme.colors.gray4};
  }

  .ag-checkbox-label {
    color: ${({ theme }) => theme.colors.gray7};
  }

  // Estilização das setas da árvore
  .ag-icon-tree-indeterminate,
  .ag-icon-tree-open,
  .ag-icon-tree-closed {
    color: ${({ theme }) => theme.colors.gray7};
  }

  // Remoção do ícone de filtro que aparece no nome da coluna ao fazer uma filtragem
  .ag-filter-icon {
    display: none;
  }

  // Estilização do menu de filtro
  .ag-filter {
    background: ${({ theme }) => theme.colors.white};
  }

  .ag-filter-virtual-list-item {
    height: fit-content !important;
  }

  // Estilização dos menus
  .ag-tabs.ag-menu {
    border: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  .ag-menu-header {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  // Estilização dos inputs
  .ag-input-field-input {
    border: 1px solid ${({ theme }) => theme.colors.gray2} !important;

    ::placeholder {
      color: ${({ theme }) => theme.colors.gray4};
    }

    :focus {
      box-shadow: none !important;
    }

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.gray3} !important;
    }
  }

  // Estilização do menu do click direito na célula
  .ag-menu {
    width: 13rem;

    background-color: ${({ theme }) => theme.colors.white};

    border: 1px solid ${({ theme }) => theme.colors.gray2};
    box-shadow: 0px 0px 8px 0px rgba(113, 128, 150, 0.24);
  }

  .ag-menu-option-text {
    color: ${({ theme }) => theme.colors.gray6};

    font-weight: 400;
  }

  .ag-menu-option-shortcut {
    color: ${({ theme }) => theme.colors.gray4};

    font-size: 0.75rem;
    font-weight: 400;

    text-align: end;
  }

  .ag-menu-option-active {
    background-color: ${({ theme }) => `${theme.colors.primary}14`};
  }

  .ag-icon-copy {
    color: ${({ theme }) => theme.colors.gray5};
  }

  // Estilização do input de edição de valores
  .ag-cell-inline-editing {
    border: 1px solid ${({ theme }) => theme.colors.purple4} !important;
    box-shadow: 0px 0px 16px 0px rgba(159, 122, 234, 0.24) !important;
  }
`;
