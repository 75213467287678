import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Tooltip } from 'react-tooltip';
import { Pagination } from 'src/components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import apiWorkspace from 'src/workspaces/service/api';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import {
  CardBody,
  CardContainer,
  CardContent,
} from 'src/components/ProjectCard/styles';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { clear } from 'src/workspaces/redux/reducers/Workspace';
import { AutoComplete } from 'src/components/AutoComplete';
import { MagnifyingGlass } from 'phosphor-react';
import { Status } from 'src/components/Status';
import {
  clearWorkspaceHomeOptions,
  saveWorkspaceHomeOptions,
} from 'src/workspaces/redux/reducers/WorkspaceHomeOptions';
import { Card } from 'src/components/Card';
import { useQueryProjects } from 'src/models/hooks/useQueryProjects';
import { useQuerySeriesGroupedByTag } from 'src/workspaces/hooks/useQuerySeriesGroupedByTag';

import { Workspace, WorkspaceSearchErrorProps } from './types';
import {
  ActionsContainer,
  ContainerWithoutGroups,
  DivSearchBox,
  List,
} from './styles';
import { WorkspaceContainer, WorkspaceHead } from '../WorkspaceCard/styles';
import { WorkspaceCard } from '../WorkspaceCard';

const QUANTITY_ITEMS_PER_PAGE = 6;

const WORKSPACE_NAME_LOCAL_STORAGE = 'workspaceNames';

export const ListWorkspaces: React.FC = () => {
  const {
    auth: { user },
    workspaceHomeOptions,
  } = useSelector((state: RootState) => state);

  const [workspaces, setWorkspaces] = useState<Workspace>();
  const [page, setPage] = useState(1);
  const [workspaceTotal, setWorkspaceTotal] = useState<number>();

  const [searchValue, setSearchValue] = useState<string>('');
  const [lastSearch, setLastSearch] = useState('');
  const [oldWorkspaceNames, setOldWorkspaceNames] = useState<string[]>([]);
  const [searchError, setSearchError] = useState<WorkspaceSearchErrorProps>();
  const [requestAllowed, setRequestAllowed] = useState(true);

  const { t: translate } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { projectsData, projectsIsLoading } = useQueryProjects({
    projectType: 'modelling',
    engineName: 'bergman',
    engineVersion: '0.5.2',
    limit: 1,
  });

  const { seriesGroupedByTagData, seriesGroupedByTagIsLoading } =
    useQuerySeriesGroupedByTag({
      searchValue: '',
      requestAllowed: true,
    });

  useEffect(() => {
    if (workspaceHomeOptions.page || workspaceHomeOptions.search) {
      if (workspaceHomeOptions.page) {
        setPage(workspaceHomeOptions.page);
      }
      if (workspaceHomeOptions.search) {
        setSearchValue(workspaceHomeOptions.search);
      }

      dispatch(clearWorkspaceHomeOptions());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const userHasPermissionCreateWorkspace = user.permissions.find(
    (permission) => permission === 'create:workspaces',
  );

  const searchAdjusted =
    searchValue.length >= 3 && searchValue.length <= 50 ? searchValue : '';

  const {
    data: workspaceData,
    isLoading,
    isFetching,
    isError,
  } = useQuery(
    ['workspaces', searchAdjusted, page],
    async () => {
      const { data } = await apiWorkspace.get<Workspace>(
        `/workspaces?workspace_name=${encodeURIComponent(
          searchAdjusted,
        )}&skip=${
          (page - 1) * QUANTITY_ITEMS_PER_PAGE
        }&limit=${QUANTITY_ITEMS_PER_PAGE}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60,
      onSuccess(data) {
        if (
          searchValue &&
          searchValue.length >= 3 &&
          searchValue.length <= 50 &&
          data.total
        ) {
          saveWorkspaceNameInLocalStorage();
        }
      },
      enabled: requestAllowed || searchValue === '',
    },
  );

  useEffect(() => {
    if (workspaceData) {
      setWorkspaces(workspaceData);
    }
    setRequestAllowed(false);
    setLastSearch(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (searchValue.length >= 3 && searchValue.length <= 50) {
      interval = setTimeout(() => {
        setRequestAllowed(true);
      }, 1000);
    }

    return () => {
      if (interval) {
        setRequestAllowed(false);
        clearInterval(interval);
      }
    };
  }, [searchValue]);

  useEffect(() => {
    if (workspaces) {
      const total = workspaces.total;

      setWorkspaceTotal(total);

      if (!!total && Math.ceil(total / 6) < page) {
        setPage(Math.ceil(total / 6));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces]);

  function saveWorkspaceNameInLocalStorage() {
    if (!oldWorkspaceNames.includes(searchValue)) {
      const userProjectNames = [searchValue, ...oldWorkspaceNames];

      const allProjects = JSON.parse(
        localStorage.getItem(WORKSPACE_NAME_LOCAL_STORAGE) ?? '{}',
      );

      if (user.email) {
        localStorage.setItem(
          WORKSPACE_NAME_LOCAL_STORAGE,
          JSON.stringify({
            ...allProjects,
            [user.email]: userProjectNames,
          }),
        );

        setOldWorkspaceNames(userProjectNames);
      }
    }
  }

  const getWorkspaceNamesInLocalStorage = useCallback((): string[] => {
    const allWorkspaceNames = localStorage.getItem(
      WORKSPACE_NAME_LOCAL_STORAGE,
    );

    if (allWorkspaceNames && user.email) {
      const userWorkspaceNames = JSON.parse(allWorkspaceNames)?.[
        user.email
      ] as string[];

      if (userWorkspaceNames) {
        return userWorkspaceNames;
      }
    }

    return [];
  }, [user.email]);

  useEffect(() => {
    setOldWorkspaceNames(getWorkspaceNamesInLocalStorage());
  }, [getWorkspaceNamesInLocalStorage]);

  function saveSearchAndPageInRedux() {
    if (page !== 1 || searchValue) {
      dispatch(
        saveWorkspaceHomeOptions({
          page,
          search: searchValue,
        }),
      );
    }
  }

  function handleSearchProject(value: string) {
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return;
    }

    if (value.length < 3 && value.length > 0) {
      setSearchError({
        message: 'searchMinCharactersError',
        quantityLetters: 3,
      });
      return;
    }
    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      value.length >= 3 && setPage(1);
    }
  }

  const handleChangePage = (pageAux: number): void => {
    setRequestAllowed(true);
    setPage(pageAux);
  };

  function deleteWorkspaceNameInLocalStorage(index: number) {
    const userWorkspaceNames = [...oldWorkspaceNames];

    userWorkspaceNames.splice(index, 1);

    const allWorkspaces = JSON.parse(
      localStorage.getItem(WORKSPACE_NAME_LOCAL_STORAGE) ?? '{}',
    );

    if (user.email) {
      localStorage.setItem(
        WORKSPACE_NAME_LOCAL_STORAGE,
        JSON.stringify({
          ...allWorkspaces,
          [user.email]: userWorkspaceNames,
        }),
      );

      setOldWorkspaceNames(userWorkspaceNames);
    }
  }

  const oldWorkspaceNamesShow = getWorkspaceNamesInLocalStorage().filter(
    (oldWorkspaceNamesAux) =>
      searchValue !== oldWorkspaceNamesAux &&
      oldWorkspaceNamesAux.includes(searchValue),
  );

  const canUserCreateWorkspace =
    !!projectsData?.total || Object.keys(seriesGroupedByTagData ?? {}).length;
  const isCheckingIfUserCanCreateWorkspace =
    projectsIsLoading && seriesGroupedByTagIsLoading && !canUserCreateWorkspace;

  return (
    <>
      <DivSearchBox>
        <Card
          textCard={translate('workspaceTitle')}
          textDescription={translate('workspaceDescription')}
        />
        <div>
          <AutoComplete
            icon={<MagnifyingGlass size="1.25rem" />}
            testid="searchWorkspace"
            placeholder={translate('searchWorkspace')}
            onChange={(event) => {
              handleSearchProject(event);
              setPage(1);
            }}
            error={
              searchError?.quantityLetters === 3
                ? translate(searchError.message).replace('XX', '3')
                : searchError?.quantityLetters === 50
                ? translate(searchError.message).replace('XX', '50')
                : undefined
            }
            options={oldWorkspaceNamesShow}
            value={searchValue}
            onDelete={deleteWorkspaceNameInLocalStorage}
            className="inputAdjustPadding"
          />

          <Tooltip
            id="workspace-button-create"
            className="customTooltipTheme"
          />
          <ActionsContainer>
            {userHasPermissionCreateWorkspace && (
              <div
                data-tooltip-id="workspace-button-create"
                data-tooltip-html={
                  !canUserCreateWorkspace && !isCheckingIfUserCanCreateWorkspace
                    ? translate(
                        'workspaceCreateNewProjectToStartOrUploadYourSerie',
                      )
                    : undefined
                }
              >
                <Button
                  buttonType="primary"
                  onClick={() => {
                    dispatch(clear());
                    navigate('/workspaces/new');
                  }}
                  data-cy="button-create-workspace"
                  data-testid="button-create-workspace"
                  disabled={!canUserCreateWorkspace}
                  loading={isCheckingIfUserCanCreateWorkspace}
                >
                  <p>{translate('myProjectsCreateWorkspace')}</p>
                </Button>
              </div>
            )}
          </ActionsContainer>
          <Tooltip
            id="models-my-projects-create-project"
            className="customTooltipTheme"
          />
        </div>
      </DivSearchBox>

      {isError ? (
        <ContainerMaintenance
          content="workspaces"
          text={translate('fetchWorkspacesError')}
          data-testid="workspace-error"
        />
      ) : workspaceData?.total === 0 && searchValue.length >= 1 ? (
        <ContainerWithoutGroups>
          <Status
            type="noSearchResults"
            title={`${translate('searchWorkspaceFail')} "${lastSearch}".`}
          />
        </ContainerWithoutGroups>
      ) : isLoading || isFetching || !workspaceData || !workspaces ? (
        // eslint-disable-next-line react/jsx-indent
        <>
          <List>
            {Array.from({ length: 6 }, (_, number) => number).map((number) => (
              <CardContainer
                key={`loading-${number}`}
                data-testid={`loading-workspace-${number}`}
              >
                <WorkspaceContainer>
                  <CardContent>
                    <WorkspaceHead>
                      <div>
                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '4rem',
                            height: '4rem',
                          }}
                        />

                        <ContainerSkeleton
                          withLoading={false}
                          style={{
                            width: '80%',
                            height: '1.688rem',
                          }}
                        />
                      </div>
                    </WorkspaceHead>

                    <CardBody className="workspace-body">
                      <span className="workspace-description-title">
                        {translate('description')}
                      </span>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '90%',
                          height: '1.313rem',
                          marginTop: '0.5rem',
                        }}
                      />
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '45%',
                          height: '1.313rem',
                          marginTop: '0.5rem',
                        }}
                      />

                      <span>{translate('projectHeadLastUpdated')}</span>
                      <ContainerSkeleton
                        withLoading={false}
                        style={{
                          width: '65%',
                          height: '1.313rem',
                          marginTop: '0.5rem',
                        }}
                      />
                    </CardBody>
                  </CardContent>
                </WorkspaceContainer>
              </CardContainer>
            ))}
          </List>
          {!!workspaceTotal && (
            <Pagination
              name="workspaces"
              page={page}
              setPage={setPage}
              total={workspaceTotal}
              quantityItemsPerPage={QUANTITY_ITEMS_PER_PAGE}
            />
          )}
        </>
      ) : workspaces.total > 0 ? (
        <>
          <List>
            {workspaces.records.map((card) => (
              <WorkspaceCard
                card={card}
                key={card.id}
                saveSearchAndPage={saveSearchAndPageInRedux}
              />
            ))}
          </List>

          <Pagination
            name="workspaces"
            page={page}
            setPage={handleChangePage}
            total={workspaces.total}
            quantityItemsPerPage={QUANTITY_ITEMS_PER_PAGE}
          />
        </>
      ) : (
        <ContainerMaintenance
          content="workspaces"
          text={translate('workspaceErrorNoWorkspaceFound')}
          data-testid="container-without-workspaces"
          style={{ height: '25rem' }}
        />
      )}
    </>
  );
};
