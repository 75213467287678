import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { Head } from 'src/components/Head';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/Table';
import { RootState } from 'src/redux/store';

import { Container, ContainerModelSummary } from './styles';
import { ModelsSorting } from './components/ModelsSorting';
import { ForecastDispersion } from './components/ForecastDispersion';

export const ModelOverview: React.FC = () => {
  const { project } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  return (
    <Container>
      <Head title={translate('modelOverviewTitle')} />
      <Card
        textCard={translate('modelOverviewTitle')}
        textDescription={translate('modelOverviewTitleDescription')}
        className="containerLinear"
      />
      <section>
        <ContainerModelSummary
          className="containerLinear"
          data-testid="container-model-summary"
        >
          <Card textCard={translate('modelOverviewSummary')} />
          {project.projectError ? (
            <Table data-testid="container-model-summary-table-error">
              <Thead>
                <Tr>
                  <Th>{translate('modelOverviewType')}</Th>
                  <Th>{translate('modelOverviewQuantity')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>--</Td>
                  <Td>--</Td>
                </Tr>
              </Tbody>
            </Table>
          ) : project.model ? (
            <Table data-testid="container-model-summary-table">
              <Thead>
                <Tr>
                  <Th>{translate('modelOverviewType')}</Th>
                  <Th>{translate('modelOverviewQuantity')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {project.model.model_summary.map((summary, index) => (
                  <Tr key={`summary-${index.toString()}`}>
                    <Td
                      data-cy={`models-summary-model-type-${summary.type
                        .toLowerCase()
                        .replaceAll(' ', '-')}`}
                    >
                      {summary.type === 'ARIMA'
                        ? summary.type
                        : summary.type === 'Forecast Combination'
                        ? `${translate(summary.type.replace(' ', '_'))}`
                        : summary?.type === 'Regularized Regression'
                        ? `${translate(summary.type.replace(' ', '_'))}`
                        : summary?.type === 'Tree Models'
                        ? `${translate(summary.type.replace(' ', '_'))}`
                        : summary?.type === 'Elementary'
                        ? `${translate(summary.type)}`
                        : summary.type}
                    </Td>
                    <Td
                      data-cy={`models-summary-quantity-${summary.type
                        .toLowerCase()
                        .replaceAll(' ', '-')}`}
                    >
                      {summary.quantity}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <ContainerSkeleton
              data-testid="container-model-summary-loading"
              style={{ height: '394px' }}
            />
          )}
        </ContainerModelSummary>
        <ModelsSorting />
      </section>
      <ForecastDispersion />
    </Container>
  );
};
