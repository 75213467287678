import ContainerMaintenanceENTranslations from 'src/components/ContainerMaintenance/translate/en-us';
import ContainerMaintenancePTBRTranslations from 'src/components/ContainerMaintenance/translate/pt-br';
import footerENTranslations from 'src/components/Layout/Footer/translations/en';
import footerPTBRTranslations from 'src/components/Layout/Footer/translations/pt-br';
import layoutENTranslations from 'src/components/Layout/translations/en';
import layoutPTBRTranslations from 'src/components/Layout/translations/pt-br';
import paginationENTranslations from 'src/components/Pagination/translations/en-us';
import paginationPTBRTranslations from 'src/components/Pagination/translations/pt-br';
import SelectENTranslations from 'src/components/Select/translate/en';
import SelectPTBRTranslations from 'src/components/Select/translate/pt-br';
import { sideBarFeatureStoreENTranslate } from 'src/feature-store/components/Layout/SideBar/translate/en';
import { sideBarFeatureStorePTBRTranslate } from 'src/feature-store/components/Layout/SideBar/translate/pt-br';
import reviewCreateSeriesENTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/CreateSeries/ReviewSeries/translate/en';
import reviewCreateSeriesPTBRTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/CreateSeries/ReviewSeries/translate/pt-br';
import createSeriesENTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/CreateSeries/translate/en';
import createSeriesPTBRTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/CreateSeries/translate/pt-br';
import editUnitENTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/modalEditSerie/translate/en';
import editUnitPTBRTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/modalEditSerie/translate/pt-br';
import indicatorSeriesENTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/translate/en';
import indicatorSeriesPTBRTranslate from 'src/feature-store/pages/Admin/IndicatorSeries/translate/pt-br';
import creategGroupModalENTranslate from 'src/feature-store/pages/Groups/components/CreateGroupModal/translate/en';
import creategGroupModalPTBRTranslate from 'src/feature-store/pages/Groups/components/CreateGroupModal/translate/pt-br.';
import deleteGroupModalENTranslate from 'src/feature-store/pages/Groups/components/DeleteGroupModal/translate/en';
import deleteGroupModalPTBRTranslate from 'src/feature-store/pages/Groups/components/DeleteGroupModal/translate/pt-br';
import groupsENTranslate from 'src/feature-store/pages/Groups/translate/en';
import groupsPTBRTranslate from 'src/feature-store/pages/Groups/translate/pt-br';
import preDefinedSeriesEN from 'src/feature-store/pages/GroupView/translations/en';
import preDefinedSeriesPTBR from 'src/feature-store/pages/GroupView/translations/pt-br';
import indicatorsENTranslate from 'src/feature-store/pages/Indicators/translate/en';
import indicatorsPTBRTranslate from 'src/feature-store/pages/Indicators/translate/pt-br';
import exportSeriesENTranslate from 'src/feature-store/pages/VisualizeFeatures/components/ExportSeries/translations/en';
import exportSeriesPTBR from 'src/feature-store/pages/VisualizeFeatures/components/ExportSeries/translations/pt-br';
import filterIndicatorSeriesENTranslations from 'src/feature-store/pages/VisualizeFeatures/components/FilterIndicatorSeries/translations/en';
import filterIndicatorSeriesPTBRTranslations from 'src/feature-store/pages/VisualizeFeatures/components/FilterIndicatorSeries/translations/pt-br';
import sendToGroupENTranslations from 'src/feature-store/pages/VisualizeFeatures/components/SendToGroups/translations/en';
import sendToGroupPTBRTranslations from 'src/feature-store/pages/VisualizeFeatures/components/SendToGroups/translations/pt-br';
import viewFeaturesEN from 'src/feature-store/pages/VisualizeFeatures/translations/en';
import viewFeaturesPTBR from 'src/feature-store/pages/VisualizeFeatures/translations/pt-br';
import classificationSidebarENUSTranslations from 'src/models/components/Layout/ClassificationSideBar/translations/en-us';
import classificationSidebarPTBRTranslations from 'src/models/components/Layout/ClassificationSideBar/translations/pt-br';
import menuSideBarENTranslations from 'src/models/components/Layout/ProjectSideBar/translations/en';
import menuSideBarPTBRTranslations from 'src/models/components/Layout/ProjectSideBar/translations/pt-br';
import confusionMatrixENUSTranslations from 'src/models/pages/Classification/components/ConfusionMatrix/translations/en-us';
import confusionMatrixPTBRTranslations from 'src/models/pages/Classification/components/ConfusionMatrix/translations/pt-br';
import modelPerformanceENUSTranslations from 'src/models/pages/Classification/components/ModelPerformance/translations/en-us';
import modelPerformancePTBRTranslations from 'src/models/pages/Classification/components/ModelPerformance/translations/pt-br';
import ROCCurveENUSTranslations from 'src/models/pages/Classification/components/RocCurves/translations/en-us';
import ROCCurvePTBRTranslations from 'src/models/pages/Classification/components/RocCurves/translations/pt-br';
import modelInfoENUSTranslations from 'src/models/pages/Classification/ModelExplorer/components/ModelInfo/translations/en-us';
import modelInfoPTBRTranslations from 'src/models/pages/Classification/ModelExplorer/components/ModelInfo/translations/pt-br';
import modelExplorerENUSTranslations from 'src/models/pages/Classification/ModelExplorer/translations/en-us';
import modelExplorerPTBRTranslations from 'src/models/pages/Classification/ModelExplorer/translations/pt-br';
import claasModelInProductionENUSTranslations from 'src/models/pages/Classification/ModelInProduction/translations/en-us';
import claasModelInProductionPTBRTranslations from 'src/models/pages/Classification/ModelInProduction/translations/pt-br';
import predictionsENUSTranslations from 'src/models/pages/Classification/components/Predictions/translations/en-us';
import predictionsPTBRTranslations from 'src/models/pages/Classification/components/Predictions/translations/pt-br';
import correlationMatrixEN from 'src/models/pages/DataView/CorrelationMatrix/translations/en';
import correlationMatrixPTBR from 'src/models/pages/DataView/CorrelationMatrix/translations/pt-br';
import overviewEN from 'src/models/pages/DataView/Overview/translations/en';
import overviewPTBR from 'src/models/pages/DataView/Overview/translations/pt-br';
import variableImportanceEN from 'src/models/pages/DataView/VariableImportance/translations/en';
import variableImportancePTBR from 'src/models/pages/DataView/VariableImportance/translations/pt-br';
import ClaaSRequirementsENUSTranslations from 'src/models/pages/Modelling/Classification/components/Modals/RequirementsModal/translations/en-us';
import ClaaSRequirementsPTBRTranslations from 'src/models/pages/Modelling/Classification/components/Modals/RequirementsModal/translations/pt-br';
import step1ClaaSENUSTranslations from 'src/models/pages/Modelling/Classification/components/Step1/translations/en-us';
import step1ClaaSPTBRTranslations from 'src/models/pages/Modelling/Classification/components/Step1/translations/pt-br';
import { Step2ClaaSENUSTranslations } from 'src/models/pages/Modelling/Classification/components/Step2/translations/en-us';
import { Step2ClaaSPTBRTranslations } from 'src/models/pages/Modelling/Classification/components/Step2/translations/pt-br';
import Step3ClaaSENUSTranslations from 'src/models/pages/Modelling/Classification/components/Step3/translations/en-us';
import Step3ClaaSPTBRTranslations from 'src/models/pages/Modelling/Classification/components/Step3/translations/pt-br';
import ClassificationModellingENUSTranslations from 'src/models/pages/Modelling/Classification/translations/en-us';
import ClassificationModellingPTBRTranslations from 'src/models/pages/Modelling/Classification/translations/pt-br';
import FeatureStoreEN from 'src/models/pages/Modelling/TimeSeries/components/FeatureStore/translations/en-us';
import FeatureStorePTBR from 'src/models/pages/Modelling/TimeSeries/components/FeatureStore/translations/pt-br';
import inputFileENTranslations from 'src/models/pages/Modelling/TimeSeries/components/InputFile/translations/en';
import inputFilePTBRTranslations from 'src/models/pages/Modelling/TimeSeries/components/InputFile/translations/pt-br';
import { conclusionModalENTranslations } from 'src/models/pages/Modelling/TimeSeries/components/Modal/Conclusion/translations/en-us';
import { conclusionModalPTBRTranslations } from 'src/models/pages/Modelling/TimeSeries/components/Modal/Conclusion/translations/pt-br';
import featureSelectionENUSTranslations from 'src/models/pages/Modelling/TimeSeries/components/Modal/FeatureSelection/translations/en-us';
import featureSelectionPTBRTranslations from 'src/models/pages/Modelling/TimeSeries/components/Modal/FeatureSelection/translations/pt-br';
import lagsModalENUSTranslations from 'src/models/pages/Modelling/TimeSeries/components/Modal/LagsModal/translations/en-us';
import lagsModalPTBRTranslations from 'src/models/pages/Modelling/TimeSeries/components/Modal/LagsModal/translations/pt-br';
import modalAdvancedENUSTranslations from 'src/models/pages/Modelling/TimeSeries/components/Modal/ModalAdvanced/translations/en-us';
import modalAdvancedPTBRTranslations from 'src/models/pages/Modelling/TimeSeries/components/Modal/ModalAdvanced/translations/pt-br';
import { modalWarningNWindowsENUSTranslate } from 'src/models/pages/Modelling/TimeSeries/components/Modal/ModalWarningNWindows/translate/en-us';
import { modalWarningNWindowsPTBRTranslate } from 'src/models/pages/Modelling/TimeSeries/components/Modal/ModalWarningNWindows/translate/pt-br';
import { modalSeeRequirementsTimeSeriesENTranslate } from 'src/models/pages/Modelling/TimeSeries/components/Modal/SeeRequirementsTimeSeries/translation/en';
import { modalSeeRequirementsTimeSeriesPTBRTranslate } from 'src/models/pages/Modelling/TimeSeries/components/Modal/SeeRequirementsTimeSeries/translation/pt-br';
import modellingENTranslations from 'src/models/pages/Modelling/TimeSeries/translations/en';
import modellingPTBRTranslations from 'src/models/pages/Modelling/TimeSeries/translations/pt-br';
import modellingStartENUSTranslations from 'src/models/pages/Modelling/translations/en-us';
import modellingStartPTBRTranslations from 'src/models/pages/Modelling/translations/pt-br';
import modelExclusionEn from 'src/models/pages/MyProjects/components/ModalExclusion/translations/en';
import modelExclusionPTBR from 'src/models/pages/MyProjects/components/ModalExclusion/translations/pt-br';
import MyProjectsENTranslations from 'src/models/pages/MyProjects/translations/en';
import MyProjectsPTBRTranslations from 'src/models/pages/MyProjects/translations/pt-br';
import modelCrossValidationENTranslations from 'src/models/TimeSeries/ModelExplorer/CrossValidation/translations/en-us';
import modelCrossValidationPTBRTranslations from 'src/models/TimeSeries/ModelExplorer/CrossValidation/translations/pt-br';
import arimaDispersionEN from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/ARIMA/translations/en';
import arimaDispersionPTBR from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/ARIMA/translations/pt-br';
import forecastCombinationEN from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/ForecastCombination/translations/en';
import forecastCombinationPTBR from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/ForecastCombination/translations/pt-br';
import modelOverviewENUSTranslations from 'src/models/TimeSeries/ModelExplorer/ModelOverview/translations/en';
import modelOverViewPTBRTranslations from 'src/models/TimeSeries/ModelExplorer/ModelOverview/translations/pt-br';
import modelSpecificsENUSTranslations from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/translations/en';
import modelSpecificsPTBRTranslations from 'src/models/TimeSeries/ModelExplorer/ModelSpecifics/translations/pt-br';
import commonModelExplorerENUSTranslations from 'src/models/TimeSeries/ModelExplorer/translations/en';
import commonModelExplorerPTBRTranslations from 'src/models/TimeSeries/ModelExplorer/translations/pt-br';
import modelInProductionEn from 'src/models/TimeSeries/ModelInProduction/translations/en';
import modelInProductionEnPTBR from 'src/models/TimeSeries/ModelInProduction/translations/pt-br';
import { exportProjectOverviewENUSTranslate } from 'src/models/TimeSeries/ProjectOverview/components/ExportProjectOverview/translate/en-us';
import { exportProjectOverviewPTBRTranslate } from 'src/models/TimeSeries/ProjectOverview/components/ExportProjectOverview/translate/pt-br';
import { projectOverviewProjectionsENTranslate } from 'src/models/TimeSeries/ProjectOverview/components/Projections/translate/en';
import { projectOverviewProjectionsPTTranslate } from 'src/models/TimeSeries/ProjectOverview/components/Projections/translate/pt';
import { projectOverviewENTranslate } from 'src/models/TimeSeries/ProjectOverview/translate/en';
import { projectOverviewPTBRTranslate } from 'src/models/TimeSeries/ProjectOverview/translate/pt-br';
import { page404ENTranslate } from 'src/pages/404/translate/en';
import { page404PTBRTranslate } from 'src/pages/404/translate/pt-br';
import homeENTranslations from 'src/pages/Home/translations/en';
import homePTBRTranslations from 'src/pages/Home/translations/pt-br';
import landingEN from 'src/pages/Landing/translations/en';
import landingPTBR from 'src/pages/Landing/translations/pt-br';
import { RegisterNewUserENTranslate } from 'src/pages/UserModule/AdminPanel/components/ModalRegisterNewUser/translate/en';
import { RegisterNewUserPTBRTranslate } from 'src/pages/UserModule/AdminPanel/components/ModalRegisterNewUser/translate/pt-br';
import { ModalStatusRegisterNewUserENTranslate } from 'src/pages/UserModule/AdminPanel/components/ModalSuccessRegisterUser/translate/en';
import { ModalStatusRegisterNewUserPTBRTranslate } from 'src/pages/UserModule/AdminPanel/components/ModalSuccessRegisterUser/translate/pt-br';
import { PanelAdminENTranslate } from 'src/pages/UserModule/AdminPanel/translate/en';
import { PanelAdminPTBRTranslate } from 'src/pages/UserModule/AdminPanel/translate/pt-br';
import { ModalRegisterEditCustomerENTranslate } from 'src/pages/UserModule/CustomersList/components/ModalRegisterEditCustomer/translate/en';
import { ModalRegisterEditCustomerPTTranslate } from 'src/pages/UserModule/CustomersList/components/ModalRegisterEditCustomer/translate/pt-br';
import { ListCustomersENTranslate } from 'src/pages/UserModule/CustomersList/translate/en';
import { ListCustomersPTBRTranslate } from 'src/pages/UserModule/CustomersList/translate/pt-br';
import { Language } from 'src/redux/reducers/Auth';
import AccessGroupPTBR from 'src/feature-store/pages/Admin/GroupPremium/translate/pt-br';
import AccessGroupEN from 'src/feature-store/pages/Admin/GroupPremium/translate/en-us';
import modelDetailsPTBRTRanslations from 'src/models/pages/Classification/components/ModelDetails/translations/pt-br';
import modelDetailsENUSTRanslations from 'src/models/pages/Classification/components/ModelDetails/translations/en-us';
import { WarningDailyProjectPTTranslate } from 'src/models/components/Layout/ProjectSideBar/WarningDailyProject/translate/pt';
import { WarningDailyProjectENTranslate } from 'src/models/components/Layout/ProjectSideBar/WarningDailyProject/translate/en';
import VariableImpactsENUSTranslations from 'src/models/pages/Classification/components/VariableImpacts/translations/en-us';
import VariableImpactsPTBRTranslations from 'src/models/pages/Classification/components/VariableImpacts/translations/pt-br';
import { AISelectionInformationPTTranslate } from 'src/models/TimeSeries/AISelection/components/Information/translate/pt-br';
import { AISelectionInformationENTranslate } from 'src/models/TimeSeries/AISelection/components/Information/translate/en-us';
import { AISelectionResultENTranslate } from 'src/models/TimeSeries/AISelection/components/Results/translate/en-us';
import { AISelectionResultPTTranslate } from 'src/models/TimeSeries/AISelection/components/Results/translate/pt-br';
import { AISelectionExplanatoryVariablesPTTranslate } from 'src/models/TimeSeries/AISelection/components/ExplanatoryVariables/translate/pt-br';
import { AISelectionExplanatoryVariablesENTranslate } from 'src/models/TimeSeries/AISelection/components/ExplanatoryVariables/translate/en-us';
import { AISelectionExportENTranslate } from 'src/models/TimeSeries/AISelection/components/AISelectionExport/translate/en-us';
import { AISelectionExportPTTranslate } from 'src/models/TimeSeries/AISelection/components/AISelectionExport/translate/pt-br';
import { AISelectionResultsUnderstandingENTranslate } from 'src/models/TimeSeries/AISelection/components/Results/ResultsUnderstanding/translate/en-us';
import { AISelectionResultsUnderstandingPTBRTranslate } from 'src/models/TimeSeries/AISelection/components/Results/ResultsUnderstanding/translate/pt-br';
import { IASelectionPotencialImpactsENTranslate } from 'src/models/TimeSeries/AISelection/components/PotentialImpacts/translate/en-us';
import { IASelectionPotencialImpactsPTTranslate } from 'src/models/TimeSeries/AISelection/components/PotentialImpacts/translate/pt-br';
import { AISelectionPTBRTranslate } from 'src/models/TimeSeries/AISelection/translate/pt-br';
import { AISelectionENUSTranslate } from 'src/models/TimeSeries/AISelection/translate/en-us';
import adminENTranslate from 'src/feature-store/pages/Admin/translate/en';
import adminPTBRTranslate from 'src/feature-store/pages/Admin/translate/pt-br';
import { ModalManagerENTranslate } from 'src/pages/UserModule/CustomersList/components/ModalManager/translate/en';
import { ModalManagerPTBRTranslate } from 'src/pages/UserModule/CustomersList/components/ModalManager/translate/pt-br';
import { AISelectionAnnualSummaryENTranslate } from 'src/models/TimeSeries/AISelection/components/AnnualSummary/translate/en-us';
import { AISelectionAnnualSummaryPTTranslate } from 'src/models/TimeSeries/AISelection/components/AnnualSummary/translate/pt-br';
import { modelInProductionAnnualSummaryPTTranslate } from 'src/models/TimeSeries/ModelInProduction/components/AnnualSummary/translate/pt-br';
import { modelInProductionAnnualSummaryENTranslate } from 'src/models/TimeSeries/ModelInProduction/components/AnnualSummary/translate/en-us';
import { userSelectionResultsENTranslate } from 'src/models/TimeSeries/ModelInProduction/components/Results/translate/en-us';
import { userSelectionResultsPTBRTranslate } from 'src/models/TimeSeries/ModelInProduction/components/Results/translate/pt-br';
import { premiumENTranslate } from 'src/feature-store/components/Layout/Premium/translate/en';
import { premiumPTBRTranslate } from 'src/feature-store/components/Layout/Premium/translate/pt-br';
import { forecastDispersionComponentENUSTranslate } from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/components/ForecastDispersion/translate/en-us';
import { forecastDispersionComponentPTBRTranslate } from 'src/models/TimeSeries/ModelExplorer/ModelDispersion/components/ForecastDispersion/translate/pt-br';
import modelUpdateSidebarENUSTranslations from 'src/models/components/Layout/ModelUpdateSidebar/translations/en-us';
import modelUpdateSidebarPTBRTranslations from 'src/models/components/Layout/ModelUpdateSidebar/translations/pt-br';
import ShareUpdateModalENUSTranslations from 'src/models/components/Layout/ShareUpdateModal/translations/en-us';
import ShareUpdateModalPTBRTranslations from 'src/models/components/Layout/ShareUpdateModal/translations/pt-br';
import { ConfigFiltersENUSTranslate } from 'src/models/TimeSeries/DependentVariablesConfig/components/ConfigFilters/translate/en-us';
import { ConfigFiltersPTBRTranslate } from 'src/models/TimeSeries/DependentVariablesConfig/components/ConfigFilters/translate/pt-br';
import { DependentVariablesConfigENUSTranslate } from 'src/models/TimeSeries/DependentVariablesConfig/translate/en-us';
import { DependentVariablesConfigPTBRTranslate } from 'src/models/TimeSeries/DependentVariablesConfig/translate/pt-br';
import { CreateFiltersENUSranslate } from 'src/models/TimeSeries/DependentVariablesConfig/components/CreateFilters/translate/en-us';
import { CreateFiltersPTBRranslate } from 'src/models/TimeSeries/DependentVariablesConfig/components/CreateFilters/translate/pt-br';
import { FeatureStoreVariablesENUSTranslate } from 'src/models/components/Layout/FeatureStoreVariables/translate/en-us';
import { FeatureStoreVariablesPTBRTranslate } from 'src/models/components/Layout/FeatureStoreVariables/translate/pt-br';
import ModelUpdateDocsModalENUSTranslations from 'src/models/components/Layout/Modals/ModelUpdateDocsModal/translations/en-us';
import ModelUpdateDocsModalPTBRTranslations from 'src/models/components/Layout/Modals/ModelUpdateDocsModal/translations/pt-br';
import UserConfigsPTBRTranslations from 'src/components/Layout/UserConfigs/translations/pt-br';
import UserConfigsENUSTranslations from 'src/components/Layout/UserConfigs/translations/en-us';
import { categoriesSidebarPTBRTranslate } from 'src/feature-store/components/Layout/Categories/translate/pt-br';
import { categoriesSidebarENTranslate } from 'src/feature-store/components/Layout/Categories/translate/en-us';
import { AIChatPTBRTranslations } from 'src/feature-store/pages/AIChat/translate/pt-br';
import { AIChatENUSTranslations } from 'src/feature-store/pages/AIChat/translate/en-us';
import DownloadWidgetPTBRTranslations from 'src/models/components/DownloadWidget/translations/pt-br';
import DownloadWidgetENUSTranslations from 'src/models/components/DownloadWidget/translations/en-us';
import { AdminPanelDeleteUserPTBRTranslate } from 'src/pages/UserModule/AdminPanel/components/ModalDeleteUserClient/transtale/pt-br';
import { AdminPanelDeleteUserENTranslate } from 'src/pages/UserModule/AdminPanel/components/ModalDeleteUserClient/transtale/en-us';
import { TutorialConfigPTBRTranslate } from 'src/models/TimeSeries/DependentVariablesConfig/components/Tutorial/translate/pt-br';
import { TutorialConfigENUSTranslate } from 'src/models/TimeSeries/DependentVariablesConfig/components/Tutorial/translate/en-us';
import overallPerformanceENUSTranslations from 'src/models/TimeSeries/OverAllPerformance/translations/en-us';
import variablePerformanceENUSTranslations from 'src/models/TimeSeries/VariablePerformance/translations/en-us';
import overallPerformancePTBRTranslations from 'src/models/TimeSeries/OverAllPerformance/translations/pt-br';
import variablePerformancePTBRTranslations from 'src/models/TimeSeries/VariablePerformance/translations/pt-br';
import realVsForecastPTBRTranslations from 'src/models/TimeSeries/VariablePerformance/components/RealVsForecast/translations/pt-br';
import realVsForecastENUSTranslations from 'src/models/TimeSeries/VariablePerformance/components/RealVsForecast/translations/en-us';
import { CreateWorkspaceENUSTranslations } from 'src/workspaces/pages/Create/translations/en-us';
import { CreateWorkspacePTBRTranslations } from 'src/workspaces/pages/Create/translations/pt-br';
import { modalCreateIndicatorENUSTranslations } from 'src/feature-store/pages/Admin/components/ModalCreateIndicator/translate/en-us';
import { modalCreateIndicatorPTBRTranslations } from 'src/feature-store/pages/Admin/components/ModalCreateIndicator/translate/pt-br';
import { unauthorizedSsoPTBR } from 'src/pages/UnauthorizedSSO/translations/pt-br';
import { unauthorizedSsoEN } from 'src/pages/UnauthorizedSSO/translations/en-us';
import metricsByPeriodENUSTranslations from 'src/models/TimeSeries/VariablePerformance/components/MetricsByPeriod/translations/en-us';
import metricsByPeriodPTBRTranslations from 'src/models/TimeSeries/VariablePerformance/components/MetricsByPeriod/translations/pt-br';
import projectionsVariationENUSTranslations from 'src/models/TimeSeries/VariablePerformance/components/ProjectionsVariation/translations/en-us';
import projectionsVariationPTBRTranslations from 'src/models/TimeSeries/VariablePerformance/components/ProjectionsVariation/translations/pt-br';
import { performanceByPeriodTableENUSTranslations } from 'src/models/TimeSeries/OverAllPerformance/components/PerformanceByPeriod/translations/en-us';
import { performanceByPeriodTablePTBRTranslations } from 'src/models/TimeSeries/OverAllPerformance/components/PerformanceByPeriod/translations/pt-br';
import { ShareWorkSpaceENUSTranslations } from 'src/workspaces/pages/ControlPanel/components/Modal/ShareWorkSpace/translations/en-us';
import { ShareWorkSpacePTBRranslations } from 'src/workspaces/pages/ControlPanel/components/Modal/ShareWorkSpace/translations/pt-br';
import { WorkspaceCardENUSTranslations } from 'src/workspaces/pages/Home/components/WorkspaceCard/translations/en-us';
import { WorkspaceCardPTBRTranslations } from 'src/workspaces/pages/Home/components/WorkspaceCard/translations/pt-br';
import { WorkspaceOverviewENUSTranslation } from 'src/workspaces/pages/Overview/translate/en-us';
import { WorkspaceOverviewPTBRTranslation } from 'src/workspaces/pages/Overview/translate/pt-br';
import workspaceSideBarENUSTranslations from 'src/workspaces/components/Layout/SideBar/translations/en-us';
import workspaceSideBarPTBRTranslations from 'src/workspaces/components/Layout/SideBar/translations/pt-br';
import { WorkspaceControlPanelENUSTranslations } from 'src/workspaces/pages/ControlPanel/translations/en-us';
import { WorkspaceControlPanelPTBRTranslations } from 'src/workspaces/pages/ControlPanel/translations/pt-br';
import { workspaceProjectionsResultENTranslate } from 'src/workspaces/pages/Projections/components/Results/translate/en-us';
import { workspaceProjectionsResultsUnderstandingENTranslate } from 'src/workspaces/pages/Projections/components/Results/ResultsUnderstanding/translate/en-us';
import { workspaceProjectionsResultPTTranslate } from 'src/workspaces/pages/Projections/components/Results/translate/pt-br';
import { workspaceProjectionsResultsUnderstandingPTBRTranslate } from 'src/workspaces/pages/Projections/components/Results/ResultsUnderstanding/translate/pt-br';
import { workspaceProjectionsPotencialImpactsPTTranslate } from 'src/workspaces/pages/Projections/components/PotentialImpacts/translate/pt-br';
import { workspaceProjectionsPotencialImpactsENTranslate } from 'src/workspaces/pages/Projections/components/PotentialImpacts/translate/en-us';
import { workspaceProjectionsExplanatoryVariablesPTTranslate } from 'src/workspaces/pages/Projections/components/ExplanatoryVariables/translate/pt-br';
import { workspaceProjectionsExplanatoryVariablesENTranslate } from 'src/workspaces/pages/Projections/components/ExplanatoryVariables/translate/en-us';
import { workspaceProjectionsAnnualSummaryPTTranslate } from 'src/workspaces/pages/Projections/components/AnnualSummary/translate/pt-br';
import { workspaceProjectionsAnnualSummaryENTranslate } from 'src/workspaces/pages/Projections/components/AnnualSummary/translate/en-us';
import { workspaceProjectionsPTBRTranslate } from 'src/workspaces/pages/Projections/translate/pt-br';
import { workspaceProjectionsENUSTranslate } from 'src/workspaces/pages/Projections/translate/en-us';
import { VersionInfoENUSTranslations } from 'src/components/VersionInfo/translations/en-us';
import { VersionInfoPTBRTranslations } from 'src/components/VersionInfo/translations/pt-br';
import { workspaceProjectionsExportENTranslate } from 'src/workspaces/pages/Projections/components/Export/translate/en-us';
import { workspaceProjectionsExportPTTranslate } from 'src/workspaces/pages/Projections/components/Export/translate/pt-br';
import { FiltersSidebarENUSTranslation } from 'src/components/FiltersSidebar/translate/en-us';
import { FiltersSidebarPTBRTranslation } from 'src/components/FiltersSidebar/translate/pt-br';
import { workspacePerformanceByPeriodTableENUSTranslations } from 'src/workspaces/pages/OverallPerformance/components/PerformanceByPeriod/translations/en-us';
import { workspacePerformanceByPeriodTablePTBRTranslations } from 'src/workspaces/pages/OverallPerformance/components/PerformanceByPeriod/translations/pt-br';
import { workspaceRealVsForecastENUSTranslations } from 'src/workspaces/pages/VariablePerformance/components/RealVsForecast/translations/en-us';
import { workspaceRealVsForecastPTBRTranslations } from 'src/workspaces/pages/VariablePerformance/components/RealVsForecast/translations/pt-br';
import { workspaceMetricsByPeriodENUSTranslations } from 'src/workspaces/pages/VariablePerformance/components/MetricsByPeriod/translations/en-us';
import { workspaceMetricsByPeriodPTBRTranslations } from 'src/workspaces/pages/VariablePerformance/components/MetricsByPeriod/translations/pt-br';
import { workspaceOverallPerformancePTBRTranslations } from 'src/workspaces/pages/OverallPerformance/translations/pt-br';
import { workspaceOverallPerformanceENUSTranslations } from 'src/workspaces/pages/OverallPerformance/translations/en-us';
import { workspaceVariablePerformanceENUSTranslations } from 'src/workspaces/pages/VariablePerformance/translations/en-us';
import { workspaceVariablePerformancePTBRTranslations } from 'src/workspaces/pages/VariablePerformance/translations/pt-br';
import { AgGridTableENUSTranslations } from 'src/components/AgGridTable/translations/en-us';
import { AgGridTablePTBRTranslations } from 'src/components/AgGridTable/translations/pt-br';
import { sectionButtonFavoriteENUSTranslate } from 'src/feature-store/pages/GroupView/components/SectionButton/translations/en-us';
import { sectionButtonFavoritePTBRTranslate } from 'src/feature-store/pages/GroupView/components/SectionButton/translations/pt-br';
import { dataTableENUSTranslate } from 'src/feature-store/pages/GroupView/DataTable/translations/en-us';
import { dataTablePTBRTranslate } from 'src/feature-store/pages/GroupView/DataTable/translations/pt-br';
import { subscribeToAlertENUSTranslate } from 'src/feature-store/components/SubscribeToAlert/translations/en-us';
import { subscribeToAlertPTBRTranslate } from 'src/feature-store/components/SubscribeToAlert/translations/pt-br';
import { buttonSyncAdjustPTBRTranslate } from 'src/workspaces/components/ButtonSyncAdjust/translate/pt-br';
import { buttonSyncAdjustENUSTranslate } from 'src/workspaces/components/ButtonSyncAdjust/translate/en-us';
import { homeFeatureStoreENUS } from 'src/feature-store/pages/HomeFeatureStore/translations/en-us';
import { homeFeatureStorePTBR } from 'src/feature-store/pages/HomeFeatureStore/translations/pt-br';
import { favoriteStatusPTTranslate } from 'src/feature-store/components/FavoriteStatus/translate/pt-br';
import { favoriteStatusENUSTranslate } from 'src/feature-store/components/FavoriteStatus/translate/en-us';
import { projectionTagPTBRTranslate } from 'src/feature-store/components/ProjectionTag/translate/pt-br';
import { projectionTagENUSranslate } from 'src/feature-store/components/ProjectionTag/translate/en-us';
import { workspaceHomePTBRTranslations } from 'src/workspaces/pages/Home/translations/pt-br';
import { workspaceHomeENUSTranslations } from 'src/workspaces/pages/Home/translations/en-us';
import { WorkspaceNewReleaseConfigurationENUSTranslations } from 'src/workspaces/pages/NewReleaseConfiguration/translations/en-us';
import { WorkspaceNewReleaseConfigurationPTBRTranslations } from 'src/workspaces/pages/NewReleaseConfiguration/translations/pt-br';
import { WorkspacePlanningFlowPTBRTranslations } from 'src/workspaces/components/PlanningFlow/translations/pt-br';
import { WorkspacePlanningFlowENUSTranslations } from 'src/workspaces/components/PlanningFlow/translations/en-us';
import { IndicatorInfoENUSTranslations } from 'src/feature-store/pages/VisualizeFeatures/components/IndicatorInfo/translations/en-us';
import { IndicatorInfoPTBRTranslations } from 'src/feature-store/pages/VisualizeFeatures/components/IndicatorInfo/translations/pt-br';
import { confidenceProjectionTagENUSTranslate } from 'src/feature-store/components/ConfidenceProjectionTag/translate/en-us';
import { confidenceProjectionTagPTBRTranslate } from 'src/feature-store/components/ConfidenceProjectionTag/translate/pt-br';
import { MySeriesENUSTranslate } from 'src/models/pages/MySeries/translate/en-us';
import { MySeriesPTBRTranslate } from 'src/models/pages/MySeries/translate/pt-br';

import { commonTextsENUS, commonTextsPTBR } from './translations/common';

export const enUSTranslations = {
  ...commonTextsENUS,
  ...homeENTranslations,
  ...landingEN,
  ...correlationMatrixEN,
  ...overviewEN,
  ...variableImportanceEN,
  ...MyProjectsENTranslations,
  ...modellingStartENUSTranslations,
  ...modellingENTranslations,
  ...inputFileENTranslations,
  ...lagsModalENUSTranslations,
  ...featureSelectionENUSTranslations,
  ...modalAdvancedENUSTranslations,
  ...FeatureStoreEN,
  ...conclusionModalENTranslations,
  ...paginationENTranslations,
  ...commonModelExplorerENUSTranslations,
  ...modelSpecificsENUSTranslations,
  ...modelOverviewENUSTranslations,
  ...ContainerMaintenanceENTranslations,
  ...modelCrossValidationENTranslations,
  ...arimaDispersionEN,
  ...modelInProductionEn,
  ...modelExclusionEn,
  ...forecastCombinationEN,
  ...ContainerMaintenanceENTranslations,
  ...SelectENTranslations,
  ...menuSideBarENTranslations,
  ...footerENTranslations,
  ...layoutENTranslations,
  ...filterIndicatorSeriesENTranslations,
  ...sendToGroupENTranslations,
  ...preDefinedSeriesEN,
  ...viewFeaturesEN,
  ...groupsENTranslate,
  ...creategGroupModalENTranslate,
  ...deleteGroupModalENTranslate,
  ...indicatorsENTranslate,
  ...exportSeriesENTranslate,
  ...step1ClaaSENUSTranslations,
  ...Step3ClaaSENUSTranslations,
  ...predictionsENUSTranslations,
  ...modelExplorerENUSTranslations,
  ...classificationSidebarENUSTranslations,
  ...ListCustomersENTranslate,
  ...ModalRegisterEditCustomerENTranslate,
  ...RegisterNewUserENTranslate,
  ...ModalStatusRegisterNewUserENTranslate,
  ...PanelAdminENTranslate,
  ...page404ENTranslate,
  ...sideBarFeatureStoreENTranslate,
  ...claasModelInProductionENUSTranslations,
  ...confusionMatrixENUSTranslations,
  ...ROCCurveENUSTranslations,
  ...VariableImpactsENUSTranslations,
  ...modelInfoENUSTranslations,
  ...projectOverviewENTranslate,
  ...modalSeeRequirementsTimeSeriesENTranslate,
  ...Step2ClaaSENUSTranslations,
  ...modalWarningNWindowsENUSTranslate,
  ...ClassificationModellingENUSTranslations,
  ...exportProjectOverviewENUSTranslate,
  ...modelPerformanceENUSTranslations,
  ...ClaaSRequirementsENUSTranslations,
  ...indicatorSeriesENTranslate,
  ...createSeriesENTranslate,
  ...reviewCreateSeriesENTranslate,
  ...editUnitENTranslate,
  ...projectOverviewProjectionsENTranslate,
  ...AccessGroupEN,
  ...modelDetailsENUSTRanslations,
  ...WarningDailyProjectENTranslate,
  ...AISelectionInformationENTranslate,
  ...AISelectionResultENTranslate,
  ...AISelectionExplanatoryVariablesENTranslate,
  ...AISelectionExportENTranslate,
  ...AISelectionResultsUnderstandingENTranslate,
  ...IASelectionPotencialImpactsENTranslate,
  ...AISelectionENUSTranslate,
  ...adminENTranslate,
  ...ModalManagerENTranslate,
  ...AISelectionAnnualSummaryENTranslate,
  ...modelInProductionAnnualSummaryENTranslate,
  ...userSelectionResultsENTranslate,
  ...premiumENTranslate,
  ...forecastDispersionComponentENUSTranslate,
  ...modelUpdateSidebarENUSTranslations,
  ...ShareUpdateModalENUSTranslations,
  ...ConfigFiltersENUSTranslate,
  ...DependentVariablesConfigENUSTranslate,
  ...CreateFiltersENUSranslate,
  ...FeatureStoreVariablesENUSTranslate,
  ...ModelUpdateDocsModalENUSTranslations,
  ...UserConfigsENUSTranslations,
  ...categoriesSidebarENTranslate,
  ...AIChatENUSTranslations,
  ...DownloadWidgetENUSTranslations,
  ...AdminPanelDeleteUserENTranslate,
  ...TutorialConfigENUSTranslate,
  ...overallPerformanceENUSTranslations,
  ...variablePerformanceENUSTranslations,
  ...realVsForecastENUSTranslations,
  ...CreateWorkspaceENUSTranslations,
  ...modalCreateIndicatorENUSTranslations,
  ...unauthorizedSsoEN,
  ...metricsByPeriodENUSTranslations,
  ...projectionsVariationENUSTranslations,
  ...performanceByPeriodTableENUSTranslations,
  ...ShareWorkSpaceENUSTranslations,
  ...WorkspaceCardENUSTranslations,
  ...WorkspaceOverviewENUSTranslation,
  ...workspaceSideBarENUSTranslations,
  ...WorkspaceControlPanelENUSTranslations,
  ...workspaceProjectionsResultENTranslate,
  ...workspaceProjectionsResultsUnderstandingENTranslate,
  ...workspaceProjectionsPotencialImpactsENTranslate,
  ...workspaceProjectionsExplanatoryVariablesENTranslate,
  ...workspaceProjectionsAnnualSummaryENTranslate,
  ...workspaceProjectionsENUSTranslate,
  ...VersionInfoENUSTranslations,
  ...workspaceProjectionsExportENTranslate,
  ...FiltersSidebarENUSTranslation,
  ...workspacePerformanceByPeriodTableENUSTranslations,
  ...workspaceRealVsForecastENUSTranslations,
  ...workspaceMetricsByPeriodENUSTranslations,
  ...workspaceOverallPerformanceENUSTranslations,
  ...workspaceVariablePerformanceENUSTranslations,
  ...AgGridTableENUSTranslations,
  ...sectionButtonFavoriteENUSTranslate,
  ...dataTableENUSTranslate,
  ...subscribeToAlertENUSTranslate,
  ...buttonSyncAdjustENUSTranslate,
  ...homeFeatureStoreENUS,
  ...favoriteStatusENUSTranslate,
  ...projectionTagENUSranslate,
  ...workspaceHomeENUSTranslations,
  ...WorkspaceNewReleaseConfigurationENUSTranslations,
  ...WorkspacePlanningFlowENUSTranslations,
  ...IndicatorInfoENUSTranslations,
  ...confidenceProjectionTagENUSTranslate,
  ...MySeriesENUSTranslate,
};

export const ptBRTranslations = {
  ...commonTextsPTBR,
  ...homePTBRTranslations,
  ...landingPTBR,
  ...correlationMatrixPTBR,
  ...overviewPTBR,
  ...variableImportancePTBR,
  ...MyProjectsPTBRTranslations,
  ...modellingStartPTBRTranslations,
  ...modellingPTBRTranslations,
  ...inputFilePTBRTranslations,
  ...lagsModalPTBRTranslations,
  ...featureSelectionPTBRTranslations,
  ...modalAdvancedPTBRTranslations,
  ...FeatureStorePTBR,
  ...conclusionModalPTBRTranslations,
  ...paginationPTBRTranslations,
  ...commonModelExplorerPTBRTranslations,
  ...modelSpecificsPTBRTranslations,
  ...modelOverViewPTBRTranslations,
  ...ContainerMaintenancePTBRTranslations,
  ...modelCrossValidationPTBRTranslations,
  ...arimaDispersionPTBR,
  ...modelInProductionEnPTBR,
  ...modelExclusionPTBR,
  ...forecastCombinationPTBR,
  ...ContainerMaintenancePTBRTranslations,
  ...SelectPTBRTranslations,
  ...menuSideBarPTBRTranslations,
  ...footerPTBRTranslations,
  ...layoutPTBRTranslations,
  ...filterIndicatorSeriesPTBRTranslations,
  ...sendToGroupPTBRTranslations,
  ...preDefinedSeriesPTBR,
  ...viewFeaturesPTBR,
  ...groupsPTBRTranslate,
  ...creategGroupModalPTBRTranslate,
  ...deleteGroupModalPTBRTranslate,
  ...indicatorsPTBRTranslate,
  ...exportSeriesPTBR,
  ...step1ClaaSPTBRTranslations,
  ...Step3ClaaSPTBRTranslations,
  ...predictionsPTBRTranslations,
  ...modelExplorerPTBRTranslations,
  ...classificationSidebarPTBRTranslations,
  ...ListCustomersPTBRTranslate,
  ...ModalRegisterEditCustomerPTTranslate,
  ...RegisterNewUserPTBRTranslate,
  ...ModalStatusRegisterNewUserPTBRTranslate,
  ...PanelAdminPTBRTranslate,
  ...page404PTBRTranslate,
  ...sideBarFeatureStorePTBRTranslate,
  ...claasModelInProductionPTBRTranslations,
  ...confusionMatrixPTBRTranslations,
  ...ROCCurvePTBRTranslations,
  ...modelInfoPTBRTranslations,
  ...VariableImpactsPTBRTranslations,
  ...projectOverviewPTBRTranslate,
  ...modalSeeRequirementsTimeSeriesPTBRTranslate,
  ...Step2ClaaSPTBRTranslations,
  ...step1ClaaSPTBRTranslations,
  ...modalWarningNWindowsPTBRTranslate,
  ...ClassificationModellingPTBRTranslations,
  ...exportProjectOverviewPTBRTranslate,
  ...modelPerformancePTBRTranslations,
  ...ClaaSRequirementsPTBRTranslations,
  ...indicatorSeriesPTBRTranslate,
  ...createSeriesPTBRTranslate,
  ...reviewCreateSeriesPTBRTranslate,
  ...editUnitPTBRTranslate,
  ...projectOverviewProjectionsPTTranslate,
  ...AccessGroupPTBR,
  ...modelDetailsPTBRTRanslations,
  ...WarningDailyProjectPTTranslate,
  ...AISelectionInformationPTTranslate,
  ...AISelectionResultPTTranslate,
  ...AISelectionExplanatoryVariablesPTTranslate,
  ...AISelectionExportPTTranslate,
  ...AISelectionResultsUnderstandingPTBRTranslate,
  ...IASelectionPotencialImpactsPTTranslate,
  ...AISelectionPTBRTranslate,
  ...adminPTBRTranslate,
  ...ModalManagerPTBRTranslate,
  ...AISelectionAnnualSummaryPTTranslate,
  ...modelInProductionAnnualSummaryPTTranslate,
  ...userSelectionResultsPTBRTranslate,
  ...premiumPTBRTranslate,
  ...forecastDispersionComponentPTBRTranslate,
  ...modelUpdateSidebarPTBRTranslations,
  ...ShareUpdateModalPTBRTranslations,
  ...ConfigFiltersPTBRTranslate,
  ...DependentVariablesConfigPTBRTranslate,
  ...CreateFiltersPTBRranslate,
  ...FeatureStoreVariablesPTBRTranslate,
  ...ModelUpdateDocsModalPTBRTranslations,
  ...UserConfigsPTBRTranslations,
  ...categoriesSidebarPTBRTranslate,
  ...AIChatPTBRTranslations,
  ...DownloadWidgetPTBRTranslations,
  ...AdminPanelDeleteUserPTBRTranslate,
  ...TutorialConfigPTBRTranslate,
  ...overallPerformancePTBRTranslations,
  ...variablePerformancePTBRTranslations,
  ...realVsForecastPTBRTranslations,
  ...CreateWorkspacePTBRTranslations,
  ...modalCreateIndicatorPTBRTranslations,
  ...unauthorizedSsoPTBR,
  ...metricsByPeriodPTBRTranslations,
  ...projectionsVariationPTBRTranslations,
  ...performanceByPeriodTablePTBRTranslations,
  ...ShareWorkSpacePTBRranslations,
  ...WorkspaceCardPTBRTranslations,
  ...WorkspaceOverviewPTBRTranslation,
  ...workspaceSideBarPTBRTranslations,
  ...WorkspaceControlPanelPTBRTranslations,
  ...workspaceProjectionsResultPTTranslate,
  ...workspaceProjectionsResultsUnderstandingPTBRTranslate,
  ...workspaceProjectionsPotencialImpactsPTTranslate,
  ...workspaceProjectionsExplanatoryVariablesPTTranslate,
  ...workspaceProjectionsAnnualSummaryPTTranslate,
  ...workspaceProjectionsPTBRTranslate,
  ...VersionInfoPTBRTranslations,
  ...workspaceProjectionsExportPTTranslate,
  ...FiltersSidebarPTBRTranslation,
  ...workspacePerformanceByPeriodTablePTBRTranslations,
  ...workspaceRealVsForecastPTBRTranslations,
  ...workspaceMetricsByPeriodPTBRTranslations,
  ...workspaceOverallPerformancePTBRTranslations,
  ...workspaceVariablePerformancePTBRTranslations,
  ...AgGridTablePTBRTranslations,
  ...sectionButtonFavoritePTBRTranslate,
  ...dataTablePTBRTranslate,
  ...subscribeToAlertPTBRTranslate,
  ...buttonSyncAdjustPTBRTranslate,
  ...homeFeatureStorePTBR,
  ...favoriteStatusPTTranslate,
  ...projectionTagPTBRTranslate,
  ...workspaceHomePTBRTranslations,
  ...WorkspaceNewReleaseConfigurationPTBRTranslations,
  ...WorkspacePlanningFlowPTBRTranslations,
  ...IndicatorInfoPTBRTranslations,
  ...confidenceProjectionTagPTBRTranslate,
  ...MySeriesPTBRTranslate,
};

export const resources: { [language: string]: any } = {
  'en-US': {
    name: 'English',
    translation: enUSTranslations,
  },
  'pt-BR': {
    name: 'Portuguese - Brazil',
    translation: ptBRTranslations,
  },
};

type MessageKey = keyof typeof enUSTranslations;

const messageHasTranslate: MessageKey[] = [
  'step1MessageErrorCouldNotBeOpened',
  'step1MessageErrorMustHave1DataVariable',
  'step1MessageErrorMustHave1Column1',
  'step3ModalVariablesWarningTitle',
  'step3ModalVariablesWarningErrorToProcessInput',
  'step3ModalVariablesWarningRemovingVariables',
  'step3ModalVariablesWarningVariablesThatMightBeRemoved',
  'step3ModalVariablesWarningNumberOfObservations',
  'step3ModalVariablesWarningMinimumDataRequirements',
  'step3ModalVariablesWarningThereIsMoreThanOneObservation',
  'step3ModalVariablesWarningLaggedVariablesThatMightBeRemoved',
  'step3ModalVariablesRemovingRedundantVariables',
  'step2MessageErrorPleaseAddMoreObservation',
  'step2MessageSuccessToAddSeriesByFSTitle',
  'step2MessageSuccessToAddSeriesByFSDescription',
  'step2MessageErrorTryingToFormat',
  'step3ModalVariablesMessageDatasetDoesNotContainForecastPeriod',
  'step3ModalVariablesMessageDatasetDidNotReachRecommendedNumberOfObservations',
  'step3ModalVariablesSomeExplanatoryVariablesContainMissingValues',
  'AISelectionMessageErrorServerCanNotFind',
  'AISelectionMessageErrorServerRequestedResource',
  'AISelectionMessageErrorEngineNotSupported',
  'AISelectionMessageErrorAnInternalError',
  'modelInProductionMessageErrorAnnualSeriesRateIsNotModel',
  'modelInProductionMessageErrorQuarterlySeriesRateIsNotModel',
  'AIselectionAnnualSummaryIsOnlyAvailableForSeriesWith1Year',
  'AISelectionMonthlyAggregationIsNotAvailable',
  'dataViewPotenciasOutliersNotIdentified',
  'AISelectionQuarterlySeriesSummaryIsOnlyAvailable',
  'step2MessageErrorDependentVariableWasRemoved',
  'variableImportanceMessageErrorNoExplanatoryVariables',
  'modelSpecificsArimaBreakdownOnlyAvailableForMonthlyData',
  'step2MessageErrorInvalidPrefixVariableName',
  'buttonSyncAdjustNoManualAdjustDataFound',
  'buttonSyncAdjustAlreadyExistsTaskBeingProcessing',
];

export function translateSomeMessagesFromBackend(
  message: string,
  language: Language,
): string {
  const key = messageHasTranslate.find(
    (keyAux) => enUSTranslations[keyAux] === message,
  );
  if (key) {
    if (language === 'en-us') {
      return enUSTranslations[key];
    }
    if (language === 'pt-br') {
      return ptBRTranslations[key];
    }
  }
  return message;
}
