import { useQuery } from 'react-query';
import ms from 'ms';

import apiSeries from '../service/apiSeries';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useQuerySerieTags = () => {
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: 'serie-tags',
    queryFn: async () => {
      const response = await apiSeries.get<string[]>('/tags');
      return response.data;
    },
    staleTime: ms('20 min'),
    cacheTime: ms('20 min'),
  });
  return {
    serieTagsData: data,
    serieTagsIsLoading: isLoading || isFetching,
    serieTagsIsError: isError,
  };
};
