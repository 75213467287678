import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { SliderComponent as Slider } from 'src/components/Slider';
import api from 'src/models/service/api';
import { RootState } from 'src/redux/store';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';

import { ContentCrossValidationWindows } from './styles';

interface ResponseCrossValidationWindows {
  title: string;
  chart_data: [
    {
      data: Date[];
      y: number[];
    },
  ];
}

interface CrossValidationWindowsProps {
  type:
    | 'arima'
    | 'regularized-regression'
    | 'forecast-combination'
    | 'tree-models';
  modelId: number;
  nWindows: number | undefined;
  isLoadingModelInfo: boolean;
  isErrorModelInfo: boolean;
}

export const CrossValidationWindows: React.FC<CrossValidationWindowsProps> = ({
  type,
  modelId,
  nWindows,
  isLoadingModelInfo,
  isErrorModelInfo,
}) => {
  const [windowSelected, setWindowSelected] = useState(1);

  const { project } = useSelector((state: RootState) => state);

  const { t: translate } = useTranslation();

  const {
    data: dataCrossValidationWindow,
    isLoading: isLoadingCrossValidationWindow,
    isFetching: isFetchingCrossValidationWindow,
    isError: isErrorCrossValidationWindow,
  } = useQuery(
    [
      `${type} cross-validation-windows`,
      project.id,
      project.selectedY?.id,
      modelId,
      windowSelected,
    ],
    async () => {
      const { data } = await api.get<ResponseCrossValidationWindows>(
        `/projects/${project.id}/${project.selectedY?.id}/models/${type}/${modelId}/cv_windows/${windowSelected}`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!project.id && !!project.selectedY?.id && !!modelId && !!project.model,
    },
  );

  const sliderMarks = useMemo(() => {
    let quantitySliderNumber = 0;
    let multi = 1;
    if (nWindows) {
      const quantity = nWindows;

      if (quantity <= 40) {
        quantitySliderNumber = quantity;
      } else if (quantity <= 200) {
        quantitySliderNumber = Math.floor(quantity / 5);
        multi = 5;
      } else if (quantity > 200) {
        quantitySliderNumber = Math.floor(quantity / 10);
        multi = 10;
      }

      if (multi === 1) {
        return Array.from({ length: quantitySliderNumber }, (_, number) => ({
          [number + 1]: number + 1,
        }));
      }

      const values = Array.from(
        { length: quantitySliderNumber },
        (_, number) => (number + 1) * multi,
      );

      values.splice(0, 0, 1);

      if (!values.includes(quantity)) {
        if (quantity - values[values.length - 1] <= 5) {
          values.pop();
          values.push(quantity);
        } else {
          values.push(quantity);
        }
      }

      return values.map((number) => ({ [number]: number }));
    }
  }, [nWindows]);

  const series: HChartsSeries[] = useMemo(() => {
    if (!dataCrossValidationWindow) {
      return [];
    }

    return dataCrossValidationWindow.chart_data.map((chart, index) => {
      const name =
        index === 0
          ? translate('crossValidationWindowsLegendActual')
          : translate('crossValidationWindowsLegendForecast');

      return {
        type: 'line',
        name,
        dashStyle: index === 0 ? 'Solid' : 'Dash',
        color: getChartColor(index),
        marker: {
          symbol: 'Circle',
        },
        data: chart.data.map((x, xIndex) => ({
          x: new Date(`${x}T00:00`).getTime(),
          y: chart.y[xIndex],
          custom: {
            value: formatCompactNotation(chart.y[xIndex]),
          },
        })),
      };
    });
  }, [dataCrossValidationWindow, translate]);

  const options: HChartsOptions = {
    chart: {
      height: 300,
    },
    tooltip: {
      pointFormat:
        `<tr><td><b>${translate('date')}:</b> </td>` +
        `<td style="text-align: right">{point.x: ${
          project.selectedY?.info?.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
        }}</td></tr>` +
        `<tr><td><b>${translate('value')}:</b> </td>` +
        '<td style="text-align: right">{point.custom.value}</td></tr>',
    },
  };

  return (
    <div className="containerLinear">
      <Card
        textCard={translate('crossValidationWindowsTitle')}
        textDescription={translate('crossValidationWindowsDescription')}
      />
      {project.projectError || isErrorModelInfo ? (
        <ContainerMaintenance
          content="chart"
          data-testid="container-cross-validation-windows-error"
        />
      ) : isLoadingModelInfo || !nWindows || !sliderMarks ? (
        // eslint-disable-next-line react/jsx-indent
        <ContainerSkeleton data-testid="container-cross-validation-windows-loading" />
      ) : (
        <div>
          <Slider
            dataCy="slider-cross-validation-windows"
            min={1}
            defaultValue={1}
            marks={sliderMarks.reduce((obj, number) => ({
              ...obj,
              ...number,
            }))}
            step={1}
            max={nWindows}
            onAfterChange={(value) => setWindowSelected(value)}
            disabled={nWindows === 1}
            style={{ width: '100%', marginBottom: '24px' }}
          />
          {isErrorCrossValidationWindow ? (
            <ContainerMaintenance content="chart" />
          ) : isLoadingCrossValidationWindow ||
            isFetchingCrossValidationWindow ||
            !dataCrossValidationWindow ? (
            // eslint-disable-next-line react/jsx-indent
            <ContainerSkeleton data-testid="container-cross-validation-windows-chart-loading" />
          ) : (
            <ContentCrossValidationWindows>
              <h3>{dataCrossValidationWindow.title}</h3>
              <HCharts
                series={series}
                options={options}
                frequency={project.selectedY?.info?.frequency}
                dataCy="chart-cross-validation-windows"
                resizeWidthWithSidebar
              />
            </ContentCrossValidationWindows>
          )}
        </div>
      )}
    </div>
  );
};
