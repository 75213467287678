import React, { useContext, useEffect, useRef, useState } from 'react';

import { Input } from 'src/components/Input';
import apiWorkspace from 'src/workspaces/service/api';
import api from 'src/models/service/api';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CaretDown, CaretUp } from 'phosphor-react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';

import {
  ButtonClean,
  MenuContainerStyled,
  MenuOptionAdjusted,
  ModelIdContent,
  OtherModelContent,
  Overlay,
  SpinnerAdjusted,
} from './styles';
import { ModelId, ResponseModels } from '../VariablesTable/Row/types';
import { GetValueKey, SetValue, YsModelId } from '../Step3/types';

type Props = {
  yKey: string;
  workspaceId?: string;
  projectId: string;
  yId: string;
  isDisabled: boolean;
  getValue: (key: GetValueKey) => SetValue[GetValueKey];
  setValue: (key: GetValueKey, value: SetValue[GetValueKey]) => void;
};

export const ModelIdMenu: React.FC<Props> = ({
  yKey,
  workspaceId,
  projectId,
  yId,
  isDisabled,
  getValue,
  setValue,
}) => {
  const [visible, setVisible] = useState(false);
  const [inputError, setInputError] = useState('');
  const [otherModelId, setOtherModelId] = useState('');
  const [canSeeOtherModelId, setCanSeeOtherModelId] = useState(false);
  const [canLoadModelId, setCanLoadModelId] = useState(false);

  const divRelativeRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const { isEdition } = useContext(WorkspaceConfigContext);

  const requestApi = isEdition ? apiWorkspace : api;

  const { t: translate } = useTranslation();
  const {
    auth: { user },
  } = useSelector((state: RootState) => state);

  const ysModelId = getValue('ysModelId') as YsModelId;
  const modelId = ysModelId[yKey];

  const {
    data: modelsIdData,
    isLoading: modelsIdLoading,
    isFetching: modelsIdFetching,
    isError: modelsIdError,
  } = useQuery<ModelId[]>(
    ['project models', projectId, yId],
    async () => {
      const editionRequest = `/workspaces/${workspaceId}/users/projects/${projectId}/ys/${yId}/models`;
      const creationRequest = `/projects/${projectId}/${yId}/models`;

      const { data } = await requestApi.get<ResponseModels>(
        isEdition ? editionRequest : creationRequest,
      );

      if (data.models.every((model) => !model.model_code)) {
        throw new Error();
      }

      return data.models.map((model) => ({
        code: model.model_code,
        isUserSelection: model.user_selection,
        isAiSelection: model.ai_selection,
      }));
    },
    {
      staleTime: 5000 * 60,
      enabled:
        canLoadModelId ||
        (!!isEdition && !!workspaceId && !modelId.model_label),
    },
  );

  function handleSelect(modelIdAux: 'ai-selection' | 'user-selection') {
    setVisible(false);
    setCanSeeOtherModelId(false);
    setOtherModelId('');
    setInputError('');

    setValue('ysModelId', {
      [yKey]: {
        model_id: modelIdAux,
        model_label: modelIdAux,
        error: false,
      },
    });
  }

  function handleOpen() {
    setVisible(true);
  }

  useEffect(() => {
    if (visible === true && divRelativeRef && menuRef) {
      const tableContainer = document.querySelector(
        '#workspace-table-container',
      );

      if (tableContainer && divRelativeRef.current && menuRef.current) {
        if (
          divRelativeRef.current.offsetTop - tableContainer.scrollTop >
          tableContainer.clientHeight
        ) {
          tableContainer.scrollTop =
            divRelativeRef.current.offsetTop -
            tableContainer.clientHeight +
            divRelativeRef.current.scrollHeight;
        }
      }
    }
  }, [visible, canSeeOtherModelId]);

  function handleClose() {
    setVisible(false);
    setInputError('');
  }

  function handleChangeOtherModelId(value: string) {
    setOtherModelId(value);
  }

  function handleCanSeeOtherModelId() {
    setCanSeeOtherModelId(true);
    setCanLoadModelId(true);
  }

  function handlePressEnterOnInput(valuePasted?: string) {
    if (!modelsIdData) {
      return;
    }

    const valueAux = valuePasted || otherModelId;

    if (!valueAux) {
      setInputError('createWorkspaceModelIdRequiredField');
      return;
    }

    if (!modelsIdData.some((modelIdAux) => modelIdAux.code === valueAux)) {
      setInputError('createWorkspaceModelIdInvalid');
      return;
    }

    setVisible(false);
    setInputError('');
    setValue('ysModelId', {
      [yKey]: {
        model_id: valueAux,
        model_label: 'others',
        error: false,
      },
    });
  }

  useEffect(() => {
    if (!modelId.model_label) {
      if (
        modelId.model_id === 'user-selection' ||
        modelId.model_id === 'ai-selection'
      ) {
        setValue('ysModelId', {
          [yKey]: {
            model_id: modelId.model_id,
            model_label: modelId.model_id,
            error: false,
          },
        });
      } else if (modelsIdData && modelId.model_id) {
        const modelIdSelected = modelsIdData.find(
          (modelCode) => modelCode.code === modelId.model_id,
        );

        if (!modelIdSelected) {
          return;
        }

        setOtherModelId(modelIdSelected.code);

        setValue('ysModelId', {
          [yKey]: {
            model_id: modelIdSelected.isAiSelection
              ? 'ai-selection'
              : modelIdSelected.isUserSelection
              ? 'user-selection'
              : modelIdSelected.code,
            model_label: modelIdSelected.isAiSelection
              ? 'ai-selection'
              : modelIdSelected.isUserSelection
              ? 'user-selection'
              : 'others',
            error: false,
          },
        });
      } else if (!modelsIdLoading && !modelsIdFetching && !modelId.error) {
        setValue('ysModelId', {
          [yKey]: {
            model_id: 'ai-selection',
            model_label: 'ai-selection',
            error: false,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelsIdData, modelsIdFetching, modelsIdLoading, projectId, yId]);

  useEffect(() => {
    if (modelsIdError) {
      setValue('ysModelId', {
        [yKey]: {
          model_id: '--',
          model_label: 'others',
          error: true,
        },
      });

      setVisible(false);

      setValue('ysSelected', { [yKey]: false });
    }
  }, [modelsIdError, projectId, setValue, yId, yKey]);

  const modelIdAux =
    !modelId || modelId.error
      ? '--'
      : modelId.model_id === 'ai-selection'
      ? 'createWorkspaceAISelection'
      : modelId.model_id === 'user-selection'
      ? 'createWorkspaceUserSelection'
      : 'createWorkspaceAnotherModels';

  useEffect(() => {
    if (
      modelId.model_id === 'ai-selection' ||
      modelId.model_id === 'user-selection'
    ) {
      setCanSeeOtherModelId(false);
      setOtherModelId('');
    }
  }, [modelId]);

  useEffect(() => {
    if (
      modelIdAux === 'createWorkspaceAnotherModels' &&
      (!otherModelId || otherModelId !== modelId.model_id)
    ) {
      setOtherModelId(modelId.model_id);
    }
    if (modelIdAux === 'createWorkspaceAnotherModels') {
      setCanSeeOtherModelId(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  if (
    (modelId.model_id === undefined && modelsIdLoading && modelsIdFetching) ||
    !modelId.model_label
  ) {
    return (
      <ContainerSkeleton
        withLoading={false}
        style={{ height: '2.75rem' }}
        data-testid={`model-id-menu-loading-project-${projectId}`}
      />
    );
  }

  return (
    <ModelIdContent ref={divRelativeRef}>
      <ButtonClean
        type="button"
        onClick={handleOpen}
        disabled={isDisabled}
        data-testid={`model-id-menu-project-${projectId}-variable-${yId}-button-open-close`}
      >
        <div>
          <p
            data-testid={`model-id-menu-project-${projectId}-variable-${yId}-value`}
          >
            {modelIdAux !== '--' ? translate(modelIdAux) : modelIdAux}
          </p>
          {modelIdAux === 'createWorkspaceAnotherModels' && (
            <span
              data-testid={`model-id-menu-project-${projectId}-variable-${yId}-another`}
            >
              {modelId.model_id}
            </span>
          )}
        </div>
        {visible ? (
          <CaretUp weight="bold" size="1rem" />
        ) : (
          <CaretDown weight="bold" size="1rem" />
        )}
      </ButtonClean>
      {visible && (
        <MenuContainerStyled visible ref={menuRef}>
          <Overlay
            onClick={handleClose}
            data-testid={`model-id-menu-project-${projectId}-variable-${yId}-overlay`}
          />
          <MenuOptionAdjusted
            type="button"
            data-testid={`model-id-menu-project-${projectId}-variable-${yId}-button-ai-selection`}
            onClick={() => handleSelect('ai-selection')}
            position="middle"
            selected={modelIdAux === 'createWorkspaceAISelection'}
          >
            <p>{translate('createWorkspaceAISelection')}</p>
          </MenuOptionAdjusted>
          <MenuOptionAdjusted
            type="button"
            data-testid={`model-id-menu-project-${projectId}-variable-${yId}-button-user-selection`}
            onClick={() => handleSelect('user-selection')}
            position="middle"
            selected={modelIdAux === 'createWorkspaceUserSelection'}
          >
            <p>{translate('createWorkspaceUserSelection')}</p>
          </MenuOptionAdjusted>

          <MenuOptionAdjusted
            type="button"
            data-testid={`model-id-menu-project-${projectId}-variable-${yId}-button-another-model`}
            position="middle"
            onClick={handleCanSeeOtherModelId}
            disabled={modelsIdError}
            style={{ display: 'block' }}
            selected={modelIdAux === 'createWorkspaceAnotherModels'}
          >
            <p>{translate('createWorkspaceAnotherModels')}</p>
            <OtherModelContent canSeeOtherModelId={canSeeOtherModelId}>
              <div>
                <Input
                  value={otherModelId}
                  onChange={(e) => handleChangeOtherModelId(e.target.value)}
                  placeholder={translate('createWorkspaceTypeModelCode')}
                  disabled={modelsIdError}
                  error={inputError ? translate(inputError) : undefined}
                  onKeyDown={(e) =>
                    e.key === 'Enter' && handlePressEnterOnInput()
                  }
                  onPaste={(e) => {
                    handlePressEnterOnInput(e.clipboardData.getData('text'));
                  }}
                  testid={`model-id-menu-project-${projectId}-variable-${yId}-button-another-model-input`}
                  style={{
                    width: user.language === 'pt-br' ? '14rem' : '11rem',
                  }}
                />
                {(modelsIdLoading || modelsIdFetching) && (
                  <SpinnerAdjusted
                    spinnerType="primary"
                    data-testid={`model-id-menu-project-${projectId}-variable-${yId}-button-another-model-spinner`}
                  />
                )}
              </div>
            </OtherModelContent>
          </MenuOptionAdjusted>
        </MenuContainerStyled>
      )}
    </ModelIdContent>
  );
};
