import {
  Binoculars,
  ChartPie,
  Eye,
  Graph,
  Target,
  TrendUp,
  Trophy,
} from 'phosphor-react';

import { Menu } from '.';
import { ReactComponent as logo } from '../../../../../assets/logo-sidebar.svg';

export const menuProject: Menu[] = [
  {
    label: 'labelProjectOverview',
    Icon: Binoculars,
    url: '/models/time-series/:id/project-overview',
  },
  {
    label: 'labelOverallPerformance',
    Icon: TrendUp,
    url: '/models/time-series/:id/overall-performance',
  },
];

export const menuResults: Menu[] = [
  {
    label: 'labelAISelection',
    Icon: logo,
    url: '/models/time-series/:id/AI-selection',
  },
  {
    label: 'labelUserSelection',
    Icon: Trophy,
    url: '/models/time-series/:id/user-selection',
  },
  {
    label: 'labelVariablePerformance',
    Icon: Target,
    url: '/models/time-series/:id/variable-performance',
  },
];

export const menuUserSelection: Menu[] = [
  {
    label: 'labelOverview',
    Icon: Eye,
    url: '/models/time-series/:id/model-explorer/overview',
  },
  {
    label: 'labelModelExplorer',
    Icon: Graph,
    submenu: [
      {
        label: 'labelArima',
        submenu: [
          {
            label: 'labelModelSpecifics',
            url: '/models/time-series/:id/model-explorer/arima/specifics',
            dependents: ['ARIMA'],
          },
          {
            label: 'labelModelDispersion',
            url: '/models/time-series/:id/model-explorer/arima/dispersion',
            dependents: ['ARIMA'],
          },
          {
            label: 'labelCrossValidation',
            url: '/models/time-series/:id/model-explorer/arima/cross-validation',
            dependents: ['ARIMA'],
          },
        ],
      },
      {
        label: 'labelRegularizedRegression',
        submenu: [
          {
            label: 'labelModelSpecifics',
            url: '/models/time-series/:id/model-explorer/regularized-regression/specifics',
            dependents: ['Regularized Regression'],
          },

          {
            label: 'labelCrossValidation',
            url: '/models/time-series/:id/model-explorer/regularized-regression/cross-validation',
            dependents: ['Regularized Regression'],
          },
        ],
      },
      {
        label: 'labelForecastCombination',
        submenu: [
          {
            label: 'labelModelSpecifics',
            url: '/models/time-series/:id/model-explorer/forecast-combination/specifics',
            dependents: ['Forecast Combination'],
          },
          {
            label: 'labelModelDispersion',
            url: '/models/time-series/:id/model-explorer/forecast-combination/dispersion',
            dependents: ['Forecast Combination'],
          },
          {
            label: 'labelCrossValidation',
            url: '/models/time-series/:id/model-explorer/forecast-combination/cross-validation',
            dependents: ['Forecast Combination'],
          },
        ],
      },
      {
        label: 'labelTreeModels',
        submenu: [
          {
            label: 'labelModelSpecifics',
            url: '/models/time-series/:id/model-explorer/tree-models/specifics',
            dependents: ['Tree Models'],
          },

          {
            label: 'labelCrossValidation',
            url: '/models/time-series/:id/model-explorer/tree-models/cross-validation',
            dependents: ['Tree Models'],
          },
        ],
      },
      {
        label: 'labelElementary',
        submenu: [
          {
            label: 'labelModelSpecifics',
            url: '/models/time-series/:id/model-explorer/elementary/specifics',
            dependents: ['Elementary'],
          },
        ],
      },
    ],
  },
];

export const menuDataView: Menu[] = [
  {
    label: 'labelDataView',
    Icon: ChartPie,
    submenu: [
      {
        label: 'labelOverview',
        url: '/models/data-view/overview',
      },
      {
        label: 'labelCorrelationMatrix',
        url: '/models/data-view/correlation-matrix',
      },
      {
        label: 'labelVariableImportance',
        url: '/models/data-view/variable-importance',
      },
    ],
  },
];
