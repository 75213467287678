import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Head } from 'src/components/Head';
import { Pagination } from 'src/components/Pagination';
import { Status } from 'src/components/Status';
import { IndicatorCard } from 'src/feature-store/components/IndicatorCard';
import { FeatureStoreSidebarContext } from 'src/feature-store/Contexts/NavigationContext';
import {
  setLastSearch,
  setPage,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import api from 'src/feature-store/service/api';
import { useDebounce } from 'src/hooks/useDebounce';
import { RootState } from 'src/redux/store';

import {
  Container,
  ContainerWithoutIndicators,
  IndicatorsContent,
  ListIndicatorContainer,
} from './styles';
import { IndicatorsProps } from './types';

const QUANTITY_ITEMS_PAGE = 8;

export const Indicators: React.FC = () => {
  const [indicators, setIndicators] = useState<IndicatorsProps>();

  const { openIndicatorFilters, openPremiumFilters, indicatorFilterType } =
    useContext(FeatureStoreSidebarContext);

  const { page, search, breadcumbs, lastSearch } = useSelector(
    (state: RootState) => state.indicatorsPage,
  );
  const { language } = useSelector((state: RootState) => state.auth.user);

  const { debouncedValue: searchDebouced } = useDebounce(search);
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const categoryId = breadcumbs[breadcumbs.length - 1]?.category_id;

  const filteredIndicatorType =
    indicatorFilterType === 'free' ? 'freemium' : indicatorFilterType;

  const skip = (page - 1) * QUANTITY_ITEMS_PAGE;

  const commonParams = {
    skip,
    limit: 8,
    is_active: true,
    ...(filteredIndicatorType && { access_type: filteredIndicatorType }),
  };

  const paramsWithCategoryIdText = {
    ...commonParams,
    category_id: categoryId,
    text: searchDebouced,
  };

  const paramsWithoutCategoryIdText = {
    ...commonParams,
    text: searchDebouced,
  };

  const paramsWithoutTextWithCategoryId = {
    ...commonParams,
    category_id: categoryId,
  };

  const paramsWithoutTextWithoutCategoryId = {
    ...commonParams,
  };

  const {
    data: indicatorsData,
    isFetching: isFetchingIndicators,
    isLoading: isLoadingIndicators,
    isError: isIndicatorsErrored,
  } = useQuery(
    ['indicators', page, categoryId, searchDebouced, indicatorFilterType],
    async () => {
      const { data } = await api.get<IndicatorsProps>('/indicators', {
        params:
          categoryId !== 'ALL' && searchDebouced
            ? paramsWithCategoryIdText
            : categoryId === 'ALL' && searchDebouced
            ? paramsWithoutCategoryIdText
            : categoryId !== 'ALL' && !searchDebouced
            ? paramsWithoutTextWithCategoryId
            : paramsWithoutTextWithoutCategoryId,
      });

      return data;
    },
    {
      staleTime: 1000 * 60,
      enabled:
        ((!(breadcumbs.length === 0) && search === '') ||
          !!indicatorFilterType ||
          indicatorFilterType === null) &&
        !!categoryId,
      onSettled: () => {
        dispatch(setLastSearch(search));
      },
    },
  );

  useEffect(() => {
    openIndicatorFilters(true);
    openPremiumFilters(false);
    if (indicatorsData) {
      setIndicators(indicatorsData);
    }
  }, [indicatorsData, openIndicatorFilters, openPremiumFilters]);

  const category = breadcumbs[breadcumbs.length - 1]?.category_name[language];

  const indicatorNotFoundMessage =
    breadcumbs.length === 1
      ? `${translate('indicatorSearchFail')} "${lastSearch}".`
      : `${translate('indicatorSearchFail')} "${lastSearch}" ${translate(
          'indicatorsIn',
        )} ${category}.`;

  return (
    <Container>
      <Head title={translate('indicatorsTitle')} />

      <IndicatorsContent className="containerLinear">
        <Card
          textCard={translate('indicatorsTitle')}
          textDescription={translate('indicatorsDescription')}
        />

        {isIndicatorsErrored && breadcumbs.length > 0 ? (
          <ContainerMaintenance
            content="indicator"
            text={translate('indicatorsUnableToLoadIndicators')}
          />
        ) : isFetchingIndicators ||
          isLoadingIndicators ||
          breadcumbs.length === 0 ? (
          // eslint-disable-next-line prettier/prettier, react/jsx-indent
          <>
            <ListIndicatorContainer>
              {Array.from({ length: QUANTITY_ITEMS_PAGE - 1 }).map(
                (_, index) => (
                  <IndicatorCard
                    key={`indicator-loading${index.toString()}`}
                    data-testid={`indicator-loading-${(index + 1).toString()}`}
                    loading
                  />
                ),
              )}
            </ListIndicatorContainer>

            {indicators?.total && (
              <Pagination
                total={indicators?.total}
                page={page}
                setPage={(selectedPage) => {
                  dispatch(setPage(selectedPage));
                }}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('indicatorsPaginationName')}
              />
            )}
          </>
        ) : indicators && indicators.total > 0 ? (
          <>
            <ListIndicatorContainer data-cy="list-indicator-container">
              {indicators?.data?.map((indicator) => (
                <IndicatorCard
                  key={`indicator-${indicator.indicator_code}`}
                  data-testid={`card-indicator-${indicator?.name['en-us']
                    .replaceAll(' ', '-')
                    .toLocaleLowerCase()}`}
                  indicator={indicator}
                />
              ))}
            </ListIndicatorContainer>

            {indicators?.total && (
              <Pagination
                total={indicators?.total}
                page={page}
                setPage={(selectedPage) => {
                  dispatch(setPage(selectedPage));
                }}
                quantityItemsPerPage={QUANTITY_ITEMS_PAGE}
                name={translate('indicatorsPaginationName')}
              />
            )}
          </>
        ) : (
          indicators &&
          indicators.total === 0 &&
          !isLoadingIndicators && (
            <ContainerWithoutIndicators>
              <Status
                type="noSearchResults"
                title={
                  lastSearch.length >= 1
                    ? indicatorNotFoundMessage
                    : `${translate('indicatorFindAnyCategory')}`
                }
                description={translate(
                  'indicatorTryBrowsingCategoriesOrSearch',
                )}
              />
            </ContainerWithoutIndicators>
          )
        )}
      </IndicatorsContent>
    </Container>
  );
};
