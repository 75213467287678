import styled from 'styled-components';

export const SelectProjectsContainer = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  margin-top: 1rem;

  height: 30rem;
  overflow-y: auto;

  max-height: 30rem;
`;

export const ProjectCard = styled.button`
  display: flex;
  align-items: center;

  padding: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0.5rem;

  label {
    padding-left: 2.125rem;
    pointer-events: none;
  }

  input {
  }

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;

    img {
      height: 2rem;
      width: 2rem;
      border-radius: 3.2px;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.25rem;

      h4 {
        font-weight: 500;
        font-size: 0.875rem;

        color: ${({ theme }) => theme.colors.gray6};
      }

      p {
        font-weight: 400;
        font-size: 0.75rem;

        color: ${({ theme }) => theme.colors.gray4};
      }
    }
  }

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
