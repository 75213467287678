import { AxiosError } from 'axios';

import apiWorkspace from '../service/api';

type LockEditionResponse = {
  canEdit: boolean;
  error?: {
    isPublishing?: boolean;
    userIsEditing?: string;
  };
};

export const lockEdition = async (
  workspaceId: string,
): Promise<LockEditionResponse> => {
  try {
    await apiWorkspace.patch(`/workspaces/${workspaceId}/edit`);

    return { canEdit: true };
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 400) {
      if (
        error.response?.data?.detail?.detail?.startsWith(
          'Workspace already locked for editing by ',
        )
      ) {
        return {
          canEdit: false,
          error: {
            userIsEditing: error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          },
        };
      }
      if (
        error.response?.data?.detail?.detail ===
        'You cannot perform this action, Workspace is publishing a new version.'
      ) {
        return {
          canEdit: false,
          error: {
            isPublishing: true,
          },
        };
      }
    }

    return {
      canEdit: false,
    };
  }
};

export const unlockEdition = async (workspaceId: string): Promise<void> => {
  try {
    await apiWorkspace.delete(`/workspaces/${workspaceId}/edit`);
    // eslint-disable-next-line no-empty
  } catch {}
};
