import styled from 'styled-components';

type ToggleSwitchProjectionProps = {
  position: 'start' | 'middle' | 'end' | 'alone';
};

export const ToggleSwitchProjectionContainer = styled.div<ToggleSwitchProjectionProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  text-align: start;
  gap: 1rem;

  padding: 1rem;

  z-index: 1001;

  transition: all 0.2s;

  border-radius: ${({ position }) =>
    position === 'start'
      ? '5px 5px 0 0'
      : position === 'end'
      ? '0 0 5px 5px'
      : position === 'alone' && '5px'};

  cursor: pointer !important;

  > p {
    color: ${({ theme }) => theme.colors.gray5} !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 150% !important;

    opacity: 1 !important;

    flex: 1;
  }

  &:disabled {
    cursor: not-allowed !important;

    > p,
    > svg {
      opacity: 0.6 !important;
    }
  }

  &:hover {
    background: ${({ color, theme }) =>
      `${color || theme.colors.primary}14`} !important;

    > p,
    > svg {
      color: ${({ color, theme }) => color || theme.colors.primary} !important;
    }

    &:disabled {
      background: ${({ theme }) => theme.colors.gray1} !important;

      > p {
        color: ${({ theme }) => theme.colors.gray5} !important;
      }

      > svg {
        color: ${({ theme }) => theme.colors.gray4} !important;
      }
    }
  }
`;
