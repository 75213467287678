export const MySeriesENUSTranslate = {
  mySeriesTitle: 'My Series',
  mySeriesDescription: 'List of all the external series you have uploaded',
  mySeriesYouHaveNotUploaded: "You haven't uploaded any series yet",
  mySeriesClickTheButtonAbove:
    'Click the button above to upload your externally modeled series.',
  mySeriesInvalidFormatFile: 'Invalid file format',
  mySeriesSearchPlaceholder: 'Search serie',
  mySeriesSearchFail: `We couldn't find any serie containing`,
  mySeriesFetchError: 'Unable to load series',
  mySeriesButtonUpload: 'Upload new series',
  mySeriesUploadYourSeries: 'Upload your series',
  mySeriesUploading: 'uploading',
  mySeriesCreateSerieErrorTitle: 'Failed to create',
  mySeriesCreateSerieErrorDescription:
    'An error occurred while creating the series {{serie}}. Please try again later.',
  mySeriesDeleteSerieErrorTitle: 'Failed to delete',
  mySeriesDeleteSerieErrorDescription:
    'An error occurred while deleting the series {{serie}}. Please try again later.',
  mySeriesUploadFileUploadYourFile: 'Upload your file',
  mySeriesUploadFileDragItHere: 'Drag it here or click to select it manually.',
  mySeriesUploadFileViewRequirements: 'View requirements',
  mySeriesUploadFileEnterTheTag: 'Enter the tag for this series',
  mySeriesOverwriteTitle_one: 'Series already available',
  mySeriesOverwriteTitle_other: 'Series already available',
  mySeriesOverwriteDescription_one:
    'The series {{series}} is already available.<br/>Do you want to overwrite the existing series?',
  mySeriesOverwriteDescription_other:
    'The series {{series}} are already available.<br/>Do you want to overwrite the existing series?',
  mySeriesUploadErrorTitle: 'Unable to upload',
  mySeriesUploadErrorLeast3Characters:
    'Series names should contain at least 3 characters',
  mySeriesUploadErrorMaximum50Characters:
    'Series names should contain a maximum of 50 characters.',
  mySeriesUploadErrorDefault:
    'An error occurred during the upload. Please try again later.',
  mySeriesNoEspecialCharacters: 'No special characters allowed',
};
