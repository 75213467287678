import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  max-width: calc(100vw - 2rem);
  width: 70rem;
`;

export const ImageContainer = styled.div`
  width: 60%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 2rem;

  background: ${({ theme }) => theme.colors.gray0};
  border-radius: 8px 0 0 8px;

  img {
    width: 100%;
  }
`;

export const InformationContainer = styled.div`
  width: 40%;
`;

export const InformationContent = styled.div`
  padding: 2.5rem;

  max-height: calc(100vh - 8rem);
  height: 32rem;

  overflow-y: auto;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const ITEM_COLOR = {
  gray: 'gray5',
  blue: 'primary',
  green: 'green4',
} as const;

type ItemProps = {
  color: keyof typeof ITEM_COLOR;
};

export const Item = styled.li<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  gap: 1.5rem;

  p {
    font-size: 1rem;
    font-weight: 600;
    line-height: 150%;
    color: ${({ color, theme }) => theme.colors[ITEM_COLOR[color]]};

    flex: 1;

    align-self: center;
  }

  svg {
    width: 2rem;
    height: 2rem;

    color: ${({ color, theme }) => theme.colors[ITEM_COLOR[color]]};
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;

  padding: 1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
`;
