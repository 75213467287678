import React, { useContext } from 'react';

import { HourglassMedium, MagnifyingGlass } from '@phosphor-icons/react';

import { StatusProps } from '../types';
import { AwaitingIcon } from '../styles';
import { PlanningFlowContext } from '../..';

export const AwaitingApprovalStatus: React.FC<StatusProps> = ({
  stepId,
  isSelected,
  isDisabled,
}) => {
  const { handleAccessStep, isConfigurationMode } =
    useContext(PlanningFlowContext);

  if (isDisabled) return null;

  return (
    <AwaitingIcon
      type="button"
      onClick={() =>
        handleAccessStep &&
        !isConfigurationMode &&
        handleAccessStep(stepId, 'awaiting_approval')
      }
      data-testid="button-waiting-approval"
    >
      {isSelected || isConfigurationMode ? (
        <HourglassMedium data-testid="hourglass-waiting-approval-icon" />
      ) : (
        <MagnifyingGlass data-testid="magnifying-glass-waiting-approval-icon" />
      )}
    </AwaitingIcon>
  );
};
