export const categoriesSidebarPTBRTranslate = {
  categoriesSidebarFilters: 'Filtrar pesquisa',
  categoriesSidebarDescription:
    'Procure o que você quer ou navegue entre nossos filtros e categorias.',
  categoriesSidebarCountries: 'PAÍS',
  categoriesSidebarIndicatorType: 'TIPOS DE INDICADOR',
  categoriesSidebarSelectCountries: 'Selecione o país',
  categoriesBoxSidebarTitle: 'CATEGORIAS',
  categoriesAllSelectedOnCountryFilter:
    'Selecione um país para explorar as categorias',
};
