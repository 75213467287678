import styled, { css } from 'styled-components';
import { Link as LinkRouterDom } from 'react-router-dom';

export const MyProjectsAndSeriesHeaderContainer = styled.div`
  display: flex;
  gap: 1rem;

  margin-bottom: 1.5rem;
`;

type LinkProps = {
  isSelected: boolean;
};

export const Link = styled(LinkRouterDom)<LinkProps>`
  padding: 0.75rem 1rem;
  border-radius: 5px;

  font-size: 0.875rem;

  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          background-color: ${() => theme.colors.primary}1E;

          font-weight: 600;
          color: ${() => theme.colors.primary};

          cursor: not-allowed;
        `
      : css`
          background-color: ${() => theme.colors.white};
          border: 1px solid ${() => theme.colors.gray2};

          font-weight: 500;
          color: ${() => theme.colors.gray4};

          cursor: pointer;

          transition: 0.2s all ease-in-out;

          &:hover {
            border: 1px solid ${() => theme.colors.gray3};
            color: ${() => theme.colors.gray5};
          }
        `}
`;
