import { CheckBox } from 'src/components/CheckBox';
import { Tr } from 'src/components/Table';
import styled, { css, keyframes } from 'styled-components';

type RenameVariableContainerProps = {
  error: boolean;
};

export const RenameVariableAndModelIDContainer = styled.div<RenameVariableContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  > div:first-of-type {
    > div {
      border: none !important;
      padding: 0 !important;

      height: fit-content !important;
    }
  }

  textarea {
    min-height: 1.5rem;
    height: 1.5rem;
    overflow: hidden;
  }

  textarea:disabled {
    background: white;

    cursor: default;

    ${({ error }) =>
      error &&
      css`
        color: ${({ theme }) => theme.colors.gray4};
      `}
  }

  button {
    padding: 0;
    height: fit-content;

    transition: all 0.2s;

    svg {
      width: 1.25rem;
      height: 1.25rem;

      margin: 0.25rem 0;

      color: ${({ theme }) => theme.colors.gray4};
    }

    &:hover:not(:disabled) {
      svg {
        color: ${({ theme }) => theme.colors.gray5};
      }
    }

    &:disabled {
      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }
`;

export const TrContent = styled(Tr)`
  td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    height: 4.8125rem !important;
  }

  .firstTd {
    border-right: 1px solid ${({ theme }) => theme.colors.gray2};
  }

  .projectLastTd {
    padding-right: 1rem;
  }

  .withoutPaddingLeft {
    padding-left: 0;
  }
`;

export const WarningIcon = styled.div`
  min-width: 1.5rem;
  min-height: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  background: ${({ color }) => `${color}1F`};

  svg {
    color: ${({ color }) => color};
  }
`;

const ErrorAppearanceAnimation = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

type ErrorContainerProps = {
  visible: boolean;
};

export const ErrorContainer = styled.div<ErrorContainerProps>`
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;

  width: 22rem;

  padding: 1rem;

  border-radius: 5px;

  background: ${({ theme }) => `${theme.colors.red2}14`};

  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colors.red2};

  ${({ visible }) =>
    visible &&
    css`
      animation: ${ErrorAppearanceAnimation} 0.7s;
    `}

  @media (max-width: 1366px) {
    width: 17rem;
  }
`;

export const ModelUpdateContainer = styled.div`
  height: 2.75rem;

  > p,
  > span {
    padding-left: 2px !important;
  }

  > p {
    padding-top: 2px !important;
  }

  .select__control,
  .select__control--is-focused {
    border: none !important;
    box-shadow: none !important;

    &:hover {
      border: none !important;
    }
  }

  .select__value-container {
    padding: 0 !important;
  }

  .select__indicator {
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }

  .select__value-container {
    height: 2.75rem;
  }

  .select__single-value,
  .select__option {
    white-space: pre-wrap;
  }

  .model-update-date,
  > span {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;

    color: ${({ theme }) => theme.colors.gray4};

    margin-top: 0.25rem;
  }

  .select__option--is-disabled {
    cursor: not-allowed;
  }

  .select__control--is-disabled {
    background-color: ${({ theme }) => theme.colors.white};

    .select__dropdown-indicator {
      svg {
        color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }

  .unselected {
    .model-update-name {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const CheckBoxInflate = styled(CheckBox)`
  span {
    left: unset;
    right: 0px;
  }
`;
