import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;

  min-height: calc(100vh - 4.625rem - 7.875rem);

  > div {
    height: calc(100vh - 4.625rem - 7.938rem);

    @media (max-height: 768px) {
      height: 38rem;
    }
  }

  .container-maintenance {
    strong {
      margin-top: 0.5rem;
    }
  }

  .naked-button-control-panel {
    padding: 0;
    height: fit-content;

    color: ${({ theme }) => theme.colors.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray3};

      > div > div {
        border: 3px solid ${({ theme }) => theme.colors.primaryLight};
        border-left-color: ${({ theme }) => theme.colors.gray2};
      }
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    > div > div {
      border: 3px solid ${({ theme }) => theme.colors.primary};
      border-left-color: ${({ theme }) => theme.colors.gray3};
    }
  }
`;

export const WorkspaceInfoContainer = styled.div`
  width: 70% !important;

  display: flex;
  flex-direction: column;

  flex: 1;

  @media (max-width: 1366px) {
    width: 65% !important;
  }
`;

export const WorkspaceBasicInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;

  > img {
    width: 4rem;
    height: 4rem;

    border-radius: 8px;
  }
`;

export const NameDescriptionContainer = styled.div`
  width: 100%;

  flex: 1;

  h2 {
    color: ${({ theme }) => theme.colors.gray6};
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;

    white-space: pre-line;
    word-break: break-word;
  }

  > p {
    margin-top: 0.5rem;
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 150%;
    white-space: pre-line;
    word-break: break-word;
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const WorkspaceIcon = styled.div`
  svg {
    width: 4rem;
    height: 4rem;

    border-radius: 8px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: ${({ theme }) => theme.colors.gray4};
  }

  h3 {
    color: ${({ theme }) => theme.colors.gray4};

    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  flex: 0 0 auto;

  padding-top: 1.5rem;
  margin-top: 1.5rem;

  border-top: 1px solid ${({ theme }) => theme.colors.gray1};

  .naked-button-blue {
    height: fit-content;

    padding: 0 !important;

    color: ${({ theme }) => theme.colors.primary};

    transition: all 0.2s;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .warning-button {
    padding: 0;

    svg {
      width: 1.75rem;
      height: 1.75rem;

      color: ${({ theme }) => theme.colors.yellow4};

      transition: all 0.2s;
    }

    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.yellow5};
      }
    }
  }
`;

export const WorkspaceVariablesContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;

  overflow-y: hidden;

  position: relative;

  > div:not(:last-of-type) {
    flex: 0 0 auto;
  }
`;

export const ContainerModal = styled.div`
  width: 29.063rem;
  padding: 2rem 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > svg {
    color: ${({ theme }) => theme.colors.red3};
  }

  > h1 {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1.5rem 0 0.75rem;
    color: ${({ theme }) => theme.colors.gray6};
  }

  > p {
    color: ${({ theme }) => theme.colors.gray5};
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
  }

  button {
    display: flex;
    justify-content: center;
    padding: 0.75rem 2rem;
    height: 2.5rem;

    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
  }
`;
