const viewFeaturesEN = {
  viewFeaturesTitle: 'View Series',
  viewFeaturesSeriesDescription:
    'See the chart of this time series, browse the available openings, and find more information about this indicator.',
  viewFeaturesDescription: 'Description',
  viewFeaturesRegion: 'Region',
  viewFeaturesVariable: 'Variable',
  viewFeaturesFrequency: 'Frequency',
  viewFeaturesPrimaryTr: 'Primary Transformation',
  viewFeaturesSecondaryTr: 'Secondary Transformation',
  viewFeaturesLine: 'Line',
  viewFeaturesBar: 'Bar',
  viewFeaturesArea: 'Area',
  viewFeaturesProjection: 'Forecast',
  viewFeaturesSendToGroup: 'Send to a Group',
  viewFeaturesSendToGroupButton: 'Send to Group',
  viewFeaturesSendToGroupDescription:
    'Send one or more series of this indicator to your groups.',
  viewFeaturesExportSeries: 'Export Series',
  viewFeaturesExportSeriesDescription:
    'Export the information for that series to a spreadsheet.',
  viewFeaturesUnableSerie: 'Unable to load this serie.',
  viewFeaturesUpdateInfo:
    'Oops! The series is under maintenance due to some updates. It will be available again soon.',
  viewFeatureStartHistoric: 'Start of historical data',
  viewFeatureEndHistoric: 'End of historical data',
  viewFeatureStartProjection: 'Start of forecast data',
  viewFeatureEndProjection: 'End of forecast data',
  viewFeatureLastUpdated: 'Last Updated',
  viewFeatureLastUpdateObservation: 'Last historical Update',
  viewFeatureLastUpdateProjection: 'Last forecast Update',
  viewFeatureStoreBlueMessage:
    'You don’t have access to this Feature Store series',
  viewFeatureHistorical: 'Historical',
  viewFeatureProjection: 'Forecast',
  viewFeatureCopySerieToClipboard: 'Copy serie code',
  viewFeatureCopySeriesToClipboard: 'Copy series code',
  viewFeaturesSaveToGroup: 'Save to a group',
  viewFeaturesLoadingSeries:
    'Please wait for the information to be loaded before changing this option',
};

export default viewFeaturesEN;
