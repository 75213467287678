import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    width: 100%;
  }

  > button {
    padding: 0;
    height: fit-content;

    color: ${({ theme }) => theme.colors.primary};

    transition: all 0.2s;

    &:not(:disabled):hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray3};
    }

    margin-bottom: 0.5rem;
  }
`;

export const AggregationOptionContainer = styled.div`
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > div:first-of-type {
    gap: 0.5rem;

    > svg {
      width: 1rem !important;
      height: 1rem !important;
    }
  }

  div + div {
    margin-top: 0.5rem;
  }

  button {
    width: 100%;
  }

  button + button {
    margin-left: 1rem;
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;

    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;
  }
`;

type AggregationOptionsProps = {
  isIndividualConfiguration: boolean;
};

export const AggregationOptions = styled.div<AggregationOptionsProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  ${({ isIndividualConfiguration }) =>
    isIndividualConfiguration &&
    css`
      button {
        opacity: 0.24 !important;

        cursor: default !important;
      }
    `}
`;

export const IndividualConfiguration = styled.div`
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 0.25rem 1rem;

  border-radius: 999px;
  background: ${({ theme }) => theme.colors.gray1};

  font-size: 0.75rem;
  font-weight: 500;
  line-height: normal;

  color: ${({ theme }) => theme.colors.gray5};
`;

type SelectOptionButtonProps = {
  selected: boolean;
};

export const SelectOptionButton = styled.button<SelectOptionButtonProps>`
  height: 2.75rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 0.75rem 1rem;
  outline: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 5px;

  font-size: 0.75rem;
  font-weight: ${({ selected }) => (selected ? '600' : '500')};
  color: ${({ theme }) => theme.colors.gray4};

  transition: all 0.2s ease-in;

  svg {
    width: 2.5rem;
    height: 1.25rem;

    path {
      stroke: ${({ theme }) => theme.colors.gray4};

      transition: all 0.2s ease-in;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ selected }) =>
    selected
      ? css`
          outline: 2px solid ${({ theme }) => theme.colors.primary};

          color: ${({ theme }) => theme.colors.primary};

          svg path {
            stroke: ${({ theme }) => theme.colors.primary};
          }
        `
      : css`
          &:not(:disabled):hover {
            outline-color: ${({ theme }) => theme.colors.gray3};
            color: ${({ theme }) => theme.colors.gray5};

            svg path {
              stroke: ${({ theme }) => theme.colors.gray5};
            }
          }
        `}
`;
