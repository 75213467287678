import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;

  position: relative;

  padding: 0.75rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  margin-bottom: 1.5rem;

  transition: all 0.2s;

  cursor: pointer;

  > input {
    width: 100%;
    max-width: 19rem;
    min-width: 11.25rem;

    border: none;
    outline: none;

    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;

    color: ${({ theme }) => theme.colors.gray5};

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray4};
    }

    &:disabled {
      background: white;
    }
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.gray3};
  }
`;

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;

  padding: 0.25rem 0.75rem;

  border-radius: 999px;

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 150%;
`;

export const DefaultTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.primary};
  background: rgba(76, 148, 255, 0.12);
`;

export const WorkspaceTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.secondary};
  background: rgba(242, 125, 180, 0.12);

  button {
    font-size: 0px;

    svg {
      width: 0.875rem;
      height: 0.875rem;

      color: ${({ theme }) => theme.colors.secondary};

      transition: all 0.2s;

      &:hover {
        color: ${({ theme }) => theme.colors.secondaryDark};
      }
    }
  }
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 15.5rem;

  display: flex;
  flex-direction: column;

  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;

  overflow-y: auto;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 4px;

  box-shadow: 0px 4px 8px 0px rgba(45, 55, 72, 0.12);

  background-color: white;

  z-index: 2;
`;

export const Option = styled.button`
  width: 100%;

  text-align: start;
  color: ${({ theme }) => theme.colors.gray5};
  font-size: 0.875rem;

  padding: 0.75rem 1rem;

  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.gray1};
  }
`;

export const EmptyOption = styled.div`
  width: 100%;

  text-align: center;
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.875rem;

  padding: 0.75rem 1rem;
`;

export const MenuInformation = styled.div`
  padding: 0.5rem 1rem;
  padding-top: 0.75rem;

  cursor: auto;

  p {
    font-size: 0.75rem;

    color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const NewOption = styled.button`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  position: sticky;
  bottom: 0;

  padding: 0.75rem 1rem;

  background: white;

  p {
    font-size: 0.875rem;
    font-weight: 600;

    color: ${({ theme }) => theme.colors.gray5};
  }

  span {
    padding: 0.25rem 0.75rem;

    border-radius: 999px;

    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;

    color: ${({ theme }) => theme.colors.secondary};
    background: rgba(242, 125, 180, 0.12);
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gray1};
  }
`;

export const ErrorInfo = styled.p`
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.red4} !important;
`;

export const Spinner = styled.div`
  border: 3px solid ${({ theme }) => `${theme.colors.secondaryLight}cc`};
  border-left-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
