import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { Status } from 'src/components/Status';

import { OverwriteModalContainer } from './styles';

type OverwriteModalProps = {
  onCancel: () => void;
  onContinue: () => void;
  series: string[];
};

export const OverwriteModal: React.FC<OverwriteModalProps> = ({
  onCancel,
  onContinue,
  series,
}) => {
  const { t } = useTranslation();

  return (
    <Modal visible setVisible={onCancel}>
      <OverwriteModalContainer>
        <Status
          type="warning"
          dataCy="overwrite-modal-content"
          isModal
          title={t('mySeriesOverwriteTitle', { count: series.length })}
          description={t('mySeriesOverwriteDescription', {
            count: series.length,
            series: series.toString(),
          })}
        />
      </OverwriteModalContainer>
      <ModalFooter>
        <Button
          buttonType="naked"
          onClick={onCancel}
          data-testid="overwrite-button-cancel"
          data-cy="overwrite-button-cancel"
        >
          {t('cancel')}
        </Button>
        <Button
          buttonType="primary"
          onClick={onContinue}
          data-testid="overwrite-button-continue"
          data-cy="overwrite-button-continue"
        >
          {t('continue')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
