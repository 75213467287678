import styled, { css, keyframes } from 'styled-components';

type PropsContainer = {
  visible: boolean;
};

export const Container = styled.div<PropsContainer>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: ${({ visible }) => (visible ? 'flex' : 'none')} !important;
  justify-content: center;
  align-items: center;
  z-index: 2001;
`;

const opacityAnimation = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity:1;
  }
`;

const defaultAnimation = keyframes`
  from{
    opacity: 0;
    transform: translate3d(0,-60px,0);
  }
  to{
    opacity:1;
    transform:translate3d(0,0,0);
  }
`;

type PropsContent = {
  visible: boolean;
  animation: boolean;
  hasFooter?: boolean;
  animationName: 'opacityAnimation' | 'defaultAnimation';
};

export const Content = styled.div<PropsContent>`
  ${({ hasFooter }) =>
    hasFooter &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}

  background: ${({ theme }) => theme.colors.white};
  padding: 1.5rem;
  border-radius: 8px;
  z-index: 2002;

  ${({ animation, animationName }) =>
    animation &&
    css`
      animation-name: ${animationName === 'opacityAnimation'
        ? opacityAnimation
        : defaultAnimation};
      animation-duration: 1.5s;
    `};

  ${({ visible }) =>
    visible === false &&
    css`
      display: none;
    `};
`;
