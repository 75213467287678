import React from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { MetricsByPeriod } from './components/MetricsByPeriod';
import { RealVsForecast } from './components/RealVsForecast';
import { Container } from './styles';
import { ProjectionsVariation } from './components/ProjectionsVariation';
import { ButtonSyncAdjust } from '../../components/ButtonSyncAdjust';

export const WorkspaceVariablePerformance: React.FC = () => {
  const { t: translate } = useTranslation();
  const { releaseSelected } = useSelector(
    (state: RootState) => state.workspace,
  );

  const showButtonSyncAdjust = !releaseSelected?.data.approval_flow?.enable;

  return (
    <Container>
      <Head title={translate('labelVariablePerformance')} />

      <div className="containerLinear page-title">
        <Card
          textCard={translate('labelVariablePerformance')}
          textDescription={translate(
            'workspaceLabelVariablePerformanceDescription',
          )}
        />
        {showButtonSyncAdjust && <ButtonSyncAdjust />}
      </div>

      <RealVsForecast />
      <ProjectionsVariation />
      <MetricsByPeriod />
    </Container>
  );
};
