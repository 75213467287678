const homeENTranslations = {
  homeWelcome: 'Welcome to the platform',
  homeModuleQuestion: 'Which module would you like to access?',
  homeEnterButton: 'Enter',
  homeFeatureStoreSubheading: 'Economic Data',
  homeFeatureStoreTitle: 'Feature Store',
  homeFeatureStoreDescription:
    'Access reliable economic data and projections. Ready to use. Everything in one place.',
  homeModelsSubheading: 'AI Models',
  homeModelsTitle: 'Models',
  homeModelsDescription:
    'Create forecast models simply. Large-scale testing and selection of best models. Fast, accurate results.',
  homeWorkspacesSubheading: 'Integrated Planning',
  homeWorkspacesTitle: 'Workspaces',
  homeWorkspacesDescription:
    'Create a collaborative work environment. Results and performance insights. Effective governance.',
  homeAdminOptions: 'Admin Options',
  homeAdminUserControl: 'User control',
  homeAdminFeatureStoreIndicators: 'Feature Store indicators',
  homeAdminAccessGroups: 'Access groups',
  homeDiscoverOurPlans: 'Discover our plans',
};

export default homeENTranslations;
