import styled from 'styled-components';

export const LoadingCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  padding: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 0.5rem;

  > div:nth-child(1) {
    width: 1.125rem;
    height: 1.125rem;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    gap: 1rem;

    width: 100%;

    > div:nth-child(1) {
      width: 2rem;
      height: 2rem;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      width: 100%;

      div:nth-child(1) {
        width: 30%;
        height: 1.375rem;
      }

      div:nth-child(2) {
        width: 42%;
        height: 0.9375rem;
      }
    }
  }
`;
