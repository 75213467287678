import styled from 'styled-components';

export const DivSearchBox = styled.div`
  display: flex;
  gap: 1.5rem;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > div:first-child {
    margin-bottom: 0px;
  }

  > div:nth-child(2) {
    display: flex;
    gap: 1.5rem;

    input {
      width: 15.625rem;
    }

    .inputAdjustPadding {
      div {
        > div {
          padding-top: 0.8125rem !important;
          padding-bottom: 0.8125rem !important;

          height: 2.8125rem;
        }
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;

  height: 3.125rem;
`;

export const ContainerWithoutGroups = styled.div`
  width: 100%;
  height: 37.188rem;
  padding: 1.5rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const List = styled.div`
  min-height: 31.5rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 1.5rem;

  margin-top: 1.5rem;

  @media (max-width: 1140px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
