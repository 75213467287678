const STORAGE_PROJECT_NAMES_KEY = 'projectNames';

export function storageProjectNamesGet(email: string): string[] {
  const allProjectNames = localStorage.getItem(STORAGE_PROJECT_NAMES_KEY);

  if (allProjectNames && email) {
    const userProjectNames = JSON.parse(allProjectNames)?.[email];

    if (userProjectNames) {
      return userProjectNames;
    }
  }

  return [];
}

export function storageProjectNamesSave(email: string, value: string): void {
  const oldProjectNames = storageProjectNamesGet(email);
  if (!oldProjectNames.includes(value)) {
    const userProjectNames = [value, ...oldProjectNames];

    const allProjects = JSON.parse(
      localStorage.getItem('projectNames') ?? '{}',
    );

    if (email) {
      localStorage.setItem(
        'projectNames',
        JSON.stringify({
          ...allProjects,
          [email]: userProjectNames,
        }),
      );
    }
  }
}

export function storageProjectNamesRemove(email: string, value: string): void {
  const oldProjectNames = storageProjectNamesGet(email);

  const userProjectNames = [...oldProjectNames].filter(
    (projectName) => projectName !== value,
  );

  const allProjects = JSON.parse(localStorage.getItem('projectNames') ?? '{}');

  if (email) {
    localStorage.setItem(
      'projectNames',
      JSON.stringify({
        ...allProjects,
        [email]: userProjectNames,
      }),
    );
  }
}
