import api from 'src/models/service/api';
import apiWorkspace from 'src/workspaces/service/api';
import { useQuery } from 'react-query';

type Project = {
  id: string;
  created: string;
  parent_id: string | null;
};

type Updates = {
  id: string;
  label: string;
  status: string;
  created: string;
};

export type ModelUpdateProps = {
  selected: string;
  updates: Updates[];
};

export type ModelUpdateData = {
  value: string;
  label: string;
  isDisabled: boolean;
};

type UseModelUpdateResponse = {
  modelUpdateData: ModelUpdateData[] | undefined;
  modelUpdateIsLoading: boolean;
  modelUpdateIsFetching: boolean;
};

export const useModelUpdate = (
  project: Project,
  workspaceId: string | undefined,
  isEdition: boolean,
): UseModelUpdateResponse => {
  const requestApi = isEdition ? apiWorkspace : api;

  const {
    data: modelUpdateData,
    isLoading: modelUpdateIsLoading,
    isFetching: modelUpdateIsFetching,
  } = useQuery(
    ['project updates', project.id],
    async () => {
      const originalProject = {
        value: project.id,
        label: `Original\n${project.created}`,
        isDisabled: false,
      };

      try {
        const { data } = await requestApi.get<ModelUpdateProps>(
          `${isEdition ? `/workspaces/${workspaceId}/users` : ''}/projects/${
            project.parent_id ?? project.id
          }/updates`,
        );

        return [
          ...data.updates.map((update) => ({
            value: update.id,
            label: `${update.label}\n${update.created}`,
            isDisabled: update.status !== 'success',
          })),
          originalProject,
        ];
      } catch {
        return [originalProject];
      }
    },
    {
      staleTime: 5 * 1000 * 60,
      enabled: !isEdition || (isEdition && !!workspaceId),
    },
  );

  return { modelUpdateData, modelUpdateIsLoading, modelUpdateIsFetching };
};
