import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  height: 28.125rem;
`;

export const HeaderChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;

  div {
    display: flex;
    gap: 0.5rem;
  }
`;

export const FakeIconTypeChart = styled.span`
  display: flex;
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border-radius: 16px;
  background: rgba(76, 148, 255, 0.12);
`;

export const ResourcePaid = styled.div`
  display: flex;
  padding: 1.5rem 2rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.gray1};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 24px 0px rgba(160, 174, 192, 0.32);

  width: 21.4375rem;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    color: ${({ theme }) => theme.colors.gray5};
  }

  strong p {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
  }

  button {
    display: flex;
    padding: 0.75rem 1.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;

    border-radius: 0.3125rem;
    background: rgba(76, 148, 255, 0.12);

    width: 11.6875rem;

    color: ${({ theme }) => theme.colors.primary};
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;

    :hover {
      background: rgba(76, 148, 255, 0.2);
    }
  }
`;
