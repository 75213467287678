import React, { useContext } from 'react';

import { AxiosError } from 'axios';
import { parseISO } from 'date-fns';
import { PencilSimple } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { SelectedFilters } from 'src/components/SelectedFilters';
import { SelectedFilterOptions } from 'src/components/SelectedFilters/types';
import { translateSomeMessagesFromBackend } from 'src/i18n';
import { WorkspaceProjectionsContext } from 'src/workspaces/contexts/WorkspaceProjectionsContext';
import apiWorkspace from 'src/workspaces/service/api';
import { RootState } from 'src/redux/store';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

import {
  AnnualSummaryContainer,
  ContentTable,
  Legend,
  TdLabel,
  TdValue,
  Th,
} from './styles';
import { useQueryReleaseData } from '../../../../hooks/useQueryReleaseData';

interface Error {
  detail?: {
    description?: string;
    detail?: string;
  };
}

interface SummaryHistoricalForecast {
  dates: string[];
  values: number[];
}

interface SummaryResponse {
  historical: SummaryHistoricalForecast;
  forecast?: SummaryHistoricalForecast;
}

interface Summary {
  historical: SummaryHistoricalForecast;
  forecast: SummaryHistoricalForecast;
}

export const AnnualSummary: React.FC = () => {
  const {
    workspace,
    auth: { user },
    workspace: { frequency, releasePreview, releaseSelected },
    workspaceProjectionsOptions: { inflation, forecastType },
  } = useSelector((state: RootState) => state);

  const { listOfVariables, isLoadingListOfVariables, errorListOfVariables } =
    useContext(WorkspaceProjectionsContext);

  const { data: releaseData } = useQueryReleaseData(
    workspace.id,
    workspace.releaseSelected?.id,
  );

  const { t: translate } = useTranslation();

  const selectedFilterInflation =
    user.language === 'pt-br'
      ? `${translate('value')} ${inflation}`
      : `${transformUppercaseFirstLetter(inflation)} ${translate(
          'value',
        ).toLowerCase()}`;

  const selectedFilters: SelectedFilterOptions[] = [];

  const hasForecastLabel =
    frequency === 'monthly' && releaseSelected?.id !== releasePreview;

  hasForecastLabel &&
    selectedFilters.push({
      type: 'other',
      id: 'forecast',
      icon: <PencilSimple />,
      selected: translate(
        forecastType === 'adjusted'
          ? 'workspaceProjectionsMostRecent'
          : forecastType,
      ),
    });

  const hasInflationLabel = workspace.releaseSelected?.data.ys.some(
    (y) => y.is_inflated,
  );

  hasInflationLabel &&
    selectedFilters.push({
      type: 'inflation',
      selected: selectedFilterInflation,
    });

  const reversedSteps = [...(releaseData?.data?.steps ?? [])].reverse();

  let lastStepWithData = reversedSteps.findIndex(
    ({ status }) => status !== 'created',
  );

  if (lastStepWithData === -1) {
    lastStepWithData = 1;
  }

  const {
    data: dataLevel,
    isLoading: isLoadingLevel,
    isFetching: isFetchingLevel,
    isError: isErrorLevel,
    error: errorLevel,
  } = useQuery<Summary, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      lastStepWithData,
      'yearly',
      'level',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<SummaryResponse>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/series/raw/${workspace.ySelected?.y_label}?frequency=yearly&transformation=level&inflation=${inflationAux}&data_type=${forecastType}&step=${lastStepWithData}`,
      );

      return {
        historical: data.historical,
        forecast: {
          dates: data.forecast?.dates || [],
          values: data.forecast?.values || [],
        },
        serie: workspace.ySelected?.y_label,
      };
    },
    {
      enabled: !!workspace.id && !!workspace.ySelected && !!listOfVariables?.y,
      staleTime: 1000 * 60 * 20,
    },
  );

  const {
    data: dataVariation,
    isLoading: isLoadingVariation,
    isFetching: isFetchingVariation,
    isError: isErrorVariation,
  } = useQuery<Summary, AxiosError<Error>>(
    [
      'workspace',
      'projections',
      'series data',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
      forecastType,
      lastStepWithData,
      'yearly',
      'variation',
      inflation,
    ],
    async () => {
      const inflationAux = inflation === 'nominal' ? 'inflate' : 'original';

      const { data } = await apiWorkspace.get<SummaryResponse>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/series/raw/${workspace.ySelected?.y_label}?frequency=yearly&transformation=variation&inflation=${inflationAux}&data_type=${forecastType}&step=${lastStepWithData}`,
      );

      return {
        historical: data.historical,
        forecast: {
          dates: data.forecast?.dates || [],
          values: data.forecast?.values || [],
        },
        serie: workspace.ySelected?.y_label,
      };
    },
    {
      enabled: !!workspace.id && !!workspace.ySelected && !!listOfVariables?.y,
      staleTime: 1000 * 60 * 20,
    },
  );

  function returnMessageError() {
    let messageError = errorLevel?.response?.data?.detail?.detail;

    if (
      messageError ===
      'Annual series summary is only available for series with at least 1 year of observation.'
    ) {
      return messageError;
    }

    messageError =
      errorListOfVariables?.response?.data?.detail?.description ??
      errorLevel?.response?.data?.detail?.description;

    if (
      messageError === 'No data is available.' ||
      messageError === 'The requested resource does not exist.'
    ) {
      return 'Annual Series Rate is not available for this model';
    }

    return messageError;
  }

  return (
    <AnnualSummaryContainer
      className="containerLinear"
      data-testid="annual-summary-container"
    >
      <Card textCard={translate('workspaceProjectionsAnnualSummaryTitle')} />

      <SelectedFilters filters={selectedFilters} />

      {isErrorLevel || errorListOfVariables ? (
        <ContainerMaintenance
          content="chart"
          size="sm"
          text={translateSomeMessagesFromBackend(
            returnMessageError() ?? '',
            user.language,
          )}
        />
      ) : isLoadingListOfVariables ||
        isLoadingLevel ||
        isFetchingLevel ||
        !dataLevel ? (
        // eslint-disable-next-line prettier/prettier, react/jsx-indent
        <ContainerSkeleton
          style={{ height: '180px' }}
          data-testid="annual-summary-container-loading"
        />
      ) : (
        <>
          <ContentTable>
            <table>
              <thead>
                <tr>
                  <Th isProjection={false}>{}</Th>
                  {dataLevel.historical.dates.map((date) => (
                    <Th isProjection={false} key={`annual-summary-th-${date}`}>
                      {parseISO(date).getUTCFullYear()}
                    </Th>
                  ))}
                  {dataLevel.forecast.dates.map((date) => (
                    <Th isProjection key={`annual-summary-th-${date}`}>
                      {parseISO(date).getUTCFullYear()}
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TdLabel>
                    {translate('workspaceProjectionsAnnualSummaryLevel')}
                  </TdLabel>
                  {dataLevel.historical.values.map((value, index) => (
                    <TdValue
                      isProjection={false}
                      key={`annual-summary-level-td-${value}-${index.toString()}`}
                    >
                      <div>
                        <span>{formatCompactNotation(value)}</span>
                      </div>
                    </TdValue>
                  ))}
                  {dataLevel.forecast.values.map((value, index) => (
                    <TdValue
                      isProjection
                      key={`annual-summary-level-td-${value}-${index.toString()}`}
                    >
                      <div>
                        <span>{formatCompactNotation(value)}</span>
                      </div>
                    </TdValue>
                  ))}
                </tr>
                {isErrorVariation ? (
                  <tr>
                    <TdLabel>
                      {translate('workspaceProjectionsAnnualSummaryVariation')}
                    </TdLabel>
                    {Array.from(
                      { length: dataLevel.historical.values.length },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-historical-td-error-${index}`}
                        >
                          <div>
                            <span>--</span>
                          </div>
                        </TdValue>
                      ),
                    )}

                    {Array.from(
                      { length: dataLevel.forecast.values.length },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-forecast-td-error-${index}`}
                        >
                          <div>
                            <span>--</span>
                          </div>
                        </TdValue>
                      ),
                    )}
                  </tr>
                ) : isLoadingVariation ||
                  isFetchingVariation ||
                  !dataVariation ? (
                  // eslint-disable-next-line react/jsx-indent
                  <tr>
                    {Array.from(
                      {
                        length:
                          dataLevel.historical.values.length +
                          dataLevel.forecast.values.length +
                          1,
                      },
                      (_, index) => (
                        <TdValue
                          isProjection={false}
                          key={`annual-summary-variation-td-loading-${index}`}
                        >
                          <ContainerSkeleton
                            withLoading={false}
                            style={{ width: '65px', height: '28px' }}
                          />
                        </TdValue>
                      ),
                    )}
                  </tr>
                ) : (
                  <tr>
                    <TdLabel>
                      {translate('workspaceProjectionsAnnualSummaryVariation')}
                    </TdLabel>
                    {dataVariation.historical.dates.length !==
                      dataLevel.historical.dates.length && (
                      <TdValue isProjection={false}>
                        <div>
                          <span>--</span>
                        </div>
                      </TdValue>
                    )}
                    {dataVariation.historical.values.map((value, index) => (
                      <TdValue
                        isProjection={false}
                        key={`annual-summary-variation-td-${value}-${index.toString()}`}
                      >
                        <div>
                          <span>{formatCompactNotation(value)}%</span>
                        </div>
                      </TdValue>
                    ))}
                    {dataVariation.forecast.dates.length !==
                      dataLevel.forecast.dates.length && (
                      <TdValue isProjection>
                        <div>
                          <span>--</span>
                        </div>
                      </TdValue>
                    )}
                    {dataVariation.forecast.values.map((value, index) => (
                      <TdValue
                        isProjection
                        key={`annual-summary-variation-td-${value}-${index.toString()}`}
                      >
                        <div>
                          <span>{formatCompactNotation(value)}%</span>
                        </div>
                      </TdValue>
                    ))}
                  </tr>
                )}
              </tbody>
            </table>
          </ContentTable>
          <Legend>
            {!!dataLevel?.historical?.dates?.length && (
              <div data-testid="historical-legend">
                <div />
                <span>
                  {translate('workspaceProjectionsAnnualSummaryHistorical')}
                </span>
              </div>
            )}

            {!!dataLevel?.forecast?.dates?.length && (
              <div data-testid="forecast-legend">
                <div />
                <span>
                  {translate('workspaceProjectionsAnnualSummaryForecast')}
                </span>
              </div>
            )}
          </Legend>
        </>
      )}
    </AnnualSummaryContainer>
  );
};
