import React, { ReactElement, useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { Content } from './styles';
import { SideBar } from './SideBar';

export const WorkspaceLayout: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const location = useLocation();
  const contentRef = useRef<HTMLDivElement | null>(null);

  const {
    sideBar: { isExpanded },
  } = useSelector((state: RootState) => state);

  const hasSideBar =
    location.pathname.includes('/workspaces') &&
    !location.pathname.includes('/workspaces/new') &&
    !location.pathname.includes('/control-panel') &&
    location.pathname !== '/workspaces';

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.transition = hasSideBar
        ? 'width 0.75s'
        : 'unset';
    }
  }, [hasSideBar]);

  return (
    <>
      <Tooltip className="customTooltipWhite" id="menu-disabled-tooltip" />

      {hasSideBar && <SideBar />}

      <Content
        data-testid="layout-container-div-faas"
        data-cy="layout-container-div-faas"
        id="layout-container-div-faas"
        sidebarWidth={isExpanded ? '20rem' : '7rem'}
        hasSidebar={hasSideBar}
        ref={contentRef}
      >
        {children}
      </Content>
    </>
  );
};
