const STORAGE_SERIE_NAMES_KEY = 'serieNames';

export function storageSerieNamesGet(email: string): string[] {
  const allSerieNames = localStorage.getItem(STORAGE_SERIE_NAMES_KEY);

  if (allSerieNames && email) {
    const userSerieNames = JSON.parse(allSerieNames)?.[email];

    if (userSerieNames) {
      return userSerieNames;
    }
  }

  return [];
}

export function storageSerieNamesSave(email: string, value: string): void {
  const oldSerieNames = storageSerieNamesGet(email);
  if (!oldSerieNames.includes(value)) {
    const userSerieNames = [value, ...oldSerieNames];

    const allSeries = JSON.parse(localStorage.getItem('serieNames') ?? '{}');

    if (email) {
      localStorage.setItem(
        'serieNames',
        JSON.stringify({
          ...allSeries,
          [email]: userSerieNames,
        }),
      );
    }
  }
}

export function storageSerieNamesRemove(email: string, value: string): void {
  const oldSerieNames = storageSerieNamesGet(email);

  const userSerieNames = [...oldSerieNames].filter(
    (serieName) => serieName !== value,
  );

  const allSeries = JSON.parse(localStorage.getItem('serieNames') ?? '{}');

  if (email) {
    localStorage.setItem(
      'serieNames',
      JSON.stringify({
        ...allSeries,
        [email]: userSerieNames,
      }),
    );
  }
}
