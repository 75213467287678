import React, { CSSProperties, ForwardedRef, forwardRef } from 'react';

import { Info, XCircle } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';

import {
  ContainerLabel,
  ContainerInput,
  Input as InputStyle,
  ContentInput,
  DotLoadingContainer,
} from './styles';
import { Label } from '../Label';
import { ContainerSkeleton } from '../ContainerSkeleton';

export type InputProps = {
  icon?: React.ReactElement;
  invertedIcon?: boolean;
  label?: string;
  information?: string;
  style?: CSSProperties;
  error?: string;
  testid?: string;
  tooltipError?: string;
  loading?: boolean;
  hasIconError?: boolean;
  dotLoading?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef(
  (
    {
      icon,
      invertedIcon = false,
      label,
      information,
      style,
      max,
      testid,
      error,
      placeholder,
      tooltipError,
      loading = false,
      hasIconError = true,
      dotLoading = false,
      ...rest
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (label) {
      return (
        <div style={style}>
          <ContainerLabel>
            <Label>{label}</Label>
            {information && (
              <>
                <Info
                  size="1.125rem"
                  data-tooltip-id="input-tooltip"
                  data-tooltip-html={information}
                  data-cy={`input-information-${label?.replaceAll(' ', '-')}`}
                  data-testid="inputInfo"
                />
                <Tooltip id="input-tooltip" className="customTooltipTheme" />
              </>
            )}
          </ContainerLabel>
          <ContainerInput error={!!error} disabled={rest.disabled}>
            <ContentInput style={{ padding: loading ? '0px' : '0.75rem 1rem' }}>
              {loading ? (
                <ContainerSkeleton
                  withLoading={false}
                  {...rest}
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '7px',
                  }}
                />
              ) : (
                <>
                  {icon && invertedIcon === false && icon}
                  <InputStyle
                    data-testid={testid}
                    max={max}
                    icon={!!icon}
                    placeholder={placeholder}
                    data-cy={
                      label?.replaceAll(' ', '-') ??
                      placeholder?.toString().replaceAll(' ', '-') ??
                      undefined
                    }
                    ref={ref}
                    {...rest}
                  />
                  {!dotLoading && icon && invertedIcon && icon}
                  {error && hasIconError && (
                    <XCircle
                      size="1rem"
                      data-testid="icon_input_error_validation"
                      className="input-icon-error"
                    />
                  )}

                  {dotLoading && (
                    <DotLoadingContainer>
                      <span>.</span>
                      <span>.</span>
                      <span>.</span>
                    </DotLoadingContainer>
                  )}
                </>
              )}
            </ContentInput>
            <p
              data-testid={`input-${
                label?.replaceAll(' ', '-').toLocaleLowerCase() ??
                placeholder
                  ?.toString()
                  .replaceAll(' ', '-')
                  .toLocaleLowerCase() ??
                undefined
              }-error`}
            >
              {error}
            </p>
          </ContainerInput>
        </div>
      );
    }

    return (
      <ContainerInput error={!!error} style={style} disabled={rest.disabled}>
        <Tooltip id="input-tooltip-error" className="customTooltipTheme" />
        <ContentInput style={{ padding: loading ? '0px' : '0.75rem 1rem' }}>
          {loading ? (
            <ContainerSkeleton
              withLoading={false}
              {...rest}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '7px',
              }}
            />
          ) : (
            <>
              {icon && invertedIcon === false && icon}
              <InputStyle
                data-testid={testid}
                max={max}
                placeholder={placeholder}
                icon={!!icon}
                data-cy={
                  placeholder?.toString().replaceAll(' ', '-') ?? undefined
                }
                ref={ref}
                {...rest}
              />
              {icon && invertedIcon && icon}
              {error && hasIconError && (
                <XCircle
                  size="1rem"
                  data-testid="icon_input_error_validation"
                  data-tooltip-id="input-tooltip-error"
                  data-tooltip-html={tooltipError}
                  className="input-icon-error"
                />
              )}
            </>
          )}
        </ContentInput>

        <p>{error}</p>
      </ContainerInput>
    );
  },
);
