import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

type SelectedContainerProps = {
  loading: boolean;
};

export const SelectedContainer = styled.div<SelectedContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 20rem;

  margin-top: 0.5rem;

  cursor: ${({ loading }) => (loading ? 'progress' : 'pointer')};

  background-color: ${({ theme, loading }) =>
    loading ? theme.colors.gray0 : theme.colors.white};

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;

  color: ${({ theme }) => theme.colors.gray6};

  transition: all 0.2s;

  ${({ loading }) =>
    !loading &&
    css`
      &:hover {
        border: 1px solid ${({ theme }) => theme.colors.gray3};
      }
    `}

  input {
    background-color: ${({ theme, loading }) =>
      loading ? theme.colors.gray0 : theme.colors.white} !important;
  }

  button {
    cursor: ${({ loading }) => (loading ? 'progress' : 'pointer')};
  }
`;

export const InputContainer = styled.div`
  flex: 1;

  padding: 0.75rem 1rem;

  > div {
    > div {
      height: fit-content;

      border: none !important;

      padding: 0 !important;
    }

    input {
      height: 100%;

      vertical-align: middle;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      :disabled {
        pointer-events: none;
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  padding: 0.75rem 1rem 0.75rem 0;

  svg,
  button {
    width: 1rem;
    height: 1rem;
  }

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  .close-menu {
    transform: rotate(180deg);
  }

  button {
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const MenuContainer = styled.div`
  position: absolute;

  width: 100%;

  top: 100%;

  margin-top: 0.5rem;

  background-color: ${({ theme }) => theme.colors.white};

  border: 1px solid ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;

  z-index: 100;

  > div:first-of-type {
    padding: 1rem;

    > div {
      padding: 0.5rem 0.75rem !important;

      height: fit-content;
    }
  }
`;

export const OptionsMenu = styled.div`
  max-height: 16rem;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.313rem;
    height: 0.313rem;
    padding: 0.063rem;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }

  .close-menu {
    transform: rotate(180deg);
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray1};
  }

  button:not(:first-of-type) {
    width: fit-content;
    height: 2.5rem;

    padding: 0.75rem 1rem;
  }

  svg {
    color: ${({ theme }) => theme.colors.gray4};

    transition: all 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.gray5};
    }
  }
`;

export const RadioContainer = styled.button`
  width: 100%;
  height: 100%;

  flex: 1;

  padding: 0.75rem 0;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  text-align: start;

  label {
    width: 100%;

    p {
      font-size: 0.875rem !important;

      color: ${({ theme }) => theme.colors.gray5} !important ;
    }

    span {
      width: 0.875rem !important;
      height: 0.875rem !important;

      ::after {
        height: 0.25rem !important;
        width: 0.45rem !important;

        border-left: 2.5px solid white !important;
        border-bottom: 2.5px solid white !important;
      }
    }
  }

  > p {
    font-size: 0.875rem !important;

    color: ${({ theme }) => theme.colors.gray5};
  }
`;

export const NoOption = styled.p`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 0.875rem;

  padding: 0rem 1rem 1rem 1rem;

  text-align: center;
`;
