import React, { useCallback, useEffect, useState } from 'react';

import { Card } from 'src/components/Card';
import { MyProjectsAndSeriesHeader } from 'src/models/components/MyProjectsAndSeriesHeader';
import { SerieData, useQuerySeries } from 'src/models/hooks/useQuerySeries';
import { AutoComplete } from 'src/components/AutoComplete';
import { MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { Status } from 'src/components/Status';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { useSearchDebounce } from 'src/hooks/useSearchDebounce';
import {
  storageSerieNamesGet,
  storageSerieNamesRemove,
  storageSerieNamesSave,
} from 'src/models/storage/storageSerieNames';
import { useQuerySerieTags } from 'src/models/hooks/useQuerySerieTags';

import {
  MySeriesContainer,
  Content,
  MySeriesHeader,
  ContentAutoCompleteInput,
} from './style';
import { UploadYourSeriesMessage } from './components/UploadYourSeriesMessage';
import { UploadFile } from './components/UploadFile';
import { TableSeries } from './components/TableSeries';

export const MySeries: React.FC = () => {
  const [seriesToShow, setSeriesToShow] = useState<SerieData[]>([]);
  const [newSeries, setNewSeries] = useState<SerieData[]>([]);

  const [oldSerieNames, setOldSerieNames] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { serieTagsData } = useQuerySerieTags();

  const {
    searchValue,
    requestAllowed,
    searchError,
    isSearchValid,
    searchCompleted,
    setSearchValue,
    lastSearch,
  } = useSearchDebounce();

  const {
    auth: { user },
  } = useSelector((state: RootState) => state);

  const { t } = useTranslation();

  const searchAdjusted =
    searchValue.length >= 3 && searchValue.length <= 50 ? searchValue : '';

  const {
    seriesData,
    seriesIsLoading,
    seriesIsError,
    fetchNextPage,
    hasNextPage,
  } = useQuerySeries({
    searchValue: searchAdjusted,
    selectedTags,
    requestAllowed,
    limit: 11,
    onSuccess(data) {
      if (searchAdjusted && data?.total && user.email) {
        storageSerieNamesSave(user.email, searchAdjusted);
        setOldSerieNames(storageSerieNamesGet(user.email));
      }
    },
  });

  const resetTags = useCallback(() => {
    if (serieTagsData && serieTagsData.length > 0) {
      setSelectedTags(serieTagsData);
    }
  }, [serieTagsData]);

  useEffect(() => {
    resetTags();
  }, [resetTags]);

  useEffect(() => {
    searchCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData]);

  useEffect(() => {
    if (user.email) {
      setOldSerieNames(storageSerieNamesGet(user.email));
    }
  }, [user.email]);

  useEffect(() => {
    const seriesToShowAux =
      seriesData?.pages.flatMap((series) => series.data) || [];

    newSeries.forEach((serie) => {
      const index = seriesToShowAux.findIndex(
        (newSerie) => newSerie.name === serie.name,
      );

      if (index !== -1) {
        seriesToShowAux.splice(index, 1);
      }
    });

    setSeriesToShow([...newSeries, ...seriesToShowAux]);
  }, [newSeries, seriesData]);

  function handleSearchSeries(value: string) {
    isSearchValid(value);
  }

  function addSeries(serieData: SerieData[]) {
    setSearchValue('');
    resetTags();
    setNewSeries(serieData);
  }

  function removeNewSerie(newSerieId: string) {
    setNewSeries((state) =>
      state.filter((newSerie) => newSerie.id !== newSerieId),
    );
  }

  function updateSelectedTags(tags: string[]) {
    setSelectedTags(tags);
  }

  const fetchNextPageOnScroll = (): void => {
    if (!seriesIsLoading && !seriesIsError && hasNextPage) {
      fetchNextPage();
    }
  };

  const oldSeriesNamesShow = oldSerieNames.filter(
    (oldSeriesNamesAux) =>
      searchValue !== oldSeriesNamesAux &&
      oldSeriesNamesAux.includes(searchValue),
  );

  const isLoading =
    seriesIsLoading ||
    seriesData === undefined ||
    (!!seriesData.pages.length &&
      !!seriesData.pages[0].data.length &&
      seriesToShow.length === 0);

  const inputDisabled = searchValue.length === 0 && seriesToShow.length === 0;

  return (
    <MySeriesContainer>
      <div className="containerLinear">
        <MyProjectsAndSeriesHeader />

        <MySeriesHeader>
          <Card
            textCard={t('mySeriesTitle')}
            textDescription={t('mySeriesDescription')}
          />

          <ContentAutoCompleteInput>
            <AutoComplete
              icon={<MagnifyingGlass size="1.25rem" />}
              placeholder={t('mySeriesSearchPlaceholder')}
              testid="searchSeries"
              error={
                searchError?.quantityLetters === 3
                  ? t(searchError.message).replace('XX', '3')
                  : searchError?.quantityLetters === 50
                  ? t(searchError.message).replace('XX', '50')
                  : undefined
              }
              onChange={(value) => {
                handleSearchSeries(value);
              }}
              value={searchValue}
              options={oldSeriesNamesShow}
              onDelete={(index) => {
                if (oldSerieNames[index] && user.email) {
                  storageSerieNamesRemove(user.email, oldSerieNames[index]);
                  setOldSerieNames(storageSerieNamesGet(user.email));
                }
              }}
              className="inputAdjustPadding"
              disabled={inputDisabled}
            />

            <UploadFile addSeries={addSeries} />
          </ContentAutoCompleteInput>
        </MySeriesHeader>
        <Content>
          {seriesIsError ? (
            <ContainerMaintenance
              content="series"
              text={t('mySeriesFetchError')}
              data-testid="series-error"
              data-cy="series-error"
            />
          ) : seriesData?.pages.length &&
            seriesData?.pages[0].total === 0 &&
            searchValue.length >= 1 ? (
            // eslint-disable-next-line react/jsx-indent
            <Status
              type="noSearchResults"
              title={`${t('mySeriesSearchFail')} "${lastSearch}".`}
            />
          ) : isLoading || seriesToShow.length > 0 ? (
            <TableSeries
              data={seriesToShow || []}
              isLoading={isLoading && seriesToShow.length === 0}
              onHandleScroll={fetchNextPageOnScroll}
              removeNewSerie={removeNewSerie}
              updateSelectedTags={updateSelectedTags}
            />
          ) : (
            <UploadYourSeriesMessage />
          )}
        </Content>
      </div>
    </MySeriesContainer>
  );
};
