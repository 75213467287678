import styled from 'styled-components';

export const Container = styled.div`
  > div:first-of-type {
    margin-bottom: 2rem;
  }

  > div:nth-child(2) {
    max-height: calc(100vh - 25rem);

    overflow-y: auto;

    @media (max-height: 900px) {
      max-height: calc(100vh - 20rem);
    }
  }
`;

export const AggregationContainer = styled.div`
  margin-right: 1px;
  margin-left: 1px;

  > div {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    width: 100%;

    > div {
      flex: 1;
    }
  }

  & + & {
    margin-top: 2rem;
  }

  &:last-of-type {
    margin-bottom: 1px;
  }
`;

export const DataType = styled.h3`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;

  color: ${({ theme }) => theme.colors.gray6};

  margin-bottom: 0.5rem;
`;

export const TemporalSelectOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;
`;
