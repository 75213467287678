import React, { useEffect, useState } from 'react';

import { MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Input } from 'src/components/Input';
import {
  setLastSearch,
  setPage,
  setSearch,
} from 'src/feature-store/redux/reducers/IndicatorsPage';
import { RootState } from 'src/redux/store';

import askToChatEN from '../../../../assets/ask-chat-en.svg';
import askToChatPT from '../../../../assets/ask-chat-pt.svg';
import { AskToChat, Container, Content } from './styles';

export const SearchBar: React.FC = () => {
  const [searchError, setSearchError] = useState('');
  const [focusState, setFocusState] = useState<'onFocus' | 'outFocus'>(
    'outFocus',
  );
  const [searchState, setSearchState] = useState('');

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { language, permissions } = useSelector(
    (state: RootState) => state.auth.user,
  );
  const { breadcumbs, search } = useSelector(
    (state: RootState) => state.indicatorsPage,
  );

  const handleSearchIndicators = (value: string) => {
    setSearchState(value);

    if (value.length > 50) {
      setSearchError('indicatorsQuickSearchInputErrorMaxCharacters');
    }

    if (value.length > 0 && value.length < 3) {
      setSearchError('indicatorsQuickSearchInputErrorLeast3Characters');
      return;
    }

    setSearchError('');

    if (value !== search) {
      value.length >= 3 && dispatch(setPage(1));
      dispatch(setSearch(value));
    }
  };

  useEffect(() => {
    setSearchState(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isIndicatorsOrPremiumPage = [
      '/feature-store/indicators',
      '/feature-store/premium',
    ].some((page) => pathname.startsWith(page));

    if (!isIndicatorsOrPremiumPage) {
      setSearchState('');
      dispatch(setPage(1));
      dispatch(setSearch(''));
      dispatch(setLastSearch(''));
    }
  }, [dispatch, pathname]);

  return (
    <Container>
      <Content isFocus={focusState}>
        <Input
          id="input"
          icon={<MagnifyingGlass />}
          testid="input-search-indicator"
          data-cy="input-search-indicator"
          placeholder={
            focusState === 'outFocus'
              ? translate('indicatorQuickSearchInputDefaultPlaceholders')
              : `${translate(
                  'indicatorQuickSearchInputSelectOneCategoryPlaceholders',
                )} ${
                  breadcumbs[breadcumbs.length - 1]?.category_name[language] ??
                  breadcumbs[breadcumbs.length - 1]?.category_name['en-us'] ??
                  ''
                }`
          }
          hasIconError={false}
          style={
            searchError
              ? {
                  width: '100%',
                  maxHeight: '42px',
                }
              : { width: '100%', maxHeight: '42px' }
          }
          value={searchState}
          onChange={({ target: { value } }) => handleSearchIndicators(value)}
          error={searchError ? translate(searchError) : undefined}
          onFocus={() => setFocusState('onFocus')}
          onBlur={() => setFocusState('outFocus')}
          onClick={() => navigate('/feature-store/indicators')}
        />

        {permissions.includes('ask:questions') && (
          <AskToChat
            onClick={() => navigate('/feature-store/chat4i')}
            data-testid="ai-chat-button"
          >
            {language === 'pt-br' ? (
              <img src={askToChatPT} alt="ask to chat pt-br" />
            ) : (
              <img src={askToChatEN} alt="ask to chat en-us" />
            )}
          </AskToChat>
        )}
      </Content>
    </Container>
  );
};
