import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  position: relative;

  @media (min-width: 1140px) {
    justify-content: center !important;
    align-items: center;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    margin-bottom: 1.5rem;

    > div {
      flex: 1 1 25rem;
      margin: 0 !important;
    }
  }

  > div:last-child,
  > section:last-child {
    margin-bottom: 0;
  }

  .containerLinear {
    position: relative;
    max-width: 1140px;

    margin-bottom: 24px !important;

    @media (min-width: 1140px) {
      min-width: 1140px;
    }
  }

  .customTooltipMyProjects {
    max-width: none !important;
  }
`;

interface ButtonIconProps {
  disabled: boolean;
}

export const ButtonIcon = styled.button<ButtonIconProps>`
  float: right;
  ${({ disabled }) =>
    !disabled
      ? css`
          color: ${({ theme }) => theme.colors.gray6};
          transition: 0.2s color;
          &:hover {
            color: ${({ theme }) => theme.colors.secondary};
          }
        `
      : css`
          color: ${({ theme }) => theme.colors.gray4};
          cursor: not-allowed;
        `};
`;
