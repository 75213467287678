import styled, { css } from 'styled-components';

export const Container = styled.div`
  min-width: 35.625rem;

  flex: 1;

  display: flex;
  flex-direction: column;

  > button + button {
    margin-top: 1rem;
  }

  @media (max-width: 1000px) {
    min-width: 100%;
  }
`;

type TemplateContainerProps = {
  selected: boolean;
  isSaving: boolean;
};

export const TemplateContainer = styled.button<TemplateContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 1rem;

  padding: 1rem;

  border-radius: 8px;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray2};

  cursor: pointer;

  transition: all 0.2s;

  > svg {
    width: 2rem;
    height: 2rem;

    color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.gray4};
  }

  > div {
    > h3 {
      color: ${({ theme, selected }) =>
        selected ? theme.colors.gray6 : theme.colors.gray4};
    }

    > p {
      color: ${({ theme, selected }) =>
        selected ? theme.colors.gray5 : theme.colors.gray4};
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ isSaving, theme, selected }) =>
    !isSaving &&
    css`
      &:disabled {
        border: 1px solid
          ${selected ? theme.colors.primary : theme.colors.gray1};

        > svg {
          color: ${theme.colors.gray3};
        }

        > div {
          > h3 {
            color: ${theme.colors.gray3};
          }

          > p {
            color: ${theme.colors.gray3};
          }
        }
      }
    `}

  ${({ selected, theme }) =>
    !selected &&
    css`
      &:not(:disabled):hover {
        border-color: ${theme.colors.gray3};
      }
    `}
`;

export const TemplateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;

  flex: 1;

  > h3 {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
  }

  > p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 150%;
  }

  button {
    z-index: 1;
  }
`;
