import React from 'react';

import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

export const CellLoading: React.FC = () => (
  <ContainerSkeleton
    data-testid="cell-loading"
    data-cy="cell-loading"
    style={{ height: '0.3125rem', width: '4.5rem', margin: '0 auto' }}
    withLoading={false}
  />
);
