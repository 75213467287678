import React, { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Avatar } from 'src/components/Avatar';
import { CaretDown } from 'phosphor-react';

import {
  CardTitle,
  Changes,
  Container,
  DateText,
  ShowMoreButton,
} from './styles';

export type Adjustments = {
  date: string;
  ys: {
    before: number;
    after: number;
    id: number;
    name: string;
    tooltip: string;
  }[];
};

interface HistoricalCard {
  email: string;
  adjustments: Adjustments[];
  awaitingApprovalMessage: string | null;
  approvalMessage: string | null;
  disapprovalMessage: string | null;
  isNewStage: boolean;
  hasStepBeenRemoved: boolean;
  discardedAdjustments: boolean;
  versionFinalized: boolean;
}

export const HistoricalCard: React.FC<HistoricalCard> = ({
  email,
  adjustments,
  awaitingApprovalMessage,
  approvalMessage,
  disapprovalMessage,
  isNewStage,
  hasStepBeenRemoved,
  discardedAdjustments,
  versionFinalized,
}) => {
  const [showMore, setShowMore] = useState(false);
  const changesRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  function handleShowMore() {
    setShowMore((state) => !state);
  }

  const changes = adjustments
    ? adjustments.reduce((acc, current) => acc + current.ys.length, 0)
    : 0;

  function calcMaxHeight() {
    if (changesRef.current) {
      let quantityPTags = 0;
      let secondPTagIndex: number | null = null;
      let lastPTagIndex: number | null = null;

      for (let i = 0; i < changesRef.current.children.length; i++) {
        if (changesRef.current.children[i] instanceof HTMLParagraphElement) {
          quantityPTags++;
          if (quantityPTags === 2) {
            secondPTagIndex = i;
          }
          lastPTagIndex = i;
        }
      }

      if (secondPTagIndex && showMore === false) {
        return (
          changesRef.current.children[secondPTagIndex].getBoundingClientRect()
            .bottom - changesRef.current.getBoundingClientRect().top
        );
      }

      if (lastPTagIndex) {
        return (
          changesRef.current.children[lastPTagIndex].getBoundingClientRect()
            .bottom - changesRef.current.getBoundingClientRect().top
        );
      }
    }

    return null;
  }

  function getTitle() {
    if (approvalMessage) {
      return t('workspaceOverviewLogsApproved');
    }
    if (disapprovalMessage) {
      return t('workspaceOverviewLogsRejected');
    }
    if (awaitingApprovalMessage) {
      return t('workspaceOverviewLogsWaitingForApproval');
    }
    if (changes) {
      return `${t('workspaceOverviewLogsMade')} ${changes} ${
        changes > 1
          ? t('workspaceOverviewLogsChanges')
          : t('workspaceOverviewLogsChange')
      }`;
    }
    if (isNewStage) {
      return t('workspaceOverviewLogsCreatedNewStep');
    }

    if (discardedAdjustments) {
      return t('workspaceOverviewLogsDiscardedAdjustments');
    }

    if (hasStepBeenRemoved) {
      return t('workspaceOverviewLogsRemovedStage');
    }

    if (versionFinalized) {
      return t('workspaceOverviewLogsVersionFinalized');
    }

    return t('workspaceOverviewLogsEditionStarted');
  }

  const maxHeight = calcMaxHeight();

  const title = getTitle();

  const message =
    approvalMessage || awaitingApprovalMessage || disapprovalMessage;

  const hasShowMore = changes > 2;

  return (
    <Container data-testid="card-container">
      <Avatar email={email} size="sm" />
      <CardTitle data-testid="card-title">{title}</CardTitle>

      <Changes ref={changesRef} maxHeight={maxHeight}>
        {message ? (
          <p data-testid="card-message">{message}</p>
        ) : (
          adjustments.map((adjustment, index) => (
            <React.Fragment key={`changes-${index.toString()}`}>
              <DateText
                data-testid={`card-adjustments-date-${adjustment.date.toLowerCase()}`}
              >
                {adjustment.date.toUpperCase()}
              </DateText>
              {adjustment.ys.map((y) => (
                <p
                  key={y.id}
                  data-testid={`card-adjustments-${y.name}`}
                  data-tooltip-id="change-history-sidebar-tooltip"
                  data-tooltip-html={y.tooltip}
                >
                  {y.name}
                </p>
              ))}
            </React.Fragment>
          ))
        )}
      </Changes>
      {hasShowMore && (
        <ShowMoreButton
          onClick={handleShowMore}
          showMore={showMore}
          data-testid="button-show-more-or-less"
        >
          <CaretDown size="12px" />
          {showMore
            ? t('workspaceOverviewLogsShowLess')
            : t('workspaceOverviewLogsShowMore')}
        </ShowMoreButton>
      )}
    </Container>
  );
};
