/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';

export const useDebounce = <T,>(value: T, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [isLoadingDebounce, setIsLoadingDebounce] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setIsLoadingDebounce(false);
    }, delay);

    setIsLoadingDebounce(true);

    return () => clearTimeout(timer);
  }, [delay, value]);

  return { debouncedValue, isLoadingDebounce };
};
