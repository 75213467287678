import React, { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Card } from 'src/components/Card';
import { Head } from 'src/components/Head';
import { Button } from 'src/components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'src/components/Modal';
import { Status } from 'src/components/Status';
import { ModalFooter } from 'src/components/Modal/Footer/styles';
import { AxiosError } from 'axios';
import { NoPermissionToEditModal } from 'src/workspaces/components/NoPermissionToEdit';
import { SessionExpiredModal } from 'src/workspaces/components/SessionExpired';
import { useQuery } from 'react-query';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { queryClient } from 'src/service/queryClient';
import { useQueryWorkspaceData } from 'src/workspaces/hooks/useQueryWorkspaceData';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { Tooltip } from 'react-tooltip';
import { NewReleaseConfigurationContext } from 'src/workspaces/contexts/NewReleaseConfigurationContext';
import apiWorkspace from 'src/workspaces/service/api';
import * as Yup from 'yup';
import ms from 'ms';

import {
  BasicInformationsContainer,
  Container,
  Footer,
  FormContainer,
} from './styles';
import {
  BasicInformationsForm,
  Deadline,
  PreviewData,
  StagingAreaData,
  User,
} from './types';
import { TemplateOptions } from './components/TemplateOptions';
import { BasicForm } from './components/BasicForm';
import { PlanningConfiguration } from './components/PlanningConfiguration';

const regexNoSpecialCharacters = /[®ŧ←↓→øþæßðđŋħˀĸł«»©µ]/;

const formSchema = Yup.object().shape({
  name: Yup.string()
    .transform((value) => value.trim())
    .test(
      'no-forbidden-chars',
      'projectErrorNoSpecialCharacters',
      (value) => !regexNoSpecialCharacters.test(value ?? ''),
    )
    .min(3, 'projectErrorMinCharacters')
    .max(50, 'projectErrorMaxCharacters')
    .required('requiredField'),
  description: Yup.string()
    .transform((value) => value.trim())
    .min(3, 'projectErrorMinCharacters')
    .max(350, 'createWorkspaceDescriptionErrorMaxCharacters')
    .required('requiredField'),
});

export const NewReleaseConfiguration: React.FC = () => {
  const [saveError, setSaveError] = useState(false);

  const [showUserIsEditingModal, setShowUserIsEditingModal] = useState('');
  const [showWorkspaceIsPublishing, setShowWorkspaceIsPublishing] =
    useState(false);
  const [editionExpired, setEditionExpired] = useState(false);
  const [loadingLockEdition, setLoadingLockEdition] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [navigateToControlPanel, setNavigateToControlPanel] = useState(false);

  const [getWorkspaceInfo, setGetWorkspaceInfo] = useState(false);

  const { id } = useParams();
  const { t: translate } = useTranslation();

  const {
    selectedTemplate,
    planningHorizon,
    steps,
    isSaving,
    setShowPlanningErrors,
    saveConfiguration,
  } = useContext(NewReleaseConfigurationContext);

  const {
    auth: {
      user: { email },
    },
    workspace,
  } = useSelector((state: RootState) => state);

  const {
    data: previewData,
    isLoading: isLoadingPreview,
    isFetching: isFetchingPreview,
    isError: isErrorPreview,
  } = useQuery(
    ['workspace', id, 'releases', workspace.releasePreview],
    async () => {
      const { data } = await apiWorkspace.get<PreviewData>(
        `/workspaces/${id}/releases/${workspace.releasePreview}`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id && !!workspace.releasePreview,
    },
  );

  const {
    data: stagingAreaData,
    isLoading: isLoadingStagingArea,
    isFetching: isFetchingStagingArea,
    isError: isErrorStagingArea,
  } = useQuery(
    ['workspace staging area', id],
    async () => {
      const { data } = await apiWorkspace.get<StagingAreaData>(
        `/workspaces/${id}/staging-area`,
      );

      return data;
    },
    {
      staleTime: ms('3 min'),
      enabled: !!id,
    },
  );

  const basicForm = useForm<BasicInformationsForm>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: stagingAreaData?.data.label ?? '',
      description: stagingAreaData?.data.description ?? '',
    },
  });

  const { handleSubmit, setValue } = basicForm;

  const navigate = useNavigate();

  const { isLoading: isLoadingWorkspace, isFetching: isFetchingWorkspace } =
    useQueryWorkspaceData(id ?? '', getWorkspaceInfo, email ?? '', false);

  const lockEdition = async (returnToControlPanel = false) => {
    setLoadingLockEdition(true);

    try {
      await apiWorkspace.patch(`/workspaces/${id}/edit`);

      setCanEdit(true);
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 400) {
        if (
          error.response?.data?.detail?.detail?.startsWith(
            'Workspace already locked for editing by ',
          )
        ) {
          if (returnToControlPanel) {
            setNavigateToControlPanel(true);
          }

          setShowUserIsEditingModal(
            error.response.data.detail.detail.replace(
              'Workspace already locked for editing by ',
              '',
            ),
          );
        } else if (
          error.response?.data?.detail?.detail ===
          'You cannot perform this action, Workspace is publishing a new version.'
        ) {
          if (returnToControlPanel) {
            setNavigateToControlPanel(true);
          }

          setShowWorkspaceIsPublishing(true);
        }

        setCanEdit(false);
      }
    }

    setEditionExpired(false);
    setLoadingLockEdition(false);
  };

  const handleCancelConfiguration = () => {
    navigate(`/workspaces/${id}/control-panel`);
  };

  const stepHasEditorAndApprover = (users: User[]) => {
    let hasEditor = false;
    let hasApprover = false;

    for (let j = 0; j < users.length; j++) {
      if (users[j].profile === 'editor') {
        hasEditor = true;
      } else {
        hasApprover = true;
      }

      if (hasEditor && hasApprover) {
        break;
      }
    }

    return hasEditor && hasApprover;
  };

  const stepDeadlineIsValid = (
    deadlines: Deadline[] | null,
    lastStepDeadlines?: Deadline[] | null,
  ) => {
    const editorDeadline = deadlines?.find(
      ({ type }) => type === 'editor',
    )?.date;
    const approverDeadline = deadlines?.find(
      ({ type }) => type === 'approver',
    )?.date;

    if (!editorDeadline) return false;
    if (!approverDeadline) return false;

    const editorDateTime = new Date(editorDeadline.slice(0, 10)).getTime();
    const approverDateTime = new Date(approverDeadline.slice(0, 10)).getTime();

    if (approverDateTime < editorDateTime) return false;

    if (!lastStepDeadlines) {
      return true;
    }

    const lastApproverDeadline = lastStepDeadlines.find(
      ({ type }) => type === 'approver',
    )?.date;

    if (!lastApproverDeadline) return false;

    const lastApproverDateTime = new Date(
      lastApproverDeadline.slice(0, 10),
    ).getTime();

    if (editorDateTime < lastApproverDateTime) return false;

    return true;
  };

  const planningInfoIsValid = () => {
    let isValid = true;

    if (!planningHorizon[0] || !planningHorizon[1]) {
      return false;
    }

    for (let i = 0; i < steps.length; i++) {
      const lastStepDeadline = i === 0 ? undefined : steps[i - 1].deadlines;

      if (!stepDeadlineIsValid(steps[i].deadlines, lastStepDeadline)) {
        isValid = false;
        break;
      }

      const users = steps[i].users;

      if (!stepHasEditorAndApprover(users)) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const handleSaveConfiguration = async (data: BasicInformationsForm) => {
    if (selectedTemplate === 'planning') {
      const isValid = planningInfoIsValid();

      if (!isValid) {
        setShowPlanningErrors(true);
        return;
      }
    }

    const { success } = await saveConfiguration(data);

    if (success) {
      queryClient.invalidateQueries(['workspace staging area', id]);
      queryClient.invalidateQueries([
        'workspace',
        id,
        'releases',
        workspace.releasePreview,
      ]);

      navigate(`/workspaces/${id}/control-panel`);
    } else {
      setSaveError(true);
    }
  };

  const handleCloseSaveErrorModal = () => {
    setSaveError(false);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (!editionExpired && canEdit) {
      interval = setInterval(() => setEditionExpired(true), ms('5 min'));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editionExpired, canEdit]);

  useEffect(() => {
    if (!workspace.frequency) {
      setGetWorkspaceInfo(true);
    }

    lockEdition(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingPreviewData =
    isLoadingPreview || isFetchingPreview || !previewData;
  const isLoadingStagingAreaData =
    isLoadingStagingArea || isFetchingStagingArea || !stagingAreaData;

  useEffect(() => {
    if (!isLoadingStagingAreaData) {
      setValue('name', stagingAreaData?.data.label ?? '');
      setValue('description', stagingAreaData?.data.description ?? '');
    }
  }, [
    isLoadingStagingAreaData,
    stagingAreaData?.data.label,
    stagingAreaData?.data.description,
    setValue,
  ]);

  useEffect(() => {
    if (!!workspace.userRole && workspace.userRole !== 'manager') {
      if (workspace.userRole === 'editor') {
        navigate(`/workspaces/${id}/control-panel`);
      } else {
        navigate('/workspaces');
      }
    }
  }, [workspace.userRole, id, navigate]);

  const saveConfigurationIsDisabled =
    isLoadingPreviewData ||
    isLoadingStagingAreaData ||
    isErrorStagingArea ||
    isErrorPreview ||
    !!isSaving;

  const showLoading =
    isLoadingWorkspace ||
    isFetchingWorkspace ||
    isLoadingStagingAreaData ||
    isLoadingPreviewData ||
    !workspace.frequency;

  return (
    <Container>
      <Head
        title={`Workspace - ${translate(
          'workspaceNewReleaseConfigurationTitle',
        )}`}
      />

      <FormContainer className="containerLinear">
        <Card
          textCard={translate('workspaceNewReleaseConfigurationTitle')}
          textDescription={translate(
            'workspaceNewReleaseConfigurationDescription',
          )}
        />

        {isErrorPreview || isErrorStagingArea ? (
          <Status
            type="error"
            title={translate('workspaceNewReleaseConfigurationSaveErrorTitle')}
            description={translate(
              'workspaceNewReleaseConfigurationStagingAreaErrorDescription',
            )}
          />
        ) : showLoading ? (
          <ContainerSkeleton
            withLoading
            style={{ height: '17.8rem' }}
            data-testid="loading-saved-configuration"
          />
        ) : (
          <>
            <BasicInformationsContainer>
              <FormProvider {...basicForm}>
                <BasicForm />
              </FormProvider>

              <TemplateOptions />
            </BasicInformationsContainer>

            {selectedTemplate === 'planning' && <PlanningConfiguration />}
          </>
        )}

        <Footer>
          <Button
            buttonType="tertiary"
            onClick={handleCancelConfiguration}
            disabled={!!isSaving}
            data-testid="button-cancel"
          >
            {translate('cancel')}
          </Button>

          <Button
            buttonType="primary"
            type="button"
            loading={!!isSaving}
            onClick={handleSubmit(handleSaveConfiguration)}
            disabled={saveConfigurationIsDisabled}
            data-testid="button-save-configuration"
          >
            {translate(
              'workspaceNewReleaseConfigurationSaveConfigurationButton',
            )}
          </Button>
        </Footer>
      </FormContainer>

      {saveError && (
        <div>
          <Modal
            visible={saveError}
            setVisible={setSaveError}
            style={{ width: '30rem' }}
          >
            <Status
              type="cloudWarning"
              isModal
              title={translate(
                'workspaceNewReleaseConfigurationSaveErrorTitle',
              )}
              description={translate(
                'workspaceNewReleaseConfigurationSaveErrorDescription',
              )}
            />

            <ModalFooter>
              <Button
                buttonType="primary"
                onClick={handleCloseSaveErrorModal}
                data-testid="button-ok-error"
              >
                Ok
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}

      {(!!showUserIsEditingModal || showWorkspaceIsPublishing) && (
        <NoPermissionToEditModal
          setVisible={() => {
            setShowUserIsEditingModal('');

            if (navigateToControlPanel) {
              navigate(`/workspaces/${id}/control-panel`);
            }
          }}
          emailEditing={showUserIsEditingModal}
          errorDescription={
            showWorkspaceIsPublishing
              ? translate('workspaceOverviewResultsWorkspaceIsPublishing')
              : undefined
          }
        />
      )}

      {editionExpired && canEdit && (
        <SessionExpiredModal
          handleBlockEdition={() => lockEdition(false)}
          loadingBlockEdition={loadingLockEdition}
          workspaceId={id ?? ''}
        />
      )}

      <Tooltip
        id="tooltip-release-configuration"
        className="white-tooltip-theme"
      />
    </Container>
  );
};
