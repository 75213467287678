import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { ChartLine, DotOutline, MagnifyingGlass } from '@phosphor-icons/react';

import { StatusProps } from '../types';
import { Button, Container, Icon, LineIcon } from '../styles';
import { PlanningFlowContext } from '../..';

export const PreviewStatus: React.FC<StatusProps> = ({
  stepId,
  isSelected,
  isDisabled,
}) => {
  const { isConfigurationMode, handleAccessStep } =
    useContext(PlanningFlowContext);
  const { t: translate } = useTranslation();

  return (
    <Container
      status="baseline"
      selected={isSelected}
      isDisabled={isDisabled}
      isConfigurationMode={isConfigurationMode}
      data-testid="container-preview-state"
    >
      <Icon data-testid="icon-preview-state">
        <ChartLine weight="fill" />
      </Icon>

      <LineIcon>
        {isDisabled || isSelected || isConfigurationMode ? (
          <DotOutline weight="fill" style={{ width: '2rem', height: '2rem' }} />
        ) : (
          <Button
            type="button"
            onClick={() =>
              handleAccessStep && handleAccessStep(stepId, 'baseline')
            }
            data-testid="button-preview-state"
          >
            <MagnifyingGlass />
          </Button>
        )}
      </LineIcon>

      <p data-testid="text-preview-state">
        {translate('workspacePlanningFlowPreview')}
      </p>
    </Container>
  );
};
