import { useEffect } from 'react';

import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { insert } from 'src/workspaces/redux/reducers/Workspace';
import apiWorkspace from 'src/workspaces/service/api';
import ms from 'ms';

import { UseQueryWorkspaceResponse, UserRole, WorkspaceData } from './types';

export const useQueryWorkspaceData = (
  id: string,
  enabled: boolean,
  email: string,
  readerCanAccessPage = false,
): UseQueryWorkspaceResponse => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useQuery(
    ['workspace data', id],
    async () => {
      const response = await apiWorkspace.get<WorkspaceData>(
        `/workspaces/${id}`,
      );

      let userCanEdit = false;
      let userRole: UserRole | null = null;

      response.data.users.forEach(({ user, role }) => {
        if (user === email) {
          if (role !== 'reader') {
            userCanEdit = true;
          }

          userRole = role;
        }
      });

      if (!userCanEdit && !readerCanAccessPage) {
        navigate('/workspaces');
      }

      return { ...response.data, user_role: userRole };
    },
    {
      staleTime: ms('5 min'),
      onError: () => navigate('/workspaces'),
      enabled,
    },
  );

  useEffect(() => {
    if (data && !isLoading && !isFetching && enabled) {
      dispatch(
        insert({
          id: data.id,
          name: data.name,
          description: data.description,
          icon: data.icon_url,
          lastUpdated: data.last_updated,
          userRole: data.user_role,
          createdBy: data.created_by,
          frequency: data.frequency,
          releaseCurrent: data.releases?.current || null,
          releasePreview: data.releases?.preview || null,
          status: data.status,
          columns: [
            'Model',
            'MASE',
            'MAPE',
            'MASEs',
            'WMAPE',
            // 'Transformation',
            'RMSE',
            'MPE',
          ],
          canSyncAdjust: true,
        }),
      );
    }
  }, [data, isLoading, isFetching, dispatch, enabled]);

  return { data, isLoading, isFetching };
};
