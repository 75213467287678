import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'src/components/Input';
import { TextArea } from 'src/components/TextArea';
import { NewReleaseConfigurationContext } from 'src/workspaces/contexts/NewReleaseConfigurationContext';

import { Container } from './styles';

export const BasicForm: React.FC = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { isSaving } = useContext(NewReleaseConfigurationContext);

  const { t: translate } = useTranslation();

  const nameError = errors.name?.message;

  return (
    <Container style={{ marginBottom: nameError ? '1.563rem' : '0' }}>
      <Controller
        control={control}
        name="name"
        render={({ field: { onChange, value } }) => (
          <Input
            label={translate('name')}
            placeholder={translate(
              'workspaceNewReleaseConfigurationNamePlaceholder',
            )}
            value={value}
            onChange={onChange}
            error={nameError ? translate(nameError as string) : ''}
            disabled={!!isSaving}
            data-testid="input-name"
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field: { onChange, value } }) => (
          <TextArea
            label={translate('description')}
            placeholder={translate(
              'workspaceNewReleaseConfigurationDescriptionPlaceholder',
            )}
            // defaultValue={stagingAreaData?.data.description ?? ''}
            value={value}
            onChange={onChange}
            error={
              errors.description?.message
                ? translate(errors.description.message as string)
                : ''
            }
            disabled={!!isSaving}
            data-testid="textarea-description"
          />
        )}
      />
    </Container>
  );
};
