export const WorkspaceOverviewPTBRTranslation = {
  workspaceOverviewTitle: 'Visão Geral',
  workspaceOverviewDescription: 'Explore os resultados das suas séries.',
  workspaceOverviewResultsTitle: 'Resultados',
  workspaceOverviewResultsDescription:
    'Navegue pelas suas séries e habilite o modo de edição para fazer ajustes nas suas projeções.',
  workspaceOverviewResultsEnableEditMode: 'Ativar modo de edição',
  workspaceOverviewResultsDiscardEdition: 'Sair',
  workspaceOverviewResultsSaveEdition: 'Salvar ajustes',
  workspaceOverviewResultsGroup: 'Grupo',
  workspaceOverviewResultsInvalidValue:
    'Por favor, ajuste seus valores para um número maior que zero.',
  workspaceOverviewResultsDownloadCurrentData: 'Exportar visualização atual',
  workspaceOverviewResultsDownloadAllData: 'Exportar todos os dados',
  workspaceOverviewResultsErrorTitle: 'Não foi possível carregar este conteúdo',
  workspaceOverviewResultsErrorDescription:
    'Pode ser que esteja em manutenção. Tente mais tarde.',
  workspaceOverviewResultsEditionLockedDescription:
    '<b>XXX</b> está editando este workspace. Para não perder seus ajustes, continuará no modo de edição porém só será possível salvar quando o usuário sair da edição.',
  workspaceOverviewResultsLoadingData: 'Carregando dados...',
  workspaceOverviewResultsWorkspaceIsPublishing:
    'Está sendo publicado uma nova versão do workspace. Por favor, volte mais tarde.',
  workspaceOverviewResultsEditionBlockDownload:
    'Para exportar, por favor, saia do modo de edição.',
  workspaceOverviewResultsEditionModeEnabledTitle:
    'Você está no modo de edição',
  workspaceOverviewResultsEditionModeEnabledDescription:
    'Por favor, feche o modo de edição antes de atualizar as visualizações de dados.',
  workspaceOverviewResultsReviewModeEnabledTitle: 'Você está revisando',
  workspaceOverviewResultsReviewModeEnabledDescription:
    'Por favor, saia da revisão antes de entrar na edição do fluxo de planejamento.',
  workspaceOverviewResultsErrorToSaveTitle: 'Erro ao salvar',
  workspaceOverviewResultsErrorToSaveDescription:
    'Ocorreu um erro ao tentar salvar as informações das séries listadas abaixo. Tente novamente mais tarde.',
  workspaceOverviewResultsType: 'Tipo',
  workspaceOverviewOthers: 'Outros',
  workspaceOverviewResultsEditionDisabledTooltip:
    'Não é possível editar na prévia.',

  workspaceOverviewResultsComparisonTitle: 'Comparação de Resultados',
  workspaceOverviewResultsComparisonDescription:
    'Observe o histórico e projeção das séries selecionadas.',
  workspaceOverviewResultsComparisonSerieLabel: 'Série',
  workspaceOverviewResultsComparisonSeriePlaceholder: 'Selecione uma série',
  workspaceOverviewResultsComparisonSelectFilterTitle:
    'Selecione uma ou mais séries',
  workspaceOverviewResultsComparisonSelectFilterDescription:
    'Use a seleção acima para selecionar a série que quer visualizar ou mais de um que queira comparar. ',
  workspaceOverviewResultsComparisonErrorTitle:
    'Não foi possível carregar este conteúdo',
  workspaceOverviewResultsComparisonErrorDescription:
    'Pode ser que esteja em manutenção. Tente mais tarde.',
  workspaceOverviewResultsComparisonSelectOnly4Series:
    'É possível selecionar apenas XXX séries',
  workspaceOverviewResultsComparisonEmptyDataDescription:
    'Não existe dados para o período selecionado.',

  workspaceOverviewPlanningFlowTitle: 'Fluxo de Planejamento',
  workspaceOverviewPlanningFlowDescription:
    'Veja os principais impactos do mercado com a agregação de todos os dados. Explore diferentes visualizações e tenha os insights certos para seus problemas.',
  workspaceOverviewPlanningFlowFinalizeVersion: 'Finalizar versão',
  workspaceOverviewPlanningFlowPreview: 'Prévia',
  workspaceOverviewPlanningFlowAdjusting: 'Ajustando',
  workspaceOverviewPlanningFlowApproved: 'Aprovado',
  workspaceOverviewPlanningFlowStep: 'Etapa ',
  workspaceOverviewPlanningFlowSubmitApproval: 'Enviar para aprovação',
  workspaceOverviewPlanningFlowMessage: 'Mensagem',
  workspaceOverviewPlanningFlowMessageDescription: 'Descreva suas alterações',
  workspaceOverviewPlanningFlowRequestApproval: 'Solicitar aprovação',
  workspaceOverviewPlanningFlowMessageDescriptionMaxCharactersError:
    'Não deve ter mais do que 300 caracteres',
  workspaceOverviewPlanningFlowMessageDescriptionMinCharactersError:
    'Deve conter mais do que 2 caracteres',
  workspaceOverviewPlanningFlowWaitingApproval: 'Aguardando aprovação',
  workspaceOverviewPlanningFlowReviewScenario: 'Revisar cenário',
  workspaceOverviewPlanningFlowNumberMaxOfStepsTooltip:
    'Foi atingido o número máximo de etapas.',
  workspaceOverviewPlanningFlowApproveLastStepTooltip:
    'A última etapa ainda não foi aprovada.',
  workspaceOverviewPlanningFlowSeeMore: 'Ver mais',
  workspaceOverviewPlanningFlowSeeLess: 'Ver menos',
  workspaceOverviewPlanningFlowDisapprove: 'Reprovar',
  workspaceOverviewPlanningFlowApprove: 'Aprovar alterações',
  workspaceOverviewPlanningFlowCreatingStep: 'Criando etapa...',
  workspaceOverviewPlanningFlowDiscardAdjustments: 'Descartar ajustes',
  workspaceOverviewPlanningFlowMakeAdjustments: 'Realizar ajustes',
  workspaceOverviewPlanningFlowDiscardingAdjustments: 'Descartando ajustes...',
  workspaceOverviewPlanningFlowConfiguringStep: 'Configurando etapa...',
  workspaceOverviewPlanningFlowNoAdjustments: 'Sem ajustes',
  workspaceOverviewPlanningFlowUpdateErrorTitle:
    'Erro ao atualizar a etapa do fluxo',
  workspaceOverviewPlanningFlowUpdateErrorDescription:
    'Ocorreu um erro ao atualizar a etapa do fluxo. Por favor, tente novamente.',
  workspaceOverviewPlanningFlowIsRemoving:
    'A remoção da última etapa está sendo finalizada. Por favor, aguarde.',
  workspaceOverviewPlanningFlowModalErrorCreatingDescription:
    'Ocorreu um erro enquanto criava uma nova etapa em seu workspace.',
  workspaceOverviewPlanningFlowSaveEdition: 'Salvar edição',

  workspaceOverviewApproveModalTitle: 'Deseja aprovar as alterações?',
  workspaceOverviewApproveModalDescription:
    'Ao confirmar, os atuais valores disponíveis para todos os usuários serão substituídos para os ajustados.',

  workspaceOverviewDisapproveModalTitle: 'Deseja devolver as alterações?',
  workspaceOverviewDisapproveModalDescription:
    'Ao confirmar, os editores deste Workspace receberão as alterações de volta, junto da mensagem escrita abaixo.',

  workspaceOverviewApproveOrDisapproveModalPlaceholder:
    'Escreva uma mensagem de devolutiva',

  workspaceOverviewDiscardAdjustmentsConfirmationModalTitle:
    'Tem certeza de que deseja descartar todos os ajustes?',
  workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionFirstStep:
    'Ao confirmar, todas as mudanças feitas por você e por outras pessoas com acesso a esse Workspace serão perdidas e a prévia será restaurada.',
  workspaceOverviewDiscardAdjustmentsConfirmationModalDescriptionOtherStep:
    'Ao confirmar, todas as mudanças feitas por você e por outras pessoas com acesso a esse Workspace serão perdidas e a última versão aprovada será restaurada.',

  workspaceOverviewDeleteStepConfirmationModalTitle:
    'Tem certeza que deseja remover essa etapa?',
  workspaceOverviewDeleteStepConfirmationModalDescription:
    'Todas as modificações feitas serão permanentemente removidas. Se deseja prosseguir, digite o nome da etapa no campo abaixo.',

  workspaceOverviewLogsMessageError:
    'Não foi possível carregar o histórico de alterações',
  workspaceOverviewLogsStage: 'Etapa',
  workspaceOverviewLogsDiscardChanges: 'Descartar alterações',
  workspaceOverviewLogsTitle: 'Histórico de Alterações',
  workspaceOverviewLogsDescription:
    'Veja todas as alterações feitas nessa versão do Workspace.',

  workspaceOverviewLogsMade: 'Realizou',
  workspaceOverviewLogsChange: 'alteração',
  workspaceOverviewLogsChanges: 'alterações',
  workspaceOverviewLogsApproved: 'Aprovou etapa',
  workspaceOverviewLogsRejected: 'Rejeitou etapa',
  workspaceOverviewLogsWaitingForApproval: 'Enviou etapa para aprovação',
  workspaceOverviewLogsShowMore: 'Ver mais',
  workspaceOverviewLogsShowLess: 'Ver menos',
  workspaceOverviewLogsStillNoChanges: 'Não houve alterações até o momento.',
  workspaceOverviewLogsButton: 'Histórico de alterações',
  workspaceOverviewLogsCreatedNewStep: 'Criou uma nova etapa',
  workspaceOverviewLogsEditionStarted: 'Iniciou edição',
  workspaceOverviewLogsDiscardedAdjustments: 'Descartou os ajustes',
  workspaceOverviewLogsVersionFinalized: 'Finalizou a versão',
  workspaceOverviewLogsRelease: 'Versão',

  workspaceOverviewModalCreatedNewStageTitle: 'Uma nova etapa foi criada',
  workspaceOverviewModalCreatedNewStageDescription:
    'Clique abaixo para acessar a etapa mais recente.',
  workspaceOverviewModalCreatedNewStageButton: 'Acessar etapa mais recente',
  workspaceOverviewLogsRemovedStage: 'Removeu etapa',

  workspaceOverviewUpdateData: 'Atualizar dados',
  workspaceOverviewUpdateDataToEnableEditingMode:
    'Atualize os dados para ativar o modo de edição.',
  workspaceOverviewUpdateDataToEnableDiscardAdjustments:
    'Atualize os dados para ativar o descartar ajustes.',
  workspaceOverviewUpdateDataToEnableSubmitForApproval:
    'Atualize os dados para ativar o enviar para aprovação.',
  workspaceOverviewUpdateDataToEnableReviewScenario:
    'Atualize os dados para ativar o revisar cenário.',
  workspaceOverviewResultsEditionBlockRemoveStage:
    'Para remover, por favor, saia do modo de edição.',
  workspaceOverviewResultsReviewBlockRemoveStage:
    'Para remover, por favor, saia do modo de revisão.',

  workspaceOverviewHierarchicalFilterUncheckAll:
    'Marque/Desmarque todas as opções dos níveis abaixo',

  workspaceOverviewHierarchicalTitle: 'Árvore de Séries',
  workspaceOverviewHierarchicalDescription: 'Defina o que deseja visualizar',
  workspaceOverviewParametrization: 'Parametrização',
  workspaceOverviewParametrizationDescription:
    'Essas configurações são feitas durante a criação da versão.',
  workspaceOverviewParametrizationTemporalAggregation: 'Agregação temporal',
  workspaceOverviewParametrizationLevelAggregation: 'Agregação de nível',

  workspaceOverviewPlanningHorizon: 'Horizonte de planejamento',

  workspaceOverviewPlanningConfigurationSaveErrorTitle: 'Algo deu errado',
  workspaceOverviewPlanningConfigurationSaveErrorDescription:
    'Ocorreu um erro ao salvar a configuração. Por favor, tente novamente mais tarde.',
  workspaceOverviewPlanningConfigurationEnabled:
    'Você está editando o fluxo de planejamento no momento',
  workspaceOverviewPlanningConfigurationRemovingStep:
    'Uma etapa está sendo excluída no momento, por favor, aguarde até a finalização.',
  workspaceOverviewPlanningConfigurationCreatingStep:
    'Uma etapa está sendo configurada no momento, por favor, aguarde até a finalização.',
  workspaceOverviewPlanningConfigurationDiscardingStep:
    'Os ajustes de uma etapa estão sendo descartados, por favor, aguarde até a finalização.',

  workspaceOverviewFinalizeVersionConfirmationModalTitle:
    'Tem certeza que deseja finalizar essa versão?',
  workspaceOverviewFinalizeVersionConfirmationModalDescription:
    'Não será possível realizar mais nenhum ajuste ou configuração nessa versão. Se deseja prosseguir, por favor, digite version_name no campo abaixo.',
  workspaceOverviewPlanningConfigurationFinalizeVersionErrorTitle:
    'Algo deu errado',
  workspaceOverviewPlanningConfigurationFinalizeVersionErrorDescription:
    'Ocorreu um erro ao finalizar a versão. Por favor, tente novamente mais tarde.',
};
