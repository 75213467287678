export const WorkspacePlanningFlowPTBRTranslations = {
  workspacePlanningFlowStage: 'Etapa',

  workspacePlanningFlowPreview: 'Prévia',
  workspacePlanningFlowAdjusting: 'Ajustando',
  workspacePlanningFlowApproved: 'Aprovado',

  workspacePlanningFlowResponsible: 'Responsável',
  workspacePlanningFlowEditors: 'Editores',
  workspacePlanningFlowApprovers: 'Aprovadores',

  workspacePlanningFlow1: '1°',
  workspacePlanningFlow2: '2°',
  workspacePlanningFlow3: '3°',

  workspacePlanningFlowDeadline: 'Data limite',
  workspacePlanningFlowChooseDatePlaceholder: 'Escolha uma data',

  workspacePlanningFlowEditorDeadlineError: 'Data inferior ao último aprovado',
  workspacePlanningFlowApproverDeadlineError: 'Data inferior ao ajustado',
};
