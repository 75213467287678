import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div:last-of-type {
    height: 11.05rem;

    > div:last-of-type > div {
      height: 11.05rem;
    }
  }
`;
