import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type WorkspaceOverviewFrequency =
  | 'original'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'half-year'
  | 'yearly';

export type WorkspaceOverviewTransformation =
  | 'variationYoY'
  | 'variationPoP'
  | 'level';

export type WorkspaceOverviewForecast = 'original' | 'adjusted';

export type WorkspaceOverviewPeriod = [Date | null, Date | null];

export type WorkspaceOverviewInflation = 'real' | 'nominal';

type Data = {
  dates: string[];
  values: number[];
};

export type WorkspaceAggData = {
  hierarchy: string[];
  data: {
    historical: Data;
    forecast?: Data;
  };
}[];

export type StepStatus =
  | 'created'
  | 'baseline'
  | 'adjusting'
  | 'awaiting_approval'
  | 'approved';

export type Step = {
  number: number;
  name: string;
  status: StepStatus;
  approval_messages: string[];
  awaiting_approval_messages: string[];
  disapproval_messages: string[];
  selectedStatus: StepStatus;
};
export interface PayloadWorkspaceOverviewOptions {
  frequency: WorkspaceOverviewFrequency;
  inflation: WorkspaceOverviewInflation;
  transformation: WorkspaceOverviewTransformation;
  forecast: WorkspaceOverviewForecast;
  period: {
    original: WorkspaceOverviewPeriod;
    bimonthly: WorkspaceOverviewPeriod;
    quarterly: WorkspaceOverviewPeriod;
    'half-year': WorkspaceOverviewPeriod;
    yearly: WorkspaceOverviewPeriod;
  };
  editionModeEnabled: boolean;
  reviewModeEnabled: boolean;
  step: Step | null;
  showChangeHistory: boolean;
  lastLogTimeByStep: {
    [key: string]: number | null;
  };
  isDiscadingFirstStep: boolean;
  isEditingPlanningFlow: boolean;
  isCreatingStep: boolean;
}

const initialState: PayloadWorkspaceOverviewOptions = {
  frequency: 'original',
  inflation: 'nominal',
  transformation: 'level',
  forecast: 'adjusted',
  period: {
    original: [null, null],
    bimonthly: [null, null],
    quarterly: [null, null],
    'half-year': [null, null],
    yearly: [null, null],
  },
  editionModeEnabled: false,
  reviewModeEnabled: false,
  step: null,
  showChangeHistory: false,
  lastLogTimeByStep: {},
  isDiscadingFirstStep: false,
  isEditingPlanningFlow: false,
  isCreatingStep: false,
};

export const WorkspaceOverviewOptionsState = createSlice({
  name: 'workspaceOverviewOptions',
  initialState,
  reducers: {
    changeWorkspaceOverviewFrequency: (
      state,
      action: PayloadAction<WorkspaceOverviewFrequency>,
    ) => {
      state.frequency = action.payload;
    },
    changeWorkspaceOverviewTransformation: (
      state,
      action: PayloadAction<WorkspaceOverviewTransformation>,
    ) => {
      state.transformation = action.payload;
    },
    changeWorkspaceOverviewForecast: (
      state,
      action: PayloadAction<WorkspaceOverviewForecast>,
    ) => {
      state.forecast = action.payload;
    },
    changeWorkspaceOverviewOriginalPeriod: (
      state,
      action: PayloadAction<WorkspaceOverviewPeriod>,
    ) => {
      state.period.original = action.payload;
    },
    changeWorkspaceOverviewBimonthlyPeriod: (
      state,
      action: PayloadAction<WorkspaceOverviewPeriod>,
    ) => {
      state.period.bimonthly = action.payload;
    },
    changeWorkspaceOverviewQuarterlyPeriod: (
      state,
      action: PayloadAction<WorkspaceOverviewPeriod>,
    ) => {
      state.period.quarterly = action.payload;
    },
    changeWorkspaceOverviewHalfYearPeriod: (
      state,
      action: PayloadAction<WorkspaceOverviewPeriod>,
    ) => {
      state.period['half-year'] = action.payload;
    },
    changeWorkspaceOverviewYearlyPeriod: (
      state,
      action: PayloadAction<WorkspaceOverviewPeriod>,
    ) => {
      state.period.yearly = action.payload;
    },
    changeEditionModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.editionModeEnabled = action.payload;
    },
    changeReviewModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.reviewModeEnabled = action.payload;
    },
    changeWorkspaceOverviewInflation: (
      state,
      action: PayloadAction<WorkspaceOverviewInflation>,
    ) => {
      state.inflation = action.payload;
    },
    changeWorkspaceOverviewStep: (
      state,
      action: PayloadAction<Step | null>,
    ) => {
      state.step = action.payload;
      state.isDiscadingFirstStep = false;
    },
    changeShowChangeHistory: (state, action: PayloadAction<boolean>) => {
      state.showChangeHistory = action.payload;
    },
    changeLastLogTime: (
      state,
      action: PayloadAction<{ step: number; value: number | null }>,
    ) => {
      state.lastLogTimeByStep = {
        ...state.lastLogTimeByStep,
        [action.payload.step]: action.payload.value,
      };
    },
    changeIsDiscardingFirstStep: (state, action: PayloadAction<boolean>) => {
      state.isDiscadingFirstStep = action.payload;
    },
    changeIsEditingPlanningFlow: (state, action: PayloadAction<boolean>) => {
      state.isEditingPlanningFlow = action.payload;
    },
    changeIsCreatingStep: (state, action: PayloadAction<boolean>) => {
      state.isCreatingStep = action.payload;
    },
    resetWorkspaceOverviewPeriod: (state) => {
      state.period = {
        original: [null, null],
        bimonthly: [null, null],
        quarterly: [null, null],
        'half-year': [null, null],
        yearly: [null, null],
      };
    },
    resetWorkspaceOverviewOptions: (state) => {
      state.frequency = 'original';
      state.inflation = 'nominal';
      state.transformation = 'level';
      state.forecast = 'adjusted';
      state.period = {
        original: [null, null],
        bimonthly: [null, null],
        quarterly: [null, null],
        'half-year': [null, null],
        yearly: [null, null],
      };
      state.editionModeEnabled = false;
      state.reviewModeEnabled = false;
      state.step = null;
      state.lastLogTimeByStep = {};
      state.isDiscadingFirstStep = false;
      state.isEditingPlanningFlow = false;
      state.isCreatingStep = false;
    },
  },
});

export const {
  changeWorkspaceOverviewFrequency,
  changeWorkspaceOverviewTransformation,
  changeWorkspaceOverviewForecast,
  changeWorkspaceOverviewOriginalPeriod,
  changeWorkspaceOverviewBimonthlyPeriod,
  changeWorkspaceOverviewQuarterlyPeriod,
  changeWorkspaceOverviewHalfYearPeriod,
  changeWorkspaceOverviewYearlyPeriod,
  changeEditionModeEnabled,
  changeReviewModeEnabled,
  resetWorkspaceOverviewOptions,
  changeWorkspaceOverviewInflation,
  changeWorkspaceOverviewStep,
  changeShowChangeHistory,
  changeLastLogTime,
  changeIsDiscardingFirstStep,
  resetWorkspaceOverviewPeriod,
  changeIsEditingPlanningFlow,
  changeIsCreatingStep,
} = WorkspaceOverviewOptionsState.actions;
export default WorkspaceOverviewOptionsState.reducer;
