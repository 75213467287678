import React from 'react';

import { CellDefaultContainer } from './styles';

type CellDefaultProps = {
  name: string;
  columnName: string;
  value: string | number;
};

export const CellDefault: React.FC<CellDefaultProps> = ({
  name,
  columnName,
  value,
}) => (
  <CellDefaultContainer
    data-testid={`cell-${name}-${columnName}-${value}`}
    data-cy={`cell-${name}-${columnName}-${value}`}
  >
    {value}
  </CellDefaultContainer>
);
