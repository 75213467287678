import { Tr } from 'src/components/Table';
import styled from 'styled-components';

export const ContentProjectName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  p {
    word-break: break-all;
    white-space: normal;
    font-weight: 500;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const ButtonIcon = styled.button`
  svg {
    color: ${({ theme }) => theme.colors.gray4};

    polyline {
      stroke-width: 36px;
    }
  }
`;

export const TrContent = styled(Tr)`
  td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    height: 4.8125rem;
  }

  .projectFirstTd {
    border-right: ${({ theme }) => `1px solid ${theme.colors.gray2}`};
    padding: 0 0.5rem;
  }

  .projectLastTd {
    padding-right: 1rem;
  }

  .projectLastChildren {
    border-bottom: 0px;
  }

  .projectWithoutPaddingLeft {
    padding-left: 0;
  }

  .selectFontWeight500 {
    .model-update-name {
      font-weight: 500;
    }
    .model-update-date {
      font-weight: 500;
      text-wrap: nowrap;
    }
  }

  .placeholderOthers {
    .select__placeholder {
      color: ${({ theme }) => theme.colors.gray6} !important;
      font-weight: 500;
    }
  }

  .clearSelected {
    .select__option--is-selected {
      font-weight: 400 !important;
      color: ${({ theme }) => theme.colors.gray5} !important;
      background-color: transparent !important;
    }

    .select__option:hover {
      ///div contendo texto com hover
      background: ${({ theme }) => theme.colors.gray1} !important;
    }
  }
`;

export const ButtonSelectAllVariable = styled.button`
  font-weight: 500;
  cursor: pointer;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.gray6};

  &:disabled {
    color: ${({ theme }) => theme.colors.gray4};
    cursor: not-allowed;
  }
`;
