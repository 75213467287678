import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { RootState } from 'src/redux/store';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { RadioButton } from 'src/components/RadioButton';
import { Select } from 'src/components/Select';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import api from 'src/workspaces/service/api';

import { FilterContainer } from './styles';

export const MetricsByPeriod: React.FC = () => {
  const { t: translate } = useTranslation();
  const { workspace, auth } = useSelector((state: RootState) => state);

  const [monthsToDisplay, setMonthsToDisplay] = useState<number>(1);
  const [metric, setMetric] = useState('accuracy');

  type MetricsByPeriodData = {
    rmse: number;
    mpe: number;
    mape: number;
    accuracy: number;
    label_date: string;
    label: string;
  }[];

  const {
    data: metricsByPeriodData,
    isError,
    isFetching,
    isLoading,
  } = useQuery<MetricsByPeriodData[]>(
    [
      'workspace variable performance',
      'metrics by period',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
    ],
    async () => {
      const { data } = await api.get<MetricsByPeriodData[]>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/performances/metrics-by-period`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label,
    },
  );

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [];

    if (metricsByPeriodData) {
      const periodData =
        monthsToDisplay === 1
          ? metricsByPeriodData[0]
          : monthsToDisplay === 3
          ? metricsByPeriodData[1]
          : monthsToDisplay === 6
          ? metricsByPeriodData[2]
          : monthsToDisplay === 12 && metricsByPeriodData[3];

      if (periodData === false) {
        return seriesAux;
      }

      const adjustedData = periodData.map((data) => ({
        labelDate: data.label_date,
        label: data.label,
        value: metric.includes('mape')
          ? data.mape * 100
          : metric.includes('mpe')
          ? data.mpe * 100
          : metric.includes('rmse')
          ? data.rmse
          : data.accuracy * 100,
      }));

      // @ts-expect-error high charts não confia
      seriesAux.push({
        type: 'column',
        pointWidth: 50,
        pointPadding: 0,
        colors: adjustedData.map((_, index) => getChartColor(index)),
        colorByPoint: true,
        groupPadding: 0,
        data: adjustedData.map((info) => {
          const datas = info.labelDate.split(' - ');

          const initialDate = new Date(`${datas[0]}T00:00`);
          const finalDate = new Date(`${datas[1]}T00:00`);

          const initialDateFormatted = initialDate.toLocaleString(
            auth.user.language === 'pt-br' ? 'pt-BR' : 'en-US',
            {
              month: 'short',
              year: 'numeric',
            },
          );
          const finalDateFormatted = finalDate.toLocaleString(
            auth.user.language === 'pt-br' ? 'pt-BR' : 'en-US',
            {
              month: 'short',
              year: 'numeric',
            },
          );

          const label =
            finalDateFormatted === 'Invalid Date'
              ? initialDateFormatted
              : auth.user.language === 'pt-br'
              ? `${
                  initialDateFormatted.charAt(0).toUpperCase() +
                  initialDateFormatted.replace('. de ', ' ').slice(1)
                } - ${
                  finalDateFormatted.charAt(0).toUpperCase() +
                  finalDateFormatted.replace('. de ', ' ').slice(1)
                }`
              : `${initialDateFormatted} - ${finalDateFormatted}`;

          return {
            y: info.value,
            name: label,
            custom: {
              label: `${translate('version')} ${
                info.label.includes(' ') ? info.label.split(' ')[1] : ''
              }`,
              date: label,
              value: `${formatCompactNotation(info.value)}${
                metric !== 'rmse' ? '%' : ''
              }`,
            },
          };
        }),
      });
    }

    return seriesAux;
  }, [
    auth.user.language,
    metric,
    metricsByPeriodData,
    monthsToDisplay,
    translate,
  ]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
        type: 'column',
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        max: metric === 'accuracy' ? 100 : undefined,
        labels: {
          format: metric !== 'rmse' ? '{text}%' : '{text}',
          style: {
            fontSize: '12px',
            fontFamily: "'Inter', sans-serif",
          },
        },
      },
      xAxis: {
        type: 'category',
        minTickInterval: 0,
        labels: {
          autoRotation: [-45, -90],
        },
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          `<table><tr><th colspan="2">{point.custom.label}</th></tr>` +
          `<tr><td><b>${translate('period')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.date}</td></tr>' +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
    }),
    [translate, metric],
  );

  const selectMetric = (value: string) => {
    setMetric(value);
  };

  return (
    <div className="containerLinear">
      <Card
        textCard={translate('workspaceMetricsByPeriodTitle')}
        textDescription={translate('workspaceMetricsByPeriodDescription')}
      />
      <Select
        label={translate('workspaceMetricLabel')}
        options={['accuracy', 'mape', 'mpe', 'rmse'].map((option) => ({
          label:
            option === 'accuracy'
              ? translate('workspaceAccuracyLevel')
              : option.toUpperCase(),
          value: option,
        }))}
        onChange={(option: any) => selectMetric(option.value)}
        value={{
          label:
            metric !== 'accuracy'
              ? metric.toUpperCase()
              : translate('workspaceAccuracyLevel'),
          value: metric,
        }}
        style={{ marginBottom: '0.5rem', maxWidth: '12rem' }}
        isDisabled={isLoading || isError}
      />
      <FilterContainer>
        <p>{translate('workspaceMetricsFilterTitle')}</p>
        <div>
          <RadioButton
            label={translate('workspaceMetricsMonthsAhead')
              .replace('"X"', '1')
              .replace('months', 'month')
              .replace('meses', 'mês')}
            checked={monthsToDisplay === 1}
            onClick={() => setMonthsToDisplay(1)}
            disabled={
              isLoading || (metricsByPeriodData && !metricsByPeriodData[0])
            }
            data-testid="radio-1-months-ahead"
          />
          <RadioButton
            label={translate('workspaceMetricsMonthsAhead').replace('"X"', '3')}
            checked={monthsToDisplay === 3}
            onClick={() => setMonthsToDisplay(3)}
            disabled={
              isLoading || (metricsByPeriodData && !metricsByPeriodData[1])
            }
            data-testid="radio-3-months-ahead"
          />
          <RadioButton
            label={translate('workspaceMetricsMonthsAhead').replace('"X"', '6')}
            checked={monthsToDisplay === 6}
            onClick={() => setMonthsToDisplay(6)}
            disabled={
              isLoading || (metricsByPeriodData && !metricsByPeriodData[2])
            }
            data-testid="radio-6-months-ahead"
          />
          <RadioButton
            label={translate('workspaceMetricsMonthsAhead').replace(
              '"X"',
              '12',
            )}
            checked={monthsToDisplay === 12}
            onClick={() => setMonthsToDisplay(12)}
            disabled={
              isLoading || (metricsByPeriodData && !metricsByPeriodData[3])
            }
            data-testid="radio-12-months-ahead"
          />
        </div>
      </FilterContainer>

      {isError || !workspace ? (
        <ContainerMaintenance
          data-testid="metrics-by-period-error"
          content="chart"
        />
      ) : isLoading || isFetching || !metricsByPeriodData ? (
        <ContainerSkeleton data-testid="metrics-by-period-loading" />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy="chart-metrics-by-period"
          resizeWidthWithSidebar
          zoomLimited={false}
        />
      )}
    </div>
  );
};
