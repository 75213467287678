import styled from 'styled-components';

interface Content {
  hasSidebar: boolean;
  sidebarWidth: string;
}

export const Content = styled.div<Content>`
  width: ${({ hasSidebar, sidebarWidth }) =>
    hasSidebar ? `calc(100% - ${sidebarWidth})` : '100%'};
  height: 100%;

  background: ${({ theme }) => theme.colors.gray0};

  > div:first-child {
    padding: 1.5rem 1.5rem 0;
    @media (max-width: 400px) {
      padding: 1rem 1rem 0;
    }
  }
`;
