import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Card } from 'src/components/Card';
import { RootState } from 'src/redux/store';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { ContainerSkeleton } from 'src/components/ContainerSkeleton';
import { HCharts, HChartsOptions, HChartsSeries } from 'src/components/HCharts';
import { frequencyLatestData } from 'src/utils/charts/getLatestData';
import { ToggleSwitch } from 'src/components/ToggleSwitch';
import { getChartColor } from 'src/utils/colors/getChartColor';
import { formatCompactNotation } from 'src/utils/numbers/formatCompactNotation';
import { getLatestDataHistoricalFourMonthly } from 'src/models/TimeSeries/VariablePerformance/utils/getLatestDataHistoricalFourMonthly';
import api from 'src/workspaces/service/api';

import { ContainerToggle } from './styles';

interface RealVsForecastData {
  dates: string[];
  values: number[];
  label: string;
}

export const RealVsForecast: React.FC = () => {
  const { t: translate } = useTranslation();
  const { workspace } = useSelector((state: RootState) => state);

  const [isLatestDataActive, setIsLatestDataActive] = useState<boolean>(true);
  const [isLatestDataDisabled, setIsLatestDataDisabled] = useState(false);

  const {
    data: realVsForecastData,
    isError,
    isFetching,
    isLoading,
  } = useQuery<RealVsForecastData[]>(
    [
      'workspace variable performance',
      'real vs forecast',
      workspace.id,
      workspace.releaseSelected?.id,
      workspace.ySelected?.y_label,
    ],
    async () => {
      const { data } = await api.get<RealVsForecastData[]>(
        `/workspaces/${workspace.id}/releases/${workspace.releaseSelected?.id}/ys/${workspace.ySelected?.y_label}/performances/real-vs-forecast`,
      );

      return data;
    },
    {
      staleTime: 1000 * 60 * 20,
      enabled:
        !!workspace.id &&
        !!workspace.releaseSelected?.id &&
        !!workspace.ySelected?.y_label,
    },
  );

  useEffect(() => {
    if (realVsForecastData && workspace.frequency) {
      const dates = realVsForecastData[0].dates;

      const otherDates = realVsForecastData.flatMap((info) =>
        info.dates.filter((date) => !dates.includes(date)),
      );

      const quantity = dates.length + otherDates.length;

      if (quantity <= frequencyLatestData[workspace.frequency]) {
        setIsLatestDataDisabled(true);
        setIsLatestDataActive(false);
        return;
      }
    }

    if (isError) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    if (!workspace.ySelected?.y_label || !workspace.releaseSelected?.id) {
      return;
    }

    if (!workspace.frequency) {
      setIsLatestDataDisabled(true);
      setIsLatestDataActive(false);
      return;
    }

    setIsLatestDataDisabled(false);
  }, [realVsForecastData, isError, workspace]);

  function handleActiveLatestData(value: boolean) {
    setIsLatestDataActive(value);
  }

  const series: HChartsSeries[] = useMemo(() => {
    const seriesAux: HChartsSeries[] = [];

    if (isLatestDataActive && workspace.frequency && realVsForecastData) {
      const adjustedData = getLatestDataHistoricalFourMonthly(
        realVsForecastData,
        workspace.frequency,
      );

      adjustedData?.forEach((data, index) => {
        seriesAux.push({
          name: data.label.includes('Historical')
            ? translate('historicalData')
            : data.label.includes('Original')
            ? translate('originalForecast')
            : data.label.includes('Update') || data.label.includes('Release')
            ? `${data.label.replace(
                'Release',
                translate('version'),
              )} (${translate('Forecast')})`
            : '',
          type: 'line',
          marker: {
            symbol: 'circle',
          },
          color: getChartColor(index),
          dashStyle: data.label.includes('Historical Data') ? 'Solid' : 'Dash',
          data: data.dates.map((date, dateIndex) => ({
            x: new Date(`${date}T00:00`).getTime(),
            y: data.values[dateIndex] ?? null,
            custom: {
              value: formatCompactNotation(data.values[dateIndex]),
            },
          })),
        });
      });

      return seriesAux;
    }

    realVsForecastData?.forEach((data, index) => {
      seriesAux.push({
        name: data.label.includes('Historical')
          ? translate('historicalData')
          : data.label.includes('Original')
          ? translate('originalForecast')
          : data.label.includes('Update') || data.label.includes('Release')
          ? `${data.label.replace(
              'Release',
              translate('version'),
            )} (${translate('Forecast')})`
          : '',
        type: 'line',
        marker: {
          symbol: 'circle',
        },
        color: getChartColor(index),
        dashStyle: data.label.includes('Historical Data') ? 'Solid' : 'Dash',
        data: data.dates.map((date, dateIndex) => ({
          x: new Date(`${date}T00:00`).getTime(),
          y: data.values[dateIndex] ?? null,
          custom: {
            value: formatCompactNotation(data.values[dateIndex]),
          },
        })),
      });
    });

    return seriesAux;
  }, [isLatestDataActive, workspace.frequency, realVsForecastData, translate]);

  const options: HChartsOptions = useMemo(
    () => ({
      chart: {
        height: 300,
      },
      tooltip: {
        pointFormat:
          `<tr><td><b>${translate('date')}:</b> </td>` +
          `<td style="text-align: right">{point.x: ${
            workspace.frequency === 'annual' ? '%Y' : ' %d/%m/%Y'
          }}</td></tr>` +
          `<tr><td><b>${translate('value')}:</b> </td>` +
          '<td style="text-align: right">{point.custom.value}</td></tr>',
      },
    }),
    [workspace.frequency, translate],
  );

  return (
    <div className="containerLinear">
      <Card
        textCard={translate('workspaceRealVsForecastTitle')}
        textDescription={
          workspace.ySelected?.y_label &&
          translate('workspaceRealVsForecastDescription').replace(
            '"Y"',
            workspace.ySelected?.y_label,
          )
        }
      />
      <ContainerToggle>
        <ToggleSwitch
          label={translate('latestData')}
          checked={isLatestDataActive}
          onChange={(event) => handleActiveLatestData(event.target.checked)}
          disabled={isLatestDataDisabled}
          data-testid="real-forecast-toggle-latest-data"
        />
      </ContainerToggle>
      {isError || !workspace ? (
        <ContainerMaintenance
          content="chart"
          data-testid="chart-real-forecast-error"
        />
      ) : isLoading || isFetching || !realVsForecastData ? (
        <ContainerSkeleton data-testid="chart-real-forecast-loading" />
      ) : (
        <HCharts
          series={series}
          options={options}
          dataCy={
            isLatestDataActive
              ? 'chart-real-forecast-latest'
              : 'chart-real-forecast-complete'
          }
          resizeWidthWithSidebar
        />
      )}
    </div>
  );
};
