import React, { useEffect, useState } from 'react';

import { Trash } from 'phosphor-react';
import apiSeries from 'src/models/service/apiSeries';
import { useQuerySeriesById } from 'src/models/hooks/useQuerySeriesById';
import { Spinner } from 'src/components/Spinner';
import { queryClient } from 'src/service/queryClient';
import { createPortal } from 'react-dom';
import { FailedModal } from 'src/components/Modal/Failed';
import { useTranslation } from 'react-i18next';

import { DeleteButton, DeleteSerieContainer } from './styles';

type DeleteSerieProps = {
  serieName: string;
  serieId: string;
};

export const DeleteSerie: React.FC<DeleteSerieProps> = ({
  serieName,
  serieId,
}) => {
  const [deletionRequested, setDeletionRequested] = useState(false);
  const [failedModalVisible, setFailedModalVisible] = useState(false);

  const { t } = useTranslation();

  const { serieData, serieIsError, serieIsLoading } = useQuerySeriesById({
    id: serieId,
    enabled: deletionRequested,
    retryThreeSeconds: true,
  });

  useEffect(() => {
    if (deletionRequested) {
      if (serieData?.status === 'removed') {
        setDeletionRequested(false);
        queryClient.refetchQueries('series');
        queryClient.refetchQueries('serie-tags');
        queryClient.refetchQueries('series-grouped-by-tag');
      } else if (serieIsError) {
        setFailedModalVisible(true);
        setDeletionRequested(false);
      }
    }
  }, [deletionRequested, serieData, serieIsError]);

  async function handleDelete() {
    setDeletionRequested(true);
    try {
      await apiSeries.delete(`/series/${serieId}`);
    } catch {
      setFailedModalVisible(true);
      setDeletionRequested(false);
    }
  }

  function closeFailedModal() {
    setFailedModalVisible(false);
  }

  const isLoading =
    deletionRequested && (serieData?.status !== 'removed' || serieIsLoading);

  return (
    <DeleteSerieContainer>
      {!isLoading ? (
        <DeleteButton
          onClick={handleDelete}
          data-testid={`cell-${serieName}-delete`}
          data-cy={`cell-${serieName}-delete`}
        >
          <Trash size={20} />
        </DeleteButton>
      ) : (
        <Spinner
          spinnerType="primary"
          data-testid={`cell-${serieName}-delete-loading`}
          data-cy={`cell-${serieName}-delete-loading`}
        />
      )}

      {failedModalVisible &&
        createPortal(
          <FailedModal
            errorInfo={{
              title: t('mySeriesDeleteSerieErrorTitle'),
              description: t('mySeriesDeleteSerieErrorDescription', {
                serie: serieName,
              }),
            }}
            setVisible={closeFailedModal}
            visible
          />,
          document.body,
        )}
    </DeleteSerieContainer>
  );
};
