import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;

  > h3 {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: normal;

    color: ${({ theme }) => theme.colors.gray6};

    margin-bottom: 1.5rem;
  }

  > label {
    display: block;
    margin-bottom: 0.5rem;
  }

  > div + label {
    margin-top: 1.5rem;
  }

  input:disabled {
    cursor: not-allowed;
  }

  .react-datepicker__input-container {
    > div > div:last-of-type,
    input {
      background: white !important;
    }
  }
`;
