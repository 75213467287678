const menuSideBarENTranslations = {
  labelUserSelection: 'User Selection',
  labelAISelection: 'AI Selection',
  labelProjectOverview: 'Overview',
  labelOverallPerformance: 'Overall Performance',
  labelVariablePerformance: 'Variable Performance',
  labelModelExplorer: 'Model Explorer',
  labelModelSpecifics: 'Model Specifics',
  labelModelDispersion: 'Model Dispersion',
  labelCrossValidation: 'Cross Validation',
  labelForecastCombination: 'Forecast Combination',
  labelOverview: 'Overview',
  labelCorrelationMatrix: 'Correlation Matrix',
  labelVariableImportance: 'Variable Importance',
  labelDataView: 'Data View',
  labelTreeModels: 'Tree-based Model',
  labelRegularizedRegression: 'Regularized Regression',
  labelElementary: 'Elementary',
  labelArima: 'ARIMA',
  sideBarSelectAnotherProject: 'Click to select another project',
  sideBarDependentVariable: 'Dependent Variable',
  sideBarProject: 'PROJECT',
  sideBarVariable: 'RESULTS',
  sideBarUserSelection: 'MODEL PORTFOLIO',
  sideBarAISelectionDisabled: 'Sorry, this feature is currently unavailable.',
  sideBarCheckDependentVariableConfig: 'Check your configuration',
  sideBarFilterPlaceholder: 'Select...',
  sideBarDependentVariablePlaceholder: 'Select a variable...',
  sideBarSelectVariable: 'Variable',
};

export default menuSideBarENTranslations;
