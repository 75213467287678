export const WorkspaceNewReleaseConfigurationENUSTranslations = {
  workspaceNewReleaseConfigurationTitle: 'Configure the new version',
  workspaceNewReleaseConfigurationDescription:
    'Choose a name and description for your new version and configure the next steps.',
  workspaceNewReleaseConfigurationNamePlaceholder:
    'Enter the name of your new version',
  workspaceNewReleaseConfigurationDescriptionPlaceholder:
    'Enter a description for your new version',
  workspaceNewReleaseConfigurationSaveConfigurationButton: 'Save configuration',

  workspaceNewReleaseConfigurationChooseTemplate: 'Choose a template',
  workspaceNewReleaseConfigurationStandardTitle: 'Standard',
  workspaceNewReleaseConfigurationStandardDescription:
    'View your variables in one place and adjust values as needed.',
  workspaceNewReleaseConfigurationPlanningTitle: 'Planning',
  workspaceNewReleaseConfigurationPlanningDescription:
    'Create different planning cycles and gain more governance tools.',
  workspaceNewReleaseConfigurationSaveErrorTitle: 'Something went wrong',
  workspaceNewReleaseConfigurationSaveErrorDescription:
    'An error occurred while saving the configuration. Please try again later.',
  workspaceNewReleaseConfigurationStagingAreaErrorDescription:
    'An error occurred while loading the data. Please try again later.',
  workspaceNewReleaseConfigurationStandardListCollaborativeEnv:
    'Collaborative environment',
  workspaceNewReleaseConfigurationStandardListTable:
    'Categorized data organized in a smart table',
  workspaceNewReleaseConfigurationStandardListChart:
    'Visualize your data graphically and compare different variables',
  workspaceNewReleaseConfigurationPlanningListStep:
    'Track progress across different stages and steps',
  workspaceNewReleaseConfigurationPlanningListUserControl:
    'Establish governance with user control and approval',
  workspaceNewReleaseConfigurationStandardTemplate: 'Standard Template',
  workspaceNewReleaseConfigurationPlanningTemplate: 'Planning Template',
  workspaceNewReleaseConfigurationPlanningDisabledTooltip:
    'This template is only available for monthly data frequency.',

  workspaceNewReleaseConfigurationPlanningSettings: 'Planning Settings',
  workspaceNewReleaseConfigurationPlanningHorizon: 'Planning horizon',
  workspaceNewReleaseConfigurationConfigureStage: 'Configure the stages',
  workspaceNewReleaseConfigurationPlanningHorizonPlaceholder: 'Choose a date',
};
