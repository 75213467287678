import React from 'react';

import { CaretDown, GitFork, Info } from 'phosphor-react';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { ptBR, enUS } from 'date-fns/locale';

import { Container } from './styles';
import { VersionInfoProps } from './types';

export const VersionInfo: React.FC<VersionInfoProps> = ({
  versionName,
  versionDescription,
  horizonDates,
  showOpenSidebarButton,
  handleOpenSidebar,
}) => {
  const {
    auth: {
      user: { language },
    },
  } = useSelector((state: RootState) => state);

  const formatDate = (date: string) =>
    format(new Date(date.replace(':00Z', '')), 'LLL/yy', {
      locale: language === 'pt-br' ? ptBR : enUS,
    });

  return (
    <Container
      type="button"
      onClick={handleOpenSidebar}
      data-testid="reopen-update-history-button"
      disabled={!showOpenSidebarButton}
    >
      <GitFork />

      <div>
        <div>
          <p
            data-testid="version-viewing"
            data-tooltip-content={versionName.length > 20 ? versionName : ''}
            data-tooltip-id="tooltip-selected-release"
          >
            {versionName.length > 20
              ? `${versionName.slice(0, 17)}...`
              : versionName}
          </p>

          {versionDescription && (
            <Info
              data-tooltip-content={versionDescription}
              data-tooltip-id="tooltip-selected-release"
              data-testid="icon-information"
            />
          )}
        </div>

        {horizonDates?.start && horizonDates?.end && (
          <p data-testid="text-horizon-dates">
            {formatDate(horizonDates.start)} - {formatDate(horizonDates.end)}
          </p>
        )}
      </div>

      {showOpenSidebarButton && <CaretDown data-testid="icon-open-sidebar" />}

      <Tooltip
        id="tooltip-selected-release"
        className="newTooltipTheme"
        place="bottom"
      />
    </Container>
  );
};
