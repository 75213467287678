import { useEffect, useState } from 'react';

type ErrorProps = {
  message: string;
  quantityLetters: number;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useSearchDebounce() {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<ErrorProps>();
  const [searchTimer, setSearchTimer] = useState(1000);
  const [timeOutActive, setTimeOutActive] = useState(false);
  const [requestAllowed, setRequestAllowed] = useState(true);
  const [lastSearch, setLastSearch] = useState('');

  useEffect(() => {
    if (timeOutActive && searchValue.length >= 3) {
      setTimeout(() => {
        if (searchTimer > 0) {
          setSearchTimer(searchTimer - 1000);
        } else {
          setTimeOutActive(false);
        }
      }, 1000);
    } else {
      searchTimer === 0 && setRequestAllowed(true);
    }
  }, [searchTimer, searchValue, timeOutActive]);

  function isSearchValid(value: string) {
    setSearchValue(value);

    if (value.length > 50) {
      setSearchError({
        message: 'searchMaxCharactersError',
        quantityLetters: 50,
      });
      return false;
    }

    if (value.length < 3 && value.length > 0) {
      setSearchError({
        message: 'searchMinCharactersError',
        quantityLetters: 3,
      });
      return false;
    }

    setSearchError({
      message: '',
      quantityLetters: 0,
    });

    if (value !== searchValue) {
      setSearchTimer(1000);
      setTimeOutActive(true);

      return true;
    }

    return false;
  }

  function searchCompleted() {
    setRequestAllowed(false);
    setTimeOutActive(false);
    setSearchTimer(1000);
    setLastSearch(searchValue);
  }

  return {
    searchValue,
    setSearchValue,
    requestAllowed,
    lastSearch,
    searchError,
    setSearchError,
    setRequestAllowed,
    isSearchValid,
    searchCompleted,
  };
}
