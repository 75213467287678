import React from 'react';

import { useTranslation } from 'react-i18next';

import { ContainerFakeTable, UploadYourSeriesMessageContainer } from './styles';

export const UploadYourSeriesMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <UploadYourSeriesMessageContainer data-cy="upload-your-series">
      <ContainerFakeTable data-cy="container-fake-table">
        <table>
          <thead>
            <tr>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 3 }).map((_, index) => (
              <tr key={`fake-table-body-tr-${index.toString()}`}>
                <td>
                  {t('serie')} {index + 1}
                </td>
                <td>
                  <div>
                    <span>Tag</span>
                  </div>
                </td>
              </tr>
            ))}

            <tr>
              <td> </td>
            </tr>
          </tbody>
        </table>
        <span data-cy="upload-your-series-message">
          {t('mySeriesUploadYourSeries')}
        </span>
      </ContainerFakeTable>
      <h3>{t('mySeriesYouHaveNotUploaded')}</h3>
      <p>{t('mySeriesClickTheButtonAbove')}</p>
    </UploadYourSeriesMessageContainer>
  );
};
