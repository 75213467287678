import styled from 'styled-components';

export const DeleteSerieContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const DeleteButton = styled.button`
  display: flex;
  color: ${({ theme }) => theme.colors.gray4};

  transition: 0.2s color ease-in-out;

  &:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.gray5};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.colors.gray3};
  }
`;
