import { useQuery } from 'react-query';
import ms from 'ms';

import apiSeries from '../service/apiSeries';
import { SerieData } from './useQuerySeries';

type useQuerySerieByIdProps = {
  id: string;
  enabled: boolean;
  retryThreeSeconds: boolean;
};

type useQuerySerieResponse = {
  serieData: SerieData | undefined;
  serieIsLoading: boolean;
  serieIsError: boolean;
};

export const useQuerySeriesById = ({
  id,
  enabled,
  retryThreeSeconds,
}: useQuerySerieByIdProps): useQuerySerieResponse => {
  const { data, isLoading, isFetching, isError } = useQuery<SerieData>(
    ['serie', id],
    async () => {
      const response = await apiSeries.get<SerieData>(`/series/${id}`);

      return response.data;
    },
    {
      enabled,
      refetchInterval: retryThreeSeconds ? ms('3 sec') : undefined,
    },
  );

  return {
    serieData: data,
    serieIsLoading: isLoading || isFetching,
    serieIsError: isError,
  };
};
