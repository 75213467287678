import {
  WorkspaceOverviewForecast,
  WorkspaceOverviewFrequency,
  WorkspaceOverviewInflation,
  WorkspaceOverviewTransformation,
} from 'src/workspaces/redux/reducers/WorkspaceOverviewOptions';

export const getSeriesQueryKey = (
  workspaceId: string,
  releaseId: string,
  serie: string,
  frequency: WorkspaceOverviewFrequency,
  transformation: WorkspaceOverviewTransformation,
  inflation: WorkspaceOverviewInflation,
  originalOrAdjusted: WorkspaceOverviewForecast,
  stepNumber = 1,
  yType?: string,
): (string | number)[] => {
  const key = [
    'workspace',
    'series data',
    workspaceId,
    releaseId,
    serie,
    originalOrAdjusted,
    stepNumber,
    frequency,
    transformation,
    inflation,
  ];

  if (yType) {
    key.push(yType);
  }

  return key;
};
