import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CheckBox } from 'src/components/CheckBox';
import { arrayIcon } from 'src/utils/icons/handleProjectIcon';
import { transformUppercaseFirstLetter } from 'src/utils/strings/transformUppercaseFirstLetter';

import {
  ContainerFakeTable,
  UploadProjectSerieMessageContainer,
} from './styles';

type Props = {
  type: 'project' | 'serie';
};

export const UploadProjectSerieMessage: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();

  const title =
    type === 'serie'
      ? t('mySeriesUploadYourSeries')
      : t('createWorkspaceCreateYourProject');

  const description =
    type === 'serie'
      ? t('mySeriesYouHaveNotUploaded')
      : t('createWorkspaceYouHaveNotCreatedProject');

  const messageCreateOrUpload =
    type === 'serie'
      ? t('createWorkspaceRedirectedUploadYourSeries')
      : t('createWorkspaceStartCreatingYourProject');

  const link =
    type === 'serie' ? '/models/series' : '/models/projects/new/time-series';

  return (
    <UploadProjectSerieMessageContainer data-cy="upload-your-series">
      <ContainerFakeTable type={type} data-cy="container-fake-table">
        <table>
          <thead>
            <tr>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: 2 }).map((_, index) => (
              <tr key={`fake-table-body-tr-${index.toString()}`}>
                <td>
                  <div>
                    <CheckBox disabled />
                    <div>
                      {type === 'project' && (
                        <img src={arrayIcon[index]} alt="" />
                      )}
                      <div>
                        <h4>
                          {type === 'serie'
                            ? 'Tag'
                            : transformUppercaseFirstLetter(t('project'))}
                        </h4>
                        <p>{type === 'serie' ? t('serie') : t('variable')}</p>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}

            <tr>
              <td>
                <div />
              </td>
            </tr>
          </tbody>
        </table>
        <span data-cy="upload-your-series-message">{title}</span>
      </ContainerFakeTable>
      <h3>{description}</h3>
      <p>
        {transformUppercaseFirstLetter(t('click'))}{' '}
        <Link to={link}>{t('here')}</Link> {messageCreateOrUpload}
      </p>
    </UploadProjectSerieMessageContainer>
  );
};
