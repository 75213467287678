import styled from 'styled-components';

export const WorkspaceVariablesContent = styled.div`
  display: flex;
  flex-direction: column;

  height: calc(100% - 8rem);

  table {
    overflow-y: auto;
  }

  thead th {
    position: sticky;
    top: 0;

    z-index: 1;
  }

  th {
    text-align: start;
  }

  .container-variables-table {
    overflow-y: auto;
  }
`;

export const EditVariablesButton = styled.div`
  position: absolute;

  top: 1.5rem;
  right: 1.5rem;
`;

export const NameTd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  img {
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 5px;
  }

  p {
    text-align: start;

    color: ${({ theme }) => theme.colors.gray6};
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 150%;

    white-space: pre-wrap;
  }
`;

type TagTdProps = {
  tagColor: string;
};

export const TagTd = styled.h4<TagTdProps>`
  width: fit-content;

  padding: 0.25rem 0.75rem;
  background: ${({ tagColor }) => tagColor}1E;
  border-radius: 9999px;

  font-weight: 500;
  font-size: 0.875rem;

  color: ${({ tagColor }) => tagColor};
`;

export const VariableTd = styled.p`
  color: ${({ theme }) => theme.colors.gray7};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 150%;

  text-align: start;
`;
