export const WorkspacePlanningFlowENUSTranslations = {
  workspacePlanningFlowStage: 'Stage',

  workspacePlanningFlowPreview: 'Preview',
  workspacePlanningFlowAdjusting: 'Adjusting',
  workspacePlanningFlowApproved: 'Approved',

  workspacePlanningFlowResponsible: 'Responsible',
  workspacePlanningFlowEditors: 'Editors',
  workspacePlanningFlowApprovers: 'Approvers',

  workspacePlanningFlow1: '1st',
  workspacePlanningFlow2: '2nd',
  workspacePlanningFlow3: '3rd',

  workspacePlanningFlowDeadline: 'Deadline',
  workspacePlanningFlowChooseDatePlaceholder: 'Choose a date',

  workspacePlanningFlowEditorDeadlineError:
    'Date earlier than the last approved',
  workspacePlanningFlowApproverDeadlineError: 'Date earlier than adjusted',
};
