import React, { useState, useEffect, useContext, useMemo } from 'react';

import { Td } from 'src/components/Table';
import { CaretDown, CaretUp, Warning, X } from 'phosphor-react';
import light from 'src/styles/themes/light';
import { CheckBox } from 'src/components/CheckBox';
import { useTranslation } from 'react-i18next';
import {
  ExternalSeries,
  WorkspaceConfigContext,
} from 'src/workspaces/contexts/WorkspaceConfigContext';

import { CheckBoxInflate, WarningIcon } from '../Row/styles';
import {
  ButtonIcon,
  ButtonSelectAllVariable,
  ContentProjectName,
  TrContent,
} from '../ProjectRow/styles';
import {
  GetValueKey,
  SetValue,
  YsDisabled,
  YsError,
  YsInflate,
  YsSelected,
} from '../../Step3/types';
import { SerieRow } from '../SerieRow';
import { MySeriesMessage } from './styles';

type Props = {
  externalSeries: ExternalSeries;
  showOriginalYName: boolean;
  getValue: (key: GetValueKey) => SetValue[GetValueKey];
  setValue: (key: GetValueKey, value: SetValue[GetValueKey]) => void;
  validateNewLabel: (
    projectId: string,
    variableId: string,
    newLabel: string,
  ) => void;
};

export const TagRow: React.FC<Props> = ({
  externalSeries,
  showOriginalYName,
  getValue,
  setValue,
  validateNewLabel,
}) => {
  const [seeAllSeries, setSeeAllSeries] = useState(false);

  const { frequency } = useContext(WorkspaceConfigContext);

  const { t: translate } = useTranslation();

  const ysKey = getValue('ysKey') as string[];
  const ysSelected = getValue('ysSelected') as YsSelected;

  const ysInflate = getValue('ysInflate') as YsInflate;
  const ysError = getValue('ysError') as YsError;
  const ysDisabled = getValue('ysDisabled') as YsDisabled;

  const tagSeries = useMemo(
    () => ysKey.filter((key) => key.startsWith(`${externalSeries.tag}-`)),
    [externalSeries.tag, ysKey],
  );

  const tagSeriesEnabled = useMemo(
    () => tagSeries.filter((key) => !ysDisabled[key]),
    [tagSeries, ysDisabled],
  );

  const tagSeriesSelected = useMemo(
    () => tagSeries.filter((key) => ysSelected[key]),
    [tagSeries, ysSelected],
  );

  const tagError = useMemo(
    () => ysError[tagSeriesSelected.find((key) => ysError[key]) ?? ''],
    [tagSeriesSelected, ysError],
  );

  useEffect(() => {
    const lastTr = Array.from(
      document.querySelectorAll(
        '#workspace-table-container > table > tbody > tr',
      ),
    )
      .reverse()
      .find((tr) => getComputedStyle(tr).display !== 'none');

    const workspaceContainerHeight =
      document.getElementById('workspace-table-container')?.clientHeight ?? 0;

    const tableHeight =
      document.querySelector('#workspace-table-container > table')
        ?.clientHeight ?? 0;

    if (tableHeight >= workspaceContainerHeight) {
      const lastTableCells = lastTr?.childNodes ?? [];

      lastTableCells.forEach((td) => {
        //@ts-expect-error:ignora
        td.style.borderBottom = 'unset';
      });
    }

    return () => {
      document
        .querySelectorAll('#workspace-table-container > table > tbody > tr')
        .forEach((tr) =>
          tr.childNodes.forEach((td) => {
            //@ts-expect-error:ignora
            td.style.borderBottom = `1px solid ${light.colors.gray2}`;
          }),
        );
    };
  }, [seeAllSeries, tagSeries]);

  function handleSeeSerie() {
    setSeeAllSeries((state) => !state);
  }

  const defaultInflate =
    tagSeriesSelected.length &&
    tagSeriesSelected.every(
      (key) => ysInflate[key] === ysInflate[tagSeriesSelected[0]],
    )
      ? ysInflate[tagSeriesSelected[0]]
      : undefined;

  function handleSelectInflateSeries(value: boolean) {
    const updatedYsInflate = { ...ysInflate };

    tagSeriesEnabled.forEach((key) => {
      updatedYsInflate[key] = value;
    });

    setValue('ysInflate', updatedYsInflate);
  }

  function handleSelectSeries(value: boolean) {
    const updatedYsSelected = { ...ysSelected };

    tagSeriesEnabled.forEach((key) => {
      updatedYsSelected[key] = value;
    });

    setValue('ysSelected', updatedYsSelected);
  }

  function isRepeatedVariableLabelOrNameError(err: string) {
    return err === 'createWorkspaceRepeatedVariable';
  }

  const hasInflateYs = frequency === 'monthly';

  return (
    <>
      <TrContent>
        <Td className="projectFirstTd">
          <ContentProjectName>
            <p data-testid={`tag-name-tag-${externalSeries.tag}`}>
              {externalSeries.tag}
            </p>
            <div>
              {tagError &&
                (!isRepeatedVariableLabelOrNameError(tagError ?? '') ? (
                  <WarningIcon
                    data-testid={`rename-variable-error-tag-${externalSeries.tag}`}
                    color={light.colors.red2}
                  >
                    <X weight="bold" size="0.875rem" />
                  </WarningIcon>
                ) : (
                  <WarningIcon
                    data-tooltip-id="config-workspace-tooltip"
                    data-testid={`rename-variable-warning-tag-${externalSeries.tag}`}
                    color={light.colors.secondary}
                  >
                    <Warning weight="fill" size="1rem" />
                  </WarningIcon>
                ))}
              <MySeriesMessage>
                {translate('createWorkspaceMySeriesLabel')}
              </MySeriesMessage>

              <ButtonIcon
                type="button"
                onClick={handleSeeSerie}
                data-testid={`button-expand-tag-${externalSeries.tag}`}
              >
                {seeAllSeries ? (
                  <CaretUp weight="bold" size="1rem" />
                ) : (
                  <CaretDown weight="bold" size="1rem" />
                )}
              </ButtonIcon>
            </div>
          </ContentProjectName>
        </Td>
        <Td>
          <CheckBox
            onChange={({ target: { checked } }) => {
              handleSelectSeries(checked);
            }}
            checked={tagSeriesEnabled.length === tagSeriesSelected.length}
            disabled={!tagSeriesEnabled.length}
            data-testid={`checkbox-tag-${externalSeries.tag}`}
          />
        </Td>
        <Td className="projectWithoutPaddingLeft">
          <ButtonSelectAllVariable
            disabled={!tagSeriesEnabled.length}
            onClick={() =>
              handleSelectSeries(
                !(tagSeriesEnabled.length === tagSeriesSelected.length),
              )
            }
            data-testid={`button-select-all-tag-${externalSeries.tag}`}
          >
            {translate('createWorkspaceSelectAll')}
          </ButtonSelectAllVariable>
        </Td>
        {showOriginalYName && <Td />}
        <Td />
        <Td />
        {hasInflateYs && (
          <Td className="projectLastTd">
            <CheckBoxInflate
              label=""
              onChange={({ target }) =>
                handleSelectInflateSeries(target.checked)
              }
              checked={defaultInflate}
              disabled={!tagSeriesSelected.length}
              data-cy={`checkbox-inflate-serie-tag-${externalSeries.tag}`}
              data-testid={`checkbox-inflate-serie-tag-${externalSeries.tag}`}
            />
          </Td>
        )}
      </TrContent>
      {seeAllSeries &&
        tagSeries.map((key) => (
          <SerieRow
            key={`row-${key}`}
            yKey={key}
            isVisible={seeAllSeries}
            tag={externalSeries.tag}
            getValue={getValue}
            setValue={setValue}
            validateNewLabel={validateNewLabel}
            showOriginalYName={showOriginalYName}
          />
        ))}
    </>
  );
};
