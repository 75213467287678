import React, { useContext, useEffect, useRef, useState } from 'react';

import { MagnifyingGlass } from 'phosphor-react';
import { AutoComplete } from 'src/components/AutoComplete';
import { useSearchDebounce } from 'src/hooks/useSearchDebounce';
import { CheckBox } from 'src/components/CheckBox';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import {
  storageSerieNamesGet,
  storageSerieNamesSave,
} from 'src/models/storage/storageSerieNames';
import { getColorByFirstLetter } from 'src/utils/colors/getColorByFirstLetter';
import { ContainerMaintenance } from 'src/components/ContainerMaintenance';
import { Status } from 'src/components/Status';
import { useTranslation } from 'react-i18next';
import { storageProjectNamesRemove } from 'src/models/storage/storageProjectNames';
import { useQuerySeriesGroupedByTag } from 'src/workspaces/hooks/useQuerySeriesGroupedByTag';
import { WorkspaceConfigContext } from 'src/workspaces/contexts/WorkspaceConfigContext';

import { Content, SelectSeriesContainer, SerieCard } from './styles';
import { SelectSeriesProps, SeriesGroupedByTagShow } from './types';
import { LoadingSerieCard } from '../LoadingSerieCard';
import { UploadProjectSerieMessage } from '../UploadProjectSerieMessage';

const LIMIT = 8;

export const SelectSeries: React.FC<SelectSeriesProps> = ({
  selectedSeriesTag,
  addOrRemoveSelectSeriesTag,
}) => {
  const [oldSerieNames, setOldSerieNames] = useState<string[]>([]);

  const { frequency, isEdition } = useContext(WorkspaceConfigContext);

  const contentRef = useRef<HTMLDivElement>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();

  const {
    searchValue,
    requestAllowed,
    searchError,
    isSearchValid,
    searchCompleted,
    lastSearch,
  } = useSearchDebounce();

  const {
    seriesGroupedByTagData,
    seriesGroupedByTagIsLoading,
    seriesGroupedByTagIsError,
  } = useQuerySeriesGroupedByTag({
    searchValue,
    requestAllowed,
    onSuccess(data) {
      if (searchValue && data?.total && user.email) {
        storageSerieNamesSave(user.email, searchValue);
        setOldSerieNames(storageSerieNamesGet(user.email));
      }
    },
  });

  useEffect(() => {
    searchCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesGroupedByTagData]);

  useEffect(() => {
    if (user.email) {
      setOldSerieNames(storageSerieNamesGet(user.email));
    }
  }, [user.email]);

  function handleSearchSerie(value: string) {
    isSearchValid(value);
  }

  const oldSerieNamesShow = oldSerieNames.filter(
    (oldSerieNamesAux) =>
      searchValue !== oldSerieNamesAux &&
      oldSerieNamesAux.includes(searchValue),
  );

  const listSeriesTagShow: SeriesGroupedByTagShow[] = [];

  Object.keys(seriesGroupedByTagData || {}).forEach((key) => {
    const color = getColorByFirstLetter(key);

    const isStatusSuccess = seriesGroupedByTagData![key].some(
      (serie) => serie.status === 'success',
    );

    const isSelected = selectedSeriesTag.some(
      (selectedSerie) => selectedSerie.tag === key,
    );

    const seriesWillShow = seriesGroupedByTagData![key]
      .slice(0, 3)
      .map((serie) => serie.name);

    const seriesText = seriesWillShow.toString().replaceAll(',', ', ');

    const seriesRemaining =
      seriesGroupedByTagData![key].length - seriesWillShow.length;

    let seriesRemainingTooltip: string | null = null;

    let seriesRemainingText: string | null = null;

    if (seriesRemaining > 0) {
      seriesRemainingText = ` + ${seriesRemaining} ${t('series')}`;
      seriesRemainingTooltip = seriesGroupedByTagData![key]
        .slice(3)
        .map((y) => y.name)
        .toString();
    }

    const isDifferentFrequency = frequency
      ? seriesGroupedByTagData![key][0].frequency !== frequency
      : false;

    let errorMessage: string | null = null;

    if (isDifferentFrequency) {
      if (isEdition) {
        errorMessage = t(
          'selectSeriesWorkspaceIsDisabledDifferentFrequencyOfWorkspace',
        );
      } else {
        errorMessage = t('selectSeriesWorkspaceIsDisabledDifferentFrequency');
      }
    }

    const serieShow: SeriesGroupedByTagShow = {
      tag: key,
      series: seriesGroupedByTagData![key],
      color,
      seriesText,
      isSelected,
      seriesRemainingText,
      seriesRemainingTooltip,
      errorMessage,
      isDisabled: !isStatusSuccess || !!errorMessage,
    };

    listSeriesTagShow.push(serieShow);
  });

  const paddingRight =
    contentRef.current &&
    contentRef.current?.scrollHeight > contentRef.current?.clientHeight
      ? '0.5rem'
      : '0rem';

  const total = Object.keys(seriesGroupedByTagData || {}).length;

  const noSeries = searchValue.length === 0 && listSeriesTagShow.length === 0;

  return (
    <SelectSeriesContainer>
      <AutoComplete
        icon={<MagnifyingGlass size="1.25rem" />}
        label={t('createWorkspaceMySeriesLabel')}
        placeholder={t('mySeriesSearchPlaceholder')}
        testid="searchSerie"
        error={
          searchError?.quantityLetters === 3
            ? t(searchError.message).replace('XX', '3')
            : searchError?.quantityLetters === 50
            ? t(searchError.message).replace('XX', '50')
            : undefined
        }
        onChange={(value) => {
          handleSearchSerie(value);
        }}
        disabled={noSeries}
        value={searchValue}
        options={oldSerieNamesShow}
        onDelete={(index) => {
          if (oldSerieNames[index] && user.email) {
            storageProjectNamesRemove(user.email, oldSerieNames[index]);
            setOldSerieNames(storageSerieNamesGet(user.email));
          }
        }}
      />
      <Content
        data-testid="workspace-content-series"
        ref={contentRef}
        style={{ paddingRight }}
      >
        {seriesGroupedByTagIsError ? (
          <ContainerMaintenance
            content="series"
            text={t('mySeriesFetchError')}
          />
        ) : seriesGroupedByTagIsLoading || !seriesGroupedByTagData ? (
          Array.from({ length: LIMIT }).map((_, index) => (
            <LoadingSerieCard index={index} key={`card-loading-${index + 1}`} />
          ))
        ) : total === 0 && searchValue.length >= 1 ? (
          // eslint-disable-next-line react/jsx-indent
          <Status
            type="noSearchResults"
            title={`${t('mySeriesSearchFail')} "${lastSearch}".`}
          />
        ) : noSeries ? (
          <UploadProjectSerieMessage type="serie" />
        ) : (
          <>
            {listSeriesTagShow?.map((seriesTag) => (
              <SerieCard
                tagColor={seriesTag.color}
                key={seriesTag.tag}
                onClick={() => addOrRemoveSelectSeriesTag(seriesTag)}
                data-testid={`card-serie-${seriesTag.tag}`}
                data-tooltip-id="workspace-select-project"
                data-tooltip-html={seriesTag.errorMessage}
                disabled={seriesTag.isDisabled}
              >
                <CheckBox checked={seriesTag.isSelected} />
                <div>
                  <h4>{seriesTag.tag}</h4>
                  <p>
                    {seriesTag.seriesText}
                    <span
                      data-tooltip-id="workspace-projects-dependent-variables"
                      data-tooltip-content={seriesTag.seriesRemainingTooltip}
                    >
                      {seriesTag.seriesRemainingText}
                    </span>
                  </p>
                </div>
              </SerieCard>
            ))}
          </>
        )}
      </Content>
    </SelectSeriesContainer>
  );
};
