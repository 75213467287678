import React, { memo } from 'react';

import { ContainerSkeleton } from 'src/components/ContainerSkeleton';

import { LoadingCardContainer } from './styles';

export const LoadingSerieCard = memo(
  ({ index }: { index: number }) => {
    const titleWidth = `${Math.floor(Math.random() * 21) + 20}%`;
    const variablesWidth = `${Math.floor(Math.random() * 31) + 30}%`;

    return (
      <LoadingCardContainer data-testid={`card-serie-loading-${index + 1}`}>
        <ContainerSkeleton withLoading={false} />
        <div>
          <ContainerSkeleton
            withLoading={false}
            style={{ width: titleWidth }}
          />
          <ContainerSkeleton
            withLoading={false}
            style={{ width: variablesWidth }}
          />
        </div>
      </LoadingCardContainer>
    );
  },
  (prevProps, nextProps) => prevProps.index === nextProps.index,
);
