import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PayloadWorkspaceHomeOptions {
  search: string;
  page: number | null;
}

const initialState: PayloadWorkspaceHomeOptions = {
  search: '',
  page: null,
};

export const WorkspaceHomeOptions = createSlice({
  name: 'workspaceHomeOptions',
  initialState,
  reducers: {
    saveWorkspaceHomeOptions: (
      state,
      action: PayloadAction<PayloadWorkspaceHomeOptions>,
    ) => {
      state.page = action.payload.page;
      state.search = action.payload.search;
    },
    clearWorkspaceHomeOptions: (state) => {
      state.page = null;
      state.search = '';
    },
  },
});

export const { clearWorkspaceHomeOptions, saveWorkspaceHomeOptions } =
  WorkspaceHomeOptions.actions;

export default WorkspaceHomeOptions.reducer;
